import React from 'react'

import '../../../../assets/css/plant_ware.css';

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from 'jquery';

import useFetch from "react-fetch-hook";

export default function Plant_warehouse() {



    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const plnt_ware_hd = useRef(null);
    const plnt_ware_p1 = useRef(null);
    const plnt_ware_p2 = useRef(null);
    const plnt_ware_p3 = useRef(null);
    const plnt_ware_p4 = useRef(null);
    const plnt_ware_p5 = useRef(null);
    const plnt_ware_img1 = useRef(null);
    const plnt_ware_img2 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".plnt_ware_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".plnt_ware_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".plnt_ware_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".plnt_ware_p1",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".plnt_ware_p2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".plnt_ware_p2",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".plnt_ware_p3", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".plnt_ware_p3",
                markers: false
            }
        });

        gsap.fromTo(".plnt_ware_p4", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".plnt_ware_p4",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".plnt_ware_p5", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".plnt_ware_p5",
                markers: false
            }
        });


        gsap.fromTo(".plnt_ware_img1", {
            x: 0
        }, {
            x: 550,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".plnt_ware_img1",
                markers: false
            }
        });

        gsap.fromTo(".plnt_ware_img2", {
            x: 0
        }, {
            x: 550,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".plnt_ware_img2",
                markers: false
            }
        });

        $(".inplant_content").hide();
        $(".inplant").on("click", function () {
            var txt = $(".inplant_content").is(':visible') ? 'Read More ' : 'Read Less ' ;
            $(".inplant").text(txt);
            $('.inplant_content').slideToggle(200);
            var btn_txt = document.getElementById("inplnt_btn").text;
            if(btn_txt === 'Read More '){
                console.log('Read More');  
            }
            else if(btn_txt === 'Read Less '){
                console.log('Read Less'); 
            }
        });

        $(".agile_content").hide();
        $(".agile").on("click", function () {
            var txt = $(".agile_content").is(':visible') ? 'Read More' : 'Read Less';
            $(".agile").text(txt);
            $('.agile_content').slideToggle(200);
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/warehousing_page`
    );

    return (
        <>
            <div className='plant_warehouse SO_ware mob_padding_LR tab_padding_LR' id="inplantyard">

                {data && data.map(({ acf }) => (
                    <div className='cstm_container'>

                        <div className='row'>
                            <div className='col-lg-7 col-md-12 col-12'>

                                {acf.in_plant_warehouse.map((ware_inplan) =>
                                    <div className='so_col1_main plnt_inner_col1'>

                                        <div className='hd_dv'>
                                            <p className='so_col_title plnt_ware_hd ' ref={plnt_ware_hd}>{ware_inplan.in_plant_warehouse_hd}</p>
                                        </div>

                                        <p className='plant_col_para_1 plnt_ware_p1 in_plant_sub_hd' ref={plnt_ware_p1}>{ware_inplan.in_plant_warehouse_sub_hd}</p>
                                        <p className='plant_col_para plnt_ware_p1' ref={plnt_ware_p1}>{ware_inplan.in_pant_warehouse_description}</p>

                                        <div className='inplant_content plnt_ware_p1' ref={plnt_ware_p1} dangerouslySetInnerHTML={{ __html: ware_inplan.in_plant_warehouse_description_read_more_text }}></div>
                                        <a className="inplant plnt_ware_p1 read_more_btn rm_blue" id='inplnt_btn' data-content="toggle-text" ref={plnt_ware_p1}>Read More</a>

                                    </div>
                                )}
                            </div>
                            <div className='col-lg-5 col-md-12 col-12'>
                                {acf.in_plant_warehouse.map((ware_inplan) =>
                                    <div className='so_col1 img_planting'>
                                        <div className='img_dv'>
                                            <div className='img_oly plnt_ware_img1' ref={plnt_ware_img1}></div>
                                            <img className='col_img_plantyard' src={ware_inplan.in_plant_warehouse_image}></img>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>

                        <div className='row so_row_2'>
                            <div className='col-lg-5 col-md-12 col-12'>

                                {acf.agile_yard_management.map((ware_agile) =>
                                    <div className='so_col1 pl_0'>
                                        <div className='img_dv'>
                                            <div className='img_oly plnt_ware_img2' ref={plnt_ware_img2}></div>
                                            <img className='col_img_agileyard' src={ware_agile.agile_yard_image}></img>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-lg-7 col-md-12 col-12'>

                                {acf.agile_yard_management.map((ware_agile) =>
                                    <div className='so_col1_main'>

                                        <p className='so_col_title_1 plnt_ware_p2 ' ref={plnt_ware_p2}>{ware_agile.agile_yard_hd}</p>
                                        <p className='plant_col_para  plnt_ware_p2 yard_sub_hd' ref={plnt_ware_p2}>{ware_agile.agile_yard_description}</p>

                                        <div className='plnt_ware_p2' ref={plnt_ware_p2}  dangerouslySetInnerHTML={{ __html: ware_agile.agile_yard_description1 }}>

                                        </div>
                                        {/* <a className="agile plnt_ware_p1 read_more_btn rm_blue" data-content="toggle-text" ref={plnt_ware_p1}>Read More</a> */}

                                    </div>
                                )}
                            </div>


                        </div>

                    </div>
                ))}

            </div>
        </>
    )
}
