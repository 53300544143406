import React from 'react'
import '../../../../assets/css/customised_servc_ware.css'

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, EffectCreative, EffectFade } from "swiper";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/effect-creative";
import 'swiper/css/effect-fade';


import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Customised_servc_ware() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const cstm_srvc_wr_hd = useRef(null);
    const cstm_srvc_wr_p1 = useRef(null);
    const cstm_srvc_wr_img1 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".cstm_srvc_wr_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".cstm_srvc_wr_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".cstm_srvc_wr_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cstm_srvc_wr_p1",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/warehousing_page`
    );

    return (
        <>
            <div className='customised_servc_ware mob_padding_LR tab_padding_LR' id='value-added-services'>
                <div className='customised_contain'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-12'>

                                {acf.customised_section.map((ware_cstm) =>
                                    <div className='hd_dv'>
                                        <h2 className='heading wht_txt cstm_srvc_wr_hd' ref={cstm_srvc_wr_hd}>{ware_cstm.customised_hd}</h2>
                                    </div>
                                )}

                            </div>

                            <div className='col-12 cstm_srvc_wr_p1' ref={cstm_srvc_wr_p1}>
                                <Swiper
                                    slidesPerView={3}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1
                                        },
                                        651: {
                                            slidesPerView: 2,
                                            spaceBetween: 30
                                        },
                                        1024: {
                                            slidesPerView: 3
                                        },
                                        1280: {
                                            slidesPerView: 3,
                                            spaceBetween: 50
                                        },
                                        1600: {
                                            slidesPerView: 3,
                                            spaceBetween: 50
                                        },
                                        1920: {
                                            slidesPerView: 4,
                                            spaceBetween: 50
                                        },
                                    }}
                                    spaceBetween={30}
                                    navigation={true}
                                    modules={[Navigation]}
                                    className="customised_slider sldr_crl_bnt"
                                >

                                    {acf.customised_slider.map((ware_cstm1) =>
                                        <SwiperSlide>
                                            <div className='cstm_item'>
                                                <img className='cstm_sld_img' src={ware_cstm1.customised_icon}></img>
                                                <h2 className='cstm_sld_hd'>{ware_cstm1.customised_hd}</h2>
                                                <ul className='cstm_sld_list global_list'>

                                                    {ware_cstm1.customised_list.map((cstm_list) =>
                                                        <li>{cstm_list.customised_list_data}</li>
                                                    )}

                                                </ul>
                                            </div>
                                        </SwiperSlide>
                                    )}



                                </Swiper>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
