import React from 'react'
import '../../../assets/css/counter.css';

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";

import useFetch from "react-fetch-hook";

export default function Apollo_edge_counter() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const apdge_ct1 = useRef(null);
    const apdge_ct2 = useRef(null);
    const apdge_ct3 = useRef(null);
    const apdge_ct4 = useRef(null);

    useEffect(() => {



        if ($(window).width() <= 1440) {

            gsap.fromTo(".apdge_ct1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.3,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".apdge_ct1",
                    markers: false
                }
            });

        } else {

            // Para animation code
            gsap.fromTo(".apdge_ct1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.3,
                ease: "linear",
                delay: 2,
                scrollTrigger: {
                    trigger: ".apdge_ct1",
                    markers: false
                }
            });

        }


    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/apollo_edge_page`
    );

    return (
        <>
            <div className='counter counter_ware mob_padding_LR tab_padding_LR ae_counter' id='counter'>
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>

                            {acf.edge_counter.map((edge_count) =>
                                <div className='col-lg-3 col-md-3 col-12'>
                                    <div className='count_col cc_1 apdge_ct1' ref={apdge_ct1}>
                                        <div className='cc_inner'>
                                            <p className='cc_ttl infra_title'>{edge_count.counter_text1}</p>
                                            <p className='cc_num'>{edge_count.counter_number}</p>
                                            <p className='cc_ttl infra_title'>{edge_count.counter_text2}</p>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}
