import React from 'react'
import '../../assets/css/inside_page_spotlight.css'

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Contact_spotlight() {

  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const cnt_spot_hd = useRef(null);
  const cnt_spt_p = useRef(null);

  useEffect(() => {

    gsap.fromTo(".cnt_spot_hd", {
      y: 250,
      opacity: 0,
      skewY: 41,
    }, {
      delay: 1.7,
      duration: 0.8,
      y: 0,
      skewY: 0,
      opacity: 1,
      ease: "power4.out",
      stagger: {
        from: 'start',
        amount: 0.5,
      },
      scrollTrigger: {
        trigger: ".cnt_spot_hd",
        markers: false
      }
    });

    // Para animation code
    gsap.fromTo(".cnt_spt_p", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.8,
      ease: "linear",
      delay: 1.7,
      scrollTrigger: {
        trigger: ".cnt_spt_p",
        markers: false
      }
    });

  })

  const { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/contact_us_page/`
  );

  return (
    <>
      <div className='sector_spotlight contact_spotlight page_spotlight'>
        {data && data.map(({ acf }) => (
          <div className='spotlight_container'>
            <div className='row'>
              <div className='col-12 pr_0'>
                <div className='spotlight_dv'>
                  <div className='spot_contnt'>
                    {acf.contact_spotlight.map((spot_title) =>
                      <div className='spot_col1'>

                        <div className='hd_dv'>

                          <h1 className='spot_heading wht_txt cnt_spot_hd' ref={cnt_spot_hd}>{spot_title.contact_spot_hd1}</h1>

                        </div>
                        <p className='spot_sb_ttl cnt_spt_p' ref={cnt_spt_p}>{spot_title.contact_spot_hd2}</p>
                      </div>
                    )}
                    <div className='spot_col2' style={{ visibility: 'hidden' }}>
                      <p className='wht_txt'>Our countrywide network of warehouses provides a wide range of services including dedicated and multi-user facilities.</p>
                      <a href='#' className='btn wht_btn'>
                        <span className='btn_txt'>Learn More</span>
                        <span className='arrow_btn wht_arrow'></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
