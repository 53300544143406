import React from 'react'
import '../../../assets/css/application_form.css';

import { useEffect, useRef, useState } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

export default function Application_form(props) {


    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const app_frm_hd = useRef(null);
    const app_frm = useRef(null);

    useEffect(() => {

        gsap.fromTo(".app_frm_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".app_frm_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".app_frm", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".app_frm",
                markers: false
            }
        });
    })

    const form = useRef();
    const [loading, setLoading] = useState(false)
    const [statusMessage, setStatusMessage] = useState("");

    const nameinput = React.useRef(null);
    const emailinput = React.useRef(null);

    const expinput = React.useRef(null);
    const phoneinput = React.useRef(null);

    // const departinput = React.useRef(null);
    const fileinput = React.useRef(null);

    const captchaRef = useRef(null);


    // All field validation error hide code 
    const handleFocus = e => {
        e.target.classList.remove("error_line");

        let er1 = document.getElementById("nm_err");
        er1.classList.remove("show_error");

        let er2 = document.getElementById("eml_err");
        er2.classList.remove("show_error");

        let er2v = document.getElementById("eml_err1");
        er2v.classList.remove("show_error");

        let er3 = document.getElementById("cmp_err");
        er3.classList.remove("show_error");

        let er5 = document.getElementById("depart_err");
        er5.classList.remove("show_error");

        let er6 = document.getElementById("file_err");
        er6.classList.remove("show_error");

        let er7 = document.getElementById("file_err1");
        er7.classList.remove("show_error");

    };

    // Phone field validation error hide code    
    const handleFocus1 = e => {
        e.target.classList.remove("error_line");

        let er4 = document.getElementById("phn_err");
        er4.classList.remove("show_error");

        let er4v = document.getElementById("phn_err1");
        er4v.classList.remove("show_error");
    };


    // Form action code
    const sendEmail = async (e) => {
        e.preventDefault();

        // Fields get value Code
        let name_fld = (nameinput.current.value);
        let email_fld = (emailinput.current.value);

        let exp_fld = (expinput.current.value);
        let phone_fld = (phoneinput.current.value);

        // let dprt_fld = (departinput.current.value);
        let file_fld = (fileinput.current.value);

        let token = captchaRef.current.getValue();


        // Phone Regex Code
        let phone_fltr = document.getElementById("phone_fld");
        var mob_regx = /^([+][9][1]|[9][1]|[0]){0,1}([7-9]{1})([0-9]{9})$/;
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        // file type
        var allowedExtensions = /(\.doc|\.docx|\.odt|\.pdf|\.wps|\.wks|\.wpd)$/i;

        // Fields validation Code
        if (name_fld == '') {
            let v1 = document.getElementById("name_fld");
            v1.className += " error_line";

            let er1 = document.getElementById("nm_err");
            er1.className += " show_error";
        }
        else if (email_fld == '') {
            let v2 = document.getElementById("email_fld");
            v2.className += " error_line";

            let er2 = document.getElementById("eml_err");
            er2.className += " show_error";
        }
        else if (!email_fld.match(mailformat)) {
            let v2 = document.getElementById("email_fld");
            v2.className += " error_line";

            let er2v = document.getElementById("eml_err1");
            er2v.className += " show_error";
        }
        else if (phone_fld == '') {
            let v4 = document.getElementById("phone_fld");
            v4.className += " error_line";

            let er4 = document.getElementById("phn_err");
            er4.className += " show_error";
        }

        else if (!mob_regx.test(phone_fltr.value)) {
            let v4 = document.getElementById("phone_fld");
            v4.className += " error_line";

            let er4 = document.getElementById("phn_err1");
            er4.className += " show_error";
        }
        else if (exp_fld == '') {
            let v3 = document.getElementById("exp_fld");
            v3.className += " error_line";

            let er3 = document.getElementById("cmp_err");
            er3.className += " show_error";
        }
        // else if (dprt_fld == '') {
        //     let v5 = document.getElementById("dprt_fld");
        //     v5.className += " error_line";

        //     let er5 = document.getElementById("depart_err");
        //     er5.className += " show_error";
        // }
        else if (file_fld == '') {
            let v6 = document.getElementById("myFile");
            v6.className += " error_line";

            let er6 = document.getElementById("file_err");
            er6.className += " show_error";
        }
        else if (!allowedExtensions.exec(file_fld)) {

            // alert('Invalid file type');
            // fileInput.value = '';
            let er7 = document.getElementById("file_err1");
            er7.className += " show_error";
            return false;
        }
        else if(token == '') {
            console.log("You must confirm you are not a robot");
            let recap_er = document.getElementById("recap_err");
            recap_er.className += " show_error";
        }
        else {

            let recap_er = document.getElementById("recap_err");
            recap_er.classList.remove("show_error");

            fetch('https://script.google.com/macros/s/AKfycby0i7Qtu4Q7NxTeMYtJxgvGqC7jsx_jhoJl1Y4SgIhWWOpuqQ7whLIj5aCOGty51Z05/exec', {
                method: 'POST',
                body: new FormData(form.current),

            }).then(res => {
                console.log("SUCCESSFULLY SUBMITTED")
                setLoading(false)
            })
                .catch(err => console.log(err))

            emailjs.sendForm('service_l2n43ts', 'template_zlfuh8m', e.target, 'SsPYHKCapw4h-xBn_')
                .then((result) => {

                    console.log(result.text);
                    setStatusMessage("Your Message has been sent successfully");
                    let msg = document.getElementById("thnk_msg");
                    msg.className += " thnk_msg_show";

                    let frm_bx = document.getElementById("career_form_bx");
                    frm_bx.className += " cont_form_bx_hide";

                }, (error) => {

                    console.log(error.text);
                    setStatusMessage(`${error.text} happened`);

                });
            e.target.reset()

        }


    };

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/section_heading`
    );

    return (
        <>
            <div className='application_form  mob_padding_LR tab_padding_LR'>

                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row m_0'>

                            <div className='col-lg-12 col-md-12 col-12 pl_0 clint_logo_maincol'>

                                <div className='hd_dv' style={{ margin: 'auto' }}>
                                    <h2 className="connect_ttl heading app_frm_hd" ref={app_frm_hd}>{acf.job_apply_form_heading}</h2>
                                </div>

                                <div className='client_col2 connect_col app_frm' ref={app_frm}>

                                    <form className='connect_form' ref={form} onSubmit={sendEmail} id='career_form_bx'>

                                        <input type='hidden' name='job_name' value={props.career_fld}></input>
                                        <div className='row'>
                                            <div className='col-md-6 col-12'>
                                                <div className='frm_inr_dv'>
                                                    <input className='input_fld' type='text' placeholder='Name' name='name' id='name_fld' ref={nameinput} onFocus={handleFocus} />
                                                    <p className='error_msg' id='nm_err'>Please Enter Name</p>
                                                </div>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <div className='frm_inr_dv'>
                                                    <input className='input_fld' type='email' placeholder='Email Address' name='email' id='email_fld' ref={emailinput} onFocus={handleFocus} />
                                                    <p className='error_msg' id='eml_err'>Please Enter Email Address</p>
                                                    <p className='error_msg' id='eml_err1'>Please Enter Valid Email Address</p>
                                                </div>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <div className='frm_inr_dv'>
                                                    <input className='input_fld' type='text' placeholder='Mobile No' name='phone' id='phone_fld' ref={phoneinput} onFocus={handleFocus1} />
                                                    <p className='error_msg' id='phn_err'>Please Enter Phone No</p>
                                                    <p className='error_msg' id='phn_err1'>Please Enter Valid Phone No</p>
                                                </div>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <div className='frm_inr_dv'>
                                                    <input className='input_fld' type='text' placeholder='Experience' name='experience' id='exp_fld' ref={expinput} onFocus={handleFocus} />
                                                    <p className='error_msg' id='cmp_err'>Please Enter Experience</p>
                                                </div>
                                            </div>
                                            {/* <div className='col-md-6 col-12'>
                                            <div className='frm_inr_dv'>
                                                <select className='input_fld fld_full' name='department' id='dprt_fld' ref={departinput} onFocus={handleFocus} >
                                                    <option value=''>Select Department</option>
                                                    <option value='drp'>option 1</option>
                                                    <option value='drp'>option 1</option>
                                                    <option value='drp'>option 1</option>
                                                </select>
                                                <p className='error_msg' id='depart_err'>Please Select Department</p>
                                            </div>
                                        </div> */}
                                            <div className='col-md-12 col-12 file_input_col'>
                                                <div className='frm_inr_dv'>
                                                    <label>Attach CV</label>
                                                    <input type="file" placeholder="Attach CV" id="myFile" name="cv" ref={fileinput} onFocus={handleFocus}></input>
                                                    <p className='error_msg' id='file_err'>Please Attach CV</p>
                                                    <p className='error_msg' id='file_err1'>Please Attach valid file</p>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <br></br>
                                                <ReCAPTCHA sitekey="6LfInfMmAAAAACNp9E7DaElr2uxDJ_sF8jvI6XzW" ref={captchaRef} />
                                                <div className='frm_inr_dv recap_div'>
                                                    <p className='error_msg' id='recap_err'>Please Check reCAPTCHA</p>
                                                </div>
                                            </div>

                                            <div className='col-12 app_frm_btn_col'>

                                                <span className='btn blue_btn input_btn'>
                                                    <input className='btn_txt' type='submit' name='submit' value='submit'></input>
                                                    <span className='arrow_btn blue_arrow'></span>
                                                </span>
                                            </div>
                                        </div>

                                    </form>

                                    <p className='form_thanks_msg' id='thnk_msg'>Your form has been sent successfully</p>

                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}
