import React from 'react'
import '../../../assets/css/inside_app_form.css';
import { useParams } from "react-router-dom";
import useFetch from "react-fetch-hook";


import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Application_form from './application_form';

export default function Inside_app_form() {

    let { slug } = useParams();

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const opn_lst_hd = useRef(null);
    const opn_lst_ds = useRef(null);
    const opn_lst_1 = useRef(null);
    const opn_lst_2 = useRef(null);
    const opn_lst_3 = useRef(null);
    const opn_lst_4 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".opn_lst_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 1.8,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".opn_lst_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".opn_lst_ds", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 1.9,
            scrollTrigger: {
                trigger: ".opn_lst_ds",
                markers: false
            }
        });
        gsap.fromTo(".opn_lst_1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 1.9,
            scrollTrigger: {
                trigger: ".opn_lst_1",
                markers: false
            }
        });
        gsap.fromTo(".opn_lst_2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 1.2,
            scrollTrigger: {
                trigger: ".opn_lst_2",
                markers: false
            }
        });
        gsap.fromTo(".opn_lst_3", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 1.4,
            scrollTrigger: {
                trigger: ".opn_lst_3",
                markers: false
            }
        });
        gsap.fromTo(".opn_lst_4", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 1.6,
            scrollTrigger: {
                trigger: ".opn_lst_4",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/current_openings/?slug=${slug}`
    );

    return (
        <>
            <div className='inside_app_form  mob_padding_LR tab_padding_LR'>
                {data && data.map(({ id, title, acf }) => (
                    <div className='cstm_container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='iaf_col1'>
                                    <div className='hd_dv'>
                                        <h2 className='heading opn_lst_hd' ref={opn_lst_hd} dangerouslySetInnerHTML={{ __html: title.rendered }}></h2>
                                    </div>
                                </div>
                                <div className='iaf_col1 opn_lst_1' ref={opn_lst_1}>
                                    <h5 className='iaf_ttl'>Location: <span style={{ fontWeight: "300" }}> {acf.location} </span></h5>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='iaf_col1'>
                                    <h5 className='iaf_ttl opn_lst_ds' ref={opn_lst_ds}>Roles & responsibilities</h5>
                                    <p dangerouslySetInnerHTML={{ __html: acf.job_description }} className='opn_lst_ds' ref={opn_lst_ds}></p>
                                </div>
                            </div>
                        </div><br></br>
                        <div className='row'>

                            {/* <div className='col-lg-3 col-md-3 col-12'>
                                <div className='iaf_col1 opn_lst_1' ref={opn_lst_1}>

                                    <h5 className='iaf_ttl'>Location</h5>
                                    <p>{acf.location}</p>
                                </div>
                            </div> */}
                            {/* <div className='col-lg-3 col-md-3 col-12'>
                                <div className='iaf_col1 opn_lst_2' ref={opn_lst_2}>
                                    <h5 className='iaf_ttl'>No of openings</h5>
                                    <p>{acf.number_of_opening}</p>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-3 col-12'>
                                <div className='iaf_col1 opn_lst_3' ref={opn_lst_3}>
                                    <h5 className='iaf_ttl'>Level</h5>
                                    <p>{acf.level}</p>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-3 col-12'>
                                <div className='iaf_col1 opn_lst_4' ref={opn_lst_4}>
                                    <h5 className='iaf_ttl'>CTC</h5>
                                    <p>{acf.ctc}</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                ))}
            </div>
            {data && data.map(({ id, title, acf }) => (
                <Application_form career_fld={title.rendered} />
            ))}
        </>
    )
}
