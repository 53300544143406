import React from 'react'
import $ from "jquery";

import '../../../../assets/css/info_ware.css';

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Asc_liquid() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const liquid_hd = useRef(null);
    const liquid_p1 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".liquid_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".liquid_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".liquid_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".liquid_p1",
                markers: false
            }
        });

        $.fn.isInViewport = function () {
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight() / 2;
            var viewportTop = $(window).scrollTop();
            var viewportHalf = viewportTop + $(window).height() / 1;
            return elementBottom > viewportTop && elementTop < viewportHalf;
        };


        $(window).on("scroll", function () {

            $(".img_scroll_dv").each(function () {
                var windowTop = $(window).scrollTop();
                var elementTop = $(this).offset().top;
                var leftPosition = windowTop - elementTop;
                $(this)
                    .find(".inside_dv_img")
                    .css({ right: leftPosition });
            });

        });

    });

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/land_transport_page`
    );

    return (
        <>
            <div className='asc_liquid_sec' id='liquid'>
                <div className='cstm_container col-md-12 col-12 p_0 mob_padding_LR tab_padding_LR'>

                    {data && data.map(({ acf }) => (
                        <div className='row m_0'>

                            <div className='col-lg-12 p_0'>

                                {acf.asc_liquid_section.map((land_sub8) =>
                                    <div className='info_ware_col1'>

                                        <div className='hd_dv'>
                                            <h2 className='heading wht_txt liquid_hd' ref={liquid_hd}>{land_sub8.asc_liquid_hd}</h2>
                                        </div>

                                        <p className='asc_main_para liquid_p1' ref={liquid_p1}>{land_sub8.asc_liquid_sub_hd}</p>

                                        <div className='liq_p_dv liquid_p1' dangerouslySetInnerHTML={{ __html: land_sub8.asc_liquid_description }} ref={liquid_p1}></div>

                                    </div>
                                )}
                            </div>
                        </div>
                    ))}


                </div>




            </div>


        </>
    )
}
