import React from 'react'
import Header from "../component/global/header";
import Land_spotlight from '../component/spotlight/land_spotlight';
import Land_counter from '../component/section/services/land_transport/land_counter';
import Land_section from '../component/section/services/land_transport/land_section';
import Full_truck from '../component/section/services/land_transport/full_truck';
import Part_truck from '../component/section/services/land_transport/part_truck';
import Last_mile from '../component/section/services/land_transport/last_mile';
import Asc_prime from '../component/section/services/land_transport/asc_prime';
import Asc_liquid from '../component/section/services/land_transport/asc_liquid';
import Land_asc_edge from '../component/section/services/land_transport/land_asc_edge';

import Connect_service from '../component/section/services/connect_service';

import Footer from "../component/global/footer";
// import CustomGlobe from "../CustomGlobe";

import Transition from '../component/transition';
import { gsap } from "gsap";
import { useEffect, useRef } from 'react';
import { Power3, Power4 } from "gsap";




export default function Land_transport_page() {

  let body = useRef(null);
  var tl_1 = gsap.timeline()

  useEffect(() => {

    // Page Transition Code

    tl_1.to(body, {
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut,
      delay: 1.2
    });
    return () => {
      tl_1.to(body, {
        opacity: "0",
        pointerEvents: 'none'
      });
    }

  })


  return (
    <>
      <Transition />

      <div className='land_pg srvc_pg'>
        <div ref={(el) => (body = el)} className="Headd">

          <Header />
          <Land_spotlight />
          <Land_counter />
          <Land_section />
          <Full_truck />
          <Part_truck />
          <Last_mile />
          <Asc_prime />
          <Asc_liquid />
          {/* <Simulation_design/>  */}
          <Land_asc_edge />
          <Connect_service />
          <Footer />
        </div>
      </div>
    </>
  )
}
