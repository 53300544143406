import React from 'react'
import '../../assets/css/inside_page_spotlight.css'

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Application_form_spotlight() {


  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const app_spot_hd = useRef(null);
  const app_spt_p = useRef(null);

  useEffect(() => {

    gsap.fromTo(".app_spot_hd", {
      y: 250,
      opacity: 0,
      skewY: 41,
    }, {
      delay: 1.8,
      duration: 0.8,
      y: 0,
      skewY: 0,
      opacity: 1,
      ease: "power4.out",
      stagger: {
        from: 'start',
        amount: 0.5,
      },
      scrollTrigger: {
        trigger: ".app_spot_hd",
        markers: false
      }
    });

    // Para animation code
    gsap.fromTo(".app_spt_p", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.8,
      ease: "linear",
      delay: 1.9,
      scrollTrigger: {
        trigger: ".app_spt_p",
        markers: false
      }
    });

  })

  const { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/section_heading`
  );



  return (
    <>
      <div className='sector_spotlight life_asc_spotlight page_spotlight'>
        <div className='spotlight_container'>
          <div className='row'>

            {data && data.map(({ acf }) => (
              <div className='col-12 pr_0'>
                <div className='spotlight_dv'>
                  <div className='spot_contnt'>
                    <div className='spot_col1'>

                      <div className='hd_dv'>
                        <h1 className='spot_heading wht_txt media_hd app_spot_hd' ref={app_spot_hd}>{acf.inside_current_openings_spotlight_heading}</h1>
                      </div>


                    </div>
                    <div className='spot_col2' style={{ visibility: 'hidden' }}>
                      <p className='wht_txt'>Our countrywide network of warehouses provides a wide range of services including dedicated and multi-user facilities.</p>
                      <a href='#' className='btn wht_btn'>
                        <span className='btn_txt'>Learn More</span>
                        <span className='arrow_btn wht_arrow'></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </>
  )
}
