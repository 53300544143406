import React from 'react'
import '../../../assets/css/media.css';
import { useParams } from "react-router-dom";
import useFetch from "react-fetch-hook";

export default function Press_rel_inside_sec() {

  let { slug } = useParams();

  const { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/press_release/?slug=${slug}`
  );


  return (
    <>
      <div className='media_div  mob_padding_LR tab_padding_LR'>
        <div className='cstm_container'>
          <div className='row media_row'>

            <div className='col-lg-1 col-md-1 col-1'>

              <div className='media_col1'>
                <a href='#' className='socl_img'><img src={require('../../../assets/img/svg/md_facebook_icon.svg').default}></img></a>
                <a href='#' className='socl_img'><img src={require('../../../assets/img/svg/md_insta_icon.svg').default}></img></a>
                <a href='#' className='socl_img'><img src={require('../../../assets/img/svg/md_link_icon.svg').default}></img></a>
              </div>

            </div>

            <div className='col-lg-11 col-md-11 col-11'>

              {data && data.map(({ id, title, acf , content}) => (
                <div className='media_col2'>

                  <img className='media_hd_img' src={acf.media_image}></img>
                  <div dangerouslySetInnerHTML={{__html:content.rendered}}></div>

                  {/* <div className='media_ctn_box'>
                    <h3 className='media_ttl'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi laoreet magna ut odio blandit posuere. Nulla pretium risus quam, at ultricies nisl posuere sed. Morbi tempus pellentesque felis, sed tempus orci. Praesent tortor purus, lacinia eu porta vel, interdum vel elit. Nam sed odio eu nisl molestie sagittis. Phasellus convallis, nibh nec vehicula molestie, elit eros facilisis diam, ut tempus mi felis ac massa. Vivamus id tristique nisi, blandit eleifend tellus. Morbi sit amet ante nunc. Ut consectetur lobortis pharetra.</p>
                  </div> */}

                </div>
              ))}

            </div>

          </div>
        </div>
      </div>
    </>
  )
}
