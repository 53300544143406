import React from 'react'
import '../../../assets/css/service_offer_ware.css';
import $ from "jquery";
import { useEffect, useRef , useState} from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import emailjs from 'emailjs-com';
import useFetch from "react-fetch-hook";

export default function Lifeasc_section() {


    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const asc_lf_hd1 = useRef(null);
    const asc_lf_p2 = useRef(null);
    const asc_lf_bx1 = useRef(null);
    const asc_lf_bx2 = useRef(null);
    const asc_lf_bx3 = useRef(null);

    useEffect(() => {

        $('.career_popup').on("click", function() { 
            $('.career_popup_box').fadeIn();
            $('.carrer_overlay').fadeIn();
            $('body').css('overflow','hidden');
        });

        $('.career_cls_btn').on("click", function() { 
            $('.career_popup_box').fadeOut();
            $('.carrer_overlay').fadeOut();
            $('body').css('overflow','auto');
        });

        // Para animation code
        gsap.fromTo(".asc_lf_hd1", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".asc_lf_hd1",
                markers: false
            }
        });

        gsap.fromTo(".asc_lf_p2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".asc_lf_p2",
                markers: false
            }
        });

        gsap.fromTo(".asc_lf_bx1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".asc_lf_bx1",
                markers: false
            }
        });
        gsap.fromTo(".asc_lf_bx2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".asc_lf_bx2",
                markers: false
            }
        });
        gsap.fromTo(".asc_lf_bx3", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".asc_lf_bx3",
                markers: false
            }
        });

    })



    const form = useRef();
    const [loading, setLoading] = useState(false)
    const [statusMessage, setStatusMessage] = useState("");

    const nameinput = React.useRef(null);
    const emailinput = React.useRef(null);
    
    const phoneinput = React.useRef(null);
    const locationinput = React.useRef(null);
    
    const desinput = React.useRef(null);
    const fileinput = React.useRef(null);




    // All field validation error hide code 
    const handleFocus = e => {
        e.target.classList.remove("error_line");

        let er1 = document.getElementById("nm_err");
        er1.classList.remove("show_error");

        let er2 = document.getElementById("eml_err");
        er2.classList.remove("show_error");

        let er2v = document.getElementById("eml_err1");
        er2v.classList.remove("show_error");

        let er3 = document.getElementById("cmp_err");
        er3.classList.remove("show_error");

        let er5 = document.getElementById("depart_err");
        er5.classList.remove("show_error");

        let er6 = document.getElementById("file_err");
        er6.classList.remove("show_error");

        let er7 = document.getElementById("file_err1");
        er7.classList.remove("show_error");

    };

    // Phone field validation error hide code    
    const handleFocus1 = e => {
        e.target.classList.remove("error_line");

        let er4 = document.getElementById("phn_err");
        er4.classList.remove("show_error");

        let er4v = document.getElementById("phn_err1");
        er4v.classList.remove("show_error");
    };

    // Form action code
    const sendEmail = async (e) => {
        e.preventDefault();

        // Fields get value Code
        let name_fld = (nameinput.current.value);
        let email_fld = (emailinput.current.value);

        let phone_fld = (phoneinput.current.value);
        let location_fld = (locationinput.current.value);

        let des_fld = (desinput.current.value);
        let file_fld = (fileinput.current.value);


        // Phone Regex Code
        let phone_fltr = document.getElementById("phone_fld");
        var mob_regx = /^([+][9][1]|[9][1]|[0]){0,1}([7-9]{1})([0-9]{9})$/;
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        // file type
        var allowedExtensions = /(\.doc|\.docx|\.odt|\.pdf|\.wps|\.wks|\.wpd)$/i;

        // Fields validation Code
        if (name_fld == '') {
            let v1 = document.getElementById("name_fld");
            v1.className += " error_line";

            let er1 = document.getElementById("nm_err");
            er1.className += " show_error";
        }
        else if (email_fld == '') {
            let v2 = document.getElementById("email_fld");
            v2.className += " error_line";

            let er2 = document.getElementById("eml_err");
            er2.className += " show_error";
        }
        else if (!email_fld.match(mailformat)) {
            let v2 = document.getElementById("email_fld");
            v2.className += " error_line";

            let er2v = document.getElementById("eml_err1");
            er2v.className += " show_error";
        }
        else if (phone_fld == '') {
            let v4 = document.getElementById("phone_fld");
            v4.className += " error_line";

            let er4 = document.getElementById("phn_err");
            er4.className += " show_error";
        }

        else if (!mob_regx.test(phone_fltr.value)) {
            let v4 = document.getElementById("phone_fld");
            v4.className += " error_line";

            let er4 = document.getElementById("phn_err1");
            er4.className += " show_error";
        }
        else if (location_fld == '') {
            let v3 = document.getElementById("location_fld");
            v3.className += " error_line";

            let er3 = document.getElementById("cmp_err");
            er3.className += " show_error";
        }
        else if (des_fld == '') {
            let v5 = document.getElementById("des_fld");
            v5.className += " error_line";

            let er5 = document.getElementById("depart_err");
            er5.className += " show_error";
        }
        else if (file_fld == '') {
            let v6 = document.getElementById("myFile");
            v6.className += " error_line";

            let er6 = document.getElementById("file_err");
            er6.className += " show_error";
        }
        else if (!allowedExtensions.exec(file_fld)) {

            // alert('Invalid file type');
            // fileInput.value = '';
            let er7 = document.getElementById("file_err1");
            er7.className += " show_error";
            return false;
        }
        else {

            fetch('https://script.google.com/macros/s/AKfycbyqAgF74y0aBthqHtmyrVjqEgCfKDdIp90Oass23uHoGM5HlEEILTL0qgZ2DIDiUfg/exec', {
                method: 'POST',
                body: new FormData(form.current),

            }).then(res => {
                console.log("SUCCESSFULLY SUBMITTED")
                setLoading(false)
            })
                .catch(err => console.log(err))

            emailjs.sendForm('service_l2n43ts', 'template_23ydca9', e.target, 'SsPYHKCapw4h-xBn_')
                .then((result) => {

                    console.log(result.text);
                    setStatusMessage("Your Message has been sent successfully");
                    let msg = document.getElementById("thnk_msg");
                    msg.className += " thnk_msg_show";

                    let frm_bx = document.getElementById("career_form_bx");
                    frm_bx.className += " cont_form_bx_hide";

                }, (error) => {

                    console.log(error.text);
                    setStatusMessage(`${error.text} happened`);

                });
            e.target.reset()

        }


    };



    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/life_asc_page`
    );


    return (
        <>
            
            <div className='asc_section SO_ware mob_padding_LR tab_padding_LR life_sec'>
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-12'>

                                {acf.life_info1.map((life_sub3) =>
                                    <div className='hd_dv'>
                                        <h2 className='heading life_head asc_lf_hd1' ref={asc_lf_hd1}>{life_sub3.life_info_hd}</h2>
                                    </div>
                                )}

                            </div>
                            <div className='col-lg-12 col-md-12 col-12 abc'>

                                {acf.life_info1.map((life_sub3) =>

                                    <div dangerouslySetInnerHTML={{ __html: life_sub3.life_info_description2 }} className='asc_lf_p2' ref={asc_lf_p2}></div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

            </div>

            <div className='carrer_overlay'></div>
            <div className='career_popup_box'>
                <img className='career_cls_btn' src={require('../../../assets/img/svg/close_icon_wht.svg').default}></img>
                <h4 class="heading wht_txt">Join Us</h4>   

                <form className='enquire_from career_popup_form' ref={form} onSubmit={sendEmail} id='career_form_bx'>
                    <div className='row'>
                        <div className='col-md-6 col-12'>
                            <div className='frm_inr_dv'>
                                <input className='input_fld' type='text' placeholder='Name' name='name' id='name_fld' ref={nameinput} onFocus={handleFocus} />
                                <p className='error_msg' id='nm_err'>Please Enter Name</p>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='frm_inr_dv'>
                                <input className='input_fld' type='email' placeholder='Email Address' name='email' id='email_fld' ref={emailinput} onFocus={handleFocus} />
                                <p className='error_msg' id='eml_err'>Please Enter Email Address</p>
                                <p className='error_msg' id='eml_err1'>Please Enter Valid Email Address</p>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='frm_inr_dv'>
                                <input className='input_fld' type='text' placeholder='Mobile No' name='phone' id='phone_fld' ref={phoneinput} onFocus={handleFocus1} />
                                <p className='error_msg' id='phn_err'>Please Enter Phone No</p>
                                <p className='error_msg' id='phn_err1'>Please Enter Valid Phone No</p>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='frm_inr_dv'>
                                <input className='input_fld' type='text' placeholder='Location' name='location' id='location_fld' ref={locationinput} onFocus={handleFocus} />
                                <p className='error_msg' id='cmp_err'>Please Enter Location</p>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='frm_inr_dv'>
                                <textarea className='input_fld' placeholder='Description' name='description' id='des_fld' ref={desinput} onFocus={handleFocus} ></textarea>
                                <p className='error_msg' id='depart_err'>Please Enter Description</p>
                            </div>
                        </div>
                        <div className='col-md-6 col-12 file_input_col'>
                            <div className='frm_inr_dv'>
                                <label className='wht_txt'>Attach CV</label>
                                <input className='wht_txt' type="file" placeholder="Attach CV" id="myFile" name="cv" ref={fileinput} onFocus={handleFocus}></input>
                                <p className='error_msg' id='file_err'>Please Attach CV</p>
                                <p className='error_msg' id='file_err1'>Please Attach valid file</p>
                            </div>
                        </div>
                        <div className='col-12 '>
                            <span className='btn blue_btn input_btn'>
                                <input className='btn_txt' type='submit' name='submit' value='submit'></input>
                                <span className='arrow_btn wht_arrow'></span>
                            </span>
                        </div>
                    </div>
                </form>
                <p className='form_thanks_msg wht_txt connect_form_msg' id='thnk_msg'>Your form has been sent successfully</p> 
                
            </div> 

             
            

        </>
    )
}
