import React from 'react'
import Header from '../component/global/header';
import Footer from '../component/global/footer';
import '../assets/css/search.css';
import { useParams } from "react-router-dom";
import useFetch from "react-fetch-hook";
import Masonry from 'react-masonry-css';
import $ from "jquery";

import Transition from '../component/transition';
import { gsap } from "gsap";
import { useEffect, useRef } from 'react';
import { Power3, Power4 } from "gsap";

import News_search from '../component/search/news_search';
import Data_search from '../component/search/data_search';

export default function Search_page() {

  // gsap.registerPlugin(ScrollTrigger);

  let body = useRef(null);
  var tl_1 = gsap.timeline()

  useEffect(() => {



    tl_1.to(body, {
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut,
      delay: 1.2
    });
    return () => {
      tl_1.to(body, {
        opacity: "0",
        pointerEvents: 'none'
      });
    }

  })

  const form = useRef();
  const resultinput = React.useRef(null);

  const sendResult = (e) => {
    e.preventDefault();

    let resultinput_var = (resultinput.current.value);
    window.location.href = "/search/" + resultinput_var;
  };

  let { name } = useParams();


  const { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/blogs?search=${name}`
  );


  const breakpointColumnsObj = {
    default: 1,
    1100: 1,
    700: 2,
    500: 1
  };

  useEffect(() => {


    if ($('.my-masonry-grid_column .rslt_bx').is(':visible')) {
      $('.result-error').hide();
      $('.srch_hd').show();
      // $('.found-txt').show();
    }
    else {
      $('.result-error').show();
      $('.srch_hd').hide();
      // $('.found-txt').hide();
    }
  })



  return (
    <> <Transition />
      <div className='pg-layout searc_page'>
        <div ref={(el) => (body = el)} className="Headd">
          <Header />

          <div className='search-bg mob_padding_LR tab_padding_LR'>
            <div className='cstm_container'>
              <div className='serch-result-dv blg-srch-rsult'>

                <div className='row srch-pg-rw '>
                  <div className='col-12'>

                    <form class="srch_form" onSubmit={sendResult} >
                      <input class="srch-input" type="text" placeholder="" ref={resultinput} />
                      <button type="submit" class="srch-btn">Search</button>
                    </form>

                  </div>
                </div>

                <div className='row'>
                  <div className='col-12 srch_col'>
                    <p className=' heading  found-txt srch_hd'> Showing search result for &#8220;<span>{name}</span>&#8221;</p>
                    <Masonry
                      breakpointCols={breakpointColumnsObj}
                      className="my-masonry-grid"
                      columnClassName="my-masonry-grid_column"
                    >
                      {/* {data.length}  */}
                      {data && data.map(({ title, acf, content, slug, excerpt }) => (
                        <div className='cstm-container blog-inside-page rslt_bx' >
                          {isLoading && <div>A moment please...</div>}
                          {error && (
                            <div>{`There is a problem fetching the post data - ${error}`}</div>
                          )}

                          <h1 className="blg_ttl">{title.rendered}</h1>
                          <p className='srch_dscrb' dangerouslySetInnerHTML={{ __html: content.rendered }}></p>
                          <a className='blg-view-btn' href={acf.blog_url + slug}>Read more </a>

                        </div>
                      ))}

                      <News_search />
                      <Data_search />
                    </Masonry>
                    {/* : 'not found'} */}
                  </div>

                </div>

                <div className='result-error'>
                  <h2>No results found</h2>
                </div>

              </div>
            </div>
          </div>
          <Footer />


        </div>
      </div>
    </>
  )
}
