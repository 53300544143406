import React from 'react'
import '../../../../assets/css/case_study.css';

import { useEffect, useRef } from 'react';
import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Service_sector_lifestyle() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const cstm_Cs_p1 = useRef(null);
    const cstm_Cs_img1 = useRef(null);

    useEffect(() => {

        if ($(window).width() <= 1440) {
            // Para animation code
            gsap.fromTo(".cstm_Cs_p1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_p1",
                    markers: false
                }
            });

            gsap.fromTo(".cstm_Cs_img1", {
                x: 0
            }, {
                x: 550,
                duration: 0.5,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_img1",
                    markers: false
                }
            });
        }
        else {
            // Para animation code
            gsap.fromTo(".cstm_Cs_p1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 1.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_p1",
                    markers: false
                }
            });

            gsap.fromTo(".cstm_Cs_img1", {
                x: 0
            }, {
                x: 550,
                duration: 0.5,
                ease: "linear",
                delay: 1.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_img1",
                    markers: false
                }
            });

        }

    })


    return (
    <>

        {/*---- Warehousing  Service ----*/}
        <div className='servc_sec_white SO_ware mob_padding_LR tab_padding_LR'>
            <div className='cstm_container'>

                <div className='row'>

                    <div className='col-lg-5 col-md-12 col-12'>
                        <div className='so_col1_optimizatio'>

                            <div className='img_dv'>
                                <img className='so_col_img_multi' src={require('../../../../assets/img/sectors/other_sector/lyfstyle_warehouse.jpg')}></img>
                            </div>

                        </div>
                    </div>


                    <div className='col-lg-7 col-md-12 col-12 sector_srvc_col2 '>
                
                        <div className='so_col1_multi truck_main'>

                            <div className='hd_dv'>
                                <h2 className='heading'>Warehousing</h2>
                            </div>

                            <ul class="global_list srvc_sec">
                                <li class="wht_txt">Multi-Channel Fulfilment Capabilities </li>
                                <li class="wht_txt">High Density Storage</li>
                                <li class="wht_txt">Inventory Management </li>
                                <li class="wht_txt">Best-in-class tech solutions and WMS for security and loss prevention, customized reporting, accuracy, real-time visibility, inventory control</li>
                                <li class="wht_txt">Product Pre-Retailing & Processing</li>
                                <li class="wht_txt">Flexible and Scalable storage solutions </li>
                                <li class="wht_txt">Returns Management and Refurbishment</li>
                                <li class="wht_txt">Cross Dock Management </li>
                                <li class="wht_txt">Quality Check at Inbound</li>
                            </ul>

                        </div>
                        
                    </div>


                </div>

            </div>
        </div>

        {/*---- Transportation Service ----*/}
        <div className='servc_sec_blue SO_ware mob_padding_LR tab_padding_LR'>
            <div className='cstm_container'>

                <div className='row'>

                    <div className='col-lg-7 col-md-12 col-12'>
                    
                        <div className='so_col1_multi'>

                            <div className='hd_dv'>
                                <h2 className='heading wht_txt'>Transportation</h2>
                            </div>

                        
                            <ul class="global_list ">
                                <li class="wht_txt">Primary & Secondary Transportation </li>
                                <li class="wht_txt">Milk Run Deliveries, Last Mile Delivery</li>
                                <li class="wht_txt">Real-time visibility and route optimisation via TMS</li>
                            </ul>

                        </div>
                        
                    </div>

                    <div className='col-lg-5 col-md-12 col-12'>
                        <div className='so_col1_optimizatio'>

                            <div className='img_dv'>
                                <img className='so_col_img_multi' src={require('../../../../assets/img/sectors/other_sector/lyfstyle_transport.jpg')}></img>
                            </div>

                        </div>
                    </div>
                    

                </div>

            </div>
        </div>

        {/*---- Value Added Services ----*/}
        <div className='servc_sec_white SO_ware mob_padding_LR tab_padding_LR'>
            <div className='cstm_container'>

                <div className='row'>

                    <div className='col-lg-5 col-md-12 col-12'>
                        <div className='so_col1_optimizatio'>

                            <div className='img_dv'>
                                <img className='so_col_img_multi' src={require('../../../../assets/img/sectors/other_sector/lyfstyle_value.jpg')}></img>
                            </div>

                        </div>
                    </div>


                    <div className='col-lg-7 col-md-12 col-12  '>
                
                        <div className='so_col1_multi truck_main'>

                            <div className='hd_dv'>
                                <h2 className='heading'>Value Added Services</h2>
                            </div>

                            <ul class="global_list srvc_sec">
                                <li class="wht_txt">Garments on hangers (GoH)</li>
                                <li class="wht_txt">Protective packaging and Package solutions</li>
                                <li class="wht_txt">Product personalization & customization</li>
                                <li class="wht_txt">Stock scanning at location</li>
                                <li class="wht_txt">RFID tags management for high-value SKUs</li>
                                <li class="wht_txt">Real time information updates and Dynamic SKU profiling </li>
                                <li class="wht_txt">Network monitoring through the Control Tower</li>
                            </ul>

                        </div>
                        
                    </div>


                </div>

            </div>
        </div>

    </>
  )
}
