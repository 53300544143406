import React from 'react'
import Header from "../component/global/header";
import Press_rel_inside_spotlight from '../component/spotlight/press_rel_inside_spotlight'; 
import Press_rel_inside_sec from '../component/section/Press_release/press_rel_inside_sec';
import Footer from "../component/global/footer";


import Transition from '../component/transition';
import { gsap } from "gsap";
import { useEffect , useRef } from 'react';
import { Power3, Power4 } from "gsap";



export default function Press_release_inside_page() {

  let body = useRef(null);
  var tl_1 = gsap.timeline()

  useEffect(() => {

    // Page Transition Code

    tl_1.to(body,{
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut,
      delay: 1.2
    });
    return () => {
      tl_1.to(body, {
        opacity: "0",
        pointerEvents: 'none'
      });
    }

  })

  return (
    <>
      <Transition/>

      <div className='media-pg'>
          <div ref={(el) => (body = el)} className="Headd">

            <Header/>
            <Press_rel_inside_spotlight/>
            <Press_rel_inside_sec/>
            <Footer/>

          </div>
      </div>
    </>
  )
}
