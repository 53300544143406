import React from 'react'
import '../../assets/css/inside_page_spotlight.css'


import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Warehousing_spotlight() {

  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const ware_spot_hd = useRef(null);
  const ware_spt_p = useRef(null);
  const ware_spt_ad1 = useRef(null);
  const ware_spt_ad2 = useRef(null);
  const ware_spt_ad3 = useRef(null);
  const ware_spt_ad4 = useRef(null);
  const ware_spt_ad5 = useRef(null);

  useEffect(() => {

    gsap.fromTo(".ware_spot_hd", {
      y: 250,
      opacity: 0,
      skewY: 41,
    }, {
      delay: 2,
      duration: 0.8,
      y: 0,
      skewY: 0,
      opacity: 1,
      ease: "power4.out",
      stagger: {
        from: 'start',
        amount: 0.5,
      },
      scrollTrigger: {
        trigger: ".ware_spot_hd",
        markers: false
      }
    });

    gsap.fromTo(".ware_spt_p", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.3,
      scrollTrigger: {
        trigger: ".ware_spt_p",
        markers: false
      }
    });

    // Para animation code
    gsap.fromTo(".ware_spt_ad1", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.3,
      scrollTrigger: {
        trigger: ".ware_spt_ad1",
        markers: false
      }
    });
    gsap.fromTo(".ware_spt_ad2", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.4,
      scrollTrigger: {
        trigger: ".ware_spt_ad2",
        markers: false
      }
    });
    gsap.fromTo(".ware_spt_ad3", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.5,
      scrollTrigger: {
        trigger: ".ware_spt_ad3",
        markers: false
      }
    });





  })

  return (
    <>
      <div className='warehouse_spotlight page_spotlight service_spotlight newspot_warehouse'>
        <div className='spotlight_container'>
          <div className='row'>
            <div className='col-12 pr_0'>
              <div className='spotlight_dv'>
                <div className='spot_contnt'>
                  <div className='spot_col1'>

                    <div className='hd_dv'>
                      <h2 className='spot_heading wht_txt ware_spot_hd' ref={ware_spot_hd}>Warehousing For You</h2>
                    </div>

                    <p className='spot_sb_ttl ware_spt_p' ref={ware_spt_p}>Agile. Reliable. Efficient </p>
                    <p className='wht_txt offer_text ware_spt_p' ref={ware_spt_p}>Offering bespoke solutions to help businesses stay ahead of the game.</p>
                  </div>
                  <div className='spot_col2'>
                    {/* <p className='wht_txt'>Our countrywide network of warehouses provides a wide range of services including dedicated and multi-user facilities. Delivering agility, reliability, and sustainability, our solutions are transforming businesses across verticals.</p> */}
                    <div class="dropdown">
                      <a href="#" class="js-link"><i class="fa fa-chevron-down"></i></a>
                      <ul class="warehouse_list">
                        <a className='ware_spt_ad1 dedicated_link' ref={ware_spt_ad1} ><li className='service_listing lst_prnt'>A complete Range:</li> </a>
                        <ul class="asc_edge_spot_sub_ul">
                          <a class="ware_spt_ad1" ref={ware_spt_ad1} href="/warehousing#dedicated-warehousing">
                            <li class="service_listing">Dedicated warehousing</li>
                          </a>
                          <a class="ware_spt_ad1" ref={ware_spt_ad1} href="/warehousing#multi-User-facilities">
                            <li class="service_listing">Multi-User Facilities</li>
                          </a>
                          <a class="ware_spt_ad1" ref={ware_spt_ad1} href="/warehousing#inplantyard">
                            <li class="service_listing">In-Plant Warehousing</li>
                          </a>
                          <li style={{display:'none'}}></li>
                        </ul>
                        <a className='ware_spt_ad2 multi_link' ref={ware_spt_ad2} href='/warehousing#state-of-the-art-technology'><li className='service_listing'>State-Of-The-Art Technology</li> </a>
                        <a className='ware_spt_ad3' ref={ware_spt_ad3} href='/warehousing#value-added-services'><li className='service_listing'>Value-Added Services</li> </a>
                        <a className='ware_spt_ad3' ref={ware_spt_ad3} href='/warehousing#growth-catalysts'><li className='service_listing'>Growth Catalysts</li> </a>

                      </ul>
                    </div>
                    {/* <a href='#' className='btn wht_btn'>
                      <span className='btn_txt'>Learn More</span>
                      <span className='arrow_btn wht_arrow'></span>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
