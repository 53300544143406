import React from 'react'
import '../../../assets/css/leadership_sec.css';
import { useEffect, useRef } from "react";
import $ from "jquery";
import useFetch from "react-fetch-hook";

export default function Leadership_sec() {

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/leadership_page`
    );


    return (
        <>

            


            <div className='leadership_sec mob_padding_LR tab_padding_LR'>

                {data && data.map(({ acf }) => (
                    <div className='cstm_container'>

                        
                        <div className='row leader_row'>
                            {/* <div className='col-12 leader_sec_hd_col'>
                                <div className="hd_dv" style={{ margin: 'auto' }}>
                                    <h1 className="heading" >{acf.leadership_sec_hd}</h1>
                                </div>
                            </div> */}

                                {/* Team 1 */}
                                <div className='row aes_row3 aes_sec2_row raja_profile_row'>

                                    <div className='col-lg-6 col-md-6 col-12 pr_0'>
                                        <div className='aes_col1'>
                                                <div className='img_dv'>
                                                    <img className='so_col_img' src='https://apollosupplychain.com/cms/wp-content/uploads/2023/05/raaja_kanwar_new.jpg'></img>
                                                </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 pl_0 aes_bg asc_order' >
                                            <div className='aes_col2'>
                                                <h1 className='lead_back_name'>Raaja Kanwar</h1>
                                                <h3 class="lead_back_desig">Chairman and Managing Director, Apollo International Limited & Apollo Supply Chain</h3>
                                                <div class="lead_back_dtls" id="scroll_tm_dv1">
                                                    <div>
                                                        <p>A scion of the Kanwar family, the promoters of Apollo Tyres, Raaja Kanwar chose to carve a path following his passion for start-ups using disruptive technology. Raaja Kanwar founded Apollo International Limited (AIL) in 1994 to fulfil the stated aim of ‘Delivering Sustainable Value Creation’. Present in over 45 countries, AIL is a global business conglomerate that operates AIL operates in diverse sectors including tyres, garments and accessories, EPC & Project Management, digital cinema, and the healthcare industry. Today, leading companies across the globe trust AIL for its unmatched business expertise and high standards of business ethics.</p>

                                                        <p>Taking his vision of nurturing entrepreneurship to India’s logistics industry, Mr. Kanwar founded Apollo Supply Chain (ASC). Today, ASC is a prominent integrated logistics company with a global network spanning 100 countries. Recognitions such as World Economic Forum’s “Global Growth Company” and the “Best Integrated Logistics Company of the Year” received at Global Logistics Show in 2017 are testimonies to the organization’s sharp focus on service excellence.</p>
                                                        <p>Mr. Kanwar is a member of several prestigious organizations such as Young President’s Organization, World Economic Forum, Confederation of India Industry, All India Management Association and The Owner’s Forum. He is currently the Vice Chairman, CII Haryana. Mr. Kanwar has played a key role in the evolution of digital cinema in India. In 2006, the then Hon`ble Prime Minister of India, Dr. Manmohan Singh formally recognized Mr. Kanwar’s contribution to the industry. In 2017-18 and 2018-19, URS Media & Asia One Magazine Asia & GCC featured Mr. Kanwar in their World’s Greatest Leaders edition.</p>
                                                        <p>A management degree holder from Drexel University, USA, Raaja is an avid fitness enthusiast. He is a keen investor in start-ups that work on ideas and technologies that hold the promise to make the world a better place for the next generation.
</p>

                                                       
                                                    </div>
                                                </div>
                                                <a class="linkedin_box" href="https://in.linkedin.com/in/raajakanwar" target="_blank">
                                                    <img src="https://apollosupplychain.com/cms/wp-content/uploads/2022/11/u_linkedin.svg"/>
                                                </a>
                                            </div>
                                    </div>

                            </div>
                        
                            {acf.leadership_sec.map((leader_sub) =>
                                <div className='col-lg-6 col-md-12 col-12 leader_outer'>
                                    <div className='leader_inner'>
                                        <img className='leader_img' src={leader_sub.leader_image}></img>
                                        <div className='leader_front_dv'>
                                            <h1 className="lead_front_name">{leader_sub.leader_name}</h1>
                                            <h3 className="lead_front_desig" dangerouslySetInnerHTML={{ __html: leader_sub.leader_designation }}></h3>
                                        </div>
                                        <div className='leader_back_dv'>
                                            <h1 className='lead_back_name'>{leader_sub.leader_name}</h1>
                                            <h3 className='lead_back_desig'>{leader_sub.leader_designation}</h3>
                                            <div className='lead_back_dtls' id='scroll_tm_dv1' >
                                                <div dangerouslySetInnerHTML={{ __html: leader_sub.leader_bio }}></div>
                                            </div>

                                            <a className='linkedin_box' href={leader_sub.leader_linkedin_url} target='_blank'>
                                                <img src={leader_sub.leader_linkedin_icon} ></img>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Team 2 */}
                            {/* {acf.rakesh_gupta.map((team_sub2) =>
                                <div className='col-lg-6 col-md-6 col-12 leader_outer'>
                                    <div className='leader_inner'>
                                        <img className='leader_img' src={team_sub2.team_image}></img>
                                        <div className='leader_front_dv'>
                                            <h1 className="lead_front_name">{team_sub2.team_name}</h1>
                                            <h3 className="lead_front_desig"> {team_sub2.team_designation}</h3>
                                        </div>
                                        <div className='leader_back_dv'>
                                            <h1 className='lead_back_name'>{team_sub2.team_name}</h1>
                                            <h3 className='lead_back_desig'>{team_sub2.team_designation}</h3>
                                            <div className='lead_back_dtls' id='scroll_tm_dv1' >
                                                <div dangerouslySetInnerHTML={{ __html: team_sub2.team_bio }}></div>
                                            </div>

                                            <a className='linkedin_box' href={team_sub2.linkedin_url} target='_blank'>
                                                <img src={team_sub2.linkedin_icon} ></img>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )} */}

                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
