import React from 'react'
import Header from "../../component/global/header";
import C_openings_spotlight from '../../component/spotlight/c_openings_spotlight'; 
import Openings_list from '../../component/section/careers/openings_list';
import Footer from "../../component/global/footer";


import Transition from '../../component/transition';
import { gsap } from "gsap";
import { useEffect , useRef } from 'react';
import { Power3, Power4 } from "gsap";



export default function Current_opening_page() {

    let body = useRef(null);
    var tl_1 = gsap.timeline()
  
    useEffect(() => {
  
      // Page Transition Code
  
      tl_1.to(body,{
        opacity: "1",
        pointerEvents: "auto",
        ease: Power4.easeInOut,
        delay: 1.2
      });
      return () => {
        tl_1.to(body, {
          opacity: "0",
          pointerEvents: 'none'
        });
      }
  
    })
  
    return (
      <>
        <Transition/>
  
        <div className='curnt_opn_pg career_pg'>
            <div ref={(el) => (body = el)} className="Headd">
  
              <Header/>
              <C_openings_spotlight/>
              <Openings_list/>
              <Footer/>
  
            </div>
        </div>
      </>
    )
  }
  