import React from 'react'
import Header from "../component/global/header";
import Footer from "../component/global/footer";
import '../assets/css/privacy.css';

import Transition from '../component/transition';
import { gsap } from "gsap";
import { useEffect, useRef } from 'react';
import { Power3, Power4 } from "gsap";
import useFetch from "react-fetch-hook";

export default function Privacy_page() {

    let body = useRef(null);
    var tl_1 = gsap.timeline()

    useEffect(() => {

        // Page Transition Code

        tl_1.to(body, {
            opacity: "1",
            pointerEvents: "auto",
            ease: Power4.easeInOut,
            delay: 1.2
        });
        return () => {
            tl_1.to(body, {
                opacity: "0",
                pointerEvents: 'none'
            });
        }

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/privacy_policy_page/`
    );

    return (
        <>
            <Transition />

            <div className='privacy_pg'>
                <div ref={(el) => (body = el)} className="Headd">

                    <Header />

                    <div className='privacy_content_dv mob_padding_LR tab_padding_LR'>

                        {data && data.map(({ title , content }) => (
                            <div className='cstm_container'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <h2 className="pp_hd">{title.rendered}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: content.rendered }} className='dynamic_dv'></div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                    <Footer />

                </div>
            </div>
        </>
    )
}
