import React from 'react'
import '../../../../assets/css/result_cstm.css';
import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Result_cstm() {


    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const cstm_rslt_hd = useRef(null);
    const cstm_rslt_p1 = useRef(null);
    const cstm_rslt_p2 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".cstm_rslt_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".cstm_rslt_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".cstm_rslt_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cstm_rslt_p1",
                markers: false
            }
        });
        gsap.fromTo(".cstm_rslt_p2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cstm_rslt_p2",
                markers: false
            }
        });

    })


    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/consumer_sector_page`
    );

    return (
        <>
            <div className='result_cstm mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-12'>

                                {acf.consumer_result_hd_sec.map((cstm_rslt) =>
                                    <div className='hd_dv' style={{ margin: 'auto' }}>
                                        <h2 className='heading txt_cntr wht_txt cstm_rslt_hd' ref={cstm_rslt_hd}>{cstm_rslt.consumer_result_hd}</h2>
                                    </div>
                                )}
                                {acf.consumer_result_hd_sec.map((cstm_rslt) =>
                                    <p className='reslt_para txt_cntr cstm_rslt_p1' ref={cstm_rslt_p1}>{cstm_rslt.consumer_result_sub_hd}</p>
                                )}

                            </div>

                            {/* {acf.consumer_result_box_sec.map((cstm_rslt1) =>
                                <div className='col-lg-4 col-md-4 col-12'>
                                    <div className='result_box cstm_rslt_p2' ref={cstm_rslt_p2}>
                                        <div className='rslt_inner'>
                                            <img src={cstm_rslt1.consumer_result_icon}></img>
                                            <div dangerouslySetInnerHTML={{ __html: cstm_rslt1.consumer_result_description }}></div>
                                        </div>
                                    </div>
                                </div>
                            )} */}

                            <div className='col-lg-4 col-md-4 col-12'>
                                <div className='result_box cstm_rslt_p2' ref={cstm_rslt_p2}>
                                    <div className='rslt_inner'>
                                        <img src='https://apollosupplychain.com/cms/wp-content/uploads/2022/11/29_percent.svg'></img>
                                        <p className='rslt_ttl'>Capacity Improvement</p>
                                        <p class="rslt_bx_p">The annual tonne-km by 29 percent</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-12'>
                                <div className='result_box cstm_rslt_p2' ref={cstm_rslt_p2}>
                                    <div className='rslt_inner'>
                                        <img src='https://apollosupplychain.com/cms/wp-content/uploads/2022/11/7_percent.svg'></img>
                                        <p className='rslt_ttl'>Reduced Transport Cost</p>
                                        <p class="rslt_bx_p">The transportation cost by nearly 7 percent</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-12'>
                                <div className='result_box cstm_rslt_p2' ref={cstm_rslt_p2}>
                                    <div className='rslt_inner'>
                                        <img src='https://apollosupplychain.com/cms/wp-content/uploads/2022/11/26_percent.svg'></img>
                                        <p className='rslt_ttl'>Optimized warehouse space</p>
                                        <p class="rslt_bx_p">Reduced warehousing area by 26 percent</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}
