import React from 'react'
import $ from "jquery";
import '../../../assets/css/info_ware.css';

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Asc_section() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const asc_lf_p1 = useRef(null);

    useEffect(() => {

        // Para animation code
        gsap.fromTo(".asc_lf_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.8,
            scrollTrigger: {
                trigger: ".asc_lf_p1",
                markers: false
            }
        });

        $.fn.isInViewport = function () {

            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight() / 2;
            var viewportTop = $(window).scrollTop();
            var viewportHalf = viewportTop + $(window).height() / 1;
            return elementBottom > viewportTop && elementTop < viewportHalf;
        };


        $(window).on("scroll", function () {

            $(".img_scroll_dv").each(function () {
                var windowTop = $(window).scrollTop();
                var elementTop = $(this).offset().top;
                var leftPosition = windowTop - elementTop;
                $(this)
                    .find(".inside_dv_img")
                    .css({ right: leftPosition });
            });
        });

    });

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/life_asc_page`
    );

    return (
        <>
            <div className='info_ware_sec '>

                <div className='row m_0'>

                    <div className='cstm_container col-md-12 col-12 p_0 mob_padding_LR tab_padding_LR'>

                        {data && data.map(({ acf }) => (
                            <div className='col-lg-12 p_0'>
                                <div className='info_ware_col1'>

                                    {acf.life_info.map((life_sub2) =>
                                        <p className='info_para_cust asc_lf_p1' ref={asc_lf_p1}>{life_sub2.life_info_description}</p>
                                    )}

                                </div>
                            </div>
                        ))}
                    </div>

                    {/* <div className='col-12 p_0'>
                        <div className='img_scroll_dv life_at_ascscroll'>
                            <div className='inside_dv_img'>

                                <img className='scroll_img' src={require('../../../assets/img/career1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career4.jpg')}></img>

                                <img className='scroll_img' src={require('../../../assets/img/career1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career4.jpg')}></img>

                                <img className='scroll_img' src={require('../../../assets/img/career1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career4.jpg')}></img>


                                <img className='scroll_img' src={require('../../../assets/img/career1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career4.jpg')}></img>

                                <img className='scroll_img' src={require('../../../assets/img/career1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career4.jpg')}></img>

                                <img className='scroll_img' src={require('../../../assets/img/career1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/career4.jpg')}></img>
                            </div>
                        </div>
                    </div> */}


                </div>

            </div>
        </>
    )
}
