import React from 'react'
import '../../../../assets/css/result_cstm.css';
import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Result_automo() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const auto_rslt_hd = useRef(null);
    const auto_rslt_p1 = useRef(null);
    const auto_rslt_p2 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".auto_rslt_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".auto_rslt_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".auto_rslt_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".auto_rslt_p1",
                markers: false
            }
        });
        gsap.fromTo(".auto_rslt_p2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".auto_rslt_p2",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/automotive_sec_page`
    );


    return (
        <>
            <div className='result_automo  mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-12'>

                                {acf.automotive_result_hd_sec.map((auto_rslt) =>
                                    <div className='hd_dv' style={{ margin: 'auto' }}>
                                        <h2 className='heading txt_cntr wht_txt auto_rslt_hd' ref={auto_rslt_hd}>{auto_rslt.automotive_result_hd}</h2>
                                    </div>
                                )}
                                {acf.automotive_result_hd_sec.map((auto_rslt) =>
                                    <p className='reslt_para txt_cntr auto_rslt_p1' ref={auto_rslt_p1}>{auto_rslt.automotive_result_sub_hd}</p>
                                )}

                            </div>

                            {/* {acf.automotive_result_box_sec.map((auto_rslt1) =>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className='result_box auto_rslt_1 auto_rslt_p2' ref={auto_rslt_p2}>
                                        <div className='rslt_inner'>
                                            <img src={auto_rslt1.automotive_result_icon}></img>
                                            <div dangerouslySetInnerHTML={{ __html: auto_rslt1.automotive_result_description }}></div>
                                        </div>
                                    </div>
                                </div>
                            )} */}

                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='result_box auto_rslt_1 auto_rslt_p2' ref={auto_rslt_p2}>
                                    <div className='rslt_inner'>
                                        <img src='https://apollosupplychain.com/cms/wp-content/uploads/2022/11/finished_goods.svg'></img>
                                        <p className='rslt_ttl'>Just-In-Time</p>
                                        <p class="rslt_bx_p">The improvements enabled JIT (Just In Time) delivery to OEMs and warehouses located across India. This included movements and storage of goods from the point of the procurement of the raw material to the storage of finished goods</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='result_box auto_rslt_1 auto_rslt_p2' ref={auto_rslt_p2}>
                                    <div className='rslt_inner'>
                                        <img src='https://apollosupplychain.com/cms/wp-content/uploads/2022/11/asc_capabilities.svg'></img>
                                        <p className='rslt_ttl'>Partners in Growth</p>
                                        <p class="rslt_bx_p">The resulting service excellence inspired greater faith in our capabilities.  The company subsequently entrusted us with the responsibility of managing warehouses facilities at multiple locations.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='result_box auto_rslt_1 auto_rslt_p2' ref={auto_rslt_p2}>
                                    <div className='rslt_inner'>
                                        <img src='https://apollosupplychain.com/cms/wp-content/uploads/2022/11/8_hours_per_day.svg'></img>
                                        <p className='rslt_ttl'>Reduced Overtime</p>
                                        <p class="rslt_bx_p">A significant reduction in overtime – from a daily average of 14 hours down to 8 hours.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='result_box auto_rslt_1 auto_rslt_p2' ref={auto_rslt_p2}>
                                    <div className='rslt_inner'>
                                        <img src='https://apollosupplychain.com/cms/wp-content/uploads/2022/11/98.svg'></img>
                                        <p className='rslt_ttl'>Improved On-time SLA</p>
                                        <p class="rslt_bx_p"> Achieved on-time delivery SLAs (Service Levell Agreement) of 98%.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
