import React from 'react'
import '../../../assets/css/ae_glance.css';

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Ae_glance() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const gln_hd = useRef(null);
    const gln_lst1 = useRef(null);
    const gln_lst2 = useRef(null);
    const gln_lst3 = useRef(null);
    const gln_lst4 = useRef(null);
    const gln_lst5 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".gln_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".gln_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".gln_lst1", {
            opacity: 0,
        }, {
            opacity: 0.8,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".gln_lst1",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/apollo_edge_page`
    );


    return (
        <>
            <div className='ae_glance mob_padding_LR tab_padding_LR' id='at_a_glance'>
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>

                            {acf.glance_section.map((edge_glance) =>
                                <div className='col-12'>

                                    <div className='hd_dv'>
                                        <h2 className='wht_txt glance_hd gln_hd' ref={gln_hd}>{edge_glance.glance_hd}</h2>
                                    </div>

                                    <div className='row'>

                                        {edge_glance.glance_list.map((glnc_list) =>
                                            <div className='col-lg-5 col-md-12 col-12'>
                                                <ul className='global_list glance_list'>
                                                    <li className='gln_lst1' ref={gln_lst1}>{glnc_list.glance_list_data}</li>
                                                </ul>
                                            </div>
                                        )}

                                    </div>


                                </div>
                            )}

                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}
