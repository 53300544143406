import React from 'react'
import '../../../../assets/css/solution_center.css'

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Solution_center() {


    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const sol_wr_hd = useRef(null);
    const sol_wr_p1 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".sol_wr_hd", {
            y: 400,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".sol_wr_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".sol_wr_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".sol_wr_p1",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/warehousing_page`
    );

    return (
        <>
            <div className='solution_center  mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-12 solu_col1'>

                                {acf.solutions_tab_section.map((ware_soltab) =>
                                    <div className='hd_dv' style={{ margin: 'auto' }}>
                                        <h2 className='heading sol_wr_hd' ref={sol_wr_hd}>{ware_soltab.solutions_tab_hd}</h2>
                                    </div>
                                )}

                            </div>
                            <div className='col-12'>
                                <div className='solutions_tabs sol_wr_p1' ref={sol_wr_p1}>
                                    <nav>
                                        <div className="nav nav-tabs mb-3 st_tab" id="nav-tab" role="tablist">

                                            {acf.fulfilment_centers_tab.map((ware_soltab1) =>
                                                <a className="nav-link active st_link" id="nav-fullfill-tab" data-toggle="tab" data-target="#nav-fullfill" type="button" role="tab" aria-controls="nav-fullfill" aria-selected="true">{ware_soltab1.solution_tab_name}</a>
                                            )}
                                            {acf.distribution_centers_tab.map((ware_soltab2) =>
                                                <a className="nav-link st_link" id="nav-distrib-tab" data-toggle="tab" data-target="#nav-distrib" type="button" role="tab" aria-controls="nav-distrib" aria-selected="false">{ware_soltab2.solution_tab_name}</a>
                                            )}
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade active show" id="nav-fullfill" role="tabpanel" aria-labelledby="nav-fullfill-tab">
                                            {acf.fulfilment_centers_tab.map((ware_soltab1) =>
                                                <div className='solu_content_dv'>
                                                    <img src={ware_soltab1.solution_tab_image}></img>
                                                    <div className='full_patch_dv'>
                                                        <p className='solu_sub_hd'>{ware_soltab1.solution_tab_name}</p>
                                                        <p className='solu_sub_ttl'>{ware_soltab1.solution_tab_description}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="tab-pane fade" id="nav-distrib" role="tabpanel" aria-labelledby="nav-distrib-tab">
                                            {acf.distribution_centers_tab.map((ware_soltab2) =>
                                                <div className='solu_content_dv'>
                                                    <img src={ware_soltab2.solution_tab_image}></img>
                                                    <div className='full_patch_dv'>
                                                        <p className='solu_sub_hd'>{ware_soltab2.solution_tab_name}</p>
                                                        <p className='solu_sub_ttl'>{ware_soltab2.solution_tab_description}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}
