import React from 'react'
import '../../../../assets/css/challange_cstm.css';

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Challange_cstm() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const cstm_rslt_hd = useRef(null);
    const cstm_rslt_p1 = useRef(null);
    const cstm_rslt_lst = useRef(null);

    useEffect(() => {

        gsap.fromTo(".cstm_rslt_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".cstm_rslt_hd",
                markers: false
            }
        });


        // Para animation code
        gsap.fromTo(".cstm_rslt_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cstm_rslt_p1",
                markers: false
            }
        });

        gsap.fromTo(".cstm_rslt_lst", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cstm_rslt_lst",
                markers: false
            }
        });


    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/consumer_sector_page`
    );

    return (
        <>
            <div className='challange_cstm  mob_padding_LR tab_padding_LR' id='challange_cstm'>
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>

                            <div className='col-12'>

                                {acf.customer_challenge.map((cstm_clng) =>
                                    <div className='hd_dv' style={{ margin: 'auto' }}>
                                        <h2 className='heading txt_cntr cstm_rslt_hd' ref={cstm_rslt_hd}>{cstm_clng.customer_challenge_hd}</h2>
                                    </div>
                                )}

                                {acf.customer_challenge.map((cstm_clng) =>
                                    <p className='chalng_para cstm_rslt_p1' ref={cstm_rslt_p1}>{cstm_clng.customer_challenge_description}</p>
                                )}

                            </div>

                            {/* {acf.customer_challenge_list.map((cstm_clng1) =>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <ul className='global_list chalng_list'>
                                        <li className='cstm_rslt_lst' ref={cstm_rslt_lst}>{cstm_clng1.cstm_challenge_list}</li>
                                    </ul> 
                                </div>

                            )} */}

                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className='chalng_box'>
                                    <div className='chalng_inner'>
                                        <div className='chalng_icon_dv'>
                                            <img src={require('../../../../assets/img/svg/chlng_icon.svg').default}></img>
                                        </div>
                                        <div className='chalng_txt_dv'>
                                            <p className='chlng_bx_hd'>Process Overlaps</p>
                                            <p>Two warehouse facilities, located within a distance of 100 km distance, presented the challenges of redundant stoppage nodes and process overlaps. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className='chalng_box'>
                                    <div className='chalng_inner'>
                                        <div className='chalng_icon_dv'>
                                            <img src={require('../../../../assets/img/svg/chlng_icon.svg').default}></img>
                                        </div>
                                        <div className='chalng_txt_dv'>
                                            <p className='chlng_bx_hd'>Low Capacity Utilization</p>
                                            <p>The warehouses lacked multi-tier racking systems, as a result of which pallets were stored mostly on the warehouse floor. This resulted in low storage utilization.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className='chalng_box'>
                                    <div className='chalng_inner'>
                                        <div className='chalng_icon_dv'>
                                            <img src={require('../../../../assets/img/svg/chlng_icon.svg').default}></img>
                                        </div>
                                        <div className='chalng_txt_dv'>
                                            <p className='chlng_bx_hd'>Higher Logistics Cost</p>
                                            <p>Inefficient production planning and demand forecasting methods resulted in the mismanagement of inventory handling, adding further to the total logistics cost.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}
