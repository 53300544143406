import React from 'react'
import '../../../../assets/css/service_offer_ware.css';

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Part_truck() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const prk_trk_hd = useRef(null);
    const prk_trk_p1 = useRef(null);
    const prk_trk_img1 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".prk_trk_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".prk_trk_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".prk_trk_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".prk_trk_p1",
                markers: false
            }
        });

        gsap.fromTo(".prk_trk_img1", {
            x: 0
        }, {
            xPercent: 101,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".prk_trk_img1",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/land_transport_page`
    );


    return (
        <>
            <div className='park_truck_sec SO_ware mob_padding_LR tab_padding_LR ' id='ptl'>

                {data && data.map(({ acf }) => (
                    <div className='cstm_container'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-12'>

                                {acf.part_truck_section.map((land_sub3) =>
                                    <div className='info_ware_col1'>

                                        <div className='hd_dv'>
                                            <h2 className='heading part_truck_head prk_trk_hd' ref={prk_trk_hd}>{land_sub3.park_truck_hd}</h2>
                                        </div>

                                        <p className='asc_prime_para prk_trk_p1' ref={prk_trk_p1}>{land_sub3.park_truck_description}</p>

                                    </div>
                                )}

                            </div>


                        </div>



                        <div className='row'>

                            <div className='col-lg-7 col-md-12 col-12'>
                                <div id="accordion" className="surface_prk_trk_acrd accordion_solution truck_loaded solution_pad prk_trk_p1 park_truck_accord" ref={prk_trk_p1}>

                                    {/* Solution One */}

                                    {acf.the_aggregator_model.map((land_acrd1) =>
                                        <div className="card">
                                            <div className="card-header">
                                                <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseOne">
                                                    <span className="soluion_ttl">
                                                        {land_acrd1.land_truck_hd}
                                                    </span>
                                                    <span className="status_icon accord_icon">
                                                        <img className="sol_plus_icon" src={require('../../../../assets/img/svg/accordian_plus.svg').default}></img>
                                                        <img className="sol_minus_icon" src={require('../../../../assets/img/svg/accordian_minus.svg').default}></img>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapseOne" className="collapse" data-parent="#accordion">
                                                <div className="card-body">
                                                    <div className='warehouse_content'>
                                                        <p className='soluion_content'>{land_acrd1.land_truck_description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Solution One */}
                                    {acf.one_contact_one_contract.map((land_acrd2) =>
                                        <div className="card">
                                            <div className="card-header">
                                                <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseTwo">
                                                    <span className="soluion_ttl">
                                                        {land_acrd2.land_truck_hd}
                                                    </span>
                                                    <span className="status_icon accord_icon">
                                                        <img className="sol_plus_icon" src={require('../../../../assets/img/svg/accordian_plus.svg').default}></img>
                                                        <img className="sol_minus_icon" src={require('../../../../assets/img/svg/accordian_minus.svg').default}></img>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapseTwo" className="collapse " data-parent="#accordion">
                                                <div className="card-body">
                                                    <div className='warehouse_content'>
                                                        <p className='soluion_content'>{land_acrd2.land_truck_description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Solution One */}
                                    {acf.save_money_minimize_oda.map((land_acrd3) =>
                                        <div className="card">
                                            <div className="card-header">
                                                <button className="collapsed card-link" data-toggle="collapse" data-target="#collapsethree">
                                                    <span className="soluion_ttl">
                                                        {land_acrd3.land_truck_hd}
                                                    </span>
                                                    <span className="status_icon accord_icon">
                                                        <img className="sol_plus_icon" src={require('../../../../assets/img/svg/accordian_plus.svg').default}></img>
                                                        <img className="sol_minus_icon" src={require('../../../../assets/img/svg/accordian_minus.svg').default}></img>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapsethree" className="collapse" data-parent="#accordion">
                                                <div className="card-body">
                                                    <div className='warehouse_content'>
                                                        <p className='soluion_content'>{land_acrd3.land_truck_description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Solution One */}
                                    {acf.knowledge_of_local_conditions.map((land_acrd4) =>
                                        <div className="card">
                                            <div className="card-header">
                                                <button className="collapsed card-link" data-toggle="collapse" data-target="#collapsefour">
                                                    <span className="soluion_ttl">
                                                        {land_acrd4.land_truck_hd}
                                                    </span>
                                                    <span className="status_icon accord_icon">
                                                        <img className="sol_plus_icon" src={require('../../../../assets/img/svg/accordian_plus.svg').default}></img>
                                                        <img className="sol_minus_icon" src={require('../../../../assets/img/svg/accordian_minus.svg').default}></img>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapsefour" className="collapse" data-parent="#accordion">
                                                <div className="card-body">
                                                    <div className='warehouse_content'>
                                                        <p className='soluion_content'>{land_acrd4.land_truck_description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Solution One */}
                                    {acf.control_towers_for_e2e_visibility.map((land_acrd5) =>
                                        <div className="card">
                                            <div className="card-header">
                                                <button className="collapsed card-link" data-toggle="collapse" data-target="#collapsefive">
                                                    <span className="soluion_ttl">
                                                        {land_acrd5.land_truck_hd}
                                                    </span>
                                                    <span className="status_icon accord_icon">
                                                        <img className="sol_plus_icon" src={require('../../../../assets/img/svg/accordian_plus.svg').default}></img>
                                                        <img className="sol_minus_icon" src={require('../../../../assets/img/svg/accordian_minus.svg').default}></img>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapsefive" className="collapse" data-parent="#accordion">
                                                <div className="card-body">
                                                    <div className='warehouse_content'>

                                                        <p className='soluion_content'>{land_acrd5.land_truck_description}</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                            <div className='col-lg-5 col-md-12 col-12'>
                                <div className='so_col1_network img_air'>

                                    {acf.part_truck_section.map((land_sub3) =>
                                        <div className='img_dv'>
                                            <div className='img_oly prk_trk_img1' ref={prk_trk_img1}></div>
                                            <img className='so_col_img' src={land_sub3.park_truck_image}></img>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>




                    </div>
                ))}

            </div>
        </>
    )
}
