import React from 'react'
import '../../assets/css/inside_page_spotlight.css'

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


export default function Infrastructure_spotlight() {


  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const infra_spot_hd = useRef(null);
  const infra_spt_p = useRef(null);
  const infra_spt_ad1 = useRef(null);
  const infra_spt_ad2 = useRef(null);


  useEffect(() => {

    gsap.fromTo(".infra_spot_hd", {
      y: 250,
      opacity: 0,
      skewY: 41,
    }, {
      delay: 2,
      duration: 0.8,
      y: 0,
      skewY: 0,
      opacity: 1,
      ease: "power4.out",
      stagger: {
        from: 'start',
        amount: 0.5,
      },
      scrollTrigger: {
        trigger: ".infra_spot_hd",
        markers: false
      }
    });

    // Para animation code
    gsap.fromTo(".infra_spt_p", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.5,
      scrollTrigger: {
        trigger: ".infra_spt_p",
        markers: false
      }
    });

    // Para animation code
    gsap.fromTo(".infra_spt_ad1", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.5,
      scrollTrigger: {
        trigger: ".infra_spt_ad1",
        markers: false
      }
    });
    gsap.fromTo(".infra_spt_ad2", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.6,
      scrollTrigger: {
        trigger: ".infra_spt_ad2",
        markers: false
      }
    });

  })


  return (
    <>
      <div className='infra_spotlight page_spotlight service_spotlight'>
        <div className='spotlight_container'>
          <div className='row'>
            <div className='col-12 pr_0'>
              <div className='spotlight_dv'>
                <div className='spot_contnt'>
                  <div className='spot_col1'>

                    <div className='hd_dv'>
                      <h1 className='spot_heading wht_txt infra_spot_hd' ref={infra_spot_hd}>Infrastructure For You</h1>
                    </div>
                
                    <p className='wht_txt offer_text infra_spt_p' ref={infra_spt_p}>Our world-class CFS facilities offer international connectivity and nationwide reach for your goods.</p>
                  </div>
                  <div className='spot_col2 infras_spot'>

                    <div class="dropdown">
                      <a href="#" class="js-link"><i class="fa fa-chevron-down"></i></a>
                      <ul class="warehouse_list">
                        <a className='infra_spt_ad1' ref={infra_spt_ad1} href='/CFS-&-ICDs#cfs_icds'><li className='service_listing'>CFS</li> </a>
                        <a className='infra_spt_ad2' ref={infra_spt_ad2} href='/CFS-&-ICDs#custom_brokerage'><li className='service_listing'>Custom Brokerage</li> </a>
                      </ul>
                    </div>
                    {/* <a href='#' className='btn wht_btn'>
                      <span className='btn_txt'>Learn More</span>
                      <span className='arrow_btn wht_arrow'></span>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
