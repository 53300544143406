import React from 'react'
import '../../assets/css/inside_page_spotlight.css'

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Freight_spotlight() {

  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const frght_spot_hd = useRef(null);
  const frght_spot_p = useRef(null);
  const frght_spt_ad1 = useRef(null);
  const frght_spt_ad2 = useRef(null);

  useEffect(() => {

    gsap.fromTo(".frght_spot_hd", {
      y: 250,
      opacity: 0,
      skewY: 41,
    }, {
      delay: 2,
      duration: 0.7,
      y: 0,
      skewY: 0,
      opacity: 1,
      ease: "power4.out",
      stagger: {
        from: 'start',
        amount: 0.5,
      },
      scrollTrigger: {
        trigger: ".frght_spot_hd",
        markers: false
      }
    });

    gsap.fromTo(".frght_spot_p", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.7,
      ease: "linear",
      delay: 2.5,
      scrollTrigger: {
        trigger: ".frght_spot_p",
        markers: false
      }
    });

    // Para animation code
    gsap.fromTo(".frght_spt_ad1", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.5,
      scrollTrigger: {
        trigger: ".frght_spt_ad1",
        markers: false
      }
    });
    gsap.fromTo(".frght_spt_ad2", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.6,
      scrollTrigger: {
        trigger: ".frght_spt_ad2",
        markers: false
      }
    });
    

  })
  return (
    <>
      <div className='freight_spotlight page_spotlight service_spotlight'>
        <div className='spotlight_container'>
          <div className='row'>
            <div className='col-12 pr_0'>
              <div className='spotlight_dv'>
                <div className='spot_contnt'>
                  <div className='spot_col1'>

                    <div className='hd_dv'>
                      <h1 className='spot_heading wht_txt frght_spot_hd' ref={frght_spot_hd}>Freight Forwarding</h1>
                    </div>
                    
                    <p className='spot_sb_ttl frght_spot_p' ref={frght_spot_p}>Leading The Way </p>
                    {/* <p className='wht_txt offer_text'>Need our expertise for supply chain strategy, network design, compliance? Our guidance and advisory services are with you every step of the way</p> */}
                  </div>
                  <div className='spot_col2'>
                    {/* <p className='wht_txt'>Our countrywide network of warehouses provides a wide range of services including dedicated and multi-user facilities. Delivering agility, reliability, and sustainability, our solutions are transforming businesses across verticals.</p> */}
                    <div className="dropdown">
                      <a href="#" className="js-link"><i className="fa fa-chevron-down"></i></a>
                      <ul className="warehouse_list">
                        <a className='frght_spt_ad1' ref={frght_spt_ad1} href='/freight-forwarding#ocean_freight'><li className='service_listing'>Ocean Freight</li> </a>
                        <a className='frght_spt_ad2' ref={frght_spt_ad2} href='/freight-forwarding#air_freight'><li className='service_listing'>Air Freight </li> </a>
                      </ul>
                    </div>
                    {/* <a href='#' className='btn wht_btn'>
                      <span className='btn_txt'>Learn More</span>
                      <span className='arrow_btn wht_arrow'></span>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
