import React from 'react'
import { useParams } from "react-router-dom";
import useFetch from "react-fetch-hook";
import Masonry from 'react-masonry-css';
import $ from "jquery";


export default function Search_page() {



    let { name } = useParams();


    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/events?search=${name}`
    );


    const breakpointColumnsObj = {
        default: 1,
        1100: 1,
        700: 2,
        500: 1
    };


    return (
        <>




            {/* {data.length}  */}
            {data && data.map(({ title, acf, content, slug, excerpt }) => (
                <div className='cstm-container blog-inside-page rslt_bx'  >
                    {isLoading && <div>A moment please...</div>}
                    {error && (
                        <div>{`There is a problem fetching the post data - ${error}`}</div>
                    )}

                    <h1 className="blg_ttl">{title.rendered}</h1>
                    <p className='srch_dscrb' dangerouslySetInnerHTML={{ __html: content.rendered }}></p>
                    <a className='blg-view-btn' href={acf.url + slug}>Read more</a>

                </div>
            ))}




        </>
    )
}
