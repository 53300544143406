import React from 'react'
import '../../../../assets/css/asc_advance_ware.css';


import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Asc_advance_ware() {


    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const advnc_wr_hd = useRef(null);
    const advnc_wr_p1 = useRef(null);
    const advnc_wr_p2 = useRef(null);
    const advnc_wr_p3 = useRef(null);
    const advnc_wr_p1_2 = useRef(null);
    const advnc_wr_p4 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".advnc_wr_hd", {
            y: 400,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".advnc_wr_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".advnc_wr_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".advnc_wr_p1",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".advnc_wr_p2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".advnc_wr_p2",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".advnc_wr_p3", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".advnc_wr_p3",
                markers: false
            }
        });
        gsap.fromTo(".advnc_wr_p1_2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".advnc_wr_p1_2",
                markers: false
            }
        });


        gsap.fromTo(".advnc_wr_p4", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".advnc_wr_p4",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/warehousing_page`
    );

    return (
        <>
            <div className='asc_advance advnc_ware mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>

                            {acf.warehouse_advantage.map((ware_advntg) =>
                                <div className='col-12 asc_advnc_col1'>

                                    <div className='hd_dv'>
                                        <h2 className='heading wht_txt advnc_wr_hd' ref={advnc_wr_hd}>{ware_advntg.ware_advantage_hd1}</h2>
                                    </div>
                                    <div className='hd_dv'>
                                        <h2 className='heading wht_txt advnc_wr_hd' ref={advnc_wr_hd}>{ware_advntg.ware_advantage_hd2}</h2>
                                    </div>

                                    <p className='advn_col_desc advnc_wr_p1' ref={advnc_wr_p1}>{ware_advntg.ware_advantage_sub_hd}</p>

                                </div>
                            )}

                            {acf.warehouse_advantage_box.map((ware_advntg1) =>
                                <div className='col-lg-4 col-md-12 col-12'>
                                    <div className='asc_advnc_col advnc_wr_p1_2' ref={advnc_wr_p1_2}>
                                        <img src={ware_advntg1.warehouse_advantage_image}></img>

                                        {/* <div className='aac_hd_dv'>
                                            <p className='aac_hd'>{ware_advntg1.warehouse_advantage_hd}</p>
                                        </div> */}
                                        <div className='asc_advnc_ctnt'>
                                            <img className='asc_ad_arrow' src={require('../../../../assets/img/svg/up_right_arrow.svg').default}></img>
                                            <p className='aac_hd'>{ware_advntg1.warehouse_advantage_hd}</p>
                                            <p className='aac_desc'>{ware_advntg1.warehouse_advantage_description}</p>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {acf.warehouse_advantage.map((ware_advntg) =>
                                <div className='col-12'>
                                    <hr></hr>
                                    <h5 className='asc_adv_ttl2 wht_txt advnc_wr_p4' ref={advnc_wr_p4}>{ware_advntg.ware_advantage_sub_hd1}</h5>
                                    <a href={ware_advntg.warehouse_advantage_button_url} className='btn wht_btn advnc_wr_p4' ref={advnc_wr_p4}>
                                        <span className='btn_txt'>{ware_advntg.warehouse_advantage_button_name}</span>
                                        <span className='arrow_btn wht_arrow'></span>
                                    </a>
                                </div>
                            )}

                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}
