import React from 'react'
import '../../../assets/css/blog_featured.css';
import '../../../assets/css/event_sec.css';

import useFetch from "react-fetch-hook";
import $ from "jquery";

import { useEffect, useRef, useState } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Event_tab from './event_tab';
import News_tab from './news_tab';


export default function Event_sec() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const event_tab_anm = useRef(null);

    useEffect(() => {

        gsap.fromTo(".event_tab_anm", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".event_tab_anm",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        "https://apollosupplychain.com/cms/wp-json/wp/v2/events/"
    );

    return (
        <>
            <div className='event_sec blog_featured mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>

                    <div className='row event_tab_anm' ref={event_tab_anm}>
                        <div className='col-12'>
                            <News_tab />
                            <Event_tab />
                            {/* <nav>
                                <div className="nav nav-tabs mb-3 st_tab" id="nav-tab" role="tablist">
                                    <a className="nav-link active st_link" id="nav-news-tab" data-toggle="tab" data-target="#nav-news" type="button" role="tab" aria-controls="nav-news" aria-selected="false">News</a>
                                    <a className="nav-link st_link" id="nav-event-tab" data-toggle="tab" data-target="#nav-event" type="button" role="tab" aria-controls="nav-event" aria-selected="true">Events</a>
                                </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade active show" id="nav-news" role="tabpanel" aria-labelledby="nav-news-tab"></div>
                                <div className="tab-pane fade " id="nav-event" role="tabpanel" aria-labelledby="nav-event-tab"></div>
                            </div> */}
                        </div>
                    </div>




                </div>
            </div>
        </>
    )
}
