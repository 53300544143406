import React from 'react'
import '../../../../assets/css/counter.css';
import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
import useFetch from "react-fetch-hook";

export default function Infra_counter_ware() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const infra_c_ct1 = useRef(null);
    const infra_c_ct2 = useRef(null);
    const infra_c_ct3 = useRef(null);
    const infra_c_ct4 = useRef(null);

    useEffect(() => {



        if ($(window).width() <= 1380) {

            gsap.fromTo(".infra_c_ct1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.3,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".infra_c_ct1",
                    markers: false
                }
            });
            gsap.fromTo(".infra_c_ct2", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.3,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".infra_c_ct2",
                    markers: false
                }
            });

            gsap.fromTo(".infra_c_ct3", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.3,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".infra_c_ct3",
                    markers: false
                }
            });

            gsap.fromTo(".infra_c_ct4", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.3,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".infra_c_ct4",
                    markers: false
                }
            });

        } else {

            // Para animation code
            gsap.fromTo(".infra_c_ct1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.3,
                ease: "linear",
                delay: 2,
                scrollTrigger: {
                    trigger: ".infra_c_ct1",
                    markers: false
                }
            });
            gsap.fromTo(".infra_c_ct2", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.3,
                ease: "linear",
                delay: 2,
                scrollTrigger: {
                    trigger: ".infra_c_ct2",
                    markers: false
                }
            });

            gsap.fromTo(".infra_c_ct3", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.3,
                ease: "linear",
                delay: 2,
                scrollTrigger: {
                    trigger: ".infra_c_ct3",
                    markers: false
                }
            });

            gsap.fromTo(".infra_c_ct4", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.3,
                ease: "linear",
                delay: 2,
                scrollTrigger: {
                    trigger: ".infra_c_ct4",
                    markers: false
                }
            });


        }

    })
    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/cfs_and_icd_page/`
    );

        return (
            <>
                <div className='counter counter_ware mob_padding_LR tab_padding_LR' id='counter'>
                {data && data.map(({ acf }) => (
                    <div className='cstm_container'>
                        <div className='row'>
                        
                            {acf.cfs_and_icd_counter.map((infra_cntn) =>
                                <div className='col-lg-3 col-md-3 col-12'>
                                    <div className='count_col cc_1 infra_c_ct1' ref={infra_c_ct1}>
                                    
                                        <div className='cc_inner'>
                                        
                                            <p className='cc_ttl infra_title'> {infra_cntn.counter_text1} </p>
                                        
                                            <p className='cc_num_1 infra_num'>{infra_cntn.counter_number}</p>
                                            <p className='cc_ttl infra_title'>{infra_cntn.counter_text2}</p>
                                        </div>
                                    
                                    </div>
                                </div>
                            )}                          
                            
                        </div>
                    </div>
                 ))}   
                </div>
            </>
        )
    }
