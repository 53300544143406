import React from 'react'
import '../../../assets/css/purpose.css';


import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import useFetch from "react-fetch-hook";

export default function Purpose() {

  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const prps_hd = useRef(null);
  const prps_p = useRef(null);


  useEffect(() => {

    gsap.fromTo(".prps_hd", {
      y: 250,
      opacity: 0,
      skewY: 41,
    }, {
      delay: 0,
      duration: 0.8,
      y: 0,
      skewY: 0,
      opacity: 1,
      ease: "power4.out",
      stagger: {
        from: 'start',
        amount: 0.5,
      },
      scrollTrigger: {
        trigger: ".prps_hd",
        markers: false
      }
    });

    // Para animation code
    gsap.fromTo(".prps_p", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.8,
      ease: "linear",
      delay: 0,
      scrollTrigger: {
        trigger: ".prps_p",
        markers: false
      }
    });

  })

  const { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/about_page`
  );

  return (
    <>
      <div className='purpose_sec pmv_sec mob_padding_LR tab_padding_LR' id='purpose'>
        <div className='cstm_container'>
          <div className='row'>
            <div className='col-12'>

              {data && data.map(({ acf }) => (
                <div className='purpose_col'>

                  {acf.about_sec_3.map((purpose_sub) =>
                    <div className='hd_dv'>
                      <h2 className='pd_ttl heading wht_txt prps_hd' ref={prps_hd}>{purpose_sub.purpose_hd}</h2>
                    </div>
                  )}

                  {acf.about_sec_3.map((purpose_sub) =>
                    <p className='so_sub_ttl wht_txt vlu_p prps_p' ref={prps_p}>{purpose_sub.purpose_description}</p>
                  )}
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
