import React from 'react'
import '../../../../assets/css/challange_cstm.css';

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Challange_cstm() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const auto_rslt_hd = useRef(null);
    const auto_rslt_p1 = useRef(null);
    const auto_rslt_lst = useRef(null);

    useEffect(() => {

        gsap.fromTo(".auto_rslt_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".auto_rslt_hd",
                markers: false
            }
        });


        // Para animation code
        gsap.fromTo(".auto_rslt_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".auto_rslt_p1",
                markers: false
            }
        });

        gsap.fromTo(".auto_rslt_lst", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".auto_rslt_lst",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/automotive_sec_page`
    );

    return (
        <>
            <div className='challange_cstm  mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-12'>

                                {acf.automotive_challenge.map((auto_clng) =>
                                    <div className='hd_dv' style={{ margin: 'auto' }}>
                                        <h2 className='heading txt_cntr auto_rslt_hd' ref={auto_rslt_hd}>{auto_clng.automotive_challenge_hd}</h2>
                                    </div>
                                )}

                                {/* {acf.automotive_challenge.map((auto_clng) =>
                                    <p className='chalng_para auto_rslt_p1' ref={auto_rslt_p1}>{auto_clng.automotive_challenge_description1}</p>
                                )} */}

                                {acf.automotive_challenge.map((auto_clng) =>
                                    <p className='chalng_para auto_rslt_p1' ref={auto_rslt_p1}></p>
                                )}

                            </div>

                            {/* {acf.automotive_challenge_list.map((auto_clng1) =>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <ul className='global_list chalng_list'>
                                        <li className='auto_rslt_lst' ref={auto_rslt_lst}>{auto_clng1.auto_challenge_list}</li>
                                    </ul>
                                </div>
                            )} */}

                            
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className='chalng_box'>
                                    <div className='chalng_inner'>
                                        <div className='chalng_icon_dv'>
                                            <img src={require('../../../../assets/img/svg/chlng_icon.svg').default}></img>
                                        </div>
                                        <div className='chalng_txt_dv'>
                                            <p className='chlng_bx_hd'>Stock Issues</p>
                                            <p>The manufacturer faced issues in plan execution like missing priority parts, missing checks on availability of stock etc. that reported an average of 40 complaints/ month.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className='chalng_box'>
                                    <div className='chalng_inner'>
                                        <div className='chalng_icon_dv'>
                                            <img src={require('../../../../assets/img/svg/chlng_icon.svg').default}></img>
                                        </div>
                                        <div className='chalng_txt_dv'>
                                            <p className='chlng_bx_hd'>Unoptimized warehouse layout plan</p>
                                            <p>Our team discovered that the layout plan of the warehouse was unoptimized. For example, poor goods storage plan significantly increased the amount of time spent in searching of products for picking and dispatching which, in turn, increased working hours and resulted in cost overheads.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className='chalng_box'>
                                    <div className='chalng_inner'>
                                        <div className='chalng_icon_dv'>
                                            <img src={require('../../../../assets/img/svg/chlng_icon.svg').default}></img>
                                        </div>
                                        <div className='chalng_txt_dv'>
                                            <p className='chlng_bx_hd'>Higher Lead Times</p>
                                            <p>Two warehouses, located 100 km apart, increased process redundancies, the number of storage and transportation touchpoints, and the overall lead time.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}
