import React from 'react'
import '../../../../assets/css/apllo_edge_tech.css';
import '../../../../assets/css/pharma.css';

import { useEffect, useRef } from 'react';
import $ from 'jquery';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Pharma_sec() {


    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const ph_hd = useRef(null);
    const ph_p1 = useRef(null);
    const ph_img1 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".ph_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.8,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".ph_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".ph_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.8,
            scrollTrigger: {
                trigger: ".ph_p1",
                markers: false
            }
        });

        gsap.fromTo(".ph_img1", {
            x: 0
        }, {
            xPercent: 100,
            duration: 0.5,
            ease: "linear",
            delay: 0.8,
            scrollTrigger: {
                trigger: ".ph_img1",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/other_sector_page`
    );


    return (
        <>
            <div className='lifestyle_other_sec pharma_sec apollo_edge_tech  mob_padding_LR tab_padding_LR' id="pharmaceuticals">
                
                {data && data.map(({ acf }) => (
                    <div>
                        <div className='cstm_container'>

                            <div className='row'>

                                {/* <div className='col-lg-5 col-md-5 col-12 '>

                                    {acf.pharma_section.map((other_sub2) =>
                                        <div className='img_dv'>
                                            <div className='img_oly ph_img1' ref={ph_img1}></div>
                                            <img className='tech_img' src={other_sub2.pharma_image}></img>
                                        </div>
                                    )}
                                </div> */}
                                <div className='col-lg-12 col-md-12 col-12'>

                                    {acf.pharma_section.map((other_sub2) =>
                                        <div className='ph_inner_dv'>

                                            {/* <div className='hd_dv'>
                                                <h2 className='wht_txt heading ph_hd' ref={ph_hd}>{other_sub2.pharma_hd}</h2>
                                            </div> */}
                                            <p className=' apollo_tech_para ph_p1' ref={ph_p1}>{other_sub2.pharma_descripton}</p>
                                        </div>
                                    )}
                                </div>

                            </div>

                        </div>
                    </div>
                ))}

            </div>
        </>
    )
}
