import React from 'react'
import '../../../../assets/css/service_offer_ware.css';

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Digitalization() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const net_hd = useRef(null);
    const net_p1 = useRef(null);
    const net_img1 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".net_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".net_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".net_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".net_p1",
                markers: false
            }
        });

        gsap.fromTo(".net_img1", {
            x: 0
        }, {
            xPercent: 101,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".net_img1",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/supply_chain_page/`
    );


    return (
        <>
            <div className='Network_offer SO_ware mob_padding_LR tab_padding_LR digitalize_sec' id='network_design_re-design'>

                <div className='cstm_container'>

                    <div className='row'>
                        <div className='col-lg-5 col-md-6 col-12'>

                                <div className='so_col1_network'>
                                    <div className='img_dv'>
                                        <div className='img_oly net_img1' ref={net_img1}></div>
                                        
                                            <img className='so_col_img consulting_img' src={require('../../../../assets/img/supply_chain_digitalization.jpg')}></img>
                                      
                                    </div>
                                </div>
                         

                        </div>
                        <div className='col-lg-7 col-md-6 col-12 '>
                            <div className='design_ttl'>

                                <div className='hd_dv'>
                                    <h2 className='heading supply_head net_hd' ref={net_hd}>Supply Chain Digitalization </h2>
                                </div>
                                <p className='so_col_desc network_para net_p1 sc_digital_p' ref={net_p1}>
                                The enablement of digital transformation of your supply chain has multiple benefits including but not limited to process improvements and standardization, more accurate forecasting, and improved KPIs. The biggest benefit, of course, is E2E visibility, and as a direct consequence, greater agility.
                                </p>

                                <p className='so_col_desc network_para net_p1 sc_digital_p' ref={net_p1}>
                                    <b>Process Improvements:</b> Our IT experts specialize in enabling and driving the digital transformation of supply chain organizations. Digitalization of decision-making methods like value-stream mapping, gap identification and BRD (Business Requirement Document) reporting means continual improvement, substantial OpEx savings, and greater agility.
                                </p>
                                <p className='so_col_desc network_para net_p1 sc_digital_p' ref={net_p1}><b>Control Tower:</b> An ASC Control Tower, that we can set up at your premises too, combines the power of advanced technologies like AI/ML and big data to give the critical visibility you need for informed decision-making. It means important cost savings and efficiency, improved KPIs, and forecasting. <a href='/control-tower-&-analytics-center' className='cntrol_twr_link'>Read more about Control Tower</a></p> 
                                

                                

                                <p className='so_col_desc network_para net_p1 sc_digital_p' ref={net_p1}><b>Data Analytics:</b> ASC’s interactive decision-making dashboards put the power of advanced data analytics at your fingertips. It means new insights into processes; and remarkable improvements in forecasting accuracy service levels. Try it to believe it.</p>
                            </div>
                        </div>
                    </div>




                </div>

            </div>
        </>
    )
}
