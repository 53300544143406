import React from 'react'
import '../../../../assets/css/service_offer_ware.css';
import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from 'jquery';
import useFetch from "react-fetch-hook";

export default function Ocean_freight() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const ocn_hd = useRef(null);
    const ocn_p1 = useRef(null);
    const ocn_img1 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".ocn_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".ocn_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".ocn_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ocn_p1",
                markers: false
            }
        });

        gsap.fromTo(".ocn_img1", {
            x: 0
        }, {
            xPercent: 101,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ocn_img1",
                markers: false
            }
        });

        $(".ocean_content").hide();
        $(".ocean").on("click", function () {
            var txt = $(".ocean_content").is(':visible') ? 'Read More' : 'Read Less';
            $(".ocean").text(txt);
            $('.ocean_content').slideToggle(200);
        }); // for Para

        $(".ocean_link").on("click", function () {
            var txt = $(".ocean_content").is(':visible') ? 'Read Less' : 'Read Less';
            $(".ocean").text(txt);
            $('.ocean_content').slideDown(200);
        }); // for Spotlight link

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/freight_forwarding/`
    );

    return (
        <>
            <div className='ocean_freight_section SO_ware mob_padding_LR tab_padding_LR' id="ocean_freight">
                {data && data.map(({ acf }) => (
                    <div className='cstm_container'>

                        <div className='row'>

                            <div className='col-lg-12 col-md-12 col-12'>
                                <div className='hd_dv'>
                                    {acf.ocean_freight_section.map((ocn_cntn) =>
                                        <h2 className='heading ocean_head ocn_hd' ref={ocn_hd}>{ocn_cntn.ocean_hd} </h2>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-12 col-12 ocean_para_main'>

                                {acf.ocean_freight_section.map((ocn_cntn) =>
                                    <p className='so_col_desc ocean_ttl ocn_p1' ref={ocn_p1}>{ocn_cntn.ocean_hd2}</p>
                                )}

                                {acf.ocean_freight_section.map((ocn_cntn) =>
                                    <div className='ocn_p1' dangerouslySetInnerHTML={{ __html: ocn_cntn.ocean_description1 }} ref={ocn_p1}>

                                    </div>
                                )}

                                {acf.ocean_freight_section.map((ocn_cntn) =>
                                    <div className='ocean_content ocn_p1' dangerouslySetInnerHTML={{ __html: ocn_cntn.ocean_description_with_read_more }} ref={ocn_p1}>

                                    </div>
                                )}

                                <a className="ocean ocn_p1 read_more_btn" data-content="toggle-text" ref={ocn_p1}>Read More</a>
                            </div>
                            <div className='col-lg-5 col-md-12 col-12'>
                                <div className='so_col1_optimization freight_img ocn_frg_img_dv'>
                                    <div className='img_dv'>
                                        <div className='img_oly blue_oly ocn_img1' ref={ocn_img1}></div>
                                        {acf.ocean_freight_section.map((ocn_img) =>
                                            <img className='so_col_img_multi' src={ocn_img.ocean_image}></img>
                                        )}
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                ))}
            </div>
        </>
    )
}
