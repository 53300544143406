import React from 'react'
import '../../../../assets/css/case_study.css';

import { useEffect, useRef } from 'react';
import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Service_sector_ecom() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const cstm_Cs_p1 = useRef(null);
    const cstm_Cs_img1 = useRef(null);

    useEffect(() => {

        if ($(window).width() <= 1440) {
            // Para animation code
            gsap.fromTo(".cstm_Cs_p1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_p1",
                    markers: false
                }
            });

            gsap.fromTo(".cstm_Cs_img1", {
                x: 0
            }, {
                x: 550,
                duration: 0.5,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_img1",
                    markers: false
                }
            });
        }
        else {
            // Para animation code
            gsap.fromTo(".cstm_Cs_p1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 1.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_p1",
                    markers: false
                }
            });

            gsap.fromTo(".cstm_Cs_img1", {
                x: 0
            }, {
                x: 550,
                duration: 0.5,
                ease: "linear",
                delay: 1.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_img1",
                    markers: false
                }
            });

        }

    })


    return (
    <>

        {/*---- Warehousing  Service ----*/}
        <div className='servc_sec_white SO_ware mob_padding_LR tab_padding_LR'>
            <div className='cstm_container'>

                <div className='row'>

                    <div className='col-lg-5 col-md-12 col-12'>
                        <div className='so_col1_optimizatio'>

                            <div className='img_dv'>
                                <img className='so_col_img_multi' src={require('../../../../assets/img/sectors/e-commerce_and_fmcg/ecom_warehouse.jpg')}></img>
                            </div>

                        </div>
                    </div>


                    <div className='col-lg-7 col-md-12 col-12 sector_srvc_col2 '>
                
                        <div className='so_col1_multi truck_main'>

                            <div className='hd_dv'>
                                <h2 className='heading'>Warehousing</h2>
                            </div>

                            <ul class="global_list srvc_sec">
                                <li class="wht_txt">Fulfilment Centres, Regional distribution Centres, Delivery Stations, and Dark Stores management </li>
                                <li class="wht_txt">Customised/ automated storage and retrieval solutions</li>
                                <li class="wht_txt">ISO and statutory Compliant facilities </li>
                                <li class="wht_txt">Best-in-class tech solutions for customized reporting, real-time visibility, inventory control, product recall, and bill of material management</li>
                                <li class="wht_txt">Robust processes and tech-based controls for security and loss prevention </li>
                                <li class="wht_txt">Returns Management and Packaging Material Management </li>
                                <li class="wht_txt">Industry leading KPI fulfilment track-record with marquee clients </li>
                                <li class="wht_txt">Customised commercial models accommodating demand ramp up and variations </li>
                                <li class="wht_txt">Industry leading implementation and transition through leveraging our extensive network and project management capabilities </li>
                            </ul>

                        </div>
                        
                    </div>


                </div>

            </div>
        </div>

        {/*---- Transportation Service ----*/}
        <div className='servc_sec_blue SO_ware mob_padding_LR tab_padding_LR'>
            <div className='cstm_container'>

                <div className='row'>

                    <div className='col-lg-7 col-md-12 col-12'>
                    
                        <div className='so_col1_multi'>

                            <div className='hd_dv'>
                                <h2 className='heading wht_txt'>Transportation</h2>
                            </div>

                        
                            <ul class="global_list ">
                                <li class="wht_txt">Strong Primary/Secondary and Tertiary Distribution Network</li>
                                <li class="wht_txt">Capabilities to handle FTL/ LTL/ PTL movements </li>
                                <li class="wht_txt">Modern Trade Deliveries: Same-day and T+1 deliveries options</li>
                                <li class="wht_txt">Hyper-local deliveries and driver app management </li>
                                <li class="wht_txt">Control Tower for real-time visibility and decision-making support </li>
                            </ul>

                        </div>
                        
                    </div>

                    <div className='col-lg-5 col-md-12 col-12'>
                        <div className='so_col1_optimizatio'>

                            <div className='img_dv'>
                                <img className='so_col_img_multi' src={require('../../../../assets/img/sectors/e-commerce_and_fmcg/ecom_transport.jpg')}></img>
                            </div>

                        </div>
                    </div>
                    

                </div>

            </div>
        </div>

        {/*---- Value- Added  Service ----*/}
        <div className='servc_sec_white SO_ware mob_padding_LR tab_padding_LR'>
            <div className='cstm_container'>

                <div className='row'>

                    <div className='col-lg-5 col-md-12 col-12'>
                        <div className='so_col1_optimizatio'>

                            <div className='img_dv'>
                                <img className='so_col_img_multi' src={require('../../../../assets/img/sectors/e-commerce_and_fmcg/ecom_vlu.jpg')}></img>
                            </div>

                        </div>
                    </div>


                    <div className='col-lg-7 col-md-12 col-12  '>
                
                        <div className='so_col1_multi truck_main'>

                            <div className='hd_dv'>
                                <h2 className='heading'>Value Added Services</h2>
                            </div>

                            <ul class="global_list srvc_sec">
                                <li class="wht_txt">Expertise in handling rural Last Mile Deliveries</li>
                                <li class="wht_txt">Sorting, Kitting, Packing, Labelling, Packaging </li>
                                <li class="wht_txt">Multi-channel order fulfilment </li>
                                <li class="wht_txt">Full technology stack for order management, inventory placement, order fulfilment, and Last-Mile Delivery </li>
                                <li class="wht_txt">Flexible solutions to address variability in demand volumes </li>
                            </ul>

                        </div>
                        
                    </div>


                </div>

            </div>
        </div>


    </>
  )
}
