import React from 'react'
import $ from "jquery";

import '../../../../assets/css/info_ware.css';

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Info_ware() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const ware_in_p = useRef(null);
    const ware_in_p1 = useRef(null);
    const ware_in_p2 = useRef(null);
    const ware_in_p3 = useRef(null);

    useEffect(() => {


        if ($(window).width() <= 1600) {
            // Para animation code
            gsap.fromTo(".ware_in_p", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".ware_in_p",
                    markers: false
                }
            });
        }
        else {

            // Para animation code
            gsap.fromTo(".ware_in_p", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 2,
                scrollTrigger: {
                    trigger: ".ware_in_p",
                    markers: false
                }
            });
        }


        // Para animation code
        gsap.fromTo(".ware_in_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.6,
            scrollTrigger: {
                trigger: ".ware_in_p1",
                markers: false
            }
        });

        gsap.fromTo(".ware_in_p2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.6,
            scrollTrigger: {
                trigger: ".ware_in_p2",
                markers: false
            }
        });

        gsap.fromTo(".ware_in_p3", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.6,
            scrollTrigger: {
                trigger: ".ware_in_p3",
                markers: false
            }
        });

        $.fn.isInViewport = function () {

            var elementTop = $(this).offset().top;

            var elementBottom = elementTop + $(this).outerHeight() / 2;

            var viewportTop = $(window).scrollTop();

            var viewportHalf = viewportTop + $(window).height() / 1;

            return elementBottom > viewportTop && elementTop < viewportHalf;

        };


        $(window).on("scroll", function () {

            $(".img_scroll_dv").each(function () {
                var windowTop = $(window).scrollTop();
                var elementTop = $(this).offset().top;
                var leftPosition = windowTop - elementTop;
                $(this)
                    .find(".inside_dv_img")
                    .css({ right: leftPosition });
            });

            // if ($('.mission_sec').isInViewport()) {}
        });

        $(".state_tech_content").hide();
        $(".state_tech").on("click", function () {
            var txt = $(".state_tech_content").is(':visible') ? 'Read More' : 'Read Less';
            $(".state_tech").text(txt);
            $('.state_tech_content').slideToggle(200);
        });

    });

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/warehousing_page`
    );

    return (
        <>
            <div className='info_ware_sec warehouse_info_sec' >

                {data && data.map(({ acf }) => (
                    <div className='row m_0'>

                        <div className='cstm_container col-md-12 col-12 p_0 mob_padding_LR tab_padding_LR'>
                            <div className='col-lg-12 p_0'>
                                <div className='info_ware_col1'>
                                    {acf.warehouse_info.map((ware_info) =>
                                        <div dangerouslySetInnerHTML={{ __html: ware_info.warehouse_description }} className='info_para_cust ware_in_p ware_info_1' ref={ware_in_p}></div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='col-12 p_0'>
                            <div className='img_scroll_dv'>
                                <div className='inside_dv_img'>

                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc4.jpg')}></img>

                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc4.jpg')}></img>

                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc4.jpg')}></img>


                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc4.jpg')}></img>

                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc4.jpg')}></img>

                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/warehousing-for-you/about/servc4.jpg')}></img>
                                </div>
                            </div>
                        </div>

                        <div className='cstm_container mob_padding_LR tab_padding_LR'>
                            <div className='col-12 p_0 '>
                                {acf.warehouse_info.map((ware_info) =>
                                    <div className='warehousing_heading'>

                                        <p className='info_para ware_in_p1' ref={ware_in_p1}>{ware_info.warehouse_description1}</p>
                                        <p className='info_para_1 ware_in_p1' ref={ware_in_p1}>{ware_info.warehouse_info_hd}</p>

                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='cstm_container mob_padding_LR tab_padding_LR'>
                            <div className='row warehouse_bdr'>

                                <div className='col-lg-6 col-md-6 col-12'>

                                    {acf.omnichannel_info.map((ware_omni) =>
                                        <div className='info_ware_col1'>

                                            <p className='info_title  ware_in_p2' ref={ware_in_p2}> {ware_omni.omnichannel_hd}</p>
                                            <p className='ware_para ware_in_p2' ref={ware_in_p2}>{ware_omni.omnichannel_description}</p>

                                        </div>
                                    )}
                                </div>

                                <div className='col-lg-6 col-md-6 col-12'>

                                    {acf.multi_user_info.map((ware_multi) =>
                                        <div className='info_ware_col1'>

                                            <p className='info_title  ware_in_p2' ref={ware_in_p2} >{ware_multi.multi_user_hd}</p>
                                            <p className='ware_para  ware_in_p2' ref={ware_in_p2} >{ware_multi.multi_user_description}</p>

                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>

                        <div className='cstm_container  mob_padding_LR tab_padding_LR'>

                            {/* {acf.state_tech_info_.map((ware_tech) =>
                                <div className='row'>
                                    <div className='col-12'>
                                        <p className='info_title_1 ware_in_p3' ref={ware_in_p3}> {ware_tech.state_tech_hd}</p>
                                    </div>

                                    <div className='col-lg-12 col-md-12 col-12 '>
                                        <div className='info_ware_col_one state_tech_dv'>

                                            <p className='tech_sub_para ware_in_p3' ref={ware_in_p3}>{ware_tech.state_tech_description}</p>

                                            <div className='state_tech_content ware_in_p3' ref={ware_in_p3} dangerouslySetInnerHTML={{ __html: ware_tech.state_tech_description1 }}>

                                            </div>

                                            <a className="state_tech ware_in_p3 read_more_btn rm_blue" data-content="toggle-text" ref={ware_in_p3}>Read More</a>

                                        </div>
                                    </div>

                                </div>
                            )} */}
                        </div>



                    </div>
                ))}

            </div>
        </>
    )
}
