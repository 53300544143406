import React from 'react'
import '../../../../assets/css/counter.css';
import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
import CountUp from 'react-countup';
import useFetch from "react-fetch-hook";

export default function Counter_ware() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const ware_c_ct1 = useRef(null);
    const ware_c_ct2 = useRef(null);
    const ware_c_ct3 = useRef(null);

    useEffect(() => {


   

        if ($(window).width() <= 1380) {

            gsap.fromTo(".ware_c_ct1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.3,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".ware_c_ct1",
                    markers: false
                }
            });

        } else {

            // Para animation code
            gsap.fromTo(".ware_c_ct1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.3,
                ease: "linear",
                delay: 2,
                scrollTrigger: {
                    trigger: ".ware_c_ct1",
                    markers: false
                }
            });

        }

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/warehousing_page`
    );

    return (
        <>
            <div className='counter counter_ware mob_padding_LR tab_padding_LR' id='counter'>
                <div className='cstm_container'>
                    {data && data.map(({ acf }) => (
                        <div className='row'>

                            {acf.warehouse_counter.map((ware_count) =>
                                <div className='col-lg-4 col-md-4 col-12'>
                                    <div className='count_col cc_1 ware_c_ct1' ref={ware_c_ct1}>
                                        <div className='cc_inner'>
                                            <p className='cc_ttl'>{ware_count.counter_text1}</p>
                                            <p className='cc_num'>{ware_count.counter_number}</p>
                                            <p className='cc_ttl'>{ware_count.counter_text2}</p>
                                            
                                        </div>
                                    </div>
                                </div>
                            )}
                            
                        </div>
                    ))}
                </div>
            </div>
            
        </>
    )
}
