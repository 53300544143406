import React from 'react'
import '../../../../assets/css/case_study.css';

import { useEffect, useRef } from 'react';
import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Service_sector_automo() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const cstm_Cs_p1 = useRef(null);
    const cstm_Cs_img1 = useRef(null);

    useEffect(() => {

        if ($(window).width() <= 1440) {
            // Para animation code
            gsap.fromTo(".cstm_Cs_p1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_p1",
                    markers: false
                }
            });

            gsap.fromTo(".cstm_Cs_img1", {
                x: 0
            }, {
                x: 550,
                duration: 0.5,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_img1",
                    markers: false
                }
            });
        }
        else {
            // Para animation code
            gsap.fromTo(".cstm_Cs_p1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 1.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_p1",
                    markers: false
                }
            });

            gsap.fromTo(".cstm_Cs_img1", {
                x: 0
            }, {
                x: 550,
                duration: 0.5,
                ease: "linear",
                delay: 1.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_img1",
                    markers: false
                }
            });

        }

    })


    return (
    <>

        {/*---- Inbound Logistics Service ----*/}
        <div className='servc_sec_white SO_ware mob_padding_LR tab_padding_LR'>
            <div className='cstm_container'>

                <div className='row'>

                    <div className='col-lg-5 col-md-12 col-12'>
                        <div className='so_col1_optimizatio'>

                            <div className='img_dv'>
                                <img className='so_col_img_multi' src={require('../../../../assets/img/sectors/automotive_and_industrial_sector/auto_inbound.jpg')}></img>
                            </div>

                        </div>
                    </div>


                    <div className='col-lg-7 col-md-12 col-12 sector_srvc_col2 '>
                
                        <div className='so_col1_multi truck_main'>

                            <div className='hd_dv'>
                                <h2 className='heading'>Inbound Logistics</h2>
                            </div>

                            <ul class="global_list srvc_sec">
                                <li class="wht_txt">Multi- modal Transport</li>
                                <li class="wht_txt">JIT / JIS delivery to Plant</li>
                                <li class="wht_txt">Import Custom clearing and Forwarding</li>
                                <li class="wht_txt">Domestic Freight Transport</li>
                                <li class="wht_txt">Real-time visibility and route optimisation via TMS</li>
                                <li class="wht_txt">Freight Bill Auditing/Payment </li>
                                <li class="wht_txt">Innovative Packaging Solutions </li>
                            </ul>

                        </div>
                        
                    </div>


                </div>

            </div>
        </div>

        {/*---- In-Plant / Warehousing  Service ----*/}
        <div className='servc_sec_blue SO_ware mob_padding_LR tab_padding_LR'>
            <div className='cstm_container'>

                <div className='row'>

                    <div className='col-lg-7 col-md-12 col-12'>
                    
                        <div className='so_col1_multi'>

                            <div className='hd_dv'>
                                <h2 className='heading wht_txt'>In-Plant / Warehousing Operations</h2>
                            </div>

                        
                            <ul class="global_list ">
                                <li class="wht_txt">Product inspection for RM</li>
                                <li class="wht_txt">Individual/ Shared storage</li>
                                <li class="wht_txt">Yard Management for OEMs</li>
                                <li class="wht_txt">RM & FG Inventory Management</li>
                                <li class="wht_txt">Kitting/de-kitting & assembling </li>
                                <li class="wht_txt">Labelling & Packaging</li>
                                <li class="wht_txt">Multi-SKU complexity sorting</li>
                                <li class="wht_txt">Line feeding (including Milk run between product lines)</li>
                                <li class="wht_txt">VMI (replenishment planning basis demand forecasting)</li>
                                <li class="wht_txt">Product Inspection for FG</li>
                                <li class="wht_txt">Bin and Trolley Management </li>
                                <li class="wht_txt">Serialization</li>
                            </ul>

                        </div>
                        
                    </div>

                    <div className='col-lg-5 col-md-12 col-12'>
                        <div className='so_col1_optimizatio'>

                            <div className='img_dv'>
                                <img className='so_col_img_multi' src={require('../../../../assets/img/sectors/automotive_and_industrial_sector/auto_inplant.jpg')}></img>
                            </div>

                        </div>
                    </div>
                    

                </div>

            </div>
        </div>

        {/*---- Outbound Logistics Service ----*/}
        <div className='servc_sec_white SO_ware mob_padding_LR tab_padding_LR'>
            <div className='cstm_container'>

                <div className='row'>

                    <div className='col-lg-5 col-md-12 col-12'>
                        <div className='so_col1_optimizatio'>

                            <div className='img_dv'>
                                <img className='so_col_img_multi' src={require('../../../../assets/img/sectors/automotive_and_industrial_sector/auto_outbound.jpg')}></img>
                            </div>

                        </div>
                    </div>


                    <div className='col-lg-7 col-md-12 col-12  '>
                
                        <div className='so_col1_multi truck_main'>

                            <div className='hd_dv'>
                                <h2 className='heading'>Outbound Logistics</h2>
                            </div>

                            <ul class="global_list srvc_sec">
                                <li class="wht_txt">Dispatch Planning & Transportation Management </li>
                                <li class="wht_txt">Seamless co-ordination for Local logistics</li>
                                <li class="wht_txt">Multi- modal Transportation</li>
                                <li class="wht_txt">Service Logistics (spares aftermarket via authorised service centres, service centre management for repairs in-house, etc.)</li>
                                <li class="wht_txt">POD Management</li>
                                <li class="wht_txt">Real-time visibility and route optimisation via TMS</li>
                                <li class="wht_txt">Returnable packaging management with track and trace</li>
                                <li class="wht_txt">Export Custom Clearing & Forwarding</li>
                                <li class="wht_txt">Freight Bill Auditing/Payment</li>
                            </ul>

                        </div>
                        
                    </div>


                </div>

            </div>
        </div>

    </>
  )
}
