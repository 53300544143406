import React from 'react'
import '../../../assets/css/sector_info.css';
import '../../../assets/css/integ_logist.css';
import $ from "jquery";

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Integ_logist() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const integ_p1 = useRef(null);
    const integ_p2 = useRef(null);
    const integ_hd1 = useRef(null);
    const img_oly1 = useRef(null);

    useEffect(() => {


        gsap.fromTo(".integ_hd1", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".integ_hd1",
                markers: false
            }
        });

        gsap.fromTo(".integ_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".integ_p1",
                markers: false
            }
        });

        gsap.fromTo(".integ_p2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".integ_p2",
                markers: false
            }
        });

        gsap.fromTo(".img_oly1", {
            x: 0
        }, {
            x: 550,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".img_oly1",
                markers: false
            }
        });


        $.fn.isInViewport = function () {
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight() / 2;
            var viewportTop = $(window).scrollTop();
            var viewportHalf = viewportTop + $(window).height() / 1;
            return elementBottom > viewportTop && elementTop < viewportHalf;
        };


        $(window).on("scroll", function () {

            $(".img_scroll_dv").each(function () {
                var windowTop = $(window).scrollTop();
                var elementTop = $(this).offset().top;
                var leftPosition = windowTop - elementTop;
                $(this)
                    .find(".inside_dv_img")
                    .css({ right: leftPosition });
            });
        });

        $(".inetg_content1").hide();
        $(".integ").on("click", function () {
            var txt = $(".inetg_content1").is(':visible') ? 'Read More' : 'Read Less';
            $(".integ").text(txt);
            $('.inetg_content1').slideToggle(200);
        });

    });

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/apollo_edge_page`
    );

    return (
        <>
            <div className='sector_info mob_padding_LR tab_padding_LR' id="integrated_logistics_services">

                {data && data.map(({ acf }) => (
                    <div className='cstm_container'>
                        <div className='row'>
                            <div className='col-12'>
                                {acf.edge_info.map((edge_info) =>
                                    <p className='sec_info_para integ_p1' ref={integ_p1}>{edge_info.edge_info_description}</p>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className='ae_img_slider'>
                <div className='row m_0'>
                    <div className='col-12 p_0'>
                        <div className='img_scroll_dv'>
                            <div className='inside_dv_img'>

                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img4.jpg')}></img>

                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img4.jpg')}></img>

                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img4.jpg')}></img>


                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img4.jpg')}></img>

                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img4.jpg')}></img>

                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../assets/img/asc-edge/about/abt_img4.jpg')}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='integ_logist mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>

                            {acf.integrated_logistics_services_section.map((edge_log) =>
                                <div className='col-12'>

                                    <div className='hd_dv'>
                                        <h2 className='heading integ_hd1' ref={integ_hd1}>{edge_log.integrated_hd}</h2>
                                    </div>

                                    <p className='sec_info_para integ_p2' ref={integ_p2}>{edge_log.integrated_sub_hd}</p>
                                </div>
                            )}
                            {acf.integrated_logistics_services_section.map((edge_log) =>
                                <div className='col-lg-6 col-md-12 col-12 inet_col1'>

                                    <div className='inteq_content integ_p2' dangerouslySetInnerHTML={{ __html: edge_log.integrated_description1 }} ref={integ_p2} >

                                    </div>


                                    <div className='inetg_content1 integ_p2' dangerouslySetInnerHTML={{ __html: edge_log.integrated_description2 }} ref={integ_p2}>

                                    </div>

                                    <a className="integ integ_p2 read_more_btn rm_blue" data-content="toggle-text" ref={integ_p2}>Read More</a>

                                </div>
                            )}

                            {acf.integrated_logistics_services_section.map((edge_log) =>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className='img_dv integ_imgdv'>
                                        <div className='img_oly img_oly1' ref={img_oly1}></div>
                                        <img className='integ_img ' src={edge_log.integrated_image}></img>
                                    </div>
                                </div>
                            )}


                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
