import React from 'react'
import '../../assets/css/inside_page_spotlight.css'

export default function Press_release_spotlight() {
  return (
    <>
      <div className='press_release_spotlight page_spotlight'>
        <div className='spotlight_container'>
          <div className='row'>
            <div className='col-12 pr_0'>
              <div className='spotlight_dv'>
                <div className='spot_contnt'>
                  <div className='spot_col1'>
                    <h1 className='spot_heading wht_txt media_hd'>Press Release</h1>
                    <p className='spot_sb_ttl'>Get In Touch With Us</p>
                  </div>
                  <div className='spot_col2' style={{ visibility: 'hidden' }}>
                    <p className='wht_txt'>Our countrywide network of warehouses provides a wide range of services including dedicated and multi-user facilities.</p>
                    <a href='#' className='btn wht_btn'>
                      <span className='btn_txt'>Learn More</span>
                      <span className='arrow_btn wht_arrow'></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
