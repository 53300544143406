import React from 'react'
import $ from "jquery";
import '../../../../assets/css/info_ware.css';

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Control_chain() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const cc_p = useRef(null);
    const cc_p1 = useRef(null);

    useEffect(() => {


        if ($(window).width() <= 1380) {
            // Para animation code
            gsap.fromTo(".cc_p", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".cc_p",
                    markers: false
                }
            });
        }
        else {
            
            // Para animation code
            gsap.fromTo(".cc_p", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 2.5,
                scrollTrigger: {
                    trigger: ".cc_p",
                    markers: false
                }
            });
        }


        // Para animation code
        gsap.fromTo(".cc_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cc_p1",
                markers: false
            }
        });

        $.fn.isInViewport = function () {
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight() / 2;
            var viewportTop = $(window).scrollTop();
            var viewportHalf = viewportTop + $(window).height() / 1;
            return elementBottom > viewportTop && elementTop < viewportHalf;
        };


        $(window).on("scroll", function () {
            $(".img_scroll_dv").each(function () {
                var windowTop = $(window).scrollTop();
                var elementTop = $(this).offset().top;
                var leftPosition = windowTop - elementTop;
                $(this)
                    .find(".inside_dv_img")
                    .css({ right: leftPosition });
            });
        });

    });

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/control_tower_page/`
      );

    return (
        <>
            <div className='info_ware_sec '>
                {data && data.map(({ acf }) => (
                    <div className='row m_0'>

                            <div className='cstm_container col-md-12 col-12 p_0 mob_padding_LR tab_padding_LR'>
                                <div className='col-lg-12 p_0'>
                                    <div className='info_ware_col1'>
                                    {acf.control_t_description1.map((control_sub2) =>
                                        <p className='info_para_cust cc_p' ref={cc_p}>{control_sub2.control_t_description1}</p>
                                    )}    
                                    </div>
                                </div>
                            </div>    
                                            
                        <div className='col-12 p_0'>
                            <div className='img_scroll_dv'>
                                <div className='inside_dv_img'>

                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image4.jpg')}></img>

                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image4.jpg')}></img>

                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image4.jpg')}></img>


                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image4.jpg')}></img>

                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image4.jpg')}></img>

                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/control-tower-and-analytics-center/CT_Image4.jpg')}></img>
                                </div>
                            </div>
                        </div>

                        <div className='cstm_container mob_padding_LR tab_padding_LR'>
                            
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12 col-12'>              
                                        <div className='info_ware_col1'>
                                        {acf.control_t_description_2.map((controlLft_sub2) =>
                                            <p className='asc_control adv_para  cc_p1' ref={cc_p1}>{controlLft_sub2.control_t_description_2_left}</p>
                                        )}  
                                        </div>                                
                                    </div>

                                    <div className='col-lg-12 col-md-12 col-12'>                   
                                        <div className='info_ware_col1'>
                                            {acf.control_t_description_2.map((controlRgt_sub2) =>
                                            <p className='ware_sub_paragraph cc_p1' ref={cc_p1}>{controlRgt_sub2.control_t_description_2_right}</p>
                                            )}
                                        </div>                           
                                    </div>
                                </div>                        
                        </div>


                    </div>
                ))}
            </div>
        </>
    )
}
