import React from 'react'
import Header from "../component/global/header";
import Infrastructure_spotlight from '../component/spotlight/infrastructure_spotlight';
import Infra_counter_ware from '../component/section/services/infrastructure/infra_counter_ware';
import Infrastructure_sec from '../component/section/services/infrastructure/infrastructure_sec';
import Cfs_icd_infra from '../component/section/services/infrastructure/cfs_icd_infra';
import Custom_brokerage from '../component/section/services/infrastructure/custom_brokerage';
import Connect_service from '../component/section/services/connect_service';

import Footer from "../component/global/footer";
// import CustomGlobe from "../CustomGlobe";

import Transition from '../component/transition';
import { gsap } from "gsap";
import { useEffect, useRef } from 'react';
import { Power3, Power4 } from "gsap";








export default function Infrastructure_page() {

  let body = useRef(null);
  var tl_1 = gsap.timeline()

  useEffect(() => {

    // Page Transition Code

    tl_1.to(body, {
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut,
      delay: 1.2
    });
    return () => {
      tl_1.to(body, {
        opacity: "0",
        pointerEvents: 'none'
      });
    }

  })


  return (
    <>
      <Transition />

      <div className='infra_pg srvc_pg'>
        <div ref={(el) => (body = el)} className="Headd">

          <Header />
          <Infrastructure_spotlight />
          <Infra_counter_ware />
          <Infrastructure_sec />
          <Cfs_icd_infra/>
          <Custom_brokerage />
          <Connect_service />
          <Footer />
        </div>
      </div>
    </>
  )
}
