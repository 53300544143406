import React, { useRef, useState, useEffect } from "react";
import '../../../assets/css/key_sector.css';
import $ from "jquery";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, EffectCreative, EffectFade } from "swiper";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/effect-creative";
import 'swiper/css/effect-fade';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Key_sector() {


    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const ks_animate = useRef(null);
    const ks_acord_animate = useRef(null);
    const ks_img_animate = useRef(null);


    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    useEffect(() => {

        gsap.fromTo(".ks_animate", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            scrollTrigger: {
                trigger: ".ks_animate",
                markers: false
            }
        });

        gsap.fromTo(".ks_acord_animate", {
            opacity: 0,
        }, {
            delay: 0.2,
            duration: 0.8,
            opacity: 1,
            scrollTrigger: {
                trigger: ".ks_acord_animate",
                markers: false
            }
        });

        gsap.fromTo(".ks_img_animate", {
            opacity: 0,
            scale: 0,
        }, {
            scale: 1,
            delay: 0.2,
            duration: 0.8,
            opacity: 1,
            scrollTrigger: {
                trigger: ".ks_img_animate",
                markers: false
            }
        });

        $("#accordion_Sector").on("hide.bs.collapse show.bs.collapse", e => {
            $(e.target)
                .prev()
                .find("i")
                .toggleClass("");
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/home_page`
    );

    return (
        <>
            <div className='key_sector_sec mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>
                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-xl-4 col-lg-4 col-md-6 col-12'>
                                <div className='key_sec_col1'>

                                    {/* <div className='hd_dv'>
                                    <h2 className='heading wht_txt'>Key Sectors <br />we work with </h2> 
                                </div> */}
                                    <div className='hd_dv'>
                                        <h2 className='heading ks_animate wht_txt' ref={ks_animate}>{acf.keysec_hd1}</h2>
                                    </div>
                                    <div className='hd_dv'>
                                        <h2 className='heading ks_animate wht_txt' ref={ks_animate}>{acf.keysec_hd2}</h2>
                                    </div>


                                    <div id="accordion_Sector" className="myaccordion ks_acord_animate" ref={ks_acord_animate}>
                                        <Swiper

                                            onSwiper={setThumbsSwiper}
                                            slidesPerView={5}
                                            freeMode={true}
                                            direction={"vertical"}
                                            allowTouchMove= {false}
                                            watchSlidesProgress={true}
                                            modules={[FreeMode, Navigation, Thumbs]}
                                            className="accord_slider"
                                        >

                                            <SwiperSlide>
                                                <div className="card csmt_card">
                                                    <div className="card-header" id="headingOne">
                                                        <h2 className="mb-0">
                                                            {acf.consumer_durable.map((acrd1) =>
                                                                <button className="wht_txt d-flex align-items-center justify-content-between sec_btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                    {acrd1.key_acrd_hd}
                                                                    <span className="accord_icon key_icon">
                                                                        <img className="plus_icon" src={require('../../../assets/img/svg/plus_wht_icon.svg').default}></img>
                                                                        <img className="minus_icon" src={require('../../../assets/img/svg/minus_wht_icon.svg').default}></img>
                                                                    </span>
                                                                </button>
                                                            )}
                                                        </h2>
                                                    </div>
                                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion_Sector">
                                                        {acf.consumer_durable.map((acrd1) =>
                                                            <div className="card-body">

                                                                <p className="wht_txt" dangerouslySetInnerHTML={{ __html: acrd1.key_acrd_desc }}></p>
                                                                <a href={acrd1.key_acrd_button_url} className='btn wht_btn'>
                                                                    <span className='btn_txt'>{acrd1.key_acrd_button_name}</span>
                                                                    <span className='arrow_btn wht_arrow'></span>
                                                                </a>

                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="card csmt_card">
                                                    <div className="card-header" id="headingTwo">
                                                        <h2 className="mb-0">
                                                            {acf.automotive_new.map((acrd2) =>
                                                                <button className="wht_txt d-flex align-items-center justify-content-between sec_btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                    {acrd2.key_acrd_hd}
                                                                    <span className="accord_icon key_icon">
                                                                        <img className="plus_icon" src={require('../../../assets/img/svg/plus_wht_icon.svg').default}></img>
                                                                        <img className="minus_icon" src={require('../../../assets/img/svg/minus_wht_icon.svg').default}></img>
                                                                    </span>
                                                                </button>
                                                            )}
                                                        </h2>
                                                    </div>
                                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion_Sector">
                                                        {acf.automotive_new.map((acrd2) =>
                                                            <div className="card-body">
                                                                <p className="wht_txt" dangerouslySetInnerHTML={{ __html: acrd2.key_acrd_desc }}></p>
                                                                <a href={acrd2.key_acrd_button_url} className='btn wht_btn'>
                                                                    <span className='btn_txt'>{acrd2.key_acrd_button_name}</span>
                                                                    <span className='arrow_btn wht_arrow'></span>
                                                                </a>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="card csmt_card">
                                                    <div className="card-header" id="headingThree">
                                                        <h2 className="mb-0">
                                                            {acf.e_commerce.map((acrd3) =>
                                                                <button className="wht_txt d-flex align-items-center justify-content-between sec_btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                    {acrd3.key_acrd_hd}
                                                                    <span className="accord_icon key_icon">
                                                                        <img className="plus_icon" src={require('../../../assets/img/svg/plus_wht_icon.svg').default}></img>
                                                                        <img className="minus_icon" src={require('../../../assets/img/svg/minus_wht_icon.svg').default}></img>
                                                                    </span>
                                                                </button>
                                                            )}
                                                        </h2>
                                                    </div>
                                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion_Sector">
                                                        {acf.e_commerce.map((acrd3) =>
                                                            <div className="card-body">
                                                                <p className="wht_txt" dangerouslySetInnerHTML={{ __html: acrd3.key_acrd_desc }}></p>
                                                                <a href={acrd3.key_acrd_button_url} className='btn wht_btn'>
                                                                    <span className='btn_txt'>{acrd3.key_acrd_button_name}</span>
                                                                    <span className='arrow_btn wht_arrow'></span>
                                                                </a>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="card csmt_card">
                                                    <div className="card-header" id="headingFour">
                                                        <h2 className="mb-0">
                                                            {acf.pharmaceuticals.map((acrd4) =>
                                                                <button className=" wht_txt d-flex align-items-center justify-content-between sec_btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                    {acrd4.key_acrd_hd}
                                                                    <span className="accord_icon key_icon">
                                                                        <img className="plus_icon" src={require('../../../assets/img/svg/plus_wht_icon.svg').default}></img>
                                                                        <img className="minus_icon" src={require('../../../assets/img/svg/minus_wht_icon.svg').default}></img>
                                                                    </span>
                                                                </button>
                                                            )}
                                                        </h2>
                                                    </div>
                                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion_Sector">
                                                        {acf.pharmaceuticals.map((acrd4) =>
                                                            <div className="card-body ">
                                                                <p className="wht_txt" dangerouslySetInnerHTML={{ __html: acrd4.key_acrd_desc }}></p>
                                                                <a href={acrd4.key_acrd_button_url} className='btn wht_btn'>
                                                                    <span className='btn_txt'>{acrd4.key_acrd_button_name}</span>
                                                                    <span className='arrow_btn wht_arrow'></span>
                                                                </a>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="card csmt_card">
                                                    <div className="card-header" id="headingFive">
                                                        <h2 className="mb-0">
                                                            {acf.lifestyle.map((acrd5) =>
                                                                <button className="lst_key wht_txt d-flex align-items-center justify-content-between sec_btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                    {acrd5.key_acrd_hd}
                                                                    <span className="accord_icon key_icon">
                                                                        <img className="plus_icon" src={require('../../../assets/img/svg/plus_wht_icon.svg').default}></img>
                                                                        <img className="minus_icon" src={require('../../../assets/img/svg/minus_wht_icon.svg').default}></img>
                                                                    </span>
                                                                </button>
                                                            )}
                                                        </h2>
                                                    </div>
                                                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion_Sector">
                                                        {acf.lifestyle.map((acrd5) =>
                                                            <div className="card-body lst_key">
                                                                <p className="wht_txt" dangerouslySetInnerHTML={{ __html: acrd5.key_acrd_desc }}></p>
                                                                <a href={acrd5.key_acrd_button_url} className='btn wht_btn'>
                                                                    <span className='btn_txt'>{acrd5.key_acrd_button_name}</span>
                                                                    <span className='arrow_btn wht_arrow'></span>
                                                                </a>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-8 col-lg-8 col-md-6 col-12 key_col2'>
                                <div className='key_sec_col2 ks_img_animate' ref={ks_img_animate}>
                                    <Swiper
                                        effect={"fade"}
                                        speed={800}
                                        scrollbar={{ draggable: false }}
                                        allowTouchMove={false}
                                        thumbs={{ swiper: thumbsSwiper }}
                                        modules={[FreeMode, Navigation, Thumbs, EffectFade]}
                                        className="key_Sec_slider"
                                    >
                                        <SwiperSlide>
                                            <div className="sector_details_dv sec_sld1">
                                                <div className="sector_img_dv">
                                                    {acf.consumer_durable_img.map((acrdimg1) =>
                                                        <img src={acrdimg1.key_image}></img>
                                                    )}
                                                </div>
                                                {/* <h2 className="sec_strk_hd techno">Consumer Durables & High-Tech Industries</h2> */}

                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="sector_details_dv sec_sld2">
                                                <div className="sector_img_dv">
                                                    {acf.automotive_new_img.map((acrdimg2) =>
                                                        <img src={acrdimg2.key_image}></img>
                                                    )}
                                                </div>
                                                {/* <h2 className="sec_strk_hd pharma">Automotive & Industrial</h2> */}

                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="sector_details_dv sec_sld3">
                                                <div className="sector_img_dv">
                                                    {acf.ecom_img.map((acrdimg3) =>
                                                        <img src={acrdimg3.key_image}></img>
                                                    )}
                                                </div>
                                                {/* <h2 className="sec_strk_hd e_com">E-Commerce & FMCG</h2> */}

                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="sector_details_dv sec_sld4">
                                                <div className="sector_img_dv">
                                                    {acf.pharma_img.map((acrdimg4) =>
                                                        <img src={acrdimg4.key_image}></img>
                                                    )}
                                                </div>
                                                {/* <h2 className="sec_strk_hd food_bevg"> Pharmaceuticals</h2> */}

                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="sector_details_dv sec_sld5">
                                                <div className="sector_img_dv">
                                                    {acf.lifestyle_img.map((acrdimg5) =>
                                                        <img src={acrdimg5.key_image}></img>
                                                    )}
                                                </div>
                                                {/* <h2 className="sec_strk_hd food_bevg"> Pharmaceuticals</h2> */}

                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
