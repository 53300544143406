import React from 'react'
import Header from "../../component/global/header";
import Lifestyle_spotlight from '../../component/spotlight/sector/lifestyle_spotlight';
import Lifestyle_other_sec from '../../component/section/sector/other_sector/lifestyle_other_sec';
import Pharma_sec from '../../component/section/sector/other_sector/pharma_sec';
import Connect_service from '../../component/section/services/connect_service';

import Footer from "../../component/global/footer";
// import CustomGlobe from "../CustomGlobe";

import Transition from '../../component/transition';
import { gsap } from "gsap";
import { useEffect, useRef } from 'react';
import { Power3, Power4 } from "gsap";
import Service_sector_lifestyle from '../../component/section/sector/other_sector/service_sector_lifestyle';


export default function Lifestyle_page() {

  let body = useRef(null);
  var tl_1 = gsap.timeline()

  useEffect(() => {

    // Page Transition Code

    tl_1.to(body, {
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut,
      delay: 1.2
    });
    return () => {
      tl_1.to(body, {
        opacity: "0",
        pointerEvents: 'none'
      });
    }

  })


  return (
    <>
      <Transition />

      <div className='other_sec_pg sector_pg'>
        <div ref={(el) => (body = el)} className="Headd">

          <Header />
          <Lifestyle_spotlight />
          <Lifestyle_other_sec />
          <Service_sector_lifestyle/>
          <Connect_service />
          <Footer />

        </div>
      </div>
    </>
  )
}
