import React from 'react'
import '../../../assets/css/solutions_offer.css';
import Solutions_offer_mob from './solutions_offer_mob';

import $ from "jquery";
import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Solutions_offer() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const sol_animate = useRef(null);
    const tb_bx1_animate = useRef(null);
    const tb_bx2_animate = useRef(null);
    const tb_bx3_animate = useRef(null);
    const tb_bx4_animate = useRef(null);
    const tb_bx5_animate = useRef(null);
    const tb_bx6_animate = useRef(null);
    const tb_bx7_animate = useRef(null);
    const tb_bx8_animate = useRef(null);
    const tb_dv_animate = useRef(null);

    useEffect(() => {

        gsap.fromTo(".sol_animate", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".sol_animate",
                markers: false
            }
        });

        gsap.fromTo(".tb_bx1_animate", {
            opacity: 0,
        }, {
            delay: 0.2,
            duration: 0.3,
            opacity: 1,
            scrollTrigger: {
                trigger: ".tb_bx1_animate",
                markers: false
            }
        });
        gsap.fromTo(".tb_bx2_animate", {
            opacity: 0,
        }, {
            delay: 0.2,
            duration: 0.3,
            opacity: 1,
            scrollTrigger: {
                trigger: ".tb_bx2_animate",
                markers: false
            }
        });
        gsap.fromTo(".tb_bx3_animate", {
            opacity: 0,
        }, {
            delay: 0.2,
            duration: 0.3,
            opacity: 1,
            scrollTrigger: {
                trigger: ".tb_bx3_animate",
                markers: false
            }
        });
        gsap.fromTo(".tb_bx4_animate", {
            opacity: 0,
        }, {
            delay: 0.2,
            duration: 0.3,
            opacity: 1,
            scrollTrigger: {
                trigger: ".tb_bx4_animate",
                markers: false
            }
        });
        gsap.fromTo(".tb_bx5_animate", {
            opacity: 0,
        }, {
            delay: 0.2,
            duration: 0.3,
            opacity: 1,
            scrollTrigger: {
                trigger: ".tb_bx5_animate",
                markers: false
            }
        });
        gsap.fromTo(".tb_bx6_animate", {
            opacity: 0,
        }, {
            delay: 0.2,
            duration: 0.3,
            opacity: 1,
            scrollTrigger: {
                trigger: ".tb_bx6_animate",
                markers: false
            }
        });
        gsap.fromTo(".tb_bx7_animate", {
            opacity: 0,
        }, {
            delay: 0.2,
            duration: 0.3,
            opacity: 1,
            scrollTrigger: {
                trigger: ".tb_bx7_animate",
                markers: false
            }
        });
        gsap.fromTo(".tb_bx8_animate", {
            opacity: 0,
        }, {
            delay: 0.2,
            duration: 0.3,
            opacity: 1,
            scrollTrigger: {
                trigger: ".tb_bx8_animate",
                markers: false
            }
        });

        gsap.fromTo(".tb_dv_animate", {
            opacity: 0,
        }, {
            delay:0.2,
            duration: 1,
            opacity: 1,
            scrollTrigger: {
                trigger: ".tb_dv_animate",
                markers: false
            }
        });

        $('.tb_bx').on("click", function () {
            $('.tb_bx').removeClass('active');
            $(this).addClass('active');
            $('.sol_contnt').fadeOut();
            $('#div' + $(this).attr('target')).fadeIn();
        });

        // $('.tb_bx').on("mouseenter", function () {
        //     $('.tb_bx').removeClass('active');
        //     $(this).addClass('active');
        //     $('.sol_contnt').hide();
        //     $('#div' + $(this).attr('target')).show();
        // });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/home_page`
    );


    return (
        <>
            <div className='solutions_offer mob_padding_LR tab_padding_LR' id="solution">
                <div className='cstm_container solution_offer_desktop'>
                    <div className='row'>
                        <div className='col-xl-8 col-lg-7 col-md-12 col-12'>
                            <div className='solutions_tab_dv'>
                                {data && data.map(({ acf }) => (
                                    <div className='row m_0'>
                                        <div className='col-xl-5 col-lg-6 col-md-6 col-12 sol_col_pd p_0'>

                                            {acf.warehousing_hm.map((ware_hm) =>
                                                <div className='tb_bx warehouse active tb_bx1_animate' target="1" ref={tb_bx1_animate}>
                                                    <div className='tb_prt1'>
                                                        <img className='sol_icon' src={ware_hm.service_icon_hm}></img>
                                                        <img className='sol_arrow' src={require('../../../assets/img/svg/up_right_arrow.svg').default}></img>
                                                    </div>
                                                    <div className='tb_prt2'>
                                                        <h4 className="sol_name">{ware_hm.service_name_hm}</h4>
                                                    </div>
                                                </div>
                                            )}

                                            {acf.in_plant_yard_management_hm.map((plant_hm) =>
                                                <div className='tb_bx yard_manage tb_bx2_animate' target="2" ref={tb_bx2_animate}>
                                                    <div className='tb_prt1'>
                                                        <img className='sol_icon' src={plant_hm.service_icon_hm}></img>
                                                        <img className='sol_arrow' src={require('../../../assets/img/svg/up_right_arrow.svg').default}></img>
                                                    </div>
                                                    <div className='tb_prt2'>
                                                        <h4 className="sol_name">{plant_hm.service_name_hm}</h4>
                                                    </div>

                                                </div>
                                            )}

                                            {acf.land_transport_hm.map((land_hm) =>
                                                <div className='tb_bx land_trans tb_bx3_animate' target="3" ref={tb_bx3_animate}>
                                                    <div className='tb_prt1'>
                                                        <img className='sol_icon' src={land_hm.service_icon_hm}></img>
                                                        <img className='sol_arrow' src={require('../../../assets/img/svg/up_right_arrow.svg').default}></img>
                                                    </div>
                                                    <div className='tb_prt2'>
                                                        <h4 className="sol_name">{land_hm.service_name_hm}</h4>
                                                    </div>
                                                </div>
                                            )}

                                            {acf.ocean_freight_hm.map((ocean_hm) =>
                                                <div className='tb_bx ocean_frght tb_bx4_animate' target="4" ref={tb_bx4_animate}>
                                                    <div className='tb_prt1'>
                                                        <img className='sol_icon' src={ocean_hm.service_icon_hm}></img>
                                                        <img className='sol_arrow' src={require('../../../assets/img/svg/up_right_arrow.svg').default}></img>
                                                    </div>
                                                    <div className='tb_prt2'>
                                                        <h4 className="sol_name">{ocean_hm.service_name_hm}</h4>
                                                    </div>
                                                </div>
                                            )}

                                            <div className='tb_full_bx'></div>

                                        </div>

                                        <div className='col-xl-5  col-lg-6 col-md-6 col-12 p_0'>

                                            <div className='tb_full_bx'>
                                                {/* <h2 className='heading wht_txt'>Solutions<br /> we offer</h2> */}
                                                <div className='hd_dv'>
                                                    <h2 className='heading sol_animate wht_txt' ref={sol_animate}>{acf.we_offer_hd1}</h2>
                                                </div>
                                                <div className='hd_dv'>
                                                    <h2 className='heading sol_animate wht_txt' ref={sol_animate}>{acf.we_offer_hd2}</h2>
                                                </div>
                                            </div>

                                            {acf.air_freight_hm.map((air_hm) =>
                                                <div className='tb_bx air_frght tb_bx5_animate' target="5" ref={tb_bx5_animate}>
                                                    <div className='tb_prt1'>
                                                        <img className='sol_icon' src={air_hm.service_icon_hm}></img>
                                                        <img className='sol_arrow' src={require('../../../assets/img/svg/up_right_arrow.svg').default}></img>
                                                    </div>
                                                    <div className='tb_prt2'>
                                                        <h4 className="sol_name">{air_hm.service_name_hm}</h4>
                                                    </div>
                                                </div>
                                            )}

                                            {acf.cfs_icd_hm.map((cfs_hm) =>
                                                <div className='tb_bx cfs_icds tb_bx6_animate' target="6" ref={tb_bx6_animate}>
                                                    <div className='tb_prt1'>
                                                        <img className='sol_icon' src={cfs_hm.service_icon_hm}></img>
                                                        <img className='sol_arrow' src={require('../../../assets/img/svg/up_right_arrow.svg').default}></img>
                                                    </div>
                                                    <div className='tb_prt2'>
                                                        <h4 className="sol_name">{cfs_hm.service_name_hm}</h4>
                                                    </div>
                                                </div>
                                            )}

                                            {acf.network_design_hm.map((net_hm) =>
                                                <div className='tb_bx network_dsng tb_bx7_animate' target="7" ref={tb_bx7_animate}>
                                                    <div className='tb_prt1'>
                                                        <img className='sol_icon' src={net_hm.service_icon_hm}></img>
                                                        <img className='sol_arrow' src={require('../../../assets/img/svg/up_right_arrow.svg').default}></img>
                                                    </div>
                                                    <div className='tb_prt2'>
                                                        <h4 className="sol_name">{net_hm.service_name_hm}</h4>
                                                    </div>
                                                </div>
                                            )}

                                            {acf.control_tower_hm.map((cont_hm) =>
                                                <div className='tb_bx control_tower tb_bx8_animate' target="8" ref={tb_bx8_animate}>
                                                    <div className='tb_prt1'>
                                                        <img className='sol_icon' src={cont_hm.service_icon_hm}></img>
                                                        <img className='sol_arrow' src={require('../../../assets/img/svg/up_right_arrow.svg').default}></img>
                                                    </div>
                                                    <div className='tb_prt2'>
                                                        <h4 className="sol_name">{cont_hm.service_name_hm}</h4>
                                                    </div>
                                                </div>
                                            )}

                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-5 col-md-12 col-12'>
                            {data && data.map(({ acf }) => (
                                <div className='solutions_tab_content_dv tb_dv_animate' ref={tb_dv_animate}>

                                    {acf.warehousing_hm.map((ware_hm) =>
                                        <div className='sol_contnt warehouse_content' id='div1'>
                                            <img className='sol_cnt_img' src={ware_hm.service_image_hm}></img>
                                            <h3 className='sol_cnt_ttl'>{ware_hm.service_name_hm}</h3>
                                            <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: ware_hm.service_desc_hm }}></p>
                                            <a href={ware_hm.service_button_url_hm} className='btn wht_btn'>
                                                <span className='btn_txt'>{ware_hm.service_button_name_hm}</span>
                                                <span className='arrow_btn wht_arrow'></span>
                                            </a>
                                        </div>
                                    )}

                                    {acf.in_plant_yard_management_hm.map((plant_hm) =>
                                        <div className='sol_contnt yard_content' id='div2'>
                                            <img className='sol_cnt_img' src={plant_hm.service_image_hm}></img>
                                            <h3 className='sol_cnt_ttl'>{plant_hm.service_name_hm}</h3>
                                            <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: plant_hm.service_desc_hm }}></p>
                                            <a href={plant_hm.service_button_url_hm} className='btn wht_btn'>
                                                <span className='btn_txt'>{plant_hm.service_button_name_hm}</span>
                                                <span className='arrow_btn wht_arrow'></span>
                                            </a>
                                        </div>
                                    )}

                                    {acf.land_transport_hm.map((land_hm) =>
                                        <div className='sol_contnt land_content' id='div3'>
                                            <img className='sol_cnt_img' src={land_hm.service_image_hm}></img>
                                            <h3 className='sol_cnt_ttl'>{land_hm.service_name_hm}</h3>
                                            <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: land_hm.service_desc_hm }}></p>
                                            <a href={land_hm.service_button_url_hm} className='btn wht_btn'>
                                                <span className='btn_txt'>{land_hm.service_button_name_hm}</span>
                                                <span className='arrow_btn wht_arrow'></span>
                                            </a>
                                        </div>
                                    )}

                                    {acf.ocean_freight_hm.map((ocean_hm) =>
                                        <div className='sol_contnt ocean_content' id='div4'>
                                            <img className='sol_cnt_img' src={ocean_hm.service_image_hm}></img>
                                            <h3 className='sol_cnt_ttl'>{ocean_hm.service_name_hm}</h3>
                                            <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: ocean_hm.service_desc_hm }}></p>
                                            <a href={ocean_hm.service_button_url_hm} className='btn wht_btn'>
                                                <span className='btn_txt'>{ocean_hm.service_button_name_hm}</span>
                                                <span className='arrow_btn wht_arrow'></span>
                                            </a>
                                        </div>
                                    )}

                                    {acf.air_freight_hm.map((air_hm) =>
                                        <div className='sol_contnt air_content' id='div5'>
                                            <img className='sol_cnt_img' src={air_hm.service_image_hm}></img>
                                            <h3 className='sol_cnt_ttl'>{air_hm.service_name_hm}</h3>
                                            <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: air_hm.service_desc_hm }}></p>
                                            <a href={air_hm.service_button_url_hm} className='btn wht_btn'>
                                                <span className='btn_txt'>{air_hm.service_button_name_hm}</span>
                                                <span className='arrow_btn wht_arrow'></span>
                                            </a>
                                        </div>
                                    )}

                                    {acf.cfs_icd_hm.map((cfs_hm) =>
                                        <div className='sol_contnt cfs_content' id='div6'>
                                            <img className='sol_cnt_img' src={cfs_hm.service_image_hm}></img>
                                            <h3 className='sol_cnt_ttl'>{cfs_hm.service_name_hm}</h3>
                                            <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: cfs_hm.service_desc_hm }}></p>
                                            <a href={cfs_hm.service_button_url_hm} className='btn wht_btn'>
                                                <span className='btn_txt'>{cfs_hm.service_button_name_hm}</span>
                                                <span className='arrow_btn wht_arrow'></span>
                                            </a>
                                        </div>
                                    )}

                                    {acf.network_design_hm.map((net_hm) =>
                                        <div className='sol_contnt network_content' id='div7'>
                                            <img className='sol_cnt_img' src={net_hm.service_image_hm}></img>
                                            <h3 className='sol_cnt_ttl'>{net_hm.service_name_hm}</h3>
                                            <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: net_hm.service_desc_hm }}></p>
                                            <a href={net_hm.service_button_url_hm} className='btn wht_btn'>
                                                <span className='btn_txt'>{net_hm.service_button_name_hm}</span>
                                                <span className='arrow_btn wht_arrow'></span>
                                            </a>
                                        </div>
                                    )}

                                    {acf.control_tower_hm.map((cont_hm) =>
                                        <div className='sol_contnt control_content' id='div8'>
                                            <img className='sol_cnt_img' src={cont_hm.service_image_hm}></img>
                                            <h3 className='sol_cnt_ttl'>{cont_hm.service_name_hm}</h3>
                                            <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: cont_hm.service_desc_hm }} ></p>

                                            <a href={cont_hm.service_button_url_hm}  className='btn wht_btn'>
                                                <span className='btn_txt'>{cont_hm.service_button_name_hm}</span>
                                                <span className='arrow_btn wht_arrow'></span>
                                            </a>
                                        </div>
                                    )}

                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <Solutions_offer_mob />
            </div>
        </>
    )
}
