import React from 'react'
import '../../../../assets/css/service_offer_ware.css';

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";


export default function Air_freight() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const air_hd = useRef(null);
    const air_p1 = useRef(null);
    const air_img1 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".air_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".air_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".air_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".air_p1",
                markers: false
            }
        });

        gsap.fromTo(".air_img1", {
            x: 0
        }, {
            xPercent: 101,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".air_img1",
                markers: false
            }
        });

    })
    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/freight_forwarding/`
    );

    return (
        <>
            <div className='air_freight_sec SO_ware mob_padding_LR tab_padding_LR' id='air_freight'>
            {data && data.map(({ acf }) => (
                <div className='cstm_container'>

                    <div className='row'>
                        <div className='col-lg-5 col-md-12 col-12'>
                            <div className='so_col1_network img_air'>
                                <div className='img_dv'>
                                    <div className='img_oly grd_blue_oly1 air_img1' ref={air_img1}></div>
                                    {acf.air_freight_section.map((air_img) =>
                                        <img className='so_col_img aero_img' src={air_img.air_image}></img>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-12 col-12'>
                            <div className='design_ttl air_main'>

                                <div className='hd_dv'>
                                {acf.air_freight_section.map((air_cntn) =>
                                    <h2 className='heading wht_txt air_hd' ref={air_hd}>{air_cntn.air_hd}</h2>
                                )}
                                </div>

                                {acf.air_freight_section.map((air_cntn) =>
                                    <div>
                                        <p className='so_col_desc network_para air_para air_p1' ref={air_p1}>{air_cntn.air_hd2}</p>
                                    
                            
                                    <p className='so_col_desc air_sub_para air_p1' ref={air_p1}>{air_cntn.air_description}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>




                </div>
                ))}
            </div>
        </>
    )
}
