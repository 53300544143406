import React from 'react'
import '../../assets/css/apollo_edge.css'
import $ from "jquery";
import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


import useFetch from "react-fetch-hook";

export default function Apollo_edge() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Para animation variable
    const svg_animate = useRef(null);
    const p2_animate = useRef(null);

    useEffect(() => {

        document.addEventListener("mousemove", parallax);
        function parallax(e) {
            document.querySelectorAll(".edge_vctr").forEach(function (move) {

                var moving_value = move.getAttribute("data-speed");
                var x = (e.clientX * moving_value) / 250;
                var y = (e.clientY * moving_value) / 250;

                move.style.transform = "translateX(" + x + "px) translateY(" + y + "px)";
            });
        }


        gsap.fromTo(".svg_animate", {
            scale: 0,
            opacity: 0,
        }, {
            delay: 0.2,
            duration: 1,
            scale: 1,
            opacity: 1,
            scrollTrigger: {
                trigger: ".svg_animate",
                markers: false
            }
        });


        // Para animation code
        gsap.fromTo("#p2_animate", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: "#p2_animate",
                markers: false
            }
        });

    });

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/home_page`
    );

    return (
        <>

    
            <div className='ap_edge' id='apollo-edge'>
                <div className='apollo_edge_sec mob_padding_LR tab_padding_LR' id=''>
                    <div className='cstm_container'>
                        <div className='row'>
                            <div className='col-12 apolo_txtsvg '>

                                <h3>Apollo Supply Chain</h3>
                                <svg className='' >
                                    <symbol id="text">
                                        <text className='backtext' textAnchor="middle" x="50%" y="60%">The Edge</text>
                                        <text textAnchor="middle" x="50%" y="60%">The Edge</text>
                                    </symbol>
                                    <use href="#text"></use>
                                </svg>
                            </div>

                        </div>
                        

                        <div className='row apolo_edge_row2'>

                            <div className='col-xl-12 col-12 edge_col1 asc_svg_col' ref={p2_animate} id='p2_animate'>

                                <div className='asc_svg_prt1'>
                                    
                                    <a href='/apollo-edge#advantages'>
                                        <div className='asc_svg_box asc_bx_hvr'>
                                            <div className='inner_dv_svg_bx'>
                                                <img src={require('../../assets/img/svg/map_new_icon.svg').default}></img>
                                                <h4>Global Reach. Local Play</h4>
                                                <p className='cstm_para svg_bx_para'>Large network of reliable partners and associates supporting assets for transportation and warehousing across India </p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href='/apollo-edge#advantages'>
                                        <div className='asc_svg_box asc_bx_hvr'>
                                            <div className='inner_dv_svg_bx'>
                                                <img src={require('../../assets/img/svg/tech_new_icon.svg').default}></img>
                                                <h4>Tech-Driven Solutions</h4>
                                                <p className='cstm_para svg_bx_para'>In-house operational products development as well as customisation of products like WMS, TMS, Warehouse Automation, Dashboard and Analytics</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href='/apollo-edge#advantages'>
                                        <div className='asc_svg_box asc_bx_hvr'>
                                            <div className='inner_dv_svg_bx'>
                                                <img src={require('../../assets/img/svg/consult_new_icon.svg').default}></img>
                                                <h4>Consultative Solutions</h4>
                                                <p className='cstm_para svg_bx_para'>Enhancing demand forecasting, optimizing sourcing and supplier management processes, and elevating the standard of customer experience</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href='/apollo-edge#advantages'>
                                        <div className='asc_svg_box asc_bx_hvr'>
                                            <div className='inner_dv_svg_bx'>
                                                <img src={require('../../assets/img/svg/leader_new_icon.svg').default}></img>
                                                <h4>Strong Leadership & Robust Operating Processes</h4>
                                                <p className='cstm_para svg_bx_para'>A cross-functional team of business heads for bespoke solutions design and risk assessment</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                              
                                <div className='asc_svg_prt3'>
                                    <div className='inner_dv_svg_bx1'>
                                        <h3 className='edge_ttl p_opacity' ref={p2_animate} id='p2_animate'>We do much more than solve the immediate logistics need</h3>
                                        <a href='/apollo-edge' className='btn blue_btn'>
                                            <span className='btn_txt'>Learn More</span>
                                            <span className='arrow_btn blue_arrow'></span>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        

                    </div>
                    <img src={require('../../assets/img/svg/apollo_edge_bg.svg').default} className='edge_bg' ></img>

                </div>
            </div>



        </>
    )
}
