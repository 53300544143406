import React from 'react'
import '../../assets/css/inside_page_spotlight.css'

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Supply_chain_spotlight() {

  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const supchn_spot_hd = useRef(null);
  const supchn_spot_p = useRef(null);
  const supchn_spt_ad1 = useRef(null);
  const supchn_spt_ad2 = useRef(null);
  const supchn_spt_ad3 = useRef(null); 

  useEffect(() => {

    gsap.fromTo(".supchn_spot_hd", {
      y: 250,
      opacity: 0,
      skewY: 41,
    }, {
      delay: 2,
      duration: 0.8,
      y: 0,
      skewY: 0,
      opacity: 1,
      ease: "power4.out",
      stagger: {
        from: 'start',
        amount: 0.5,
      },
      scrollTrigger: {
        trigger: ".supchn_spot_hd",
        markers: false
      }
    });

    gsap.fromTo(".supchn_spot_p", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.8,
      ease: "linear",
      delay: 2.5,
      scrollTrigger: {
        trigger: ".supchn_spot_p",
        markers: false
      }
    });

    // Para animation code
    gsap.fromTo(".supchn_spt_ad1", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.5,
      scrollTrigger: {
        trigger: ".supchn_spt_ad1",
        markers: false
      }
    });
    gsap.fromTo(".supchn_spt_ad2", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.6,
      scrollTrigger: {
        trigger: ".supchn_spt_ad2",
        markers: false
      }
    });
    gsap.fromTo(".supchn_spt_ad3", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.7,
      scrollTrigger: {
        trigger: ".supchn_spt_ad3",
        markers: false
      }
    });


  })

  return (
    <>
      <div className='supplychain_spotlight page_spotlight service_spotlight'>
        <div className='spotlight_container'>
          <div className='row'>
            <div className='col-12 pr_0'>
              <div className='spotlight_dv'>
                <div className='spot_contnt'>
                  <div className='spot_col1'>

                    <div className='hd_dv'>
                      <h1 className='spot_heading wht_txt supply_head supchn_spot_hd' ref={supchn_spot_hd}>Supply Chain</h1>
                    </div>
                    <div className='hd_dv'>
                      <h1 className='spot_heading wht_txt supply_head supchn_spot_hd' ref={supchn_spot_hd}>Consulting Services</h1>
                    </div>

                    <p className='wht_txt offer_text supchn_spot_p' ref={supchn_spot_p}>Need our expertise for supply chain strategy, network design, compliance? Our guidance and advisory services are with you every step of the way.</p>
                  </div>
                  <div className='spot_col2'>
                    {/* <p className='wht_txt'>Our countrywide network of warehouses provides a wide range of services including dedicated and multi-user facilities. Delivering agility, reliability, and sustainability, our solutions are transforming businesses across verticals.</p> */}
                    <div class="dropdown">
                      <a href="#" class="js-link"><i class="fa fa-chevron-down"></i></a>
                      <ul class="warehouse_list">
                        <a className='supchn_spt_ad1' ref={supchn_spt_ad1} href='/supply-chain-consulting#route_optimization'><li className='service_listing'>Route Optimization</li> </a>
                        <a className='supchn_spt_ad2' ref={supchn_spt_ad2} href='/supply-chain-consulting#network_design_re-design'><li className='service_listing'>Network Design & Re-Design</li> </a>
                        <a className='supchn_spt_ad3' ref={supchn_spt_ad3} href='/supply-chain-consulting#warehouse_design_simulation'><li className='service_listing'>Warehouse Design & Simulation</li> </a>
                      </ul>
                    </div>
                    {/* <a href='#' className='btn wht_btn'>
                      <span className='btn_txt'>Learn More</span>
                      <span className='arrow_btn wht_arrow'></span>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
