import React from 'react'
import '../../../../assets/css/case_study.css';

import { useEffect, useRef } from 'react';
import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Service_sector_cstm() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const cstm_Cs_p1 = useRef(null);
    const cstm_Cs_img1 = useRef(null);

    useEffect(() => {

        if ($(window).width() <= 1440) {
            // Para animation code
            gsap.fromTo(".cstm_Cs_p1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_p1",
                    markers: false
                }
            });

            gsap.fromTo(".cstm_Cs_img1", {
                x: 0
            }, {
                x: 550,
                duration: 0.5,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_img1",
                    markers: false
                }
            });
        }
        else {
            // Para animation code
            gsap.fromTo(".cstm_Cs_p1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 1.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_p1",
                    markers: false
                }
            });

            gsap.fromTo(".cstm_Cs_img1", {
                x: 0
            }, {
                x: 550,
                duration: 0.5,
                ease: "linear",
                delay: 1.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_img1",
                    markers: false
                }
            });

        }

    })


    return (
    <>

        {/*---- Warehousing Service ----*/}
        <div className='servc_sec_white SO_ware mob_padding_LR tab_padding_LR'>
            <div className='cstm_container'>

                <div className='row'>

                    <div className='col-lg-5 col-md-12 col-12'>
                        <div className='so_col1_optimizatio'>

                            <div className='img_dv'>
                                <img className='so_col_img_multi' src={require('../../../../assets/img/sectors/consumer_durables_high-tech_industries/cstm_wrhouse.jpg')}></img>
                            </div>

                        </div>
                    </div>


                    <div className='col-lg-7 col-md-12 col-12 sector_srvc_col2 '>
                
                        <div className='so_col1_multi truck_main'>

                            <div className='hd_dv'>
                                <h2 className='heading'>Warehousing</h2>
                            </div>

                            <ul class="global_list srvc_sec">
                                <li class="wht_txt">Expertise in Finished Goods (FG) & Spare Parts Logistics (SPL)</li>
                                <li class="wht_txt">In-plant logistics, fulfilment centres, distribution centre management</li>
                                <li class="wht_txt">Best-in-class tech solutions for security and loss prevention, customized reporting, real-time visibility, inventory control</li>
                                <li class="wht_txt">AI based camera analytics solutions for incidence reporting, troubleshooting, and productivity improvement </li>
                                <li class="wht_txt">WMS enabling multi-channel order management and smart inventory placement </li>
                                <li class="wht_txt">Returns Management and Insurance Claim Support.</li>
                                <li class="wht_txt">Security zones for high value inventory </li>
                                <li class="wht_txt">Efficient storage solutions for high density storage; state-of-the art facilities with pallet rack storage and Material Handling Equipment (MHE) for FMCD FGs</li>
                                <li class="wht_txt">Customised commercial models including single unit level rate for warehousing & distribution</li>
                                <li class="wht_txt">Industry leading implementation and transition through leveraging our extensive network and project management capabilities </li>
                            </ul>

                        </div>
                        
                    </div>


                </div>

            </div>
        </div>

        {/*---- Transportation  Service ----*/}
        <div className='servc_sec_blue SO_ware mob_padding_LR tab_padding_LR'>
            <div className='cstm_container'>

                <div className='row'>

                    <div className='col-lg-7 col-md-12 col-12'>
                    
                        <div className='so_col1_multi '>

                            <div className='hd_dv'>
                                <h2 className='heading wht_txt'>Transportation</h2>
                            </div>

                        
                            <ul class="global_list ">
                                <li class="wht_txt">Pan-India network for omni-channel distribution including B2B and B2C shipments </li>
                                <li class="wht_txt">Servicing first-mile, middle-mile, and last mile-distribution </li>
                                <li class="wht_txt">Expertise in full truck load (FTL) and Part Truck Load (PTL) transportation </li>
                                <li class="wht_txt">Expertise in Designing Milk Run distribution network for optimising cost and managing demand variabilities  </li>
                                <li class="wht_txt">Proven cost-savings, TAT improvement, and minimised damages through innovative Last-Mile Delivery solutions </li>
                                <li class="wht_txt">Control Tower for real-time visibility and decision-making support to management  </li>
                            </ul>

                        </div>
                        
                    </div>

                    <div className='col-lg-5 col-md-12 col-12'>
                        <div className='so_col1_optimizatio'>

                            <div className='img_dv'>
                                <img className='so_col_img_multi' src={require('../../../../assets/img/sectors/consumer_durables_high-tech_industries/cstm_trans.jpg')}></img>
                            </div>

                        </div>
                    </div>
                    

                </div>

            </div>
        </div>

        {/*---- Value Added Service ----*/}
        <div className='servc_sec_white SO_ware mob_padding_LR tab_padding_LR'>
            <div className='cstm_container'>

                <div className='row'>

                    <div className='col-lg-5 col-md-12 col-12'>
                        <div className='so_col1_optimizatio'>

                            <div className='img_dv'>
                                <img className='so_col_img_multi' src={require('../../../../assets/img/sectors/consumer_durables_high-tech_industries/cstm_vlu.jpg')}></img>
                            </div>

                        </div>
                    </div>


                    <div className='col-lg-7 col-md-12 col-12  '>
                
                        <div className='so_col1_multi truck_main'>

                            <div className='hd_dv'>
                                <h2 className='heading'>Value Added Services</h2>
                            </div>

                            <ul class="global_list srvc_sec">
                                <li class="wht_txt">Demand Planning/ Proactive Inventory Placement </li>
                                <li class="wht_txt">Flexible solutions to address variability in demand volumes </li>
                                <li class="wht_txt">Order Fulfilment and Quality Checks for Spare Parts </li>
                                <li class="wht_txt">Freight Forwarding, Custom Clearance, and Doorstep Delivery </li>
                                <li class="wht_txt">Sorting, Kitting, Packing, Labelling </li>
                                <li class="wht_txt">Reduction in handling damages/breakages</li>
                                <li class="wht_txt">Innovative packaging solutions for cost optimisation </li>
                            </ul>

                        </div>
                        
                    </div>


                </div>

            </div>
        </div>

    </>
  )
}
