import React from 'react'
import '../../assets/css/inside_page_spotlight.css'

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Controltower_spotlight() {

  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const control_spot_hd = useRef(null);
  const control_spt_p = useRef(null);
  const control_spt_ad1 = useRef(null);

  useEffect(() => {

    gsap.fromTo(".control_spot_hd", {
      y: 250,
      opacity: 0,
      skewY: 41,
    }, {
      delay: 2,
      duration: 0.8,
      y: 0,
      skewY: 0,
      opacity: 1,
      ease: "power4.out",
      stagger: {
        from: 'start',
        amount: 0.5,
      },
      scrollTrigger: {
        trigger: ".control_spot_hd",
        markers: false
      }
    });

    // Para animation code
    gsap.fromTo(".control_spt_p", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.8,
      ease: "linear",
      delay: 2.5,
      scrollTrigger: {
        trigger: ".control_spt_p",
        markers: false
      }
    });

    // Para animation code
    gsap.fromTo(".control_spt_ad1", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.2,
      scrollTrigger: {
        trigger: ".control_spt_ad1",
        markers: false
      }
    });

  })

  return (
    <>
      <div className='control_spot controltower_spotlight page_spotlight service_spotlight'>
        <div className='spotlight_container'>
          <div className='row'>
            <div className='col-12 pr_0'>
              <div className='spotlight_dv'>
                <div className='spot_contnt'>
                  <div className='spot_col1'>

                    <div className='hd_dv'>
                      <h1 className='spot_heading wht_txt control_heading control_spot_hd' ref={control_spot_hd}>Control Tower &</h1>
                    </div>
                    <div className='hd_dv'>
                      <h1 className='spot_heading wht_txt control_heading control_spot_hd' ref={control_spot_hd}>Analytics Center</h1>
                    </div>

                    {/* <p className='spot_sb_ttl'>Agile. Reliable. Efficient </p> */}
                    <p className='wht_txt offer_text control_spt_p' ref={control_spt_p}>Use real-time information and analytics for<br></br> operational, financial, and tactical advantage.</p>
                  </div>
                  <div className='spot_col2'>
                    {/* <p className='wht_txt'>Our countrywide network of warehouses provides a wide range of services including dedicated and multi-user facilities. Delivering agility, reliability, and sustainability, our solutions are transforming businesses across verticals.</p> */}
                    <div class="dropdown">
                      <a href="#" class="js-link"><i class="fa fa-chevron-down"></i></a>
                      <ul class="warehouse_list">
                        <a href='/control-tower-&-analtics-center#asc_control_tower' className='control_spt_ad1' ref={control_spt_ad1}><li className='service_listing'>ASC Control Tower</li> </a>
                      </ul>
                    </div>
                    {/* <a href='#' className='btn wht_btn'>
                      <span className='btn_txt'>Learn More</span>
                      <span className='arrow_btn wht_arrow'></span>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
