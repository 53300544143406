import React from 'react'
import Header from "../component/global/header";
import Freight_spotlight from '../component/spotlight/freight_spotlight';
import Freight_sec from '../component/section/services/freight_forwarding/freight_sec';
import Ocean_freight from '../component/section/services/freight_forwarding/ocean_freight';
import Air_freight from '../component/section/services/freight_forwarding/air_freight';
import Connect_service from '../component/section/services/connect_service';

import Footer from "../component/global/footer";

import Transition from '../component/transition';
import { gsap } from "gsap";
import { useEffect, useRef } from 'react';
import { Power3, Power4 } from "gsap";
import Customised_servc_ware from '../component/section/services/warehousing/customised_servc_ware';



export default function Freight_forwarding_page() {

  let body = useRef(null);
  var tl_1 = gsap.timeline()

  useEffect(() => {

    // Page Transition Code

    tl_1.to(body, {
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut,
      delay: 1.2
    });
    return () => {
      tl_1.to(body, {
        opacity: "0",
        pointerEvents: 'none'
      });
    }

  })


  return (
    <>
      <Transition />

      <div className='freight_frwd_pg srvc_pg'>
        <div ref={(el) => (body = el)} className="Headd">
          <Header />
          <Freight_spotlight />
          <Customised_servc_ware/>
          <Freight_sec />
          <Ocean_freight />
          <Air_freight />
          <Connect_service />
          <Footer />
        </div>
      </div>
    </>
  )
}
