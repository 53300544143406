import React, { useRef, useState, useEffect } from "react";
import '../../../assets/css/solutions_offer_mob.css';
import '../../../assets/css/apollo_edge_advantage.css';

import useFetch from "react-fetch-hook";

export default function Ae_advantage_mob() {

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/apollo_edge_page`
    );

    return (
        <>
            <div className="solution_offer_mob ae_advntg_mob mob_padding_LR tab_padding_LR" id='advantages_mob'>

                {data && data.map(({ acf }) => (
                    <div className="row">

                        {acf.advantages_section.map((edge_advntg) =>
                            <div className="col-12">
                                <div className='hd_dv'>
                                    <h2 className='heading wht_txt'>{edge_advntg.advantage_hd}</h2>
                                </div>
                            </div>
                        )}


                        {acf.advantages_section.map((edge_advntg1) =>
                            <div id="accordion" className="accordion_solution ae_advntg_mob_accord">

                                {/* Solution One */}
                                {edge_advntg1.a_strong_network_slide.map((sld1) =>
                                    <div className="card">
                                        <div className="card-header">
                                            <button className="card-link" data-toggle="collapse" data-target="#collapseOne">
                                                <span className="icons_span">
                                                    <img className='' src={sld1.advntg_slide_mob_icon_}></img>
                                                </span>
                                                <span className="solution_name">
                                                    {sld1.advntg_slide_name}
                                                </span>
                                                <span className="status_icon">
                                                    <img className="sol_plus_icon" src={require('../../../assets/img/svg/plus_wht_icon.svg').default}></img>
                                                    <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                                </span>
                                            </button>
                                        </div>
                                        <div id="collapseOne" className="collapse show" data-parent="#accordion">
                                            <div className="card-body">
                                                <div className='warehouse_content'>
                                                    <p className='sol_cnt_desc'> {sld1.advntg_slide_description}</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Solution Two */}
                                {edge_advntg1.tech_driven_solutions_slide.map((sld2) =>
                                    <div className="card">
                                        <div className="card-header">
                                            <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseTwo">
                                                <span className="icons_span">
                                                    <img className='' src={sld2.advntg_slide_mob_icon_}></img>
                                                </span>
                                                <span className="solution_name">
                                                    {sld2.advntg_slide_name}
                                                </span>
                                                <span className="status_icon">
                                                    <img className="sol_plus_icon" src={require('../../../assets/img/svg/plus_wht_icon.svg').default}></img>
                                                    <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                                </span>
                                            </button>
                                        </div>
                                        <div id="collapseTwo" className="collapse" data-parent="#accordion">
                                            <div className="card-body">
                                                <div className='warehouse_content'>
                                                    <p className='sol_cnt_desc'>{sld2.advntg_slide_description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Solution Five */}
                                {edge_advntg1.consultative_solutions_slide.map((sld3) =>
                                    <div className="card">
                                        <div className="card-header">
                                            <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseFive">
                                                <span className="icons_span">
                                                    <img className='' src={sld3.advntg_slide_mob_icon_}></img>
                                                </span>
                                                <span className="solution_name">
                                                    {sld3.advntg_slide_name}
                                                </span>
                                                <span className="status_icon">
                                                    <img className="sol_plus_icon" src={require('../../../assets/img/svg/plus_wht_icon.svg').default}></img>
                                                    <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                                </span>
                                            </button>
                                        </div>
                                        <div id="collapseFive" className="collapse" data-parent="#accordion">
                                            <div className="card-body">
                                                <div className='warehouse_content'>
                                                    <p className='sol_cnt_desc'>{sld3.advntg_slide_description}</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Solution Three */}
                                {edge_advntg1.strong_leadership_slide.map((sld4) =>
                                    <div className="card">
                                        <div className="card-header">
                                            <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseThree">
                                                <span className="icons_span">
                                                    <img className='' src={sld4.advntg_slide_mob_icon_}></img>
                                                </span>
                                                <span className="solution_name">
                                                    {sld4.advntg_slide_name}
                                                </span>
                                                <span className="status_icon">
                                                    <img className="sol_plus_icon" src={require('../../../assets/img/svg/plus_wht_icon.svg').default}></img>
                                                    <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                                </span>
                                            </button>
                                        </div>
                                        <div id="collapseThree" className="collapse" data-parent="#accordion">
                                            <div className="card-body">
                                                <div className='warehouse_content'>
                                                    <p className='sol_cnt_desc'>{sld4.advntg_slide_description}</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Solution Four */}
                                {/* {edge_advntg1.robust_countrywide_slide.map((sld5) =>
                                    <div className="card">
                                        <div className="card-header">
                                            <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseFour">
                                                <span className="icons_span">
                                                    <img className='' src={require('../../../assets/img/svg/country.svg').default}></img>
                                                </span>
                                                <span className="solution_name">
                                                    {sld5.advntg_slide_name}
                                                </span>
                                                <span className="status_icon">
                                                    <img className="sol_plus_icon" src={require('../../../assets/img/svg/plus_wht_icon.svg').default}></img>
                                                    <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                                </span>
                                            </button>
                                        </div>
                                        <div id="collapseFour" className="collapse" data-parent="#accordion">
                                            <div className="card-body">
                                                <div className='warehouse_content'>
                                                    <p className='sol_cnt_desc'>{sld5.advntg_slide_description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )} */}




                            </div>
                        )}



                    </div>
                ))}

            </div>
        </>
    )
}
