import React from 'react'
import '../../../../assets/css/case_study.css';

import { useEffect, useRef } from 'react';
import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Case_study_cstm() {


    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const auto_Cs_p1 = useRef(null);
    const auto_Cs_img1 = useRef(null);

    useEffect(() => {

        if ($(window).width() <= 1440) {
            // Para animation code
            gsap.fromTo(".auto_Cs_p1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 1,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".auto_Cs_p1",
                    markers: false
                }
            });

            gsap.fromTo(".auto_Cs_img1", {
                xPercent: 0
            }, {
                xPercent: 100,
                duration: 0.5,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".auto_Cs_img1",
                    markers: false
                }
            });
        }
        else {
            // Para animation code
            gsap.fromTo(".auto_Cs_p1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 1,
                ease: "linear",
                delay: 1.2,
                scrollTrigger: {
                    trigger: ".auto_Cs_p1",
                    markers: false
                }
            });

            gsap.fromTo(".auto_Cs_img1", {
                xPercent: 0
            }, {
                xPercent: 100,
                duration: 0.5,
                ease: "linear",
                delay: 1.2,
                scrollTrigger: {
                    trigger: ".auto_Cs_img1",
                    markers: false
                }
            });

        }

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/automotive_sec_page`
    );


    return (
        <>
            <div className='case_study cs_cstm' id='automotive_case_study'>
                {data && data.map(({ acf }) => (
                    <div className='row m_0'>
                        <div className='col-lg-3 col-md-4 col-12 p_0'>
                            <div className='cstm_cs_col1'>
                                <div className='img_dv'>
                                    <div className='img_oly cstm_cs auto_Cs_img1' ref={auto_Cs_img1}></div>

                                    {acf.automotive_case_study.map((auto_sub3) =>
                                        <img className='tech_img' src={auto_sub3.automotive_cs_image}></img>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8 col-12 p_0'>
                            <div className='cstm_cs_col2'>
                                <div className='cs_cstm_inner'>

                                    {acf.automotive_case_study.map((auto_sub3) =>
                                        <h5 className='cs_ttl auto_Cs_p1' ref={auto_Cs_p1}>{auto_sub3.automotive_cs_hd}</h5>
                                    )}

                                    {acf.automotive_case_study.map((auto_sub3) =>
                                        <p className='cs_para auto_Cs_p1' ref={auto_Cs_p1}>{auto_sub3.automotive_cs_description}</p>
                                    )}

                                </div>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        </>
    )
}
