import React, { useRef, useState, useEffect } from "react";
import '../../../assets/css/solutions_offer_mob.css';
import $ from "jquery";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Solutions_offer_mob() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const sol_animate1 = useRef(null);
    const sol_tab_animate = useRef(null);
    useEffect(() => {

        gsap.fromTo(".sol_animate1", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.5,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".sol_animate1",
                markers: false
            }
        });

        gsap.fromTo(".sol_tab_animate", {
            opacity: 0,
        }, {
            delay: 1.2,
            duration: 0.8,
            opacity: 1,
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".sol_tab_animate",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/home_page`
    );


    return (
        <>
            <div className="solution_offer_mob">

                {data && data.map(({ acf }) => (
                    <div className="row">
                        <div className="col-12">
                            {/* <div className='hd_dv'>
                            <h2 className='heading wht_txt'>Solutions<br />we offer</h2>
                        </div> */}
                            <div className='hd_dv'>
                                <h2 className='heading sol_animate1 wht_txt' ref={sol_animate1}>{acf.we_offer_hd1}</h2>
                            </div>
                            <div className='hd_dv'>
                                <h2 className='heading sol_animate1 wht_txt' ref={sol_animate1}>{acf.we_offer_hd2}</h2>
                            </div>
                        </div>

                        <div id="accordion" className="accordion_solution sol_tab_animate" ref={sol_tab_animate}>

                            {/* Solution One */}

                            {acf.warehousing_hm.map((ware_hm) =>
                                <div className="card">
                                    <div className="card-header">
                                        <button className="card-link" data-toggle="collapse" data-target="#collapseOne">
                                            <span className="icons_span">
                                                <img className='' src={ware_hm.service_icon_hm}></img>
                                            </span>
                                            <span className="solution_name">
                                                {ware_hm.service_name_hm}
                                            </span>
                                            <span className="status_icon">
                                                <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                                <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                            </span>
                                        </button>
                                    </div>
                                    <div id="collapseOne" className="collapse show" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className='warehouse_content'>
                                                <img className='sol_cnt_img' src={ware_hm.service_image_hm}></img>
                                                <h3 className='sol_cnt_ttl'>{ware_hm.service_name_hm}</h3>
                                                <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: ware_hm.service_desc_hm }}></p>
                                                <a href={ware_hm.service_button_url_hm} className='btn wht_btn'>
                                                    <span className='btn_txt'>{ware_hm.service_button_name_hm}</span>
                                                    <span className='arrow_btn wht_arrow'></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Solution Two */}
                            {acf.in_plant_yard_management_hm.map((plant_hm) =>
                                <div className="card">
                                    <div className="card-header">
                                        <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseTwo">
                                            <span className="icons_span">
                                                <img className='' src={plant_hm.service_icon_hm}></img>
                                            </span>
                                            <span className="solution_name">
                                                {plant_hm.service_name_hm}
                                            </span>
                                            <span className="status_icon">
                                                <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                                <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                            </span>
                                        </button>
                                    </div>
                                    <div id="collapseTwo" className="collapse" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className='warehouse_content'>
                                                <img className='sol_cnt_img' src={plant_hm.service_image_hm}></img>
                                                <h3 className='sol_cnt_ttl'>{plant_hm.service_name_hm}</h3>
                                                <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: plant_hm.service_desc_hm }}></p>
                                                <a href={plant_hm.service_button_url_hm} className='btn wht_btn'>
                                                    <span className='btn_txt'>{plant_hm.service_button_name_hm}</span>
                                                    <span className='arrow_btn wht_arrow'></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Solution Three */}
                            {acf.land_transport_hm.map((land_hm) =>
                                <div className="card">
                                    <div className="card-header">
                                        <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseThree">
                                            <span className="icons_span">
                                                <img className='' src={land_hm.service_icon_hm}></img>
                                            </span>
                                            <span className="solution_name">
                                                {land_hm.service_name_hm}
                                            </span>
                                            <span className="status_icon">
                                                <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                                <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                            </span>
                                        </button>
                                    </div>
                                    <div id="collapseThree" className="collapse" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className='warehouse_content'>
                                                <img className='sol_cnt_img' src={land_hm.service_image_hm}></img>
                                                <h3 className='sol_cnt_ttl'>{land_hm.service_name_hm}</h3>
                                                <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: land_hm.service_desc_hm }}></p>
                                                <a href={land_hm.service_button_url_hm} className='btn wht_btn'>
                                                    <span className='btn_txt'>{land_hm.service_button_name_hm}</span>
                                                    <span className='arrow_btn wht_arrow'></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Solution Four */}
                            {acf.ocean_freight_hm.map((ocean_hm) =>
                                <div className="card">
                                    <div className="card-header">
                                        <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseFour">
                                            <span className="icons_span">
                                                <img className='' src={ocean_hm.service_icon_hm}></img>
                                            </span>
                                            <span className="solution_name">
                                                {ocean_hm.service_name_hm}
                                            </span>
                                            <span className="status_icon">
                                                <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                                <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                            </span>
                                        </button>
                                    </div>
                                    <div id="collapseFour" className="collapse" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className='warehouse_content'>
                                                <img className='sol_cnt_img' src={ocean_hm.service_image_hm}></img>
                                                <h3 className='sol_cnt_ttl'>{ocean_hm.service_name_hm}</h3>
                                                <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: ocean_hm.service_desc_hm }}></p>
                                                <a href={ocean_hm.service_button_url_hm} className='btn wht_btn'>
                                                    <span className='btn_txt'>{ocean_hm.service_button_name_hm}</span>
                                                    <span className='arrow_btn wht_arrow'></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Solution Five */}
                            {acf.air_freight_hm.map((air_hm) =>
                                <div className="card">
                                    <div className="card-header">
                                        <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseFive">
                                            <span className="icons_span">
                                                <img className='' src={air_hm.service_icon_hm}></img>
                                            </span>
                                            <span className="solution_name">
                                                {air_hm.service_name_hm}
                                            </span>
                                            <span className="status_icon">
                                                <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                                <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                            </span>
                                        </button>
                                    </div>
                                    <div id="collapseFive" className="collapse" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className='warehouse_content'>
                                                <img className='sol_cnt_img' src={air_hm.service_image_hm}></img>
                                                <h3 className='sol_cnt_ttl'>{air_hm.service_name_hm}</h3>
                                                <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: air_hm.service_desc_hm }}></p>
                                                <a href={air_hm.service_button_url_hm} className='btn wht_btn'>
                                                    <span className='btn_txt'>{air_hm.service_button_name_hm}</span>
                                                    <span className='arrow_btn wht_arrow'></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Solution Six */}
                            {acf.cfs_icd_hm.map((cfs_hm) =>
                                <div className="card">
                                    <div className="card-header">
                                        <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseSix">
                                            <span className="icons_span">
                                                <img className='' src={cfs_hm.service_icon_hm}></img>
                                            </span>
                                            <span className="solution_name">
                                                {cfs_hm.service_name_hm}
                                            </span>
                                            <span className="status_icon">
                                                <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                                <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                            </span>
                                        </button>
                                    </div>
                                    <div id="collapseSix" className="collapse" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className='warehouse_content'>
                                                <img className='sol_cnt_img' src={cfs_hm.service_image_hm}></img>
                                                <h3 className='sol_cnt_ttl'>{cfs_hm.service_name_hm}</h3>
                                                <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: cfs_hm.service_desc_hm }}></p>
                                                <a href={cfs_hm.service_button_url_hm} className='btn wht_btn'>
                                                    <span className='btn_txt'>{cfs_hm.service_button_name_hm}</span>
                                                    <span className='arrow_btn wht_arrow'></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Solution Seven */}
                            {acf.network_design_hm.map((net_hm) =>
                                <div className="card">
                                    <div className="card-header">
                                        <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseSeven">
                                            <span className="icons_span">
                                                <img className='' src={net_hm.service_icon_hm}></img>
                                            </span>
                                            <span className="solution_name">
                                                {net_hm.service_name_hm}
                                            </span>
                                            <span className="status_icon">
                                                <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                                <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                            </span>
                                        </button>
                                    </div>
                                    <div id="collapseSeven" className="collapse" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className='warehouse_content'>
                                                <img className='sol_cnt_img' src={net_hm.service_image_hm}></img>
                                                <h3 className='sol_cnt_ttl'>{net_hm.service_name_hm}</h3>
                                                <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: net_hm.service_desc_hm }}></p>
                                                <a href={net_hm.service_button_url_hm} className='btn wht_btn'>
                                                    <span className='btn_txt'>{net_hm.service_button_name_hm}</span>
                                                    <span className='arrow_btn wht_arrow'></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Solution Eight */}
                            {acf.control_tower_hm.map((cont_hm) =>
                                <div className="card">
                                    <div className="card-header">
                                        <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseEight">
                                            <span className="icons_span">
                                                <img className='' src={cont_hm.service_icon_hm}></img>
                                            </span>
                                            <span className="solution_name">
                                                {cont_hm.service_name_hm}
                                            </span>
                                            <span className="status_icon">
                                                <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                                <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                            </span>
                                        </button>
                                    </div>
                                    <div id="collapseEight" className="collapse" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className='warehouse_content'>
                                                <img className='sol_cnt_img' src={cont_hm.service_image_hm}></img>
                                                <h3 className='sol_cnt_ttl'>{cont_hm.service_name_hm}</h3>
                                                <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: cont_hm.service_desc_hm }} ></p>
                                                <a href={cont_hm.service_button_url_hm} className='btn wht_btn'>
                                                    <span className='btn_txt'>{cont_hm.service_button_name_hm}</span>
                                                    <span className='arrow_btn wht_arrow'></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>



                    </div>
                ))}

            </div>
        </>
    )
}
