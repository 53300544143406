import React, { useRef, useState, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, EffectCreative, EffectFade, Parallax, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/effect-creative";
import 'swiper/css/effect-fade';
import '../../assets/css/home_spotlight.css'
import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Home_spotlight() {

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [thumbsSwiper1, setThumbsSwiper1] = useState(null);


  useEffect(() => {


    setTimeout(function () {
      $('.hm_sld_overlay').addClass('overly_animate1'); //transition 1 to upside
    }, 1800);

    setTimeout(function () {
      $('.hs_col2 .img_slider').css('visibility', 'visible'); //transition 2 to upside
    }, 2500);

    setTimeout(function () {
      $('.hm_sld_overlay').addClass('overly_animate2'); //transition 3 to upside
    }, 2550);

    setTimeout(function () {
      $('.hs_col2 .img_slider').addClass('img_slider_animate'); //transition 4 to upside
    }, 2000);


  })


  const { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/home_page`
  );


  return (
    <>

      {/* Desktop Spotlight  */}
      <div className='home_spotlight home_desktop_spot'>

        <div className='HS_inner'>

          <div className='hs_col1'>
            {data && data.map(({ acf }) => (
              <Swiper
                effect={"fade"}
                slidesPerView={1}
                speed={500}
                loop={true}
                scrollbar={{ draggable: false }}
                allowTouchMove={false}
                navigation={true}
                autoplay={{
                  delay: 5000,
                }}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[Autoplay, FreeMode, Navigation, Thumbs, EffectFade]}
                onSlideChange={() => console.log('slide change')}
                className="text_slider sldr_crl_bnt"
              >

                {acf.home_spotlight.map((sub) =>
                  <SwiperSlide >
                    <div className="hm_sld_txt_dv">
                      {/* <div className="hd_dv">
                    <h1 className="spot_heading hm_sld_txt">We Solve<br></br> Logistics Problems</h1>
                  </div> */}
                      <div className="hd_dv">
                        <h1 className="spot_heading hm_sld_txt ">{sub.slider_hd1}</h1>
                      </div>
                      <div className="hd_dv">
                        <h1 className="spot_heading hm_sld_txt">{sub.slider_hd2}</h1>
                      </div>
                      <p className="fade_animate ">{sub.slider_description}</p>

                      <a href={sub.slider_link} className='btn wht_btn fade_animate '>
                        <span className='btn_txt'>{sub.slider_button_name}</span>
                        <span className='arrow_btn wht_arrow'></span>
                      </a>

                    </div>
                  </SwiperSlide>
                )}

              </Swiper>
            ))}
          </div>

          <div className='hs_col2'>
            <div className="sld_bg hm_spot_bg " id="hm_spot_bg" ></div>
            <div className="hm_sld_overlay"></div>

            {data && data.map(({ acf }) => (
              <div className="img_sld_dv">
                <Swiper
                  effect={"creative"}
                  creativeEffect={{
                    prev: {
                      shadow: true,
                      // translate: ['-20%', 0, -1],
                      translate: [0, 0, -400],
                    },
                    next: {
                      translate: ["100%", 0, 0],
                    },
                  }}
                  onSwiper={setThumbsSwiper}
                  spaceBetween={0}
                  parallax={true}
                  loop={true}
                  scrollbar={{ draggable: false }}
                  allowTouchMove={false}
                  speed={1050}
                  navigation={false}
                  // autoplay={{
                  //   delay: 12000,
                  // }}
                  //thumbs={{ swiper: thumbsSwiper }}
                  modules={[Autoplay, FreeMode, Navigation, Thumbs, EffectCreative, Parallax]}
                  className="img_slider "
                >

                  {acf.home_spotlight.map((sub) =>
                    <SwiperSlide  >
                      <div className="hm_sld_img_dv sld_img1"  >
                        <img className="vsble_hdn" src={sub.slider_image} />
                      </div>
                    </SwiperSlide>
                  )}

                  {/* <SwiperSlide >
                <div className="hm_sld_img_dv sld_img2"  >
                  <img className="vsble_hdn" src={require('../../assets/img/hm_sld2.jpg')} />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="hm_sld_img_dv sld_img3" >
                  <img className="vsble_hdn" src={require('../../assets/img/hm_sld3.jpg')} />
                </div>
              </SwiperSlide> */}
                </Swiper>
              </div>
            ))}

            
          </div>

        </div>

      </div>


      {/* Tab & Mobile Spotlight  */}

      <div className='home_spotlight home_mob_spot'>

        <div className='HS_inner'>

          <div className='hs_col2'>
            <div className="sld_bg hm_spot_bg " id="hm_spot_bg" ></div>
            <div className="hm_sld_overlay"></div>

            {data && data.map(({ acf }) => (
              <div className="img_sld_dv">
                <Swiper
                  effect={"creative"}
                  creativeEffect={{
                    prev: {
                      shadow: true,
                      // translate: ['-20%', 0, -1],
                      translate: [0, 0, -400],
                    },
                    next: {
                      translate: ["100%", 0, 0],
                    },
                  }}
                  onSwiper={setThumbsSwiper1}
                  spaceBetween={0}
                  parallax={true}
                  loop={true}
                  scrollbar={{ draggable: false }}
                  allowTouchMove={false}
                  speed={1050}
                  navigation={false}
                  // autoplay={{
                  //   delay: 12000,
                  // }}
                  //thumbs={{ swiper: thumbsSwiper }}
                  modules={[Autoplay, FreeMode, Navigation, Thumbs, EffectCreative, Parallax]}
                  className="img_slider "
                >

                  {acf.home_spotlight.map((sub) =>
                    <SwiperSlide  >
                      <div className="hm_sld_img_dv sld_img1"  >
                        <img className="vsble_hdn" src={sub.slider_image} />
                      </div>
                    </SwiperSlide>
                  )}

                </Swiper>
              </div>
            ))}

            
          </div>

          <div className='hs_col1'>
            {data && data.map(({ acf }) => (
              <Swiper
                effect={"fade"}
                slidesPerView={1}
                speed={500}
                loop={true}
                scrollbar={{ draggable: false }}
                allowTouchMove={false}
                navigation={true}
                autoplay={{
                  delay: 5000,
                }}
                thumbs={{ swiper: thumbsSwiper1 }}
                modules={[Autoplay, FreeMode, Navigation, Thumbs, EffectFade]}
                onSlideChange={() => console.log('slide change')}
                className="text_slider sldr_crl_bnt"
              >

                {acf.home_spotlight.map((sub) =>
                  <SwiperSlide >
                    <div className="hm_sld_txt_dv">
                      {/* <div className="hd_dv">
                    <h1 className="spot_heading hm_sld_txt">We Solve<br></br> Logistics Problems</h1>
                  </div> */}
                      <div className="hd_dv">
                        <h1 className="spot_heading hm_sld_txt ">{sub.slider_hd1}</h1>
                      </div>
                      <div className="hd_dv">
                        <h1 className="spot_heading hm_sld_txt">{sub.slider_hd2}</h1>
                      </div>
                      <p className="fade_animate ">{sub.slider_description}</p>

                      <a href={sub.slider_link} className='btn wht_btn fade_animate '>
                        <span className='btn_txt'>{sub.slider_button_name}</span>
                        <span className='arrow_btn wht_arrow'></span>
                      </a>

                    </div>
                  </SwiperSlide>
                )}

              </Swiper>
            ))}
          </div>

        </div>

      </div>


    </>
  )
}
