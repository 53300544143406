import React from 'react'
import '../../../../assets/css/service_offer_ware.css';

import { useEffect, useRef } from 'react';
import $ from 'jquery';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Last_mile() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const lstmile_hd = useRef(null);
    const lstmile_p1 = useRef(null);
    const lstmile_img1 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".lstmile_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".lstmile_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".lstmile_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".lstmile_p1",
                markers: false
            }
        });

        gsap.fromTo(".lstmile_img1", {
            x: 0
        }, {
            xPercent: 101,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".lstmile_img1",
                markers: false
            }
        });

        $(".last_mile_content").hide();
        $(".last_mile").on("click", function () {
            var txt = $(".last_mile_content").is(':visible') ? 'Read More' : 'Read Less';
            $(".last_mile").text(txt);
            $('.last_mile_content').slideToggle(200);
        });
        $(".last_mile_link").on("click", function () {
            var txt = $(".last_mile_content").is(':visible') ? 'Read Less' : 'Read Less';
            $(".last_mile").text(txt);
            $('.last_mile_content').slideDown(200);
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/land_transport_page`
    );

    return (
        <>
            <div className='last_mile_sec air_freight_sec SO_ware mob_padding_LR tab_padding_LR' id='last-mile-delivery'>
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-lg-5 col-md-12 col-12'>
                                <div className='so_col1_network img_air'>

                                    {acf.last_mile_delivery_section.map((land_sub4) =>
                                        <div className='img_dv'>
                                            <div className='img_oly grd_blue_oly lstmile_img1' ref={lstmile_img1}></div>
                                            <img className='so_col_img' src={land_sub4.last_mile_image}></img>
                                        </div>
                                    )}

                                </div>
                            </div>
                            <div className='col-lg-7 col-md-12 col-12 lst_ml_img_col'>
                                {acf.last_mile_delivery_section.map((land_sub4) =>
                                    <div className='design_ttl air_main'>

                                        <div className='hd_dv'>
                                            <h2 className='heading wht_txt lstmile_hd' ref={lstmile_hd}>{land_sub4.last_mile_hd}</h2>
                                        </div>
                                        <p className='so_col_desc land_para lstmile_p1' ref={lstmile_p1}>{land_sub4.last_mile_description}</p>
                                        <p className='so_col_desc land_para  lstmile_p1' ref={lstmile_p1}>{land_sub4.last_mile_description1}</p>

                                        {/* <a className="last_mile lstmile_p1 read_more_btn wht_txt" data-content="toggle-text" ref={lstmile_p1}>Read More</a> */}

                                    </div>
                                )}
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}
