import React from 'react'
import '../../assets/css/inside_page_spotlight.css'
import { useParams } from "react-router-dom";
import useFetch from "react-fetch-hook";

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Blog_inside_spotlight() {


  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const blog_in_spot_hd = useRef(null);
  const blog_in_spt_ad1 = useRef(null);

  useEffect(() => {

    gsap.fromTo(".blog_in_spot_hd", {
      y: 250,
      opacity: 0,
      skewY: 41,
    }, {
      delay: 2,
      duration: 0.8,
      y: 0,
      skewY: 0,
      opacity: 1,
      ease: "power4.out",
      stagger: {
        from: 'start',
        amount: 0.5,
      },
      scrollTrigger: {
        trigger: ".blog_in_spot_hd",
        markers: false
      }
    });

    // Para animation code
    gsap.fromTo(".blog_in_spt_ad1", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2,
      scrollTrigger: {
        trigger: ".blog_in_spt_ad1",
        markers: false
      }
    });

  })

  let { slug } = useParams();

  var { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/blogs/?slug=${slug}`
  );

  return (
    <>
      <div className='sector_spotlight media_spotlight page_spotlight mi_spot blog_inside_spot'>
        <div className='spotlight_container'>
          <div className='row'>
            <div className='col-12 pr_0'>
              <div className='spotlight_dv'>
                <div className='spot_contnt'>
                  {data && data.map(({ id, title, acf }) => (
                    <div className='spot_col1 blog_spot_col1'>

                      <p className='blog_inside_slug blog_in_spt_ad1' ref={blog_in_spt_ad1}>Blog </p>
                      <h1 className='spot_heading wht_txt media_hd blog_in_spt_ad1' ref={blog_in_spt_ad1}>{title.rendered}</h1>

                      <ul className='media_dtl_list blog_in_spt_ad1' ref={blog_in_spt_ad1}>
                        {/* <li><img src={require('../../assets/img/svg/user_icon.svg').default}></img>{acf.author_name}</li> */}
                        <li><img src={require('../../assets/img/svg/date_icon.svg').default}></img>{acf.date}</li>
                        <li><img src={require('../../assets/img/svg/time_icon.svg').default}></img>{acf.time_read}</li>
                      </ul>

                    </div>
                  ))}

                  {/* <div className='spot_col2' style={{ visibility: 'hidden' }}>
                    <p className='wht_txt'>Our countrywide network of warehouses provides a wide range of services including dedicated and multi-user facilities.</p>
                    <a href='#' className='btn wht_btn'>
                      <span className='btn_txt'>Learn More</span>
                      <span className='arrow_btn wht_arrow'></span>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
