import React from 'react'
import '../../../assets/css/purpose.css';

import $ from "jquery";
import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Values() {

  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const vlu_hd = useRef(null);
  const vlu_p = useRef(null);
  const vlu_p1 = useRef(null);


  useEffect(() => {

    gsap.fromTo(".vlu_hd", {
      y: 250,
      opacity: 0,
      skewY: 41,
    }, {
      delay: 0.2,
      duration: 0.8,
      y: 0,
      skewY: 0,
      opacity: 1,
      ease: "power4.out",
      stagger: {
        from: 'start',
        amount: 0.5,
      },
      scrollTrigger: {
        trigger: ".vlu_hd",
        markers: false
      }
    });

    // Para animation code
    gsap.fromTo(".vlu_p", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.8,
      ease: "linear",
      delay: 0.2,
      scrollTrigger: {
        trigger: ".vlu_p",
        markers: false
      }
    });
    gsap.fromTo(".vlu_p1", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.8,
      ease: "linear",
      delay: 0.2,
      scrollTrigger: {
        trigger: ".vlu_p1",
        markers: false
      }
    });

  })

  const { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/about_page`
  );

  return (
    <>
      <div className='value_sec pmv_sec mob_padding_LR tab_padding_LR' id='our-values'>
        <div className='cstm_container'>
          <div className='row'>

            {data && data.map(({ acf }) => (

              <div className='col-12'>
                <div className='purpose_col value_col'>

                  {acf.about_sec_4.map((vlu_sub) =>
                    <div className='hd_dv'>
                      <h2 className='pd_ttl heading wht_txt vlu_hd' ref={vlu_hd}>{vlu_sub.value_hd}</h2>
                    </div>
                  )}

                  {/* {acf.about_sec_4.map((vlu_sub) =>
                    <p className='so_sub_ttl wht_txt vlu_p vlu_sub_ttl' ref={vlu_p}>{vlu_sub.value_sub_hd}</p>
                  )} */}
                  {acf.about_sec_4.map((vlu_sub) =>
                    <p className='so_sub_ttl wht_txt vlu_p' ref={vlu_p}>{vlu_sub.value_description1}<br></br>{vlu_sub.value_description2}</p>
                  )}

                  <div className='row vlu_point_row vlu_p1' id='vlu_desktop' ref={vlu_p1}>

                    <div className='col-lg-6 col-md-6 col-12'>

                      <div id="vlu1_accordion" className="accordion_solution ">

                        {/* Accord One */}
                        {acf.trust.map((vlu_sub1) =>
                          <div className="card">
                            <div className="card-header">
                              <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseOne_vlu1">
                                <span className="icons_span">
                                  <img className='' src={vlu_sub1.trust_icon}></img>
                                </span>
                                <span className="solution_name">{vlu_sub1.trust_hd}</span>
                                <span className="status_icon">
                                  <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                  <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                </span>
                              </button>
                            </div>
                            <div id="collapseOne_vlu1" className="collapse " data-parent="#vlu1_accordion">
                              <div className="card-body">
                                <div className='warehouse_content'>
                                  <p className='sol_cnt_desc wht_txt'>{vlu_sub1.trust_description}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Accord Two */}
                        {acf.customer.map((vlu_sub2) =>
                          <div className="card">
                            <div className="card-header">
                              <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseTwo_vlu1">
                                <span className="icons_span">
                                  <img className='' src={vlu_sub2.customer_icon}></img>
                                </span>
                                <span className="solution_name">{vlu_sub2.customer_hd}</span>
                                <span className="status_icon">
                                  <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                  <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                </span>
                              </button>
                            </div>
                            <div id="collapseTwo_vlu1" className="collapse" data-parent="#vlu1_accordion">
                              <div className="card-body">
                                <div className='warehouse_content'>
                                  <p className='sol_cnt_desc wht_txt'>{vlu_sub2.customer_description}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Accord Three */}
                        {acf.integrity.map((vlu_sub3) =>
                          <div className="card">
                            <div className="card-header">
                              <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseThree_vlu1">
                                <span className="icons_span">
                                  <img className='' src={vlu_sub3.integrity_icon}></img>
                                </span>
                                <span className="solution_name">{vlu_sub3.integrity_hd}</span>
                                <span className="status_icon">
                                  <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                  <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                </span>
                              </button>
                            </div>
                            <div id="collapseThree_vlu1" className="collapse" data-parent="#vlu1_accordion">
                              <div className="card-body">
                                <div className='warehouse_content'>
                                  <p className='sol_cnt_desc wht_txt'>{vlu_sub3.integrity_description}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}


                      </div>

                    </div>


                    <div className='col-lg-6 col-md-6 col-12'>
                      <div id="vlu2_accordion" className="accordion_solution ">

                        {/* Accord One */}
                        {acf.respect.map((vlu_sub4) =>
                          <div className="card">
                            <div className="card-header">
                              <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseOne_vlu2">
                                <span className="icons_span">
                                  <img className='' src={vlu_sub4.respect_icon}></img>
                                </span>
                                <span className="solution_name">{vlu_sub4.respect_hd}</span>
                                <span className="status_icon">
                                  <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                  <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                </span>
                              </button>
                            </div>
                            <div id="collapseOne_vlu2" className="collapse" data-parent="#vlu2_accordion">
                              <div className="card-body">
                                <div className='warehouse_content'>

                                  <p className='sol_cnt_desc wht_txt'>{vlu_sub4.respect_description}</p>

                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Accord Two */}
                        {acf.talent.map((vlu_sub5) =>
                          <div className="card">
                            <div className="card-header">
                              <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseTwo_vlu2">
                                <span className="icons_span">
                                  <img className='' src={vlu_sub5.talent_icon}></img>
                                </span>
                                <span className="solution_name">{vlu_sub5.talent_hd}</span>
                                <span className="status_icon">
                                  <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                  <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                </span>
                              </button>
                            </div>
                            <div id="collapseTwo_vlu2" className="collapse" data-parent="#vlu2_accordion">
                              <div className="card-body">
                                <div className='warehouse_content'>

                                  <p className='sol_cnt_desc wht_txt'>{vlu_sub5.talent_description}</p>

                                </div>
                              </div>
                            </div>
                          </div>
                        )}



                      </div>
                    </div>

                  </div>

                  <div className='row vlu_point_row vlu_p1' id='vlu_mob' ref={vlu_p1}>

                    <div className='col-lg-12 col-md-12 col-12 ' >

                      <div id="vlu3_accordion" className="accordion_solution ">

                        {/* Accord One */}
                        {acf.trust.map((vlu_sub1) =>
                          <div className="card">
                            <div className="card-header">
                              <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseOne_vlu3">
                                <span className="icons_span">
                                  <img className='' src={vlu_sub1.trust_icon}></img>
                                </span>
                                <span className="solution_name">{vlu_sub1.trust_hd}</span>
                                <span className="status_icon">
                                  <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                  <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                </span>
                              </button>
                            </div>
                            <div id="collapseOne_vlu3" className="collapse " data-parent="#vlu3_accordion">
                              <div className="card-body">
                                <div className='warehouse_content'>
                                  <p className='sol_cnt_desc wht_txt'>{vlu_sub1.trust_description}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Accord Two */}
                        {acf.respect.map((vlu_sub4) =>
                          <div className="card">
                            <div className="card-header">
                              <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseTwo_vlu3">
                                <span className="icons_span">
                                  <img className='' src={vlu_sub4.respect_icon}></img>
                                </span>
                                <span className="solution_name">{vlu_sub4.respect_hd}</span>
                                <span className="status_icon">
                                  <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                  <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                </span>
                              </button>
                            </div>
                            <div id="collapseTwo_vlu3" className="collapse" data-parent="#vlu3_accordion">
                              <div className="card-body">
                                <div className='warehouse_content'>
                                  <p className='sol_cnt_desc wht_txt'>{vlu_sub4.respect_description}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Accord Three */}
                        {acf.customer.map((vlu_sub2) =>
                          <div className="card">
                            <div className="card-header">
                              <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseThree_vlu3">
                                <span className="icons_span">
                                  <img className='' src={vlu_sub2.customer_icon}></img>
                                </span>
                                <span className="solution_name">{vlu_sub2.customer_hd}</span>
                                <span className="status_icon">
                                  <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                  <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                </span>
                              </button>
                            </div>
                            <div id="collapseThree_vlu3" className="collapse" data-parent="#vlu3_accordion">
                              <div className="card-body">
                                <div className='warehouse_content'>
                                  <p className='sol_cnt_desc wht_txt'>{vlu_sub2.customer_description}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Accord Four */}
                        {acf.talent.map((vlu_sub5) =>
                          <div className="card">
                            <div className="card-header">
                              <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseFour_vlu3">
                                <span className="icons_span">
                                  <img className='' src={vlu_sub5.talent_icon}></img>
                                </span>
                                <span className="solution_name">{vlu_sub5.talent_hd} </span>
                                <span className="status_icon">
                                  <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                  <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                </span>
                              </button>
                            </div>
                            <div id="collapseFour_vlu3" className="collapse" data-parent="#vlu3_accordion">
                              <div className="card-body">
                                <div className='warehouse_content'>
                                  <p className='sol_cnt_desc wht_txt'>{vlu_sub5.talent_description}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Accord Five */}
                        {acf.integrity.map((vlu_sub3) =>
                          < div className="card" >
                            <div className="card-header">
                              <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseFive_vlu3">
                                <span className="icons_span">
                                  <img className='' src={vlu_sub3.integrity_icon}></img>
                                </span>
                                <span className="solution_name">{vlu_sub3.integrity_hd}</span>
                                <span className="status_icon">
                                  <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                  <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                </span>
                              </button>
                            </div>
                            <div id="collapseFive_vlu3" className="collapse" data-parent="#vlu3_accordion">
                              <div className="card-body">
                                <div className='warehouse_content'>
                                  <p className='sol_cnt_desc wht_txt'>{vlu_sub3.integrity_description}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}


                      </div>

                    </div>


                  </div>


                </div>
              </div>

            ))}

          </div>
        </div>
      </div>
    </>
  )
}
