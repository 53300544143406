import React from 'react'
import '../../../../assets/css/solution_cstm.css';

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Solution_automo() {


    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const auto_sol_hd = useRef(null);
    const auto_sol_p1 = useRef(null);
    const auto_sol_p2 = useRef(null);
    const auto_sol_p3 = useRef(null);
    const auto_sol_img1 = useRef(null);


    useEffect(() => {

        gsap.fromTo(".auto_sol_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".auto_sol_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".auto_sol_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.4,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".auto_sol_p1",
                markers: false
            }
        });
        gsap.fromTo(".auto_sol_p2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.4,
            ease: "linear",
            delay: 0.6,
            scrollTrigger: {
                trigger: ".auto_sol_p2",
                markers: false
            }
        });
        gsap.fromTo(".auto_sol_p3", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.4,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".auto_sol_p3",
                markers: false
            }
        });

        gsap.fromTo(".auto_sol_img1", {
            opacity: 0
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".auto_sol_img1",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/automotive_sec_page`
    );

    return (
        <>
            <div className='sol_automo mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>
                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-lg-12 col-12'>

                                {acf.automotive_solution_sec.map((auto_sol) =>
                                    <div className='hd_dv' style={{ margin: 'auto' }}>
                                        <h1 className='heading wht_txt txt_cntr auto_sol_hd' ref={auto_sol_hd}>{auto_sol.automotive_solution_hd}</h1>
                                    </div>
                                )}


                                {/* <div className='solution_cstm_dv'>
                                    <img className='sol_line_img auto_sol_img1' ref={auto_sol_img1} src={require('../../../../assets/img/svg/solution_line.svg').default}></img>

                                    {acf.automotive_solution_list_sec.map((auto_sol1) =>
                                        <div className='sol_bx auto_sol_p1' ref={auto_sol_p1}>
                                            <p>{auto_sol1.automotive_solution_list}</p>
                                        </div>
                                    )}
                                </div> */}


                                <div className='solution_cstm_dv'>

                                    <div className='row'>

                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Improvements with Lean Six Sigma </p>
                                                        <p>We implemented Lean Six Sigma principles and tools to the analysis of business processes. This led to improvements in performance metrics.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Redesign Complete Warehouse</p>
                                                        <p>We redesigned the complete warehouse to improve the movement of manpower between storage locations, which speeded up the material handling process.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        

                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Service Integration</p>
                                                        <p>We integrated our key logistics services like in-plant logistics, warehousing, and transportation (long-haul and short-haul) services giving us full control of goods from the finished goods storage of the plant till the supply to its customers’ assembly line. This, in turn, substantially improved the execution time.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Establishing JIT (Just-In-Time) Warehouse</p>
                                                        <p>We established and started operating JIT warehouse with defined time-based deliveries to the manufacturer’s customers and its warehouses across India.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>


                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
