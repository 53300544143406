import React from 'react'
import '../../../assets/css/apollo_edge_service.css'

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from 'jquery';
import useFetch from "react-fetch-hook";

export default function Apollo_edge_service() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const ap_edge_ware_hd = useRef(null);
    const ap_edge_ware_hd1 = useRef(null);
    const ap_edge_ware_p1 = useRef(null);
    const ap_edge_ware_p2 = useRef(null);
    const ap_edge_ware_img1 = useRef(null);
    const ap_edge_ware_img2 = useRef(null);
    const ap_edge_ware_p3 = useRef(null);
    
    useEffect(() => {

        gsap.fromTo(".ap_edge_ware_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".ap_edge_ware_hd",
                markers: false
            }
        });

        gsap.fromTo(".ap_edge_ware_hd1", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".ap_edge_ware_hd1",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".ap_edge_ware_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ap_edge_ware_p1",
                markers: false
            }
        });

        gsap.fromTo(".ap_edge_ware_img1", {
            x: 0
        }, {
            x: 635,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ap_edge_ware_img1",
                markers: false
            }
        });

        gsap.fromTo(".ap_edge_ware_img2", {
            x: 0
        }, {
            x: 635,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ap_edge_ware_img2",
                markers: false
            }
        });

        gsap.fromTo(".ap_edge_ware_p3", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ap_edge_ware_p3",
                markers: false
            }
        });

        $(".state_tech_content").hide();
        $(".state_tech").on("click", function () {
            var txt = $(".state_tech_content").is(':visible') ? 'Read More' : 'Read Less';
            $(".state_tech").text(txt);
            $('.state_tech_content').slideToggle(200);
        });

    })


    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/warehousing_page`
    );


    return (
        <>
            <div className='apollo_edge_service mob_padding_LR tab_padding_LR' id='state-of-the-art-technology'>

                {data && data.map(({ acf }) => (
                    <div className='cstm_container'>

                        <div className='row'>
                            <div className='col-12 state_edge_hd'>
                                {acf.technology_edge_section.map((ware_techno) =>
                                    <div className='hd_dv' style={{ margin: 'auto' }}>
                                        <h2 className='heading techo_head ap_edge_ware_hd' ref={ap_edge_ware_hd}>{ware_techno.technology_hd}</h2>
                                    </div>
                                )}
                            </div>
                        </div>

                        {acf.state_tech_info_.map((ware_tech) =>
                            <div className='row state_row'>

                                <div className='col-lg-12 col-md-12 col-12 '>
                                    <div className='info_ware_col_one state_tech_dv'>

                                        <p className='tech_sub_para ap_edge_ware_p3' ref={ap_edge_ware_p3}>{ware_tech.state_tech_description}</p>

                                        <div className='state_tech_content ap_edge_ware_p3' ref={ap_edge_ware_p3} dangerouslySetInnerHTML={{ __html: ware_tech.state_tech_description1 }}>
                                        </div>

                                        <a className="state_tech ap_edge_ware_p3 read_more_btn rm_blue" data-content="toggle-text" ref={ap_edge_ware_p3}>Read More</a>

                                    </div>
                                </div>

                            </div>
                        )}

                        <div className='row aes_row2'>
                            <div className='col-lg-6 col-md-6 col-12 p_0'>
                                <div className='aes_col1'>

                                    {acf.technology_edge_section.map((ware_techno) =>
                                        <div className='img_dv'>
                                            <div className='img_oly ap_edge_ware_img1' ref={ap_edge_ware_img1}></div>
                                            <img className='so_col_img' src={ware_techno.technology_image}></img>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12 p_0 aes_bg' >
                                {acf.technology_edge_section.map((ware_techno) =>
                                    <div className='aes_col2'>
                                        <h2 className='aes_hd ap_edge_ware_p1' ref={ap_edge_ware_p1}>{ware_techno.technology_hd1}</h2>
                                        <p className='aes_desc ap_edge_ware_p1' ref={ap_edge_ware_p1}>{ware_techno.technology_para}</p>
                                        <ul className='aes_list global_list ap_edge_ware_p1' ref={ap_edge_ware_p1}>

                                            {ware_techno.technology_list.map((techno_list) =>
                                                <li>{techno_list.technology_list_data}</li>
                                            )}

                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                ))}

            </div>


            <div className='apollo_edge_service mob_padding_LR tab_padding_LR' id='growth-catalysts'>

                {data && data.map(({ acf }) => (
                    <div className='cstm_container'>

                        <div className='row'>
                            <div className='col-12 aes_main_col'>

                                {acf.ware_asc_edge_section.map((ware_asc) =>
                                    <div className='hd_dv' style={{ margin: 'auto' }}>
                                        <h2 className='heading techo_head ap_edge_ware_hd1' ref={ap_edge_ware_hd1}>{ware_asc.ware_asc_edge_hd}</h2>
                                    </div>
                                )}

                            </div>
                        </div>

                        <div className='row aes_row3 aes_sec2_row'>

                            <div className='col-lg-6 col-md-6 col-12 p_0 aes_bg asc_order' >
                                {acf.ware_asc_edge_section.map((ware_asc) =>
                                    <div className='aes_col2 grwth_catalyst'>
                                    
                                        <ul className='aes_list global_list ap_edge_ware_p2' ref={ap_edge_ware_p2}>

                                            {ware_asc.ware_asc_edge_list.map((asc_list) =>
                                                <li>{asc_list.ware_asc_edge_list_data}</li>
                                            )}

                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className='col-lg-6 col-md-6 col-12 p_0'>
                                <div className='aes_col1'>

                                    {acf.ware_asc_edge_section.map((ware_asc) =>
                                        <div className='img_dv'>
                                            <div className='img_oly ap_edge_ware_img2' ref={ap_edge_ware_img2}></div>
                                            <img className='so_col_img' src={ware_asc.ware_asc_edge_image}></img>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>



                    </div>
                ))}
            </div>

        </>
    )
}
