import React from 'react'
import Header from "../component/global/header";
import About_spotlight from '../component/spotlight/about_spotlight';
import About_sec from '../component/section/about/about_sec';
import Purpose from '../component/section/about/purpose';
import Mission from '../component/section/about/mission';
import Values from '../component/section/about/values';
import Journey_growth from '../component/section/about/journey_growth';
import Team from '../component/section/about/team';
import Apollo_edge from "../component/global/apollo_edge";
import Sustainability from '../component/section/about/Sustainability';
import Achievements from '../component/section/about/achievements';
import Footer from "../component/global/footer";
// import CustomGlobe from "../CustomGlobe";

import Transition from '../component/transition';
import { gsap } from "gsap";
import { useEffect, useRef } from 'react';
import { Power3, Power4 } from "gsap";


import Sticky from 'react-sticky-el';




export default function About_page() {

  let body = useRef(null);
  var tl_1 = gsap.timeline()

  useEffect(() => {

    // Page Transition Code

    tl_1.to(body, {
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut,
      delay: 1.2
    });
    return () => {
      tl_1.to(body, {
        opacity: "0",
        pointerEvents: 'none'
      });
    }

  })

  return (
    <>
      <Transition />

      <div className='about_pg'>
        <div ref={(el) => (body = el)} className="Headd">

          <Header />
          <About_spotlight />
          <About_sec />
          <Sticky className='fix-sec'>
            <Purpose />
          </Sticky>
          {/* <Sticky className='fix-sec'>
            <Mission />
          </Sticky> */}
          {/* <Sticky className='fix-sec value_stikcy'> */}
            <Values />
          {/* </Sticky> */}
          {/* <Journey_growth /> */}
          {/* <Team /> */}
          {/* <Apollo_edge /> */}
          {/* <Achievements /> */}
          <Sustainability />
          <Footer />
        </div>
      </div>
    </>
  )
}
