import React from 'react'
import '../../../assets/css/connect_service.css';
import '../../../assets/css/client.css';

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import ReCAPTCHA from "react-google-recaptcha";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/effect-creative";
import 'swiper/css/effect-fade';
import $ from 'jquery';
import { useEffect, useRef, useState } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";
import emailjs from 'emailjs-com';

export default function Connect_service() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const conn_frm_hd = useRef(null);
    const conn_frm = useRef(null);
    
    useEffect(() => {

        gsap.fromTo(".conn_frm_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".conn_frm_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".conn_frm", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".conn_frm",
                markers: false
            }
        });
    })

    const form = useRef();
    const [loading, setLoading] = useState(false)
    const [statusMessage, setStatusMessage] = useState("");

    const nameinput = React.useRef(null);
    const emailinput = React.useRef(null);

    const orginput = React.useRef(null);
    const phoneinput = React.useRef(null);

    const departinput = React.useRef(null);
    const msginput = React.useRef(null);
    const captchaRef = useRef(null);

    // All field validation error hide code 
    const handleFocus = e => {
        e.target.classList.remove("error_line");

        let er1 = document.getElementById("nm_err");
        er1.classList.remove("show_error");

        let er2 = document.getElementById("eml_err");
        er2.classList.remove("show_error");

        let er2v = document.getElementById("eml_err1");
        er2v.classList.remove("show_error");

        let er3 = document.getElementById("cmp_err");
        er3.classList.remove("show_error");

        let er5 = document.getElementById("depart_err");
        er5.classList.remove("show_error");

        let er6 = document.getElementById("msg_err");
        er6.classList.remove("show_error");

        

    };

    // Phone field validation error hide code    
    const handleFocus1 = e => {
        e.target.classList.remove("error_line");

        let er4 = document.getElementById("phn_err");
        er4.classList.remove("show_error");

        let er4v = document.getElementById("phn_err1");
        er4v.classList.remove("show_error");
    };

   

    // Form action code
    const sendEmail = async (e) => {

        e.preventDefault();

        // Fields get value Code
        let name_fld = (nameinput.current.value);
        let email_fld = (emailinput.current.value);

        let org_fld = (orginput.current.value);
        let phone_fld = (phoneinput.current.value);

        let dprt_fld = (departinput.current.value);
        let msg_fld = (msginput.current.value);
        let token = captchaRef.current.getValue();

        // Phone Regex Code
        let phone_fltr = document.getElementById("phone_fld");
        var mob_regx = /^([+][9][1]|[9][1]|[0]){0,1}([7-9]{1})([0-9]{9})$/;
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


        // Fields validation Code
        if (name_fld == '') {
            let v1 = document.getElementById("name_fld");
            v1.className += " error_line";

            let er1 = document.getElementById("nm_err");
            er1.className += " show_error";
        }
        else if (email_fld == '') {
            let v2 = document.getElementById("email_fld");
            v2.className += " error_line";

            let er2 = document.getElementById("eml_err");
            er2.className += " show_error";
        }
        else if (!email_fld.match(mailformat)) {
            let v2 = document.getElementById("email_fld");
            v2.className += " error_line";

            let er2v = document.getElementById("eml_err1");
            er2v.className += " show_error";
        }
        else if (phone_fld == '') {
            let v4 = document.getElementById("phone_fld");
            v4.className += " error_line";

            let er4 = document.getElementById("phn_err");
            er4.className += " show_error";
        }

        else if (!mob_regx.test(phone_fltr.value)) {
            let v4 = document.getElementById("phone_fld");
            v4.className += " error_line";

            let er4 = document.getElementById("phn_err1");
            er4.className += " show_error";
        }
        else if (org_fld == '') {
            let v3 = document.getElementById("org_fld");
            v3.className += " error_line";

            let er3 = document.getElementById("cmp_err");
            er3.className += " show_error";
        }
        else if (dprt_fld == '') {
            let v5 = document.getElementById("dprt_fld");
            v5.className += " error_line";

            let er5 = document.getElementById("depart_err");
            er5.className += " show_error";
        }
        else if (msg_fld == '') {
            let v6 = document.getElementById("msg_fld");
            v6.className += " error_line";

            let er6 = document.getElementById("msg_err");
            er6.className += " show_error";
        }
        else if(token == '') {
            
            console.log("You must confirm you are not a robot");
            let recap_er = document.getElementById("recap_err");
            recap_er.className += " show_error";
        }
        else {

            let recap_er = document.getElementById("recap_err");
            recap_er.classList.remove("show_error");

            fetch('https://script.google.com/macros/s/AKfycbwJXw7tIvH7v9XsZkhCJsaPaJkOmE8pIbSPJ8FBwsiNiH53VaUMK_6kRherJJCLnYYFYg/exec', {
                method: 'POST',
                body: new FormData(form.current),

                    }).then(res => {
                        console.log("SUCCESSFULLY SUBMITTED")
                        setLoading(false)
                    })
                        .catch(err => console.log(err))

                    emailjs.sendForm('service_l2n43ts', 'template_5iwkv2h', e.target, 'SsPYHKCapw4h-xBn_')
                        .then((result) => {

                            console.log(result.text);
                            setStatusMessage("Your Message has been sent successfully");
                            let msg = document.getElementById("thnk_msg");
                            msg.className += " thnk_msg_show";

                            let frm_bx = document.getElementById("service_form_bx");
                            frm_bx.className += " service_form_bx_hide";

                        }, (error) => {

                            console.log(error.text);
                            setStatusMessage(`${error.text} happened`);

                        });
                    e.target.reset()
        }

    };


    $(document).ready(function () {
        var url = window.location.pathname;
        console.log(url);

        if (url === '/warehousing') {
            let pg_name = 'warehousing';
            $('#page_name').val(pg_name);
        }

        if (url === '/surface-transport') {
            let pg_name = 'surface-transport';
            $('#page_name').val(pg_name);
        }

        if (url === '/freight-forwarding') {
            let pg_name = 'freight-forwarding';
            $('#page_name').val(pg_name);
        }

        if (url === '/CFS-&-ICDs') {
            let pg_name = 'CFS-&-ICDs';
            $('#page_name').val(pg_name);
        }

        if (url === '/supply-chain-consulting') {
            let pg_name = 'supply-chain-consulting';
            $('#page_name').val(pg_name);
        }

        if (url === '/control-tower-&-analytics-center') {
            let pg_name = 'control-tower-&-analytics-center';
            $('#page_name').val(pg_name);
        }

        if (url === '/sector/consumer-durables-high-tech-industries') {
            let pg_name = 'consumer-durables-high-tech-industries';
            $('#page_name').val(pg_name);
        }
        if (url === '/sector/automotive-&-industrial-sector') {
            let pg_name = 'automotive-&-industrial-sector';
            $('#page_name').val(pg_name);
        }
        if (url === '/sector/e-commerce-&-FMCG') {
            let pg_name = 'e-commerce-&-FMCG';
            $('#page_name').val(pg_name);
        }
        if (url === '/sector/other-sector') {
            let pg_name = 'other-sector';
            $('#page_name').val(pg_name);
        }

        if (url === '/apollo-edge') {
            let pg_name = 'apollo-edge';
            $('#page_name').val(pg_name);
        }


    });


    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/section_heading`
    );


    return (
        <>
            <div className='client_sec connect_service mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>
                    <div className='row m_0'>

                        {data && data.map(({ acf }) => (
                            <div className='col-lg-12 col-md-12 col-12 pl_0 clint_logo_maincol'>

                                <div className='hd_dv' style={{ margin: 'auto' }}>
                                    <h2 className="connect_ttl heading conn_frm_hd" ref={conn_frm_hd}>{acf.optimized_form_heading}</h2>
                                </div>
                                <div className='client_col2 connect_col conn_frm' ref={conn_frm}>

                                    <form className='connect_form' ref={form} onSubmit={sendEmail} id='service_form_bx' >

                                        <input className='page_name' type='hidden' name='page_name' id='page_name' />
                                        <div className='row'>
                                            <div className='col-md-6 col-12'>

                                                <div className='frm_inr_dv'>
                                                    <input className='input_fld' type='text' name='name' placeholder='Name' id='name_fld' ref={nameinput} onFocus={handleFocus} />
                                                    <p className='error_msg' id='nm_err'>Please Enter Name</p>
                                                </div>
                                            </div>
                                            <div className='col-md-6 col-12'>

                                                <div className='frm_inr_dv'>
                                                    <input className='input_fld' type='email' name='email' placeholder='Email Address' id='email_fld' ref={emailinput} onFocus={handleFocus} />
                                                    <p className='error_msg' id='eml_err'>Please Enter Email Address</p>
                                                    <p className='error_msg' id='eml_err1'>Please Enter Valid Email Address</p>
                                                </div>
                                            </div>
                                            <div className='col-md-6 col-12'>

                                                <div className='frm_inr_dv'>
                                                    <input className='input_fld' type='text' name='phone' placeholder='Mobile No' id='phone_fld' ref={phoneinput} onFocus={handleFocus1} />
                                                    <p className='error_msg' id='phn_err'>Please Enter Mobile No</p>
                                                    <p className='error_msg' id='phn_err1'>Please Enter Valid Mobile No</p>
                                                </div>

                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <div className='frm_inr_dv'>
                                                    <input className='input_fld' type='text' name='company' placeholder='Company' id='org_fld' ref={orginput} onFocus={handleFocus} />
                                                    <p className='error_msg' id='cmp_err'>Please Enter Company</p>
                                                </div>
                                            </div>
                                            <div className='col-12'>

                                                <div className='frm_inr_dv'>
                                                    <select className='input_fld' name='service' id='dprt_fld' ref={departinput} onFocus={handleFocus} >
                                                        <option value="" disabled selected>Select Service</option>
                                                        <option value='warehousing'>Warehousing</option>
                                                        <option value='surface-transport'>Surface Transport</option>
                                                        <option value='freight-forwarding'>Freight Forwarding</option>
                                                        <option value='CFS-(Infra)'>CFS (Infra)</option>
                                                        <option value='supply-chain-consulting'>Supply Chain Consulting</option>
                                                        <option value='control-tower-&-analytics-center'>Control Tower & Analytics Center</option>
                                                        <option value='others'>Others</option>
                                                    </select>
                                                    <p className='error_msg' id='depart_err'>Please Select Service</p>
                                                </div>

                                            </div>
                                            <div className='col-12'>
                                                <div className='frm_inr_dv'>
                                                    <textarea className='input_fld' name='message' placeholder='Tell Us More' id='msg_fld' ref={msginput} onFocus={handleFocus}></textarea>
                                                    <p className='error_msg' id='msg_err'>Please Enter Message</p>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                
                                                <ReCAPTCHA sitekey="6LfInfMmAAAAACNp9E7DaElr2uxDJ_sF8jvI6XzW" ref={captchaRef} />
                                                <div className='frm_inr_dv recap_div'>
                                                    <p className='error_msg' id='recap_err'>Please Check reCAPTCHA</p>
                                                </div>
                                                <br></br>
                                            </div>
                                            <div className='col-12'>
                                                <span className='btn blue_btn input_btn'>
                                                    <input className='btn_txt' type='submit' name='submit' value='submit'></input>
                                                    <span className='arrow_btn blue_arrow'></span>
                                                </span>
                                            </div>
                                        </div>

                                    </form>

                                    <p className='form_thanks_msg' id='thnk_msg'>Your form has been sent successfully</p>

                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </>
    )
}
