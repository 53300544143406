import React from "react";
import "../../../assets/css/service_offer_ware.css";

import { useEffect, useRef } from "react";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function New_sec() {
  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const air_hd = useRef(null);
  const air_p1 = useRef(null);
  const air_img1 = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      ".air_hd",
      {
        y: 250,
        opacity: 0,
        skewY: 41,
      },
      {
        delay: 0.2,
        duration: 0.8,
        y: 0,
        skewY: 0,
        opacity: 1,
        ease: "power4.out",
        stagger: {
          from: "start",
          amount: 0.5,
        },
        scrollTrigger: {
          trigger: ".air_hd",
          markers: false,
        },
      }
    );

    // Para animation code
    gsap.fromTo(
      ".air_p1",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        ease: "linear",
        delay: 0.2,
        scrollTrigger: {
          trigger: ".air_p1",
          markers: false,
        },
      }
    );

    gsap.fromTo(
      ".air_img1",
      {
        x: 0,
      },
      {
        xPercent: 101,
        duration: 0.5,
        ease: "linear",
        delay: 0.2,
        scrollTrigger: {
          trigger: ".air_img1",
          markers: false,
        },
      }
    );
  });

  const { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/life_asc_page`
  );

  return (
    <>
      {/* <div
        className="air_freight_sec SO_ware mob_padding_LR tab_padding_LR"
        id="air_freight"
      >
        <div className="cstm_container">
          {data &&
            data.map(({ acf }) => (
              <div className="row">
                <div className="col-lg-5 col-md-12 col-12">
                  <div className="so_col1_network img_air">
                    {acf.life_list_sec.map((life_sub4) => (
                      <div className="img_dv">
                        <div
                          className="img_oly grd_blue_oly1 air_img1"
                          ref={air_img1}
                        ></div>
                        <img
                          className="so_col_img aero_img"
                          src={life_sub4.life_list_image}
                        ></img>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-12">
                  {acf.life_list_sec.map((life_sub4) => (
                    <div className="design_ttl air_main">
                      <p
                        className="so_col_desc network_para air_para air_p1"
                        ref={air_p1}
                      >
                        {life_sub4.life_list_hd}
                      </p>
                      <ul className="global_list air_p1" ref={air_p1}>
                        {life_sub4.life_list_repeater.map((life_sub5) => (
                          <li className="wht_txt">
                            {life_sub5.life_list_data}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div> */}

      <div
        className="air_freight_sec SO_ware mob_padding_LR tab_padding_LR"
        id="air_freight"
      >
        <div className="cstm_container">
          {data &&
            data.map(({ acf }) => (
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  {acf.life_list_sec.map((life_sub4) => (
                    <div className="">
                      <p
                        className="so_col_desc network_para air_para air_p1"
                        ref={air_p1}
                      >
                        {life_sub4.life_list_hd}
                      </p>
                      {/* <ul className="global_list air_p1" ref={air_p1}>
                        {life_sub4.life_list_repeater.map((life_sub5) => (
                          <li className="wht_txt">
                            {life_sub5.life_list_data}
                          </li>
                        ))}
                      </ul> */}
                    </div>
                  ))}
                    {/* {acf.life_list_sec.map((life_sub4) => ( */}
                        <div className="row">
                            {/* {life_sub4.life_list_repeater.map((life_sub5) => ( */}
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="result_box cstm_rslt_p2 career_box">
                                        <div class="">
                                            <img src={require('../../../assets/img/svg/solution_icon.svg').default}></img>
                                            <p class="rslt_ttl">Fast-track Your Career Growth</p>
                                            <p class="rslt_bx_p">We provide our employees with the skills, training, support, and the opportunities they need to succeed</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="result_box cstm_rslt_p2 career_box">
                                        <div class="">
                                            <img src={require('../../../assets/img/svg/solution_icon.svg').default}></img>
                                            <p class="rslt_ttl">Diversity & Inclusion</p>
                                            <p class="rslt_bx_p">We are committed to building an encouraging and inclusive work environment where people of all talents and backgrounds thrive and succeed.

</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="result_box cstm_rslt_p2 career_box">
                                        <div class="">
                                            <img src={require('../../../assets/img/svg/solution_icon.svg').default}></img>
                                            <p class="rslt_ttl">Opportunities for Impact</p>
                                            <p class="rslt_bx_p">We value the contribution made by the local communities to our work and the natural environments. We commit ourselves to serving them in their growth and sustainability.

</p>
                                        </div>
                                    </div>
                                </div>
                            {/* ))} */}
                        </div>
                    {/* ))} */}


                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
