import React from 'react'
import $ from "jquery";
import '../../../../assets/css/advantage_control.css';

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Advantage_control() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const ad_cnt_hd = useRef(null);
    const ad_cnt_lst1 = useRef(null);
    const ad_cnt_lst2 = useRef(null);
    const ad_cnt_lst3 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".ad_cnt_hd", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ad_cnt_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".ad_cnt_lst1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ad_cnt_lst1",
                markers: false
            }
        });
        gsap.fromTo(".ad_cnt_lst2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ad_cnt_lst2",
                markers: false
            }
        });
        gsap.fromTo(".ad_cnt_lst3", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ad_cnt_lst3",
                markers: false
            }
        });

    })

    useEffect(() => {

        $.fn.isInViewport = function () {

            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight() / 2;
            var viewportTop = $(window).scrollTop();
            var viewportHalf = viewportTop + $(window).height() / 1;
            return elementBottom > viewportTop && elementTop < viewportHalf;
        };


        $(window).on("scroll", function () {

            $(".img_scroll_dv").each(function () {
                var windowTop = $(window).scrollTop();
                var elementTop = $(this).offset().top;
                var leftPosition = windowTop - elementTop;
                $(this)
                    .find(".inside_dv_img")
                    .css({ right: leftPosition });
            });
        });

    });

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/control_tower_page/`
    );

    return (
        <>
            <div className='advantage_control_sec ' id='asc_control_tower'>
                {data && data.map(({ acf }) => (
                    <div className='row m_0'>

                        <div className='cstm_container mob_padding_LR tab_padding_LR'>
                            <div className='row'>

                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='info_ware_col1'>
                                        {acf.control_tower_advantage.map((adv) =>
                                            <p className='wht_txt adv_para ad_cnt_hd' ref={ad_cnt_hd}>{adv.control_advantage_hd} </p>
                                        )}
                                    </div>
                                </div>

                            </div>


                            <div className='row'>
                                <div className='col-xl-10 col-lg-10 col-md-12 col-12'>
                                    <div className='row'>

                                        {acf.control_advantage_list.map((adv1) =>
                                            <div className='col-lg-6 col-md-6 col-12'>
                                                <ul className='global_list wht_txt adv_listing'>
                                                    <li className='ad_cnt_lst1' ref={ad_cnt_lst1}>{adv1.cstm_advantage_list}</li>
                                                </ul>
                                            </div>
                                        )}

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                ))}
            </div>
        </>
    )
}
