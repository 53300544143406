import React from 'react'
import '../../assets/css/inside_page_spotlight.css'
import { useParams } from "react-router-dom";
import useFetch from "react-fetch-hook";

export default function Press_rel_inside_spotlight() {

  let { slug } = useParams();

  var { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/press_release/?slug=${slug}`
  );

  return (
    <>
      <div className='media_spotlight page_spotlight'>
        <div className='spotlight_container'>
          <div className='row'>
            <div className='col-12 pr_0'>
              <div className='spotlight_dv'>
                <div className='spot_contnt'>
                  {data && data.map(({ id, title, acf }) => (
                    <div className='spot_col1'>

                      <h1 className='spot_heading wht_txt media_hd'>{title.rendered}</h1>
                      <ul className='media_dtl_list'>
                        <li><img src={require('../../assets/img/svg/user_icon.svg').default}></img>Vilis Karl</li>
                        <li><img src={require('../../assets/img/svg/date_icon.svg').default}></img>23 March 2022</li>
                        <li><img src={require('../../assets/img/svg/time_icon.svg').default}></img>5 min read</li>
                      </ul>

                    </div>
                  ))}

                  <div className='spot_col2' style={{ visibility: 'hidden' }}>
                    <p className='wht_txt'>Our countrywide network of warehouses provides a wide range of services including dedicated and multi-user facilities.</p>
                    <a href='#' className='btn wht_btn'>
                      <span className='btn_txt'>Learn More</span>
                      <span className='arrow_btn wht_arrow'></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
