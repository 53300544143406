import React from 'react'
import '../../../assets/css/blog_sec.css';
import useFetch from "react-fetch-hook";
import $ from "jquery";
import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Blog_sec() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const blog_bx = useRef(null);

    useEffect(() => {


        if ($(window).width() >= 1590) {
            // Para animation code
            gsap.fromTo(".blog_bx", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 1,
                scrollTrigger: {
                    trigger: ".blog_bx",
                    markers: false
                }
            });
        }
        else if ($(window).width() <= 1536) {
            // Para animation code
            gsap.fromTo(".blog_bx", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".blog_bx",
                    markers: false
                }
            });
        }

    })



    const { isLoading, data, error } = useFetch(
        "https://apollosupplychain.com/cms/wp-json/wp/v2/blogs/"
    );

    return (
        <>
            <div className='blog_sec mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='blg_sec_col'>

                                {data && data.map(({ id, title, acf, slug }) => (
                                    <div className='blog_box blog_bx' ref={blog_bx}>
                                        <a href={"/media-center/blog/" + slug}>
                                            <div className="blog_box_img">
                                                <img src={acf.media_image}></img>
                                            </div>
                                            <div className="blog_box_content">
                                                <div>
                                                    <span className='media_tag'>{acf.type}</span>
                                                    <p className="blg_ttl">{title.rendered}</p>
                                                    <p className="media_date">{acf.date}</p>
                                                </div>
                                                <img className='blg_arrow' src={require('../../../assets/img/svg/media_arrow.svg').default}></img>
                                            </div>
                                        </a>
                                    </div>
                                ))}

                                {/* <div className='blog_box ' ref={blog_bx}>
                                    <a href="#">
                                        <div className="blog_box_img">
                                            <img src={require('../../../assets/img/news_img1.jpg')}></img>
                                        </div>
                                        <div className="blog_box_content">
                                            <div>
                                                <span className='media_tag'>Blogs</span>
                                                <p className="blg_ttl">Warehousing were evaluated for a smooth, stress-free import/export program</p>
                                                <p className="media_date">23 MAR 2022</p>
                                            </div>
                                            <img className='blg_arrow' src={require('../../../assets/img/svg/media_arrow.svg').default}></img>
                                        </div>
                                    </a>
                                </div> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
