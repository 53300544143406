import React from 'react'
import '../../../../assets/css/apllo_edge_tech.css';
import '../../../../assets/css/lifestyle.css';

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Lifestyle_other_sec() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const lfstyle_hd = useRef(null);
    const lfstyle_p1 = useRef(null);
    const lfstyle_img1 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".lfstyle_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".lfstyle_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".lfstyle_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".lfstyle_p1",
                markers: false
            }
        });

        gsap.fromTo(".lfstyle_img1", {
            xPercent: 0
        }, {
            xPercent: 102,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".lfstyle_img1",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/other_sector_page`
    );

    return (
        <>
            <div className='lifestyle_other_sec apollo_edge_tech  mob_padding_LR tab_padding_LR' id="lifestyle_retail" >
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-12 ap_ed_col1 ls_col1'>

                                {acf.lifestyle_section.map((other_sub3) =>
                                    <div className='ls_inner_dv'>

                                        {/* <div className='hd_dv'>
                                            <h2 className='heading wht_txt lfstyle_hd' ref={lfstyle_hd}>{other_sub3.lifestyle_hd}</h2>
                                        </div> */}
                                        <p className='apollo_tech_para lfstyle_p1' ref={lfstyle_p1}>{other_sub3.lifestyle_descripton}</p>
                                    </div>
                                )}

                            </div>
                            {/* <div className='col-lg-5 col-md-5 col-12 '>

                                {acf.lifestyle_section.map((other_sub3) =>
                                    <div className='img_dv'>
                                        <div className='img_oly lf_oly lfstyle_img1' ref={lfstyle_img1}></div>
                                        <img className='tech_img' src={other_sub3.lifestyle_image}></img>
                                    </div>
                                )}
                            </div> */}
                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}
