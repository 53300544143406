import React from 'react'
import '../../../assets/css/about_sec.css'
import $ from "jquery";
import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function About_sec() {

  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const abt_hd1 = useRef(null);
  const abt_p1 = useRef(null);
  const abt_img1 = useRef(null);
  useEffect(() => {

   


    // Para animation code
    gsap.fromTo(".abt_hd1", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.8,
      ease: "linear",
      delay: 0.2,
      scrollTrigger: {
        trigger: ".abt_hd1",
        markers: false
      }
    });

    gsap.fromTo(".abt_p1", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.8,
      ease: "linear",
      delay: 0.2,
      scrollTrigger: {
        trigger: ".abt_p1",
        markers: false
      }
    });

    gsap.fromTo(".abt_img1", {
      y: 250,
    }, {
      y: 0,
      duration: 0.2,
      scrollTrigger: {
        trigger: ".abt_img1",
        markers: false
      }
    });

    $.fn.isInViewport = function () {

      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight() / 2;
      var viewportTop = $(window).scrollTop();
      var viewportHalf = viewportTop + $(window).height() / 1;
      return elementBottom > viewportTop && elementTop < viewportHalf;

    };


    $(window).on("scroll", function () {

      $(".img_scroll_dv").each(function () {
        var windowTop = $(window).scrollTop();
        var elementTop = $(this).offset().top;
        var leftPosition = windowTop - elementTop;
        $(this)
          .find(".inside_dv_img")
          .css({ right: leftPosition });
      });

    });


  });

  const { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/about_page`
  );

  return (
    <>
      <div className='about_sec ' id='company-profile'>
        <div className='row m_0'>
          <div className='col-12 mob_padding_LR tab_padding_LR'>
            {data && data.map(({ acf }) => (
              <div className='about_sec_col1 abt_hd1' ref={abt_hd1}>
                {acf.about_sec_2.map((absec1) =>
                  <p className='about_hd_para' >{absec1.about_sec2_hd}</p>
                )}
              </div>
            ))}
          </div>

          <div className='col-12 p_0'>
            <div className='img_scroll_dv abt_scroll'>
              <div className='inside_dv_img '>

                <img className='scroll_img' src={require('../../../assets/img/about1.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about2.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about3.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about3.jpg')}></img>


                <img className='scroll_img' src={require('../../../assets/img/about1.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about2.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about3.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about4.jpg')}></img>

                <img className='scroll_img' src={require('../../../assets/img/about1.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about2.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about3.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about4.jpg')}></img>


                <img className='scroll_img' src={require('../../../assets/img/about1.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about2.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about3.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about4.jpg')}></img>

                <img className='scroll_img' src={require('../../../assets/img/about1.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about2.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about3.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about4.jpg')}></img>

                <img className='scroll_img' src={require('../../../assets/img/about1.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about2.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about3.jpg')}></img>
                <img className='scroll_img' src={require('../../../assets/img/about4.jpg')}></img>
              </div>
            </div>
          </div>

          {/* <div className='col-lg-4 col-md-4 col-4  pl_0'>
                <img className='about_img' src={require('../../../assets/img/about1.jpg')}></img>
            </div>
            <div className='col-lg-4 col-md-4 col-4 mdl_img'>
                <img className='about_img' src={require('../../../assets/img/about2.jpg')}></img>
            </div>
            <div className='col-lg-4 col-md-4 col-4  pr_0'>
                <img className='about_img' src={require('../../../assets/img/about3.jpg')}></img>
            </div> */}

          <div className='col-12 mob_padding_LR tab_padding_LR'>
            {data && data.map(({ acf }) => (
              <div className='about_sec_col2 abt_p1' ref={abt_p1}>

                {acf.about_sec_2.map((absec1) =>
                  <p className='abt_col2_para1'>{absec1.about_sec2_para1}</p>
                )}

                {acf.about_sec_2.map((absec1) =>
                  <p className='abt_col2_para2'>{absec1.about_sec2_para2}</p>
                )}

                {acf.about_sec_2.map((absec1) =>
                  <p className='abt_col2_para2'>{absec1.about_sec2_para3}</p>
                )}

              </div>
            ))}
          </div>

          <div className='col-12 mob_padding_LR tab_padding_LR'>
            <div className='video_div'>

              <video autoPlay controls muted loop id=""> //  I changed autoplay to autoPlay
                <source
                  src="https://apollosupplychain.com/cms/wp-content/uploads/2023/05/Apollo-Supply-Chain_Logo-Reveal.mp4"
                  type="video/mp4"
                />
                Your browser does not support HTML5 video.
              </video>
             
            </div>
          </div> 

        </div>

        {data && data.map(({ acf }) => (
          <div>
            {acf.about_sec_2.map((absec1) =>
              <img className='about_svg abt_img1' ref={abt_img1} src={absec1.about_sec2_img}></img>
            )}
          </div>
        ))}

      </div>
    </>
  )
}
