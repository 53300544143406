import React from 'react'
import '../../assets/css/inside_page_spotlight.css'

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Application_form_spotlight() {

  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const copg_hd = useRef(null);

  useEffect(() => {

    gsap.fromTo(".copg_hd", {
      y: 250,
      opacity: 0,
      skewY: 41,
    }, {
      delay: 1.8,
      duration: 0.8,
      y: 0,
      skewY: 0,
      opacity: 1,
      ease: "power4.out",
      stagger: {
        from: 'start',
        amount: 0.5,
      },
      scrollTrigger: {
        trigger: ".copg_hd",
        markers: false
      }
    });

  })

  const { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/section_heading`
  );

  return (
    <>
      <div className='sector_spotlight life_asc_spotlight page_spotlight'>
        <div className='spotlight_container'>
          <div className='row'>

          {data && data.map(({ acf }) => (
            <div className='col-12 pr_0'>
              <div className='spotlight_dv'>
                <div className='spot_contnt'>
                  <div className='spot_col1'>

                    <div className='hd_dv'>
                      <h1 className='spot_heading wht_txt media_hd copg_hd' ref={copg_hd}>{acf.current_openings_spotlight_heading}</h1>
                    </div>

                    {/* <p className='spot_sb_ttl'>Get In Touch With Us</p> */}
                  </div>
                  <div className='spot_col2' style={{ visibility: 'hidden' }}>
                    <p className='wht_txt'>Our countrywide network of warehouses provides a wide range of services including dedicated and multi-user facilities.</p>
                    <a href='#' className='btn wht_btn'>
                      <span className='btn_txt'>Learn More</span>
                      <span className='arrow_btn wht_arrow'></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}

          </div>
        </div>
      </div>
    </>
  )
}
