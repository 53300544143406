import React from 'react'
import '../../../../assets/css/result_cstm.css';
import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Result_cstm() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const ecom_rslt_hd = useRef(null);
    const ecom_rslt_p1 = useRef(null);
    const ecom_rslt_p2 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".ecom_rslt_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".ecom_rslt_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".ecom_rslt_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ecom_rslt_p1",
                markers: false
            }
        });
        gsap.fromTo(".ecom_rslt_p2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ecom_rslt_p2",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/ecom_sector_page`
    );


    return (
        <>
            <div className='ecom_rsult ecommerce_result result_cstm result_automo mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-12'>

                                {acf.ecom_result_hd_sec.map((ecom_rslt) =>
                                    <div className='hd_dv' style={{ margin: 'auto' }}>
                                        <h2 className='heading txt_cntr wht_txt ecom_rslt_hd' ref={ecom_rslt_hd}>{ecom_rslt.ecom_result_hd}</h2>
                                    </div>
                                )}

                                {acf.ecom_result_hd_sec.map((ecom_rslt) =>
                                    <p className='reslt_para txt_cntr ecom_rslt_p1' ref={ecom_rslt_p1}>{ecom_rslt.ecom_result_sub_hd}</p>
                                )}

                            </div>

                            {/* {acf.ecom_result_box_sec.map((ecom_rslt1) =>
                                <div className='col-lg-4 col-md-4 col-12'>
                                    <div className='result_box ecom_rslt_p2' ref={ecom_rslt_p2}>
                                        <div className='rslt_inner'>
                                            <img src={ecom_rslt1.ecom_result_icon}></img>
                                            <div dangerouslySetInnerHTML={{ __html: ecom_rslt1.ecom_result_description }}></div>
                                        </div>
                                    </div>
                                </div>
                            )} */}

                            <div className='col-lg-4 col-md-4 col-12'>
                                <div className='result_box ecom_rslt_p2' ref={ecom_rslt_p2}>
                                    <div className='rslt_inner'>
                                        <img src='https://apollosupplychain.com/cms/wp-content/uploads/2022/11/10_days.svg'></img>
                                        <p className='rslt_ttl'>Fast Execution</p>
                                        <p class="rslt_bx_p">The complete integrated solution was implemented in a short span of 10 days with implementation of 3 new warehouses in just a week’s time.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-4 col-12'>
                                <div className='result_box ecom_rslt_p2' ref={ecom_rslt_p2}>
                                    <div className='rslt_inner'>
                                        <img src='https://apollosupplychain.com/cms/wp-content/uploads/2022/11/fill_rate.svg'></img>
                                        <p className='rslt_ttl'>Near-Perfect Fill Rates</p>
                                        <p class="rslt_bx_p">The solution resulted in a category-leading 99-percent fill rate.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-4 col-12'>
                                <div className='result_box ecom_rslt_p2' ref={ecom_rslt_p2}>
                                    <div className='rslt_inner'>
                                        <img src='https://apollosupplychain.com/cms/wp-content/uploads/2022/11/t1.svg'></img>
                                        <p className='rslt_ttl'>Greater Customer Delight</p>
                                        <p class="rslt_bx_p">Deliveries to T+1 were improved resulting in improved responsiveness and customer service.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
