import React from 'react'
import '../../assets/css/header.css';
import $ from "jquery";
import { useEffect } from 'react';


export default function Header() {


    useEffect(() => {

        // Megamenu click Event 
        // document.addEventListener("DOMContentLoaded", function () {
        //     document.querySelectorAll('.dropdown-menu').forEach(function (element) {
        //         element.addEventListener('click', function (e) {
        //             e.stopPropagation();
        //         });
        //     })
        // });





        $('.about_li a').on("click", function (e) {
            setTimeout(function () {  
                $('.megamenu').css('z-index', '0');
                $('.about_li .megamenu').css('z-index', '1');
                $('.megamenu').css('transition-delay', '0s');
                $('.megamenu').css('opacity', '0');
                $('.megamenu').css('height', '0');
              
                $('.about_megamenu').css('height', '445px');
                $('.about_megamenu').css('opacity', '1');
                // $('.mega_colum').css('display','block');
                $('.about_li .megamenu').addClass('mega_animation');
            }, 200);

            
            $('.col-megamenu').removeClass('submenu_animation');

            setTimeout(function () {
                $('.about_li .col-megamenu').addClass('submenu_animation');
            }, 350);
            e.stopPropagation();
        });

        $('.about_li .hash_tag_link').on("click", function (e) {
            $('.megamenu').css('transition-delay', '0.6s');
            e.stopPropagation();
        });

        $('.services_li a').on("click", function (e) {

            setTimeout(function () {  
                $('.megamenu').css('z-index', '0');
                $('.services_li .megamenu').css('z-index', '1');
                $('.megamenu').css('transition-delay', '0s');
                $('.megamenu').css('opacity', '0');
                $('.megamenu').css('height', '0');
                $('.service_megamenu').css('height', '400px');
                $('.service_megamenu').css('opacity', '1');
                // $('.mega_colum').css('display','block');
                $('.services_li .megamenu').addClass('mega_animation');
            }, 200);

            
            $('.col-megamenu').removeClass('submenu_animation');

            setTimeout(function () {    
                $('.services_li .col-megamenu').addClass('submenu_animation');
            }, 350);

            e.stopPropagation();
        });

        $('.services_li .hash_tag_link').on("click", function (e) {
            $('.megamenu').css('transition-delay', '0.6s');
            e.stopPropagation();
        });

        $('.sector_li a').on("click", function (e) {

            setTimeout(function () {
                $('.megamenu').css('z-index', '0');
                $('.sector_li .megamenu').css('z-index', '1');
                $('.megamenu').css('transition-delay', '0s');
                $('.megamenu').css('opacity', '0');
                $('.megamenu').css('height', '0');
                $('.sector_megamenu').css('height', '400px');
                $('.sector_megamenu').css('opacity', '1');
                $('.sector_li .megamenu').addClass('mega_animation');
            }, 200);    
            $('.col-megamenu').removeClass('submenu_animation');

            setTimeout(function () {
                $('.sector_li .col-megamenu').addClass('submenu_animation');
            }, 350);
            e.stopPropagation();
        });

 
        $('.sector_li .hash_tag_link').on("click", function (e) {
            $('.megamenu').css('transition-delay', '0.6s');
            e.stopPropagation();
        });

        $('.asc_li a').on("click", function (e) {

            setTimeout(function () {
                $('.megamenu').css('z-index', '0');
                $('.asc_li .megamenu').css('z-index', '1');
                $('.megamenu').css('transition-delay', '0s');
                $('.megamenu').css('opacity', '0');
                $('.megamenu').css('height', '0');
                $('.ascedge_megamenu').css('height', '400px');
                $('.ascedge_megamenu').css('opacity', '1');
                $('.asc_li .megamenu').addClass('mega_animation');
            }, 200); 

            $('.col-megamenu').removeClass('submenu_animation');

            setTimeout(function () {
                $('.asc_li .col-megamenu').addClass('submenu_animation');
            }, 350);

            e.stopPropagation();
        });

        $('.asc_li .hash_tag_link').on("click", function (e) {
            $('.megamenu').css('transition-delay', '0.6s');
            e.stopPropagation();
        });

        $('.media_li a').on("click", function (e) {

            setTimeout(function () {
                $('.megamenu').css('z-index', '0');
                $('.media_li .megamenu').css('z-index', '1');
                $('.megamenu').css('transition-delay', '0s');
                $('.megamenu').css('opacity', '0');
                $('.megamenu').css('height', '0');
                $('.media_megamenu').css('height', '400px');
                $('.media_megamenu').css('opacity', '1');
                $('.media_li .megamenu').addClass('mega_animation');
            }, 200); 

            $('.col-megamenu').removeClass('submenu_animation');

            setTimeout(function () {
                $('.media_li .col-megamenu').addClass('submenu_animation');
            }, 350); 
            e.stopPropagation();
        });

        $('.career_li a').on("click", function (e) {

            setTimeout(function () {
            $('.megamenu').css('z-index', '0');
            $('.career_li .megamenu').css('z-index', '1');
            $('.megamenu').css('transition-delay', '0s');
            $('.megamenu').css('opacity', '0');
            $('.megamenu').css('height', '0');
            $('.career_megamenu').css('height', '400px');
            $('.career_megamenu').css('opacity', '1');
            $('.career_li .megamenu').addClass('mega_animation');
            }, 200); 

            $('.col-megamenu').removeClass('submenu_animation');

            setTimeout(function () {
                $('.career_li .col-megamenu').addClass('submenu_animation');
            }, 350); 

            e.stopPropagation();
        });

        // $('.profile_li a').on("click", function (e) {
        //     $('.megamenu').css('z-index', '0');
        //     $('.profile_li .megamenu').css('z-index', '1');
        //     $('.megamenu').css('transition-delay', '0s');
        //     $('.megamenu').css('opacity', '0');
        //     $('.megamenu').css('height', '0');
        //     $('.profile_megamenu').css('height', '527px');
        //     $('.profile_megamenu').css('opacity', '1');

        //     $('.profile_li .megamenu').addClass('mega_animation');
        //     $('.col-megamenu').removeClass('submenu_animation');
        //     $('.profile_li .col-megamenu').addClass('submenu_animation');
        //     e.stopPropagation();
        // });

        $('.mc_2  ul li a').on("click", function () {
            $('.megamenu').css('height', '0px');
            $('.megamenu ').removeClass('mega_animation');
            $('.col-megamenu').removeClass('submenu_animation');
        });

        $('body').on("click", function () {


            setTimeout(function () {
                $('.megamenu ').removeClass('mega_animation');
                $('.megamenu').css('height', '0px');
                $('.megamenu').css('backdrop-filter', 'blur(0px)');
                // $('.mega_colum').css('display','none');
            }, 600);

            $('.col-megamenu').removeClass('submenu_animation');

        });

        $('.antag').on("click", function () {
            $('.antag').css('color', '#fff');
            $(this).css('color', '##FF7900');
            $('.mobile_nav').removeClass('open_mob_nav');
        });



        // Header Background Change On scroll    
        $(window).on("scroll", function () {
            if ($(window).scrollTop() > 200) {
                $(".header").addClass("hdr_bg");
            } else {
                $(".header").removeClass("hdr_bg");
            }
        });

        $('.mob_menu_btn').on("click", function () {
            $('.mobile_nav').addClass('open_mob_nav');
        });

        $('.close_mob_nav').on("click", function () {
            $('.mobile_nav').removeClass('open_mob_nav');
        });




        $("#mob_accordian a").on('click', function () {
            var link = $(this);
            var closest_ul = link.closest("ul");
            var parallel_active_links = closest_ul.find(".active")
            var closest_li = link.closest("li");
            var link_status = closest_li.hasClass("active");
            var count = 0;

            closest_ul.find("ul").slideUp(function () {
                if (++count == closest_ul.find("ul").length)
                    parallel_active_links.removeClass("active");
            });

            if (!link_status) {
                closest_li.children("ul").slideDown();
                closest_li.addClass("active");
            }

        });

        // let $root = $('html, body');
        // $('a[href^="#"]').on('click', function () {
        //     let href = $.attr(this, 'href');
        //     $root.animate({
        //         scrollTop: $(href).offset().top
        //     }, 500, function () {
        //         window.location.hash = href;
        //     });
        //     return false;
        // });


        // var prevScrollpos = window.pageYOffset;
        // window.onscroll = function () {
        //     var currentScrollPos = window.pageYOffset;

        //     if (prevScrollpos > currentScrollPos) {
        //         document.getElementById("desk_header").style.top = "0";
        //     } else if (prevScrollpos < currentScrollPos) {
        //         document.getElementById("desk_header").style.top = "-100px";
        //     }
        //     prevScrollpos = currentScrollPos;
        // }

        var lastScrollTop = 0, delta = 15;
        $(window).on('scroll', function (event) {
            var st = $(this).scrollTop();

            if (Math.abs(lastScrollTop - st) <= delta)
                return;
            if ((st > lastScrollTop) && (lastScrollTop > 0)) {
                // downscroll code
                $("#desk_header").css("top", "-100px");

            } else {
                // upscroll code
                $("#desk_header").css("top", "0px");
            }
            lastScrollTop = st;
        });



    })


    return (
        <>

            {/*---------------------------------------------------------------------------------------------------------------- Desktop header  */}
            <div className='header' id='desk_header'>
                <div className='hdr_container'>
                    <nav className="navbar navbar-expand-lg navbar-dark">
                        <div className="container-fluid">
                            <a className="navbar-brand asc_div" href="/">
                                <img src={require('../../assets/img/svg/apollo_icon.svg').default}></img>
                            </a>
                            {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                                <span className="navbar-toggler-icon"></span>
                            </button> */}
                            <div className="collapse navbar-collapse desktop_nav" id="main_nav">

                                <ul className="navbar-nav ms-auto desktop_nav">

                                    {/* About Menu */}
                                    <li className="nav-item dropdown has-megamenu about_li">
                                        <a className="nav-link dropdown-toggle desk_nav_link about_lk" data-toggle="dropdown" aria-expanded="false"> About us </a>
                                        <div className="dropdown-menu megamenu about_megamenu">
                                            <div className="row g-3">

                                                <div className=" col-lg-4 col-6 mega_colum mc_1 mega_col_border">
                                                    <div className="col-megamenu animi_bx1">
                                                        <h6 className="mm_ttl">About Us</h6>
                                                        <p>As a single-source integrated supply chain specialist, we provide innovative, tech-driven solutions for your logistics requirements so that you can focus on your core business and profitability.</p>
                                                    </div>
                                                </div>

                                                <div className=" col-lg-4 col-6 mega_colum mc_2 mega_col_border">
                                                    <div className="col-megamenu animi_bx2">
                                                        <ul className="list-unstyled about_sub_links">
                                                            <li><a href="/about-us" className='abt_lk'>Company Profile</a></li>
                                                            <li><a href="/about-us#purpose" className='hash_tag_link purpse_vlu_lk'>Purpose and Values</a></li>
                                                            {/* <li><a href="/about-us#growth-journey" className='hash_tag_link grwth_jrny_lk'>Growth Journey</a></li> */}
                                                            <li><a href="/about-us/leadership" className='hash_tag_link leader_lk'>Our Leadership Team</a></li>
                                                            {/* <li><a href='/about-us#apollo-edge' className='hash_tag_link apl_edge_lk'>The Apollo Edge</a></li> */}
                                                            {/* <li><a href="/about-us#milestones-&-achievements" className='hash_tag_link milston_lk'>Milestones and Achievements</a></li> */}
                                                            <li><a href="/about-us#esg" className='hash_tag_link whyus_lk'>ESG</a></li>
                                                        </ul>

                                                    </div>
                                                </div>

                                                <div className=" col-lg-4 col-6 mc_3 mega_colum">
                                                    <div className="col-megamenu animi_bx3">
                                                        <img className='mega_img' src={require('../../assets/img/menu_img/menu_about_us.jpg')}></img>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </li>

                                    {/* Service Menu */}
                                    <li className="nav-item dropdown has-megamenu services_li">
                                        <a className="nav-link dropdown-toggle desk_nav_link service_lk" data-toggle="dropdown" aria-expanded="false"> Services </a>
                                        <div className="dropdown-menu megamenu service_megamenu">
                                            <div className="row g-3">

                                                <div className=" col-lg-4 col-6 mega_colum mc_1 mega_col_border">
                                                    <div className="col-megamenu animi_bx1">
                                                        <h6 className="mm_ttl">Services</h6>
                                                        <p>Our customer-focused, tech-driven, and specialized services ensure that our customers and partners stay ahead of the competition.</p>
                                                    </div>
                                                </div>

                                                <div className=" col-lg-4 col-6 mega_colum mc_2 mega_col_border">
                                                    <div className="col-megamenu animi_bx2">
                                                        <ul className="list-unstyled">
                                                            <li><a href="/warehousing" className='warehouse'>Warehousing</a></li>
                                                            <li><a href="/surface-transport" className='surface_Trans'>Surface Transport</a></li>
                                                            <li><a href="/freight-forwarding" className='freight_Forwd'>Freight Forwarding</a></li>
                                                            <li><a href="/CFS" className='CFS_ICD'>CFS</a></li>
                                                            <li><a href="/supply-chain-consulting" className='supply_chain'>Supply Chain Consulting</a></li>
                                                            <li><a href="/control-tower-&-analytics-center" className='control_tower'>Control Tower & Analytics Center</a></li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className=" col-lg-4 col-6 mc_3 mega_colum">
                                                    <div className="col-megamenu animi_bx3">
                                                        <img className='mega_img' src={require('../../assets/img/menu_img/menu_services.jpg')}></img>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </li>

                                    {/* Sector Menu */}
                                    <li className="nav-item dropdown has-megamenu sector_li">
                                        <a className="nav-link dropdown-toggle desk_nav_link sector_lk" data-toggle="dropdown" aria-expanded="false"> Sector </a>
                                        <div className="dropdown-menu megamenu sector_megamenu">
                                            <div className="row g-3">


                                                <div className=" col-lg-4 col-6 mega_colum mc_1 mega_col_border">
                                                    <div className="col-megamenu animi_bx1">
                                                        <h6 className="mm_ttl">Sector</h6>
                                                        <p>Our customers consistently rank us as India’s most reliable integrated logistics service providers because of our ability to deploy customised solutions in the way that benefits them and their customers the most.</p>
                                                    </div>
                                                </div>

                                                <div className=" col-lg-4 col-6 mega_colum mc_2 mega_col_border">
                                                    <div className="col-megamenu animi_bx2">
                                                        <ul className="list-unstyled">
                                                            <li><a href="/sector/consumer-durables-high-tech-industries" className='consumer_lk'>Consumer Durables, High-Tech Industries</a></li>
                                                            <li><a href="/sector/automotive-&-industrial-sector" className='automotive_lk'>Automotive & Industrial Sector</a></li>
                                                            <li><a href="/sector/e-commerce-&-FMCG" className='e_com_lk'>E-Commerce & FMCG</a></li>
                                                            <li><a href="/sector/pharmaceuticals" className='hash_tag_link pharma_lk'>Pharmaceuticals</a></li>
                                                            <li><a href="/sector/lifestyle-&-retail" className='hash_tag_link lifestyle_lk'>Lifestyle & Retail</a></li>

                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className=" col-lg-4 col-6 mc_3 mega_colum">
                                                    <div className="col-megamenu animi_bx3">
                                                        <img className='mega_img' src={require('../../assets/img/menu_img/menu_sectors.jpg')}></img>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </li>

                                    {/* The ASC Edge Menu */}
                                    <li className="nav-item"><a className="nav-link desk_nav_link edge_lk" href="/#apollo-edge"> The Edge </a></li>
                                    {/* <li className="nav-item dropdown has-megamenu asc_li">
                                        <a className="nav-link dropdown-toggle desk_nav_link edge_lk" data-toggle="dropdown" aria-expanded="false">The Edge </a>
                                        <div className="dropdown-menu megamenu ascedge_megamenu">
                                            <div className="row g-3">


                                                <div className=" col-lg-4 col-6 mega_colum mc_1 mega_col_border">
                                                    <div className="col-megamenu animi_bx1">
                                                        <h6 className="mm_ttl">The Edge</h6>
                                                        <p>As an integrated logistics service provider, Apollo Supply Chain provides both light and asset-heavy tech-powered solutions that serve you best in a fast-changing market.</p>
                                                    </div>
                                                </div>

                                                <div className=" col-lg-4 col-6 mega_colum mc_2 mega_col_border">
                                                    <div className="col-megamenu animi_bx2">
                                                        <ul className="list-unstyled">
                                                            <li className='edge_hd_link'><a href="/apollo-edge" className='integ_lk'>Integrated Logistics Services</a></li>
                                                            <ul className="edge_sublink">
                                                                <li>
                                                                    <a className='hash_tag_link ' href="/apollo-edge#advantages"> <span>-</span> Advantages</a>
                                                                </li>
                                                            </ul>
                                                            <li className='edge_hd_link'><a className='hash_tag_link ' href="/apollo-edge#technologies_for_you">Technologies For You</a></li>
                                                            <ul className="edge_sublink">
                                                                <li>
                                                                    <a className='hash_tag_link ' href="/apollo-edge#features"> <span>-</span> Features</a>
                                                                </li>
                                                            </ul>
                                                            <li className='edge_hd_link'><a className='hash_tag_link ' href="/apollo-edge#at_a_glance">At A Glance</a></li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className=" col-lg-4 col-6 mc_3 mega_colum">
                                                    <div className="col-megamenu animi_bx3">
                                                        <img className='mega_img' src={require('../../assets/img/menu_img/menu_asc_edge.jpg')}></img>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </li> */}

                                    {/* Media Menu */}
                                    <li className="nav-item dropdown has-megamenu media_li">
                                        <a className="nav-link dropdown-toggle desk_nav_link media_lk" data-toggle="dropdown" aria-expanded="false"> Media Center </a>
                                        <div className="dropdown-menu megamenu media_megamenu">
                                            <div className="row g-3">

                                                <div className=" col-lg-4 col-6 mega_colum mc_1 mega_col_border">
                                                    <div className="col-megamenu animi_bx1">
                                                        <h6 className="mm_ttl">Media Center</h6>
                                                        <p>Take a look behind the scenes at Apollo Supply Chain and the latest industry happenings in the world of supply chain.</p>
                                                    </div>
                                                </div>

                                                <div className=" col-lg-4 col-6 mega_colum mc_2 mega_col_border">
                                                    <div className="col-megamenu animi_bx2">
                                                        <ul className="list-unstyled">
                                                            <li><a href="/blog" className='blog_lk'>Blog</a></li>
                                                            <li><a href="/news_and_events" className='news_event_lk'>News and Events</a></li>
                                                            {/* <li><a href="/press-release">Press Release</a></li> */}
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className=" col-lg-4 col-6 mc_3 mega_colum">
                                                    <div className="col-megamenu animi_bx3">
                                                        <img className='mega_img' src={require('../../assets/img/menu_img/menu_media_centre.jpg')}></img>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </li>

                                    {/* Career Menu */}
                                    <li className="nav-item dropdown has-megamenu career_li">
                                        <a className="nav-link dropdown-toggle desk_nav_link career_lk" data-toggle="dropdown" aria-expanded="false"> Careers </a>
                                        <div className="dropdown-menu megamenu career_megamenu">
                                            <div className="row g-3">

                                                <div className=" col-lg-4 col-6 mega_colum mc_1 mega_col_border">
                                                    <div className="col-megamenu animi_bx1">
                                                        <h6 className="mm_ttl">Careers</h6>
                                                        <p>Team spirit and innovation are at the core of what we do. </p>
                                                    </div>
                                                </div>

                                                <div className=" col-lg-4 col-6 mega_colum mc_2 mega_col_border">
                                                    <div className="col-megamenu animi_bx2">
                                                        <ul className="list-unstyled">
                                                            <li><a href="/careers/life-at-ASC" className='life_asc_lk'>Life at Apollo Supply Chain</a></li>
                                                            <li><a href="/careers/current-openings" className='curnt_opn_lk'>Current Openings</a></li>
                                                            {/* <li><a href="/careers/application-form">Application form</a></li> */}
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className=" col-lg-4 col-6 mc_3 mega_colum">
                                                    <div className="col-megamenu animi_bx3">
                                                        <img className='mega_img' src={require('../../assets/img/menu_img/menu_careers.jpg')}></img>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </li>

                                    {/* Contact Menu */}
                                    <li className="nav-item"><a className="nav-link desk_nav_link contact_lk" href="/contact"> Contact </a></li>

                                    {/* Profile Menu */}
                                    <li className="nav-item dropdown has-megamenu profile_li">

                                        <a className='profile_box dropdown-toggle' data-toggle="dropdown">
                                            <img src={require('../../assets/img/svg/profile_icon.svg').default}></img>
                                        </a>
                                        <div className="dropdown-menu megamenu profile_megamenu">
                                            <div className="row g-3">

                                                <div className=" col-lg-4 col-6 mega_colum mc_1 mega_col_border">
                                                    <div className="col-megamenu animi_bx1">
                                                        <h6 className="mm_ttl">Customer Login</h6>
                                                        <div className='login_box'>

                                                            <a className='tb_bx' href='http://tms.apollologisolutions.com/throttle/' target='_blank'>
                                                                <div className=''>
                                                                    <div className='tb_prt1'>
                                                                        <img className='sol_icon' src={require('../../assets/img/svg/TMS_Icon.svg').default}></img>
                                                                    </div>
                                                                    <div className='tb_prt2'>
                                                                        <p className="sol_name">3PL TMS</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a className='tb_bx' href='#' target='_blank'>
                                                                <div className=''>
                                                                    <div className='tb_prt1'>
                                                                        <img className='sol_icon' src={require('../../assets/img/svg/PTL_Icon.svg').default}></img>
                                                                    </div>
                                                                    <div className='tb_prt2'>
                                                                        <p className="sol_name">PTL</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a className='tb_bx' href='#' target='_blank'>
                                                                <div className=''>
                                                                    <div className='tb_prt1'>
                                                                        <img className='sol_icon' src={require('../../assets/img/svg/control_icon.svg').default}></img>
                                                                    </div>
                                                                    <div className='tb_prt2'>
                                                                        <p className="sol_name">Control Tower</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a className='tb_bx' href='#' target='_blank'>
                                                                <div className=''>
                                                                    <div className='tb_prt1'>
                                                                        <img className='sol_icon' src={require('../../assets/img/svg/BIT_Icon.svg').default}></img>
                                                                    </div>
                                                                    <div className='tb_prt2'>
                                                                        <p className="sol_name">Business Intelligence Tool</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className=" col-lg-4 col-6 mega_colum mc_2 mega_col_border">
                                                    <div className="col-megamenu animi_bx2">
                                                        <h6 className="mm_ttl">Vendor Login</h6>
                                                        <div className='login_box'>
                                                            <a className='tb_bx' href='http://tms.apollologisolutions.com/throttle/' target='_blank'>
                                                                <div >
                                                                    <div className='tb_prt1'>
                                                                        <img className='sol_icon' src={require('../../assets/img/svg/TMS_Icon.svg').default}></img>
                                                                    </div>
                                                                    <div className='tb_prt2'>
                                                                        <p className="sol_name">3PL TMS</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a className='tb_bx' href='http://tms.apollologisolutions.com/ReverseAuction/#/login' target='_blank'>
                                                                <div className=''>
                                                                    <div className='tb_prt1'>
                                                                        <img className='sol_icon' src={require('../../assets/img/svg/box.svg').default}></img>
                                                                    </div>
                                                                    <div className='tb_prt2'>
                                                                        <p className="sol_name">Reverse Auction</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a className='tb_bx' href='https://ems.zohocreatorportal.in/' target='_blank'>
                                                                <div className=''>
                                                                    <div className='tb_prt1'>
                                                                        <img className='sol_icon' src={require('../../assets/img/svg/EMS_Icon.svg').default}></img>
                                                                    </div>
                                                                    <div className='tb_prt2'>
                                                                        <p className="sol_name">Expenses Management System</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a className='tb_bx' href='#' target='_blank'>
                                                                <div className=''>
                                                                    <div className='tb_prt1'>
                                                                        <img className='sol_icon' src={require('../../assets/img/svg/PTL_Icon.svg').default}></img>
                                                                    </div>
                                                                    <div className='tb_prt2'>
                                                                        <p className="sol_name">PTL</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className=" col-lg-4 col-6 mc_3 mega_colum">
                                                    <div className="col-megamenu animi_bx3">
                                                        <img className='mega_img' src={require('../../assets/img/profile_submenu.jpg')}></img>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </li>

                                </ul>


                            </div>
                            <div className='hdr_sub_db'>

                                <a className='search_box'>
                                    <img src={require('../../assets/img/svg/search_icon.svg').default}></img>
                                </a>

                                {/* mob nav icon */}
                                <div id="mobile_menu">
                                    <img className='mob_menu_btn' src={require('../../assets/img/svg/mob_menu_icon.svg').default}></img>
                                </div>
                            </div>

                            {/* ============ mobile Navbar*/}
                            <div className='mobile_nav'>

                                <img className='close_mob_nav' src={require('../../assets/img/svg/close_icon_wht.svg').default}></img>


                                <div id="mob_accordian">
                                    <ul>
                                        <li className="active">

                                            <ul>
                                                <li className="">
                                                    <a className='lv_1 about_lk' href="#">About Us</a>
                                                    <ul>
                                                        <li><a className='lv_2 abt_lk' href="/about-us">Company Profile</a></li>
                                                        <li><a className='lv_2 antag' href="/about-us#purpose">Purpose and Values</a></li>
                                                        {/* <li><a className='lv_2 antag' href="/about-us#growth-journey">Growth Journey</a></li> */}
                                                        <li><a className='lv_2 antag' href="/about-us/leadership">Our Leadership Team</a></li>
                                                        {/* <li><a className='lv_2 antag' href='/about-us#apollo-edge'>The Apollo Edge</a></li> */}
                                                        {/* <li><a className='lv_2 antag' href="/about-us#milestones-&-achievements">Milestones and Achievements</a></li> */}
                                                        <li><a className='lv_2 antag' href="/about-us#esg">ESG</a></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a className='lv_1 service_lk' href="#">Services</a>
                                                    <ul>

                                                        {/*---- Warehousing */}
                                                        <li>
                                                            <a className='lv_2 warehouse' href="/warehousing">Warehousing</a>
                                                            {/* <ul className='lv_3ul'>
                                                                <li><a className='lv_3' href="#">Dedicated Warehouses</a></li>
                                                                <li><a className='lv_3' href="#">Multi-User Facilities</a></li>
                                                            </ul> */}
                                                        </li>

                                                        {/*---- In-Plant Management */}
                                                        <li>
                                                            <a className='lv_2 surface_Trans' href="/surface-transport">Surface Transport</a>
                                                            {/* <ul className='lv_3ul'>
                                                                <li><a className='lv_3' href="#">Demo</a></li>
                                                            </ul> */}
                                                        </li>

                                                        {/*---- Land Transport */}
                                                        <li>
                                                            <a className='lv_2 freight_Forwd' href="/freight-forwarding">Freight Forwarding</a>
                                                            {/* <ul className='lv_3ul'>
                                                                <li><a className='lv_3' href="/parks/chakan-ii">Full Truck Load</a></li>
                                                                <li><a className='lv_3' href="#">Part Truck Load</a></li>
                                                                <li><a className='lv_3' href="#">Last Mile Deliverys</a></li>
                                                                <li><a className='lv_3' href="#">Longhaul Tansportation (2 Wheeler Movement)</a></li>
                                                                <li><a className='lv_3' href="#">Rail Transport (Liquid transportation)</a></li>
                                                            </ul> */}
                                                        </li>

                                                        {/*---- Ocean Freight */}
                                                        <li>
                                                            <a className='lv_2 CFS_ICD' href="/CFS">CFS</a>
                                                            {/* <ul className='lv_3ul'>
                                                                <li><a className='lv_3' href="#">Nagpur</a></li>
                                                            </ul> */}
                                                        </li>


                                                        {/*---- Air Freight */}
                                                        <li>
                                                            <a className='lv_2 supply_chain' href="/supply-chain-consulting">Supply Chain Consulting</a>
                                                            {/* <ul className='lv_3ul'>
                                                                <li><a className='lv_3' href="/parks/verna">Verna</a></li>
                                                            </ul> */}
                                                        </li>



                                                        {/*---- CFS & ICDs */}
                                                        <li>
                                                            <a className='lv_2 control_tower' href="/control-tower-&-analytics-center">Control Tower & Analytics Center</a>
                                                            {/* <ul className='lv_3ul'>
                                                                <li><a className='lv_3' href="#">Terminal Locations</a></li>
                                                                <li><a className='lv_3' href="#">Dry Port Locations</a></li>
                                                                <li><a className='lv_3' href="#">Customs Brokerage</a></li>
                                                            </ul> */}
                                                        </li>

                                                    </ul>
                                                </li>
                                                <li>
                                                    <a className='lv_1 sector_lk' href="#">Sector</a>
                                                    <ul>
                                                        <li><a className='lv_2 consumer_lk' href="/sector/consumer-durables-high-tech-industries">Consumer Durables, High-Tech Industries</a></li>
                                                        <li><a className='lv_2 automotive_lk' href="/sector/automotive-&-industrial-sector">Automotive & Industrial Sector</a></li>
                                                        <li><a className='lv_2 e_com_lk' href="/sector/e-commerce-&-FMCG">E-Commerce & FMCG</a></li>
                                                        <li><a className='lv_2 antag' href="/sector/pharmaceuticals">Pharmaceuticals</a></li>
                                                        <li><a className='lv_2 antag' href="/sector/lifestyle-&-retail">Lifestyle & Retail</a></li>
                                                    </ul>
                                                </li>
                                                <li><a className='lv_1 edge_lk' href="/#apollo-edge">The Edge</a></li>
                                                {/* <li>
                                                    <a className='lv_1 edge_lk' href="#">The Edge</a>
                                                    <ul>
                                                      
                                                        <li><a className='lv_2 antag integ_lk' href="/apollo-edge">Integrated Logistics Services</a></li>
                                                        <li className='edge_advntg desktop_view antag'><a className='lv_2' href="/apollo-edge#advantages">- Advantages</a></li>
                                                        <li className='edge_advntg mobile_view antag'><a className='lv_2' href="/apollo-edge#advantages_mob">- Advantages</a></li>
                                                        <li><a className='lv_2 antag' href="/apollo-edge#technologies_for_you">Technologies For You</a></li>
                                                        <li className='edge_advntg antag'><a className='lv_2' href="/apollo-edge#features">- Features</a></li>
                                                        <li><a className='lv_2 antag' href="/apollo-edge#at_a_glance">At A Glance</a></li>
                                                    </ul>
                                                </li> */}
                                                <li>
                                                    <a className='lv_1 media_lk' href="#">Media Center</a>
                                                    <ul>
                                                        <li><a className='lv_2 blog_lk' href="/blog">Blog</a></li>
                                                        <li><a className='lv_2 news_event_lk' href="/news_and_events">News and Events</a></li>
                                                        {/* <li><a className='lv_2' href="#">Press Release</a></li> */}
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a className='lv_1 career_lk' href="#">Career</a>
                                                    <ul>
                                                        <li><a className='lv_2 life_asc_lk' href="/careers/life-at-ASC">Life at Apollo Supply Chain</a></li>
                                                        <li><a className='lv_2 curnt_opn_lk' href="/careers/current-openings">Current Openings</a></li>
                                                        {/* <li><a className='lv_2' href="/careers/application-form">Application Form</a></li> */}
                                                    </ul>

                                                </li>
                                                {/* <li>
                                                    <a className='lv_1' href="#">Profile</a>
                                                    <ul>
                                                        <li>
                                                            <a className='lv_2' href="#">Customer Login</a>
                                                            <ul className='lv_3ul'>
                                                                <li><a className='lv_3' href="http://tms.apollologisolutions.com/throttle/" target='_blank'>3PL TMS</a></li>
                                                                <li><a className='lv_3' href="#" target='_blank'>PTL</a></li>
                                                                <li><a className='lv_3' href="#" target='_blank'>Control Tower</a></li>
                                                                <li><a className='lv_3' href="#" target='_blank'>Business Intelligence Tool</a></li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <a className='lv_2' href="#">Vendor Login</a>
                                                            <ul className='lv_3ul'>
                                                                <li><a className='lv_3' href="http://tms.apollologisolutions.com/throttle/" target='_blank'>3PL TMS</a></li>
                                                                <li><a className='lv_3' href="http://tms.apollologisolutions.com/ReverseAuction/#/login" target='_blank'>Reverse Auction</a></li>
                                                                <li><a className='lv_3' href="https://ems.zohocreatorportal.in/" target='_blank'>Expenses Management System</a></li>
                                                                <li><a className='lv_3' href="#" target='_blank'>PTL</a></li>
                                                            </ul>
                                                        </li>

                                                    </ul>

                                                </li> */}
                                                <li ><a className='lv_1 contact_lk' href="/contact">Contact Us</a></li>

                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                            </div>


                        </div>
                    </nav>
                </div>
            </div>


        </>
    )
}
