import React from 'react'
import '../../../assets/css/media.css';
import { useParams } from "react-router-dom";
import useFetch from "react-fetch-hook";

import $ from "jquery";

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";


export default function Blog_inside_sec() {

  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const blg_in_sec_p1 = useRef(null);
  const blg_in_sec_img1 = useRef(null);

  useEffect(() => {

    // Para animation code
    gsap.fromTo(".blg_in_sec_p1", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 1,
      ease: "linear",
      delay: 0.2,
      scrollTrigger: {
        trigger: ".blg_in_sec_p1",
        markers: false
      }
    });

    gsap.fromTo(".blg_in_sec_img1", {
      x: 0
    }, {
      xPercent: 100,
      duration: 0.5,
      ease: "linear",
      delay: 2,
      scrollTrigger: {
        trigger: ".blg_in_sec_img1",
        markers: false
      }
    });

  })

  let { slug } = useParams();

  const { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/blogs/?slug=${slug}`
  );

  const pg_url = document.URL;

  return (
    <>
      <div className='media_div mob_padding_LR tab_padding_LR'>
        <div className='blog_container'>


          <div className='row media_row'>

            <div className='col-12 blog_img_col'>
              {data && data.map(({ id, title, acf, content }) => (

                <div className='img_dv'>
                  <div className='img_oly blg_in_sec_img1' ref={blg_in_sec_img1}></div>
                  <img className='media_hd_img' src={acf.media_image}></img>
                </div>

              ))}

            </div>

            <div className='col-12'>

              <div className='row'>
                <div className='col-lg-1 col-md-1 col-1 pl_0'>
                  <div className='media_col1 blg_in_sec_p1' ref={blg_in_sec_p1}>
                    <FacebookShareButton url={pg_url} quote={'Hello'}>
                      <FacebookIcon size={30} round={true}></FacebookIcon>
                    </FacebookShareButton>

                    <LinkedinShareButton url={pg_url} >
                      <LinkedinIcon size={30} round={true}></LinkedinIcon>
                    </LinkedinShareButton>

                    <TwitterShareButton url={pg_url} >
                      <TwitterIcon size={30} round={true}></TwitterIcon>
                    </TwitterShareButton>
                  </div>
                </div>
                <div className='col-lg-11 col-md-11 col-11 '>
                  {data && data.map(({ id, title, acf, content }) => (
                    <div className='media_col2'>

                      <div dangerouslySetInnerHTML={{ __html: content.rendered }} className='blg_in_sec_p1' ref={blg_in_sec_p1}></div>

                      {/* <a href="/blog" class="btn blue_btn blg_back_btn">
                        <span class="btn_txt">Back to blog page</span>
                        <span class="arrow_btn blue_arrow"></span>
                      </a> */}

                      <a href='/blog' className='blg_back_btn'>{'<'} Back to blog page </a>

                    </div>

                  ))}
                </div>
              </div>


            </div>

          </div>
        </div>
      </div>
    </>
  )
}
