import React, { useRef, useState, useEffect } from "react";
import '../../../assets/css/case_studies.css';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, EffectCreative, EffectFade } from "swiper";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/effect-creative";
import 'swiper/css/effect-fade';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Case_studies() {

    

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const study_animate = useRef(null);
    const cs_bg = useRef(null);
    const sld_cnt_anmt = useRef(null);
    

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    useEffect(() => {

        gsap.fromTo(".study_animate", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".study_animate",
                markers: false
            }
        });

        gsap.fromTo(".sld_cnt_anmt", {
            opacity: 0,
        }, {
            delay: 0.2,
            duration: 0.8,
            opacity:1,
            ease: "power4.out",
            scrollTrigger: {
                trigger: ".sld_cnt_anmt",
                markers: false
            }
        });

        gsap.fromTo(".cs_bg", {
            x: 0,
        }, {
            xPercent: 102,
            duration: 0.8,
            delay: 0.2,
            ease: "power4.out",
            scrollTrigger: {
                trigger: ".cs_bg",
                markers: false
            }
        });


    })


    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/home_page`
    );


    return (
        <>
            <div className='case_studies_Sec  mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>
                    <div className='row'>

                        {data && data.map(({ acf }) => (
                            <div className='col-12 cs_hd_col'>

                                {acf.casestudy_slider_hd.map((cs_hd) =>
                                    <div className='hd_dv' >
                                        <h2 className='heading study_animate wht_txt' ref={study_animate}>{cs_hd.casestudy_hd}</h2>
                                    </div>
                                )}
                            </div>
                        ))}

                        <div className='col-xl-6 col-lg-6 col-md-12 col-12'>

                            <div className='cs_col1'>
                                <div className="case_bg cs_bg" ref={cs_bg}></div>
                                <div className="cs_slider_dv" >
                                    {data && data.map(({ acf }) => (
                                        <Swiper
                                            effect={"creative"}
                                            creativeEffect={{
                                                prev: {
                                                    shadow: true,
                                                    translate: ['0%', 0, -1],
                                                    // translate: [0, 0, -400],
                                                },
                                                next: {
                                                    translate: ["100%", 0, 0],
                                                },
                                            }}
                                            onSwiper={setThumbsSwiper}
                                            spaceBetween={0}
                                            scrollbar={{ draggable: false }}
                                            allowTouchMove={false}
                                            speed={1000}
                                            navigation={false}
                                            //thumbs={{ swiper: thumbsSwiper }}
                                            modules={[FreeMode, Navigation, Thumbs, EffectCreative]}
                                            className="cs_img_slider "
                                        >

                                            {acf.casestudy_slider.map((cs_sub) =>
                                                <SwiperSlide>
                                                    <div className="cs_img_dv">
                                                        <img src={cs_sub.slider_image} />
                                                    </div>
                                                </SwiperSlide>
                                            )}
                                            {/* <SwiperSlide>
                                                <div className="cs_img_dv">
                                                    <img src={require('../../../assets/img/case_study/automotive.jpg')} />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="cs_img_dv">
                                                    <img src={require('../../../assets/img/case_study/consumer_durables.jpg')} />
                                                </div>
                                            </SwiperSlide> */}
                                        </Swiper>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                            <div className='cs_col2 sld_cnt_anmt' ref={sld_cnt_anmt}>

                                {data && data.map(({ acf }) => (
                                    <Swiper
                                        effect={"fade"}
                                        slidesPerView={1}
                                        speed={1200}
                                        scrollbar={{ draggable: false }}
                                        allowTouchMove={false}
                                        navigation={true}
                                        thumbs={{ swiper: thumbsSwiper }}
                                        modules={[FreeMode, Navigation, Thumbs, EffectFade]}
                                        onSlideChange={() => console.log('slide change')}
                                        className="cs_txt_slider sldr_wht_bnt"
                                    >

                                        {acf.casestudy_slider.map((cs_sub) =>
                                            <SwiperSlide>
                                                <div className="cs_sld_Content">
                                                    <h4 className="CS_ttl">{cs_sub.slider_description}</h4>
                                                    {/* <p className="cs_para">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem.</p> */}
                                                    <a href={cs_sub.button_url} className='btn blue_btn'>
                                                        <span className='btn_txt'>{cs_sub.button_name}</span>
                                                        <span className='arrow_btn blue_arrow'></span>
                                                    </a>
                                                </div>
                                            </SwiperSlide>
                                        )}

                                    </Swiper>
                                ))}


                            </div>
                           
                        </div>

                    </div>




                </div>


            </div>


        </>
    )
}
