import React from 'react'
import '../../../../assets/css/service_offer_ware.css';

import { useEffect, useRef } from 'react';
import $ from 'jquery';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Route_optimization() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const route_hd = useRef(null);
    const route_p1 = useRef(null);
    const route_img1 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".route_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".route_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".route_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".route_p1",
                markers: false
            }
        });

        gsap.fromTo(".route_img1", {
            x: 0
        }, {
            xPercent: 101,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".route_img1",
                markers: false
            }
        });


        $(".route_content").hide();
        $(".route").on("click", function () {
            var txt = $(".route_content").is(':visible') ? 'Read More' : 'Read Less';
            $(".route").text(txt);
            $('.route_content').slideToggle(200);
        }); // for Para

        $(".route_link").on("click", function () {
            var txt = $(".route_content").is(':visible') ? 'Read Less' : 'Read Less';
            $(".route").text(txt);
            $('.route_content').slideDown(200);
        }); // for Spotlight link

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/supply_chain_page/`
    );

    return (
        <>
            <div className='route_section SO_ware mob_padding_LR tab_padding_LR' id='route_optimization'>

                {data && data.map(({ acf }) => (
                    <div className='cstm_container'>

                        <div className='row'>
                            <div className='col-lg-7 col-md-6 col-12'>

                                {acf.route_section.map((rt_hd) =>
                                    <div className='so_col1_multi'>

                                        <div className='hd_dv'>
                                            <h2 className='heading wht_txt route_hd' ref={route_hd}>{rt_hd.route_hd}</h2>
                                        </div>

                                        <p className='so_col_desc route_mainhead route_p1' ref={route_p1}>{rt_hd.route_description}</p>
                                        <div   className=' route_p1' ref={route_p1} dangerouslySetInnerHTML={{ __html: rt_hd.route_description1 }} ></div>
                                        {/* <a className="route route_p1 read_more_btn" data-content="toggle-text" ref={route_p1}>Read More</a> */}

                                    </div>

                                )}

                            </div>
                            <div className='col-lg-5 col-md-6 col-12'>
                                <div className='so_col1_optimization'>
                                    <div className='img_dv'>
                                        <div className='img_oly blue_oly route_img1' ref={route_img1}></div>
                                            {acf.route_section.map((rt_img) =>
                                                <img className='so_col_img' src={rt_img.route_image}></img>
                                            )}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
