import React from 'react'
import '../../assets/css/newsletter.css';

import { useEffect, useRef, useState } from 'react';
import useFetch from "react-fetch-hook";
import emailjs from 'emailjs-com';
export default function Newsletter() {

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/contact_us_page/`
    );

    const form = useRef();
    const [loading, setLoading] = useState(false)
    const [statusMessage, setStatusMessage] = useState("");

    const emailinput_sub = React.useRef(null);

    // All field validation error hide code 
    const handleFocus_sub = e => {
        e.target.classList.remove("error_line");

        let er2_sub = document.getElementById("eml_err_sub");
        er2_sub.classList.remove("show_error");

        let er2v_sub = document.getElementById("eml_err1_sub");
        er2v_sub.classList.remove("show_error");

    };

    // Form action code
    const sendEmail_sub = async (e) => {
        e.preventDefault();

        // Fields get value Code
        let email_fld_sub = (emailinput_sub.current.value);

        // Phone Regex Code
        var mailformat_sub = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        // Fields validation Code

        if (email_fld_sub == '') {
            let v2_sub = document.getElementById("email_fld_sub");
            v2_sub.className += " error_line";

            let er2_sub = document.getElementById("eml_err_sub");
            er2_sub.className += " show_error";
        }
        else if (!email_fld_sub.match(mailformat_sub)) {
            let v2_sub = document.getElementById("email_fld_sub");
            v2_sub.className += " error_line";

            let er2v_sub = document.getElementById("eml_err1_sub");
            er2v_sub.className += " show_error";
        }
        else {

            fetch('https://script.google.com/macros/s/AKfycbxIG3AK7ENR41b0eYVh2Vsr9BiJeRxhhFlJYWnqXOSpCIz3Ubq9TDPRdGJi43G_KBXb/exec', {
                method: 'POST',
                body: new FormData(form.current),

            }).then(res => {
                console.log("SUCCESSFULLY SUBMITTED")
                setLoading(false)
            })
                .catch(err => console.log(err))

            emailjs.sendForm('service_l2n43ts', 'template_i8d2tcg', e.target, 'SsPYHKCapw4h-xBn_')
                .then((result) => {

                    console.log(result.text);
                    setStatusMessage("Your Message has been sent successfully");
                    let msg = document.getElementById("thnk_msg_sub");
                    msg.className += " thnk_msg_show";

                    let frm_bx = document.getElementById("newsletter_form_bx");
                    frm_bx.className += " cont_form_bx_hide";

                }, (error) => {

                    console.log(error.text);
                    setStatusMessage(`${error.text} happened`);

                });
            e.target.reset()

        }


    };

    return (
        <>
            <div className='newsletter_sec mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-lg-5 col-md-5 col-12'>
                                <div className='newsletter_col1'>
                                    <div className='address_dv'>
                                        {acf.corporate_office_address_section.map((ofc_add) =>
                                            <p className='add_p'>{ofc_add.corporate_office_address}</p>
                                        )}
                                        <a href='mailto:connect@apollosupplychain.com' className='cntact_dtl'>connect@apollosupplychain.com</a>

                                        {acf.phone_section.map((ofc_num) =>
                                            <a href='tel:0124 674 0200' className='cntact_dtl'>{ofc_num.phone_number}</a>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-7 col-12'>
                                <div className='newsletter_col2'>
                                    <h3 className='heading'>Subscribe to <br />our Newsletter</h3>
                                    <form className='newsletter_form' ref={form} onSubmit={sendEmail_sub} id='newsletter_form_bx'>

                                        <input type='email' name="newsletter_email" placeholder='Enter your email' className='news_ltr_input' id='email_fld_sub' ref={emailinput_sub} onFocus={handleFocus_sub}></input>
                                        <p className='error_msg sub_err' id='eml_err_sub'>Please Enter Email Address</p>
                                        <p className='error_msg sub_err' id='eml_err1_sub'>Please Enter Valid Email Address</p>

                                        <input type='submit' value='Submit' className='news_ltr_btn'></input>

                                    </form>

                                    <p className='form_thanks_msg sub_thank_msg' id='thnk_msg_sub'>Thankyou for your Subscription</p>

                                    <img className='nswltter_vctr' src={require('../../assets/img/svg/newsletter_vector.svg').default}></img>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
