import React from 'react'
import Header from "../component/global/header";
import Supply_chain_spotlight from '../component/spotlight/supplychain_spotlight';
import Counter_ware from '../component/section/services/warehousing/counter_ware';
import Supply_chain from '../component/section/services/supply_chain/supply_chain';
import Route_optimization from '../component/section/services/supply_chain/Route_optimization';
import Network_design from '../component/section/services/supply_chain/network_design';
import Simulation_design from '../component/section/services/supply_chain/simulation_design';
import Plant_warehouse from '../component/section/services/warehousing/plant_warehouse';
import Asc_advance_ware from '../component/section/services/warehousing/asc_advance_ware';
import Solution_center from '../component/section/services/warehousing/solution_center';
import Apollo_edge_service from '../component/section/services/apollo_edge_service';
import Customised_servc_ware from '../component/section/services/warehousing/customised_servc_ware';
import Connect_service from '../component/section/services/connect_service';

import Footer from "../component/global/footer";
// import CustomGlobe from "../CustomGlobe";

import Transition from '../component/transition';
import { gsap } from "gsap";
import { useEffect, useRef } from 'react';
import { Power3, Power4 } from "gsap";
import Digitalization from '../component/section/services/supply_chain/digitalization';








export default function Supply_chain_page() {

  let body = useRef(null);
  var tl_1 = gsap.timeline()

  useEffect(() => {

    // Page Transition Code

    tl_1.to(body, {
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut,
      delay: 1.2
    });
    return () => {
      tl_1.to(body, {
        opacity: "0",
        pointerEvents: 'none'
      });
    }

  })


  return (
    <>
      <Transition />

      <div className='supply_pg srvc_pg'>
        <div ref={(el) => (body = el)} className="Headd">

          <Header />
          <Supply_chain_spotlight />
          <Supply_chain />
          <Digitalization/>
          <Route_optimization />
          <Network_design />
          <Simulation_design />
          <Connect_service/>
          <Footer />
        </div>
      </div>
    </>
  )
}
