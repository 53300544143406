import React from 'react'
import '../../../../assets/css/apollo_edge_service.css'


import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Land_asc_edge() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const ap_edge_ware_hd = useRef(null);
    const ap_edge_ware_hd1 = useRef(null);
    const ap_edge_ware_p1 = useRef(null);
    const ap_edge_ware_p2 = useRef(null);
    const ap_edge_ware_img1 = useRef(null);
    const ap_edge_ware_img2 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".ap_edge_ware_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".ap_edge_ware_hd",
                markers: false
            }
        });

        gsap.fromTo(".ap_edge_ware_hd1", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".ap_edge_ware_hd1",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".ap_edge_ware_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ap_edge_ware_p1",
                markers: false
            }
        });

        gsap.fromTo(".ap_edge_ware_img1", {
            x: 0
        }, {
            x: 635,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ap_edge_ware_img1",
                markers: false
            }
        });

        gsap.fromTo(".ap_edge_ware_img2", {
            x: 0
        }, {
            x: 635,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ap_edge_ware_img2",
                markers: false
            }
        });

    })


    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/warehousing_page`
    );


    return (
        <>
            <div className='apollo_edge_service mob_padding_LR tab_padding_LR land_aplo_edge' id='technologies-for-your-transportation'>


                <div className='cstm_container'>

                    <div className='row'>
                        <div className='col-12 aes_main_col'>

                            <div className='hd_dv' style={{ margin: 'auto' }}>
                                <h2 className='heading techo_head ap_edge_ware_hd' ref={ap_edge_ware_hd}>The Technology Edge</h2>
                            </div>

                        </div>
                    </div>

                    <div className='row aes_row2'>
                        <div className='col-lg-6 col-md-6 col-12 p_0'>
                            <div className='aes_col1'>


                                <div className='img_dv'>
                                    <div className='img_oly ap_edge_ware_img1' ref={ap_edge_ware_img1}></div>
                                    <img className='so_col_img' src={require('../../../../assets/img/surface_techno.jpg')}></img>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12 p_0 aes_bg' >

                            <div className='aes_col2'>
                                <h2 className='aes_hd ap_edge_ware_p1' ref={ap_edge_ware_p1}>Our Tech Edge for Enhanced Surface-Transport Performance</h2>
                                <p className='aes_desc ap_edge_ware_p1' ref={ap_edge_ware_p1}>Powerful features that guarantee remarkable cost-efficiencies:</p>
                                <ul className='aes_list global_list ap_edge_ware_p1' ref={ap_edge_ware_p1}>

                                    <li>Enhanced TMS With Dynamic Handling Capabilities</li>
                                    <li>Comprehensive Dashboard for Real-Time Visibility and Big Savings</li>
                                    <li>Advanced Data Analytics for Greater Control</li>
                                    <li>Technology driven consultative approach for optimized load, optimized route and network design & re-design</li>

                                </ul>
                            </div>

                        </div>
                    </div>

                </div>


            </div>


            <div className='apollo_edge_service mob_padding_LR tab_padding_LR land_aplo_edge1' id='growth-catalysts'>


                <div className='cstm_container'>

                    <div className='row'>
                        <div className='col-12 aes_main_col'>


                            <div className='hd_dv' style={{ margin: 'auto' }}>
                                <h2 className='heading techo_head ap_edge_ware_hd1' ref={ap_edge_ware_hd1}>Growth Catalysts</h2>
                            </div>


                        </div>
                    </div>

                    <div className='row aes_row3'>

                        <div className='col-lg-6 col-md-6 col-12 p_0 aes_bg asc_order' >

                            <div className='aes_col2'>
                                {/* <h2 className='aes_hd' style={{ visibility: 'hidden' }}>The ASC Edge</h2> */}
                                <ul className='aes_list global_list ap_edge_ware_p2' ref={ap_edge_ware_p2}>

                                    <li>Control Tower and Analytics Center for E2E Supply Chain Visibility</li>
                                    <li>Strong vendor base with extensive reach pan India</li>
                                    <li>Single Point of Contact (SPOC) for Efficient Coordination</li>
                                    <li>Integrated Services from Primary Transportation to Last Mile  </li>
                                    <li>Quick response Team to resolve Operational Challenges</li>
                                    <li>A strong tech support</li>
                                </ul>
                            </div>

                        </div>
                        <div className='col-lg-6 col-md-6 col-12 p_0'>
                            <div className='aes_col1'>

                                <div className='img_dv'>
                                    <div className='img_oly ap_edge_ware_img2' ref={ap_edge_ware_img2}></div>
                                    <img className='so_col_img' src={require('../../../../assets/img/surface_edge.jpg')}></img>
                                </div>

                            </div>
                        </div>

                    </div>



                </div>

            </div>

        </>
    )
}
