import React from 'react'
import '../../../../assets/css/solution_cstm.css';

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Solution_cstm() {


    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const cstm_sol_hd = useRef(null);
    const cstm_sol_p1 = useRef(null);
    const cstm_sol_p2 = useRef(null);
    const cstm_sol_p3 = useRef(null);
    const cstm_sol_p4 = useRef(null);
    const cstm_sol_p5 = useRef(null);
    const cstm_sol_p6 = useRef(null);
    const cstm_sol_p7 = useRef(null);
    const cstm_sol_img1 = useRef(null);


    useEffect(() => {

        gsap.fromTo(".cstm_sol_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".cstm_sol_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".cstm_sol_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.4,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cstm_sol_p1",
                markers: false
            }
        });


        gsap.fromTo(".cstm_sol_img1", {
            opacity: 0
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cstm_sol_img1",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/consumer_sector_page`
    );


    return (
        <>
            <div className='solution_cstm mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>
                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-lg-12 col-12'>

                                {acf.consumer_solution_sec.map((cstm_sol) =>
                                    <div className='hd_dv' style={{ margin: 'auto' }}>
                                        <h1 className='heading wht_txt txt_cntr cstm_sol_hd' ref={cstm_sol_hd}>{cstm_sol.consumer_solution_hd}</h1>
                                    </div>
                                )}

                                {/* <div className='solution_cstm_dv'>

                                    <img className='sol_line_img cstm_sol_img1' ref={cstm_sol_img1} src={require('../../../../assets/img/svg/solution_line.svg').default}></img>

                                    {acf.consumer_solution_list_sec.map((cstm_sol1) =>
                                        <div className='sol_bx cstm_sol_p1' ref={cstm_sol_p1}>
                                            <p>{cstm_sol1.consumer_solution_list}</p>
                                        </div>
                                    )}

                                </div> */}

                                <div className='solution_cstm_dv'>

                                    <div className='row'>

                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Rationalization</p>
                                                        <p>The supply chain network design needed to be rejigged. The origin-destination pairs, for example, needed to be rationalized taking their respective distances from warehouses into account.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Warehouse Consolidation</p>
                                                        <p>The total number of warehouses needed to be consolidated to optimize resource utilization and compliance costs in line with local and national regulations.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Better-Suited Configuration</p>
                                                        <p>The Apollo Supply Chain team implemented a mix of ground and rack storage configuration to improve utilization and reduce costs.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Warehouse Relocation</p>
                                                        <p>Efficient transfer planning resulted in relocation of an existing warehouse within a week’s time.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Warehousing Process Optimization</p>
                                                        <p>The team improved and optimized warehousing processes at three warehouses located across North India, which together handled nearly 0.05 million SKUs.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Better Coordination</p>
                                                        <p>The team trained the manpower and the supply chain planner for improved work performance and greater coordination between the supply chain planner and the customer.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Reduced COG</p>
                                                        <p>A reduced cost of goods (COG) was obtained through improved network optimization and warehouse consolidation based on a study that helped in saving the in-transit cost.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>


                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
