import React from 'react'
import Header from "../../component/global/header";
import Career_spotlight from '../../component/spotlight/career_spotlight';
import Asc_section from '../../component/section/careers/asc_section';
import Lifeasc_section from '../../component/section/careers/lifeasc_section';
import Grow_section from '../../component/section/careers/grow_section';
// import Simulation_design from '../component/section/services/supply_chain/simulation_design';
// import Plant_warehouse from '../component/section/services/warehousing/plant_warehouse';
// import Asc_advance_ware from '../component/section/services/warehousing/asc_advance_ware';
// import Solution_center from '../component/section/services/warehousing/solution_center';
// import Apollo_edge_service from '../component/section/services/apollo_edge_service';
// import Customised_servc_ware from '../../component/section/services/warehousing/customised_servc_ware';
import Connect_service from '../../component/section/services/connect_service';
import New_sec from '../../component/section/careers/new_sec';
import Footer from "../../component/global/footer";
// import CustomGlobe from "../CustomGlobe";

import Transition from '../../component/transition';
import { gsap } from "gsap";
import { useEffect, useRef } from 'react';
import { Power3, Power4 } from "gsap";





export default function Life_at_asc_page() {

  let body = useRef(null);
  var tl_1 = gsap.timeline()

  useEffect(() => {

    // Page Transition Code

    tl_1.to(body, {
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut,
      delay: 1.2
    });
    return () => {
      tl_1.to(body, {
        opacity: "0",
        pointerEvents: 'none'
      });
    }

  })


  return (
    <>
      <Transition />

      <div className='life_at_asc_pg career_pg'>
        <div ref={(el) => (body = el)} className="Headd">

          <Header />
          <Career_spotlight />
          <Asc_section />
          <Lifeasc_section />
          <New_sec />
          <Footer />

    
        </div>
      </div>
    </>
  )
}
