import React from 'react'
import '../../../assets/css/office_dtls.css'

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Office_dtls() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const cnt_hd2 = useRef(null);
    const cnt_add1 = useRef(null);
    const cnt_add2 = useRef(null);
    const cnt_add3 = useRef(null);
    const cnt_add4 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".cnt_hd2", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".cnt_hd2",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".cnt_add1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cnt_add1",
                markers: false
            }
        });
        gsap.fromTo(".cnt_add2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cnt_add2",
                markers: false
            }
        });
        gsap.fromTo(".cnt_add3", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cnt_add3",
                markers: false
            }
        });
        gsap.fromTo(".cnt_add4", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cnt_add4",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/contact_us_page/`
    );

    return (
        <>
            <div className='office_dtls'>
                {data && data.map(({ acf }) => (
                    <div className='cstm_container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='hd_dv'>
                                    {acf.other_offices_sec.map((offic_title) =>
                                        <h2 className='heading cnt_hd2' ref={cnt_hd2} style={{ textAlign: 'center' }}>{offic_title.other_office_hd}</h2>
                                    )}
                                </div>

                            </div>
                        </div>
                        <div className='row office_row'>

                            {acf.office_address_section.map((ofc_detls) =>
                                <div className='col-lg-3 col-md-6 col-12'>
                                    <div className='ofc_inner_dv cnt_add1' ref={cnt_add1}>
                                        <img src={ofc_detls.location_icon}></img>
                                        <h3 className='ofc_location wht_txt'>{ofc_detls.office_city_name}</h3>
                                        <p className='ofc_add wht_txt' dangerouslySetInnerHTML={{ __html: ofc_detls.office_address }}></p>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
