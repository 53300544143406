import React from 'react'
import '../../../../assets/css/service_offer_ware.css';

import { useEffect, useRef } from 'react';
import $ from 'jquery';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Full_truck() {


    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const fl_trk_hd = useRef(null);
    const fl_trk_p1 = useRef(null);
    const fl_trk_img1 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".fl_trk_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".fl_trk_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".fl_trk_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".fl_trk_p1",
                markers: false
            }
        });

        gsap.fromTo(".fl_trk_img1", {
            x: 0
        }, {
            xPercent: 101,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".fl_trk_img1",
                markers: false
            }
        });

        $(".full_truck_content").hide();
        $(".full_truck").on("click", function () {
            var txt = $(".full_truck_content").is(':visible') ? 'Read More' : 'Read Less';
            $(".full_truck").text(txt);
            $('.full_truck_content').slideToggle(200);
        });
        $(".full_truck_link").on("click", function () {
            var txt = $(".full_truck_content").is(':visible') ? 'Read Less' : 'Read Less';
            $(".full_truck").text(txt);
            $('.full_truck_content').slideDown(200);
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/land_transport_page`
    );

    return (
        <>
            <div className='full_truck_sec SO_ware mob_padding_LR tab_padding_LR' id='ftl'>
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>

                            <div className='col-lg-5 col-md-12 col-12'>
                                <div className='so_col1_optimization'>

                                    {acf.full_truck_load_section.map((land_sub1) =>
                                        <div className='img_dv'>
                                            <div className='img_oly blue_oly fl_trk_img1' ref={fl_trk_img1}></div>
                                            <img className='so_col_img_multi' src={land_sub1.full_truck_image}></img>
                                        </div>
                                    )}

                                </div>
                            </div>


                            <div className='col-lg-7 col-md-12 col-12'>

                                {acf.full_truck_load_section.map((land_sub1) =>
                                    <div className='so_col1_multi truck_main'>

                                        <div className='hd_dv'>
                                            <h2 className='truck_head heading fl_trk_hd' ref={fl_trk_hd}>{land_sub1.full_truck_hd}</h2>
                                        </div>

                                        {/* <p className='so_col_desc truck_para fl_trk_p1' ref={fl_trk_p1}>{land_sub1.full_truck_description}</p>

                                        <div className='full_truck_content fl_trk_p1' ref={fl_trk_p1} dangerouslySetInnerHTML={{ __html: land_sub1.full_truck_description1 }}>
                                        </div>
                                        <a className="full_truck fl_trk_p1 read_more_btn" data-content="toggle-text" ref={fl_trk_p1}>Read More</a> */}
                                        <div className='fl_trk_p1' ref={fl_trk_p1} dangerouslySetInnerHTML={{ __html: land_sub1.full_truck_description1 }}>
                                        </div>

                                    </div>
                                )}

                            </div>


                        </div>
                    ))}


                </div>
            </div>
        </>
    )
}
