import React from 'react'
import '../../assets/css/inside_page_spotlight.css'

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Career_spotlight() {

  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const life_hd = useRef(null);


  const videoEl = useRef(null);
  const videoEl1 = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };
  const attemptPlay1 = () => {
    videoEl1 &&
    videoEl1.current &&
    videoEl1.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {

    gsap.fromTo(".life_hd", {
      y: 250,
      opacity: 0,
      skewY: 41,
    }, {
      delay: 1.8,
      duration: 0.8,
      y: 0,
      skewY: 0,
      opacity: 1,
      ease: "power4.out",
      stagger: {
        from: 'start',
        amount: 0.5,
      },
      scrollTrigger: {
        trigger: ".life_hd",
        markers: false
      }
    });



  attemptPlay();
  attemptPlay1();
  })

  const { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/life_asc_page`
  );



  return (
    <>
      <div className='sector_spotlight life_asc_spotlight page_spotlight'>
        <video className='desktop_spot_video' playsInline loop muted src={require('../../assets/video/careers_spotlight.mp4')} ref={videoEl} />
        <video className='ipad_spot_video' playsInline loop muted src={require('../../assets/video/careers_spotlight_iPad.mp4')} ref={videoEl1} />

        <div className='spotlight_container'>
          <div className='row'>
            <div className='col-12 pr_0'>
              <div className='spotlight_dv'>

                {data && data.map(({ acf }) => (

                  <div className='spot_contnt'>
                    <div className='spot_col1'>

                      {acf.life_asc_spotlight.map((life_sub1) =>
                        <div className='hd_dv'>
                          <h1 className='spot_heading wht_txt life_hd' ref={life_hd}>{life_sub1.life_asc_spot_hd}</h1>
                        </div>
                      )}

                      {/* <p className='spot_sb_ttl'>Get In Touch With Us</p> */}
                    </div>
                    <div className='spot_col2' style={{ visibility: 'hidden' }}>
                      <p className='wht_txt'>Our countrywide network of warehouses provides a wide range of services including dedicated and multi-user facilities.</p>
                      <a href='#' className='btn wht_btn'>
                        <span className='btn_txt'>Learn More</span>
                        <span className='arrow_btn wht_arrow'></span>
                      </a>
                    </div>
                  </div>

                ))}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
