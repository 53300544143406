import React from 'react'
import $ from "jquery";

import '../../../../assets/css/info_ware.css';
import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Land_section() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const land_in_p = useRef(null);
    const land_in_p1 = useRef(null);

    useEffect(() => {


        if ($(window).width() <= 1600) {
            // Para animation code
            gsap.fromTo(".land_in_p", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".land_in_p",
                    markers: false
                }
            });
        }
        else {

            // Para animation code
            gsap.fromTo(".land_in_p", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 2.5,
                scrollTrigger: {
                    trigger: ".land_in_p",
                    markers: false
                }
            });
        }


        // Para animation code
        gsap.fromTo(".land_in_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".land_in_p1",
                markers: false
            }
        });

        $.fn.isInViewport = function () {
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight() / 2;
            var viewportTop = $(window).scrollTop();
            var viewportHalf = viewportTop + $(window).height() / 1;
            return elementBottom > viewportTop && elementTop < viewportHalf;
        };


        $(window).on("scroll", function () {

            $(".img_scroll_dv").each(function () {
                var windowTop = $(window).scrollTop();
                var elementTop = $(this).offset().top;
                var leftPosition = windowTop - elementTop;
                $(this)
                    .find(".inside_dv_img")
                    .css({ right: leftPosition });
            });
        });

        

    });

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/land_transport_page`
    );

    return (
        <>
            <div className='info_ware_sec land_trans_sec '>

                {data && data.map(({ acf }) => (

                    <div className='row m_0'>

                        <div className='cstm_container col-md-12 col-12 p_0 mob_padding_LR tab_padding_LR'>
                            <div className='col-lg-12 p_0'>
                                {acf.land_transport_info.map((land_sub) =>
                                    <div className='info_ware_col1'>
                                        <p className='info_para_cust land_in_p' ref={land_in_p}>{land_sub.land_transport_description}</p>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='col-12 p_0'>
                            <div className='img_scroll_dv'>
                                <div className='inside_dv_img'>

                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image4.jpg')}></img>


                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image4.jpg')}></img>

                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image4.jpg')}></img>


                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image4.jpg')}></img>

                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image4.jpg')}></img>

                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image1.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image2.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image3.jpg')}></img>
                                    <img className='scroll_img' src={require('../../../../assets/img/services/land-transport/Surface_Image4.jpg')}></img>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='cstm_container col-md-12 col-12 p_0 mob_padding_LR tab_padding_LR'>
                            <div className='col-lg-12 p_0'>

                                {acf.land_transport_info.map((land_sub) =>
                                    <div className='info_ware_col1'>
                                        <p className='info_para_cust land_in_p1' ref={land_in_p1}>{land_sub.land_transport_aescription1}</p>
                                    </div>
                                )}

                            </div>
                        </div>


                    </div>
                ))}

            </div>
        </>
    )
}
