import React from 'react'
import '../../../../assets/css/case_study.css';

import { useEffect, useRef } from 'react';
import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Case_study_cstm() {


    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const cstm_Cs_p1 = useRef(null);
    const cstm_Cs_img1 = useRef(null);

    useEffect(() => {

        if ($(window).width() <= 1440) {
            // Para animation code
            gsap.fromTo(".cstm_Cs_p1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_p1",
                    markers: false
                }
            });

            gsap.fromTo(".cstm_Cs_img1", {
                x: 0
            }, {
                x: 550,
                duration: 0.5,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_img1",
                    markers: false
                }
            });
        }
        else {
            // Para animation code
            gsap.fromTo(".cstm_Cs_p1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 1.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_p1",
                    markers: false
                }
            });

            gsap.fromTo(".cstm_Cs_img1", {
                x: 0
            }, {
                x: 550,
                duration: 0.5,
                ease: "linear",
                delay: 1.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_img1",
                    markers: false
                }
            });

        }

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/consumer_sector_page`
    );


    return (
        <>
            <div className='case_study cs_cstm' id='consumer_case_study'>
                {data && data.map(({ acf }) => (
                    <div className='row m_0'>
                        <div className='col-lg-3 col-md-4 col-12 p_0'>

                            <div className='cstm_cs_col1'>

                                <div className='img_dv'>

                                    <div className='img_oly cstm_cs cstm_Cs_img1' ref={cstm_Cs_img1}></div>
                                    {acf.consumer_case_study.map((cstm_sub3) =>
                                        <img className='tech_img' src={cstm_sub3.consumer_cs_image}></img>
                                    )}

                                </div>
                                
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8 col-12 p_0'>
                            <div className='cstm_cs_col2'>

                                {acf.consumer_case_study.map((cstm_sub3) =>
                                    <div className='cs_cstm_inner'>
                                        <h5 className='cs_ttl cstm_Cs_p1' ref={cstm_Cs_p1}>{cstm_sub3.consumer_cs_hd}</h5>
                                        <p className='cs_para cstm_Cs_p1' ref={cstm_Cs_p1}>{cstm_sub3.consumer_cs_description}</p>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        </>
    )
}
