import React from 'react'
import Header from "../component/global/header";
import Apollo_edge_spotlight from '../component/spotlight/apollo_edge_spotlight';
import Apollo_edge_info from '../component/section/apollo_edge/apollo_edge_info';
import Apollo_edge_counter from '../component/section/apollo_edge/apollo_edge_counter';
import Integ_logist from '../component/section/apollo_edge/integ_logist';
import Apollo_edge_advantage from '../component/section/apollo_edge/apollo_edge_advantage';
import Apollo_edge_tech from '../component/section/apollo_edge/apollo_edge_tech';
import Apollo_edge_accordion from '../component/section/apollo_edge/apollo_edge_accordion';
import Connect_service from '../component/section/services/connect_service';
import Footer from "../component/global/footer";


import Transition from '../component/transition';
import { gsap } from "gsap";
import { useEffect , useRef } from 'react';
import { Power3, Power4 } from "gsap";
import Ae_glance from '../component/section/apollo_edge/ae_glance';










export default function Apollo_edge_page() {

  let body = useRef(null);
  var tl_1 = gsap.timeline()

  useEffect(() => {

    // Page Transition Code

    tl_1.to(body,{
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut,
      delay: 1.2
    });
    return () => {
      tl_1.to(body, {
        opacity: "0",
        pointerEvents: 'none'
      });
    }

  })

  return (
    <>
      <Transition/>

      <div className='apedge_pg'>
          <div ref={(el) => (body = el)} className="Headd">

            <Header/>
            <Apollo_edge_spotlight/>
             {/* <Apollo_edge_info/>  */}
            <Apollo_edge_counter/>
            <Integ_logist/>
            <Apollo_edge_advantage/>
            <Apollo_edge_tech/>
            <Apollo_edge_accordion/>
            <Ae_glance/>
            <Connect_service/>
            <Footer/>

          </div>
      </div>
    </>
  )
}
