import React from 'react'
import '../../../../assets/css/service_offer_ware.css';

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Asc_prime() {


    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const prime_hd = useRef(null);
    const prime_p1 = useRef(null);
    const prime_p2 = useRef(null);
    const prime_bx1 = useRef(null);
    const prime_bx2 = useRef(null);
    const prime_bx3 = useRef(null);

    const prime_img = useRef(null);

    useEffect(() => {

        gsap.fromTo(".prime_img", {
            x: 0
        }, {
            xPercent: 101,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".prime_img",
                markers: false
            }
        });

        gsap.fromTo(".prime_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".prime_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".prime_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".prime_p1",
                markers: false
            }
        });
        gsap.fromTo(".prime_p2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".prime_p2",
                markers: false
            }
        });


        gsap.fromTo(".prime_bx1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.4,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".prime_bx1",
                markers: false
            }
        });
        gsap.fromTo(".prime_bx2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.4,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".prime_bx2",
                markers: false
            }
        });
        gsap.fromTo(".prime_bx3", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.4,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".prime_bx3",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/land_transport_page`
    );

    return (
        <>
            <div className='asc_prime_sec SO_ware mob_padding_LR tab_padding_LR' id='the_prime'>

                {data && data.map(({ acf }) => (
                    <div className='cstm_container'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-12'>

                                {acf.asc_prime_section.map((land_sub5) =>
                                    <div className='info_ware_col1'>

                                        <div className='hd_dv'>
                                            <h2 className='heading asc_primeheading prime_hd' ref={prime_hd}>{land_sub5.asc_prime_hd}</h2>
                                        </div>
                                        <p className='asc_prime_para prime_p1' ref={prime_p1}>{land_sub5.asc_prime_sub_hd}</p>

                                    </div>
                                )}
                            </div>


                        </div>

                        <div className='row prm_bx'>

                            {acf.asc_prime_box.map((land_sub6) =>
                                <div className='col-lg-3 col-md-4 col-12 asc_rightpad'>
                                    <div className='count_col cc_1 asc_count prime_bx1' ref={prime_bx1}>
                                        <div className='cc_inner'>
                                            <p className='cc_ttl asc_ttl'>{land_sub6.asc_prime_box_hd1}</p>
                                            <div className='prime_bx_num' dangerouslySetInnerHTML={{ __html: land_sub6.asc_prime_box_number }}></div>
                                            <p className='cc_ttl asc_ttl'>{land_sub6.asc_prime_box_hd2}</p>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>


                        <div className='row'>

                            <div className='col-lg-7 col-md-12 col-12'>

                                {acf.asc_prime_section.map((land_sub7) =>
                                    <div className='design_ttl air_main asc_prime'>

                                        <p className='so_col_desc prime_para prime_p2' ref={prime_p2}>{land_sub7.asc_prime_description1}</p>

                                        <div className='prime_desc2 prime_p2' dangerouslySetInnerHTML={{ __html: land_sub7.asc_prime_description2 }} ref={prime_p2}></div>

                                    </div>
                                )}
                            </div>
                            <div className='col-lg-5 col-md-12 col-12'>
                                <div className='so_col1_network img_air'>

                                    {acf.asc_prime_section.map((land_sub7) =>
                                        <div className='img_dv'>
                                            <div className='img_oly  prime_img' ref={prime_img}></div>
                                            <img className='so_col_img' src={land_sub7.asc_prime_image}></img>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        </>
    )
}
