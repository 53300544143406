import React from 'react'
import '../../../assets/css/apollo_edge_advantage.css';
import { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Ae_advantage_mob from './ae_advantage_mob';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Apollo_edge_advantage() {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const adtg_hd = useRef(null);
    const adtg_ard1 = useRef(null);
    const adtg_ard2 = useRef(null);
    const adtg_ard3 = useRef(null);
    const adtg_ard4 = useRef(null);
    const adtg_ard5 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".adtg_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".adtg_hd",
                markers: false
            }
        });

        gsap.fromTo(".adtg_ard1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".adtg_ard1",
                markers: false
            }
        });
        gsap.fromTo(".adtg_ard2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay:0.2,
            scrollTrigger: {
                trigger: ".adtg_ard2",
                markers: false
            }
        });
        gsap.fromTo(".adtg_ard3", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".adtg_ard3",
                markers: false
            }
        });
        gsap.fromTo(".adtg_ard4", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".adtg_ard4",
                markers: false
            }
        });
        gsap.fromTo(".adtg_ard5", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".adtg_ard5",
                markers: false
            }
        });


        setNav1(slider1);
        setNav2(slider2);

    });


    const sctr_settings = {
        dots: false,
        swipe: false,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        draggable:true,
        slidesToScroll: 1,
        speed: 500,
        arrow: false,
        asNavFor: '.slider-nav'
    };

    const sctr1_settings = {
        dots: false,
        infinite: true,
        vertical: true,
        slidesToShow: 4,
        arrow: false,
        asNavFor: '.slider-for',
        centerMode: true,
        focusOnSelect: true,
        centerPadding: '10px'
    };

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/apollo_edge_page`
    );

    return (
        <>
            <div className='ap_edge_advantage advantage_desk  mob_padding_LR tab_padding_LR'  id='advantages'>

                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-12 advntg_col1 p_0'>

                                {acf.advantages_section.map((edge_advntg) =>
                                    <div className='hd_dv'>
                                        <h2 className='heading wht_txt adtg_hd' ref={adtg_hd}>{edge_advntg.advantage_hd}</h2>
                                    </div>
                                )}

                                <Slider {...sctr1_settings} className='apollo_advantage_nav' asNavFor={nav1} ref={slider => (setSlider2(slider))}>

                                    {acf.advantages_section.map((edge_advntg1) =>
                                        <div className='adv_apollo_outer_nav adtg_ard1' ref={adtg_ard1}>
                                            {edge_advntg1.a_strong_network_slide.map((sld1) =>
                                                <div className='ad_apollo_inner_nav '>
                                                    <p className=''>{sld1.advntg_slide_name}</p>
                                                    <span className='advtg_plus'></span>
                                                    <span className='advtg_minus'></span>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {acf.advantages_section.map((edge_advntg2) =>
                                        <div className='adv_apollo_outer_nav adtg_ard2' ref={adtg_ard2}>
                                            {edge_advntg2.tech_driven_solutions_slide.map((sld2) =>
                                                <div className='ad_apollo_inner_nav'>
                                                    <p className=''>{sld2.advntg_slide_name}</p>
                                                    <span className='advtg_plus'></span>
                                                    <span className='advtg_minus'></span>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {acf.advantages_section.map((edge_advntg3) =>
                                        <div className='adv_apollo_outer_nav adtg_ard3' ref={adtg_ard3}>
                                            {edge_advntg3.consultative_solutions_slide.map((sld3) =>
                                                <div className='ad_apollo_inner_nav'>
                                                    <p className=''>{sld3.advntg_slide_name}</p>
                                                    <span className='advtg_plus'></span>
                                                    <span className='advtg_minus'></span>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {acf.advantages_section.map((edge_advntg4) =>
                                        <div className='adv_apollo_outer_nav lst_nav adtg_ard4' ref={adtg_ard4}>
                                            {edge_advntg4.strong_leadership_slide.map((sld4) =>
                                                <div className='ad_apollo_inner_nav'>
                                                    <p className=''>{sld4.advntg_slide_name}</p>
                                                    <span className='advtg_plus'></span>
                                                    <span className='advtg_minus'></span>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {/* {acf.advantages_section.map((edge_advntg5) =>
                                        <div className='adv_apollo_outer_nav lst_nav adtg_ard5' ref={adtg_ard5}>
                                            {edge_advntg5.robust_countrywide_slide.map((sld5) =>
                                                <div className='ad_apollo_inner_nav'>
                                                    <p className=''>{sld5.advntg_slide_name}</p>
                                                    <span className='advtg_plus'></span>
                                                    <span className='advtg_minus'></span>
                                                </div>
                                            )}
                                        </div>
                                    )} */}


                                </Slider>

                            </div>

                            <div className='col-lg-6 col-md-6 col-12 advntg_col2'>
                                <Slider {...sctr_settings} className='advantg_apollo_sld' asNavFor={nav2} ref={slider => (setSlider1(slider))}>

                                    {acf.advantages_section.map((edge_advntg1) =>
                                        <div className='ad_apollo_outer_sld'>
                                            {edge_advntg1.a_strong_network_slide.map((sld1) =>
                                                <div className='ad_apollo_inner adtg_ard1' ref={adtg_ard1}>
                                                    <img src={sld1.advntg_slide_icon} className=''></img>
                                                    <h2 className='adv_sld_hd'>{sld1.advntg_slide_name}</h2>
                                                    <div dangerouslySetInnerHTML={{ __html: sld1.advntg_slide_description }}></div>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {acf.advantages_section.map((edge_advntg2) =>
                                        <div className='ad_apollo_outer_sld'>
                                            {edge_advntg2.tech_driven_solutions_slide.map((sld2) =>
                                                <div className='ad_apollo_inner '>
                                                    <img src={sld2.advntg_slide_icon} className=''></img>
                                                    <h2 className='adv_sld_hd'>{sld2.advntg_slide_name}</h2>
                                                    <p className='adv_sld_para' >{sld2.advntg_slide_description}</p>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {acf.advantages_section.map((edge_advntg3) =>
                                        <div className='ad_apollo_outer_sld'>
                                            {edge_advntg3.consultative_solutions_slide.map((sld3) =>
                                                <div className='ad_apollo_inner '>
                                                    <img src={sld3.advntg_slide_icon} className=''></img>
                                                    <h2 className='adv_sld_hd'>{sld3.advntg_slide_name}</h2>
                                                    <p className='adv_sld_para' >{sld3.advntg_slide_description}</p>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {acf.advantages_section.map((edge_advntg4) =>
                                        <div className='ad_apollo_outer_sld'>
                                            {edge_advntg4.strong_leadership_slide.map((sld4) =>
                                                <div className='ad_apollo_inner '>
                                                    <img src={sld4.advntg_slide_icon} className=''></img>
                                                    <h2 className='adv_sld_hd'>{sld4.advntg_slide_name}</h2>
                                                    <p className='adv_sld_para' >{sld4.advntg_slide_description}</p>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {/* {acf.advantages_section.map((edge_advntg5) =>
                                        <div className='ad_apollo_outer_sld'>

                                            {edge_advntg5.robust_countrywide_slide.map((sld5) =>
                                                <div className='ad_apollo_inner '>
                                                    <img src={sld5.advntg_slide_icon} className=''></img>
                                                    <h2 className='adv_sld_hd'>{sld5.advntg_slide_name}</h2>
                                                    <p className='adv_sld_para' >{sld5.advntg_slide_description}</p>
                                                </div>
                                            )}
                                        </div>
                                    )} */}


                                </Slider>

                            </div>

                        </div>
                    ))}
                </div>


            </div>

            <Ae_advantage_mob />

        </>
    )
}