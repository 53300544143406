import React from 'react'
import '../../../assets/css/about_apollo.css'
import $ from "jquery";
import { useEffect, useRef } from 'react';
import { Parallax } from 'swiper';


import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function About_apollo() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const hd_animate = useRef(null);
    const p1_animate = useRef(null);
    const bx1_animate = useRef(null);
    const bx2_animate = useRef(null);
    const bx3_animate = useRef(null);
    const bx4_animate = useRef(null);
    useEffect(() => {

        // Heading text animation code
        // gsap.fromTo(".hd_animate", {
        //     x: 50,
        //     opacity: 0,
        // }, {
        //     delay: 1,
        //     duration: 1,
        //     x: 0,
        //     opacity: 1,
        //     ease: "power4.out",
        //     stagger: {
        //         from: 'start',
        //         amount: 0.5,
        //     },
        //     scrollTrigger: {
        //         trigger: ".hd_animate",
        //         markers: false
        //     }
        // });

        gsap.fromTo(".hd_animate", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".hd_animate",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo("#p1_animate", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: "#p1_animate",
                markers: false
            }
        });

        gsap.fromTo("#bx1_animate", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: "#bx1_animate",
                markers: false
            }
        });

        gsap.fromTo("#bx2_animate", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: "#bx2_animate",
                markers: false
            }
        });

        gsap.fromTo("#bx3_animate", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: "#bx3_animate",
                markers: false
            }
        });
        gsap.fromTo("#bx4_animate", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: "#bx4_animate",
                markers: false
            }
        });

        document.addEventListener("mousemove", parallax);
        function parallax(e) {
            document.querySelectorAll(".layer").forEach(function (move) {

                var moving_value = move.getAttribute("data-speed");
                var x = (e.clientX * moving_value) / 250;
                var y = (e.clientY * moving_value) / 250;

                move.style.transform = "perspective(1000px) translateX(" + x + "px) translateY(" + y + "px)";
            });
        }

    });

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/home_page`
    );


    return (
        <>
            <div className='about_apollo_sec mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>
                    <div className='row'>
                        {data && data.map(({ acf }) => (
                            <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                {acf.home_sec_2.map((sub1) =>
                                    <div className='abt_apl_col1'>

                                        <div className='hd_dv'>
                                            <h2 className=' hd_animate apollo_main_head' ref={hd_animate}>{sub1.hd1}</h2>
                                        </div>
                                        <div className='hd_dv'>
                                            <h2 className='heading hd_animate apollo_heading' ref={hd_animate}>{sub1.hd2}</h2>
                                        </div>
                                        <p ref={p1_animate} id='p1_animate' dangerouslySetInnerHTML={{ __html: sub1.description }}></p>

                                        {/* <p ref={p1_animate} id='p1_animate'>We serve all major industries through freight forwarding, land transport, warehousing, contact logistics, global air and ocean freight with a strong pan-India presence and partnerships established across over 100 countries.</p> */}

                                    </div>
                                )}
                                {acf.home_sec_2.map((sub1) =>
                                    <a href={sub1.button_url} className='btn blue_btn' ref={p1_animate} id='p1_animate' >
                                        <span className='btn_txt'>{sub1.button_name}</span>
                                        <span className='arrow_btn blue_arrow'></span>
                                    </a>
                                )}
                            </div>
                        ))}
                        <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                            <div className='abt_apl_col2'>
                                {data && data.map(({ acf }) => (
                                    <div className='row'>

                                        {/* 1 */}
                                        <div className='col-xl-6 col-md-6 col-12 blnk_col'></div>
                                        {acf.count1.map((count1) =>
                                            <div className='col-xl-6 col-md-6 col-12 abt_apl_box co_box ' ref={bx1_animate} id='bx1_animate'>
                                                <h4 className='abt_apl_hd'>{count1.count_number}</h4>
                                                <h4 className='abt_apl_p'>{count1.count_descb}</h4>
                                                <img className='layer cube_img' data-speed='3' src={count1.count_image}></img>
                                                {/* <img className='layer cube_img' data-speed='3' src={require('../../../assets/img/asc_cube.png')} ></img> */}
                                            </div>
                                        )}

                                        {/* 2 */}
                                        {acf.count2.map((count2) =>
                                            <div className='col-xl-6 col-md-6 col-12 abt_apl_box cr_box ' ref={bx2_animate} id='bx2_animate'>
                                                <h4 className='abt_apl_hd'>{count2.count_number}</h4>
                                                <h4 className='abt_apl_p'>{count2.count_descb}</h4>
                                                <img className='layer circle_img' data-speed='-3' src={count2.count_image}></img>
                                                {/* <img className='layer circle_img' data-speed='-3' src={require('../../../assets/img/asc_polegon.png')} ></img> */}
                                            </div>
                                        )}
                                        <div className='col-xl-6 col-md-6 col-12 blnk_col'></div>

                                        {/* 3 */}
                                        <div className='col-xl-6 col-md-6 col-12 blnk_col'></div>
                                        {acf.count3.map((count3) =>
                                            <div className='col-xl-6 col-md-6 col-12 abt_apl_box cs_box ' ref={bx3_animate} id='bx3_animate'>
                                                <h4 className='abt_apl_hd'>{count3.count_number}</h4>
                                                <h4 className='abt_apl_p'>{count3.count_descb}</h4>
                                                <img className='layer arrow_img' data-speed='3' src={count3.count_image}></img>
                                                {/* <img className='layer arrow_img' data-speed='3' src={require('../../../assets/img/asc_arrow.png')} ></img> */}
                                            </div>
                                        )}

                                        {/* 4 */}
                                        {acf.count4.map((count4) =>
                                            <div className='col-xl-6 col-md-6 col-12 abt_apl_box ' ref={bx4_animate} id='bx4_animate'>
                                                <h4 className='abt_apl_hd'>{count4.count_number}</h4>
                                                <h4 className='abt_apl_p'>{count4.count_descb}</h4>
                                                <img className='layer team_img' data-speed='-3' src={count4.count_image}></img>
                                                {/* <img className='layer team_img' data-speed='-3' src={require('../../../assets/img/asc_team.png')} ></img> */}
                                            </div>
                                        )}
                                        <div className='col-xl-6 col-md-6 col-12 blnk_col'></div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
