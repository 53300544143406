import React from 'react'
import '../../../assets/css/apollo_edge_accordion.css';
import '../../../assets/css/cfs_icd_infra.css'
import $ from "jquery";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';

import useFetch from "react-fetch-hook";
import Apollo_edge_feature_mob from './apollo_edge_feature_mob';

export default function Apollo_edge_accordion() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const ae_acrd_p = useRef(null);
    const ae_acrd_p1 = useRef(null);

    useEffect(() => {

        // Para animation code
        gsap.fromTo(".ae_acrd_p", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ae_acrd_p",
                markers: false
            }
        });
        gsap.fromTo(".ae_acrd_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ae_acrd_p1",
                markers: false
            }
        });

        $(".ae_accord_link").on('click', function () {
            var num = $('.ae_accord_tab .tab-indicator').width();
            var slidenum = num * ($(this).attr("data-rel") - 1);

            $(".tab-indicator").css("left", slidenum);
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/apollo_edge_page`
    );

    return (
        <>
            <div className='apollo_edge_accordion  mob_padding_LR tab_padding_LR ' id='features'>
                <div className='cstm_container'>

                    <div className='desktop_asc_edge_feature'>
                        {data && data.map(({ acf }) => (
                            <div className='row'>
                                <div className='col-12'>

                                    {acf.edge_tab_section.map((edge_tb) =>
                                        <p className='ae_accord_para ae_acrd_p' ref={ae_acrd_p}>{edge_tb.edge_tab_description}</p>
                                    )}

                                    <div className='cfs_tabs ae_accord_tab ae_acrd_p1' ref={ae_acrd_p1}>
                                        <nav>
                                            <div className="nav nav-tabs mb-3 cfs_row_tab" id="nav-tab" role="tablist">

                                                <div class="tab-indicator"></div>

                                                {acf.transport_management_system_tab.map((edge_tb5) =>
                                                    <a className="nav-link active cfs_link ae_accord_link" id="nav-Transport-tab" data-toggle="tab" data-target="#nav-Transport" type="button" role="tab" aria-controls="nav-Transport" aria-selected="true" data-rel="1">
                                                        <div className='cfs_tab_div'>
                                                            <div className='cfs_icon_prt2'>
                                                                <h5 className='cfs_tb_ttl1'>{edge_tb5.edge_tab_name}</h5>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )}

                                                {acf.control_tower_and_analytics_centre_tab.map((edge_tb4) =>
                                                    <a className="nav-link cfs_link ae_accord_link" id="nav-Control-tab" data-toggle="tab" data-target="#nav-Control" type="button" role="tab" aria-controls="nav-Control" aria-selected="true" data-rel="2">
                                                        <div className='cfs_tab_div'>
                                                            <div className='cfs_icon_prt2'>
                                                                <h5 className='cfs_tb_ttl1'>{edge_tb4.edge_tab_name}</h5>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )}

                                                {acf.warehouse_management_system_tab.map((edge_tb2) =>
                                                    <a className="nav-link cfs_link ae_accord_link" id="nav-Management-tab" data-toggle="tab" data-target="#nav-Management" type="button" role="tab" aria-controls="nav-Management" aria-selected="true" data-rel="3">
                                                        <div className='cfs_tab_div'>
                                                            <div className='cfs_icon_prt2'>
                                                                <h5 className='cfs_tb_ttl1'>{edge_tb2.edge_tab_name}</h5>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )}

                                                {acf.data_analytics_and_dashboard_tab.map((edge_tb3) =>
                                                    <a className="nav-link cfs_link ae_accord_link" id="nav-Analytics-tab" data-toggle="tab" data-target="#nav-Analytics" type="button" role="tab" aria-controls="nav-Analytics" aria-selected="true" data-rel="4">
                                                        <div className='cfs_tab_div'>
                                                            <div className='cfs_icon_prt2'>
                                                                <h5 className='cfs_tb_ttl1'>{edge_tb3.edge_tab_name}</h5>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )}

                                                {acf.warehouse_automation_tab.map((edge_tb1) =>
                                                    <a className="nav-link  cfs_link ae_accord_link" id="nav-Automation-tab" data-toggle="tab" data-target="#nav-Automation" type="button" role="tab" aria-controls="nav-Automation" aria-selected="true" data-rel="5">
                                                        <div className='cfs_tab_div'>
                                                            <div className='cfs_icon_prt2'>
                                                                <h5 className='cfs_tb_ttl1'>{edge_tb1.edge_tab_name}</h5>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )}


                                            </div>
                                        </nav>
                                        <div className="tab-content cfs-tab-cntnt" id="nav-tabContent">

                                            {acf.transport_management_system_tab.map((edge_tb5) =>
                                                <div className="tab-pane fade" id="nav-Transport" role="tabpanel" aria-labelledby="nav-Transport-tab">
                                                    <div className='cfs_content_dv'>
                                                        <div className='row'>
                                                            <div className='col-lg-9 col-md-9 col-12'>
                                                                <p className='ae_accord_para1'>{edge_tb5.edge_tab_description1}</p>
                                                            </div>
                                                            <div className='col-lg-3 col-md-3 col-12'>
                                                                <div className='cfs_col2'>
                                                                    <div className='cfs_img_dv ae_acord_img'>
                                                                        <img src={edge_tb5.edge_tab_icon}></img>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {acf.control_tower_and_analytics_centre_tab.map((edge_tb4) =>
                                                <div className="tab-pane fade" id="nav-Control" role="tabpanel" aria-labelledby="nav-Control-tab">
                                                    <div className='cfs_content_dv'>
                                                        <div className='row'>
                                                            <div className='col-lg-9 col-md-9 col-12'>
                                                                <p className='ae_accord_para1'>{edge_tb4.edge_tab_description1}</p>

                                                                <div className='row'>

                                                                    {edge_tb4.edge_tab_list.map((edge_tab_list) =>
                                                                        <div className='col-lg-5 col-md-12 col-12'>
                                                                            <ul className='global_list accord_ul_list'>
                                                                                <li>{edge_tab_list.edge_tab_list_data}</li>
                                                                            </ul>
                                                                        </div>
                                                                    )}
                                                                    {/* <div className='col-lg-5 col-md-12 col-12'>
                                                                    <ul className='global_list accord_ul_list'>
                                                                        <li>Improvement in KPIs and service levels </li>
                                                                    </ul>
                                                                </div>
                                                                <div className='col-lg-5 col-md-12 col-12'>
                                                                    <ul className='global_list accord_ul_list'>
                                                                        <li>Inventory optimization</li>
                                                                    </ul>
                                                                </div>
                                                                <div className='col-lg-5 col-md-12 col-12'>
                                                                    <ul className='global_list accord_ul_list'>
                                                                        <li>Improved forecasting</li>
                                                                    </ul>
                                                                </div>
                                                                <div className='col-lg-5 col-md-12 col-12'>
                                                                    <ul className='global_list accord_ul_list'>
                                                                        <li>Network load optimization</li>
                                                                    </ul>
                                                                </div> */}

                                                                </div>

                                                            </div>
                                                            <div className='col-lg-3 col-md-3 col-12'>
                                                                <div className='cfs_col2'>
                                                                    <div className='cfs_img_dv ae_acord_img'>
                                                                        <img src={edge_tb4.edge_tab_icon}></img>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {acf.warehouse_management_system_tab.map((edge_tb2) =>
                                                <div className="tab-pane fade" id="nav-Management" role="tabpanel" aria-labelledby="nav-Management-tab">
                                                    <div className='cfs_content_dv'>
                                                        <div className='row'>
                                                            <div className='col-lg-9 col-md-9 col-12'>
                                                                <p className='ae_accord_para1'>{edge_tb2.edge_tab_description1}</p>

                                                            </div>
                                                            <div className='col-lg-3 col-md-3 col-12'>
                                                                <div className='cfs_col2'>
                                                                    <div className='cfs_img_dv ae_acord_img'>
                                                                        <img src={edge_tb2.edge_tab_icon}></img>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {acf.data_analytics_and_dashboard_tab.map((edge_tb3) =>
                                                <div className="tab-pane fade" id="nav-Analytics" role="tabpanel" aria-labelledby="nav-Analytics-tab">
                                                    <div className='cfs_content_dv'>
                                                        <div className='row'>
                                                            <div className='col-lg-9 col-md-9 col-12'>
                                                                <p className='ae_accord_para1'>{edge_tb3.edge_tab_description1}</p>

                                                            </div>
                                                            <div className='col-lg-3 col-md-3 col-12'>
                                                                <div className='cfs_col2'>
                                                                    <div className='cfs_img_dv ae_acord_img'>
                                                                        <img src={edge_tb3.edge_tab_icon}></img>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {acf.warehouse_automation_tab.map((edge_tb1) =>
                                                <div className="tab-pane fade active show" id="nav-Automation" role="tabpanel" aria-labelledby="nav-Automation-tab">
                                                    <div className='cfs_content_dv'>
                                                        <div className='row'>

                                                            <div className='col-lg-9 col-md-9 col-12'>
                                                                <p className='ae_accord_para1'>{edge_tb1.edge_tab_description1}</p>
                                                            </div>
                                                            <div className='col-lg-3 col-md-3 col-12'>
                                                                <div className='cfs_col2'>
                                                                    <div className='cfs_img_dv ae_acord_img'>
                                                                        <img src={edge_tb1.edge_tab_icon}></img>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>


                                </div>
                            </div>
                        ))}
                    </div>

                    <Apollo_edge_feature_mob />
                </div>
            </div>


        </>
    )
}
