import React from 'react'
import Header from "../component/global/header";
import Home_spot from '../component/spotlight/home_spotlight';
import About_apollo from '../component/section/home/about_apollo';
import Apollo_edge from "../component/global/apollo_edge";
import Solutions_offer from '../component/section/home/solutions_offer';
import Key_sector from '../component/section/home/key_sector';
import Case_studies from '../component/section/home/case_studies';
import Our_network from '../component/section/home/our_network';
import Client from '../component/section/home/client';
import News from '../component/section/home/news';
import Footer from "../component/global/footer";
// import CustomGlobe from "../CustomGlobe";

import Transition from '../component/transition';
import { gsap } from "gsap";
import { useEffect, useRef } from 'react';
import { Power3, Power4 } from "gsap";

import $ from 'jquery';
import Sustainability from '../component/section/about/Sustainability';

export default function Home_page() {

  let body = useRef(null);
  var tl_1 = gsap.timeline()

  useEffect(() => {

    // Page Transition Code

    tl_1.to(body, {
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut,
      delay: 1.2
    });
    return () => {
      tl_1.to(body, {
        opacity: "0",
        pointerEvents: 'none'
      });
    }

  })


  return (
    <>
      <Transition />
      <div className='home_pg'>
        <div ref={(el) => (body = el)} className="Headd">
          <Header />
          <Home_spot />
          <About_apollo />
          <Apollo_edge />
          <Solutions_offer />
          <Key_sector />
          <Case_studies />
          {/* <Our_network /> */}
          {/* <Client /> */}
          {/* <Sustainability/> */}
          <News />
          <Footer />
        </div>
      </div>

    </>
  )
}
