import React from 'react'
import '../../../assets/css/contact_dtls.css'
import Contact_form from '../../section/contact/contact_form';
import Office_dtls from '../../section/contact/office_dtls';

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Contact_dtls() {


    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const cnt_lst1 = useRef(null);


    useEffect(() => {

        // Para animation code
        gsap.fromTo(".cnt_lst1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 1.5,
            scrollTrigger: {
                trigger: ".cnt_lst1",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/contact_us_page/`
    );

    return (
        <>
            <div className='contact_dtls  mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-12 cd_col1'>

                                <ul className='contact_ul cnt_lst1' ref={cnt_lst1}>

                                    {acf.corporate_office_address_section.map((ofc_sec1) =>
                                        <li>
                                            <span>{ofc_sec1.corporate_office_hd}</span>
                                            <p>{ofc_sec1.corporate_office_address}</p>
                                        </li>
                                    )}
                                    {acf.email_section.map((ofc_sec2) =>
                                        <li>
                                            <span>{ofc_sec2.email_hd}</span>
                                            <a href='mailto:connect@apollosupplychain.com'>{ofc_sec2.email_data}</a>
                                        </li>
                                    )}
                                    {acf.phone_section.map((ofc_sec3) =>
                                        <li >
                                            <span>{ofc_sec3.phone_hd}</span>
                                            <a href='tel:0124 674 0200'>{ofc_sec3.phone_number}</a>
                                        </li>
                                    )}
                                </ul>
                                <ul className='social_ul cnt_lst1' ref={cnt_lst1}>

                                    {acf.social_links_section.map((ofc_sec4) =>
                                        <li>
                                            <a href={ofc_sec4.social_url}>
                                                <img src={ofc_sec4.social_icon}></img>
                                            </a>
                                        </li>
                                    )}
                                    {/* <li>
                                        <a href='#'>
                                            <img src={require('../../../assets/img/svg/fb_icon.svg').default}></img>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#'>
                                            <img src={require('../../../assets/img/svg/mailbox_icon.svg').default}></img>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='https://www.linkedin.com/company/apollo-logisolutions-ltd/'>
                                            <img src={require('../../../assets/img/svg/linkedin_icon1.svg').default}></img>
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12 cd_col2 map_col cnt_lst1' ref={cnt_lst1}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112256.61263132417!2d77.0075082305076!3d28.448839269188834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1895311baa79%3A0xa2ccd7239fa340e3!2sApollo%20Logisolutions%20Ltd%20(Apollo%20Tower)!5e0!3m2!1sen!2sin!4v1681812768995!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                {/* {acf.office_map_section.map((ofc_sec5) =>
                                    <div dangerouslySetInnerHTML={{ __html: ofc_sec5.office_map }}> </div>
                                )} */}
                            </div>
                        </div>
                    ))}

                    <Office_dtls />
                    <Contact_form />

                </div>
            </div>
        </>
    )
}
