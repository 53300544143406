import React from 'react'
import '../../../assets/css/openings_list.css';

import useFetch from "react-fetch-hook";

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";



export default function Openings_list() {


    const { isLoading, data, error } = useFetch(
        "https://apollosupplychain.com/cms/wp-json/wp/v2/current_openings/"
    );


    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const opn_list = useRef(null);

    useEffect(() => {

        // Para animation code
        gsap.fromTo(".opn_list", {
          opacity: 0,
        }, {
          opacity:1,
          duration: 0.3,
          ease: "linear",
          delay: 1.8,
          scrollTrigger: {
            trigger: ".opn_list",
            markers: false
          }
        });

    })

  

    return (
        <>
            <div className='openings_list mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>
                    <div className='row opn_list' ref={opn_list}>

                        {data && data.map(({ id, title, acf, slug }) => (
                            <div className='col-lg-4 col-md-6 col-12' key={id}>
                                <div className='co_list_col'>
                                    <h3 className='co_list_ttl' dangerouslySetInnerHTML={{ __html: title.rendered }}></h3>
                                    <p className='co_dtl'><b>Location : &nbsp;&nbsp;</b>{acf.location}</p>
                                    {/* <p className='co_dtl'><b>No of openings : &nbsp;&nbsp;</b>{acf.number_of_opening}</p>
                                    <p className='co_dtl'><b>Level : &nbsp;&nbsp;</b>{acf.level}</p>
                                    <p className='co_dtl'><b>CTC : &nbsp;&nbsp;</b>{acf.ctc}</p> */}
                                    <a href={"/careers/current-openings/" + slug} className='btn blue_btn co_list_btn'>
                                        <span className='btn_txt'>View details</span>
                                        <span className='arrow_btn blue_arrow'></span>
                                    </a>
                                </div>
                            </div>

                        ))}

                    </div>
                </div>
            </div>
        </>
    )
}
