import React from 'react'
import '../../../assets/css/solutions_offer_mob.css';
import useFetch from "react-fetch-hook";

export default function Apollo_edge_feature_mob() {

  const { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/apollo_edge_page`
  );

  return (
    <>
      <div className='mob_asc_edge_feature'>

        {data && data.map(({ acf }) => (

          <div>

            <div className='row'>
              <div className='col-12'>
                {acf.edge_tab_section.map((edge_tb) =>
                  <p className='ae_accord_para '>{edge_tb.edge_tab_description}</p>
                )}
              </div>
            </div>

            <div id="accordion_feature" className="accordion_solution">

              {/* ASE Edge Accord One */}
              {acf.warehouse_automation_tab.map((edge_tb1) =>
                <div className="card">
                  <div className="card-header">
                    <button className="card-link" data-toggle="collapse" data-target="#collapseOne">
                      <span className="solution_name">
                        {edge_tb1.edge_tab_name}
                      </span>
                      <span className="status_icon">
                        <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                        <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                      </span>
                    </button>
                  </div>
                  <div id="collapseOne" className="collapse show" data-parent="#accordion_feature">
                    <div className="card-body">
                      <div className='warehouse_content'>

                        <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: edge_tb1.edge_tab_description1 }}></p>
                        <div className='f_img'>
                          <img src={edge_tb1.edge_tab_icon}></img>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* ASE Edge Accord Two */}
              {acf.warehouse_management_system_tab.map((edge_tb2) =>
                <div className="card">
                  <div className="card-header">
                    <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseTwo">
                      <span className="solution_name">
                        {edge_tb2.edge_tab_name}
                      </span>
                      <span className="status_icon">
                        <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                        <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                      </span>
                    </button>
                  </div>
                  <div id="collapseTwo" className="collapse" data-parent="#accordion_feature">
                    <div className="card-body">
                      <div className='warehouse_content'>

                        <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: edge_tb2.edge_tab_description1 }}></p>
                        <div className='f_img'>
                          <img src={edge_tb2.edge_tab_icon}></img>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* ASE Edge Accord Three */}
              {acf.data_analytics_and_dashboard_tab.map((edge_tb3) =>
                <div className="card">
                  <div className="card-header">
                    <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseThree">
                      <span className="solution_name">
                        {edge_tb3.edge_tab_name}
                      </span>
                      <span className="status_icon">
                        <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                        <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                      </span>
                    </button>
                  </div>
                  <div id="collapseThree" className="collapse" data-parent="#accordion_feature">
                    <div className="card-body">
                      <div className='warehouse_content'>

                        <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: edge_tb3.edge_tab_description1 }}></p>
                        <div className='f_img'>
                          <img src={edge_tb3.edge_tab_icon}></img>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* ASE Edge Accord Four */}
              {acf.control_tower_and_analytics_centre_tab.map((edge_tb4) =>
                <div className="card">
                  <div className="card-header">
                    <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseFour">
                      <span className="solution_name">
                        {edge_tb4.edge_tab_name}
                      </span>
                      <span className="status_icon">
                        <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                        <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                      </span>
                    </button>
                  </div>
                  <div id="collapseFour" className="collapse" data-parent="#accordion_feature">
                    <div className="card-body">
                      <div className='warehouse_content'>

                        <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: edge_tb4.edge_tab_description1 }}></p>
                        <div className='f_img'>
                          <img src={edge_tb4.edge_tab_icon}></img>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* ASE Edge Accord Five */}
              {acf.transport_management_system_tab.map((edge_tb5) =>
                <div className="card">
                  <div className="card-header">
                    <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseFive">
                      <span className="solution_name">
                        {edge_tb5.edge_tab_name}
                      </span>
                      <span className="status_icon">
                        <img className="sol_plus_icon" src={require('../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                        <img className="sol_minus_icon" src={require('../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                      </span>
                    </button>
                  </div>
                  <div id="collapseFive" className="collapse" data-parent="#accordion_feature">
                    <div className="card-body">
                      <div className='warehouse_content'>


                        <p className='sol_cnt_desc' dangerouslySetInnerHTML={{ __html: edge_tb5.edge_tab_description1 }}></p>
                        <div className='f_img'>
                          <img src={edge_tb5.edge_tab_icon}></img>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              )}

            </div>

          </div>
        ))}
      </div>
    </>
  )
}
