import React, { useRef, useState } from "react";
import '../../../assets/css/news.css';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, EffectCreative, EffectFade, Mousewheel } from "swiper";
import useFetch from "react-fetch-hook";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/effect-creative";
import 'swiper/css/effect-fade';


import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from 'react';

export default function News() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const nws_sld = useRef(null);
    const nws_hd = useRef(null);

    useEffect(() => {

        gsap.fromTo(".nws_hd", {
            opacity: 0,
        }, {
            delay: 0.5,
            duration: 0.8,
            opacity: 1,
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".nws_hd",
                markers: false
            }
        });

        gsap.fromTo(".nws_sld", {
            x: 1160,
        }, {
            delay: 0.2,
            duration: 0.8,
            x: 0,
            scrollTrigger: {
                trigger: ".nws_sld",
                markers: false
            }
        });

    });

    const { isLoading, data, error } = useFetch(
        "https://apollosupplychain.com/cms/wp-json/wp/v2/news/"
    );


    return (
        <>
            <div className='news_sec mob_padding_LR tab_padding_LR'>
                <div className='news_container'>
                    <div className='row m_0'>
                        <div className='col-xl-4 col-lg-4 col-md-12 col-12'>
                            <div className='news_col1'>
                                <h4 className='news_ttl nws_hd' ref={nws_hd}>In The News</h4>
                            </div>
                        </div>
                        <div className='col-xl-8 col-lg-8 col-md-12 col-12 news_row_col2'>
                            <div className='news_col2 nws_sld' ref={nws_sld}>

                                <Swiper
                                    direction={"vertical"}
                                    slidesPerView={3}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                                            direction: "horizontal"
                                        },
                                        651: {
                                            slidesPerView: 2,
                                            direction: "horizontal"
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            direction: "horizontal"
                                        },
                                        1024: {
                                            slidesPerView: 3
                                        },
                                        1280: {
                                            slidesPerView: 3
                                        },
                                    }}
                                    spaceBetween={15}
                                    navigation={true}
                                    modules={[Navigation]}
                                    className="news_slider mySwiper sldr_crl_bnt"
                                >

                                    {data && data.map(({ id, title, acf, slug }) => (
                                        <SwiperSlide>
                                            <div className="news_slider_dv" key={id}>
                                                <a href={acf.url} target='_blank'>
                                                    <div className="news_img_dv">
                                                        <img src={acf.media_image}></img>
                                                    </div>
                                                    <div className="news_content">
                                                        <div>
                                                            <h4 dangerouslySetInnerHTML={{ __html: title.rendered }} className="news_sub_ttl"></h4>
                                                            <h4 className="news_date">{acf.date}</h4>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </SwiperSlide>

                                    ))}
                                    {/* <SwiperSlide>
                                        <div className="news_slider_dv">
                                            <a href="#">
                                                <div className="news_img_dv">
                                                    <img src={require('../../../assets/img/news_img2.jpg')}></img>
                                                </div>
                                                <div className="news_content">
                                                    <div>
                                                        <p className="news_sub_ttl">Warehousing were evaluated for a smooth, stress-free import/export program</p>
                                                        <p className="news_date">23 MAR 2022</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="news_slider_dv">
                                            <a href="#">
                                                <div className="news_img_dv">
                                                    <img src={require('../../../assets/img/news_img3.jpg')}></img>
                                                </div>
                                                <div className="news_content">
                                                    <div>
                                                        <p className="news_sub_ttl">Warehousing were evaluated for a smooth, stress-free import/export program</p>
                                                        <p className="news_date">23 MAR 2022</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="news_slider_dv">
                                            <a href="#">
                                                <div className="news_img_dv">
                                                    <img src={require('../../../assets/img/news_img1.jpg')}></img>
                                                </div>
                                                <div className="news_content">
                                                    <div>
                                                        <p className="news_sub_ttl">Warehousing were evaluated for a smooth, stress-free import/export program</p>
                                                        <p className="news_date">23 MAR 2022</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="news_slider_dv">
                                            <a href="#">
                                                <div className="news_img_dv">
                                                    <img src={require('../../../assets/img/news_img2.jpg')}></img>
                                                </div>
                                                <div className="news_content">
                                                    <div>
                                                        <p className="news_sub_ttl">Warehousing were evaluated for a smooth, stress-free import/export program</p>
                                                        <p className="news_date">23 MAR 2022</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </SwiperSlide> */}

                                </Swiper>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
