import React from 'react'
import Header from "../../component/global/header";
import Application_form_spotlight from '../../component/spotlight/application_form_spotlight'; 
import Application_form from "../../component/section/careers/application_form";
import Footer from "../../component/global/footer";


import Transition from '../../component/transition';
import { gsap } from "gsap";
import { useEffect , useRef } from 'react';
import { Power3, Power4 } from "gsap";






export default function Application_form_page() {

  let body = useRef(null);
  var tl_1 = gsap.timeline()

  useEffect(() => {

    // Page Transition Code

    tl_1.to(body,{
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut,
      delay: 1.2
    });
    return () => {
      tl_1.to(body, {
        opacity: "0",
        pointerEvents: 'none'
      });
    }

  })

  return (
    <>
      <Transition/>

      <div className='career_pg'>
          <div ref={(el) => (body = el)} className="Headd">

            <Header/>
            <Application_form_spotlight/>
            <Application_form/>
            <Footer/>

          </div>
      </div>
    </>
  )
}
