import React from 'react'
import '../../../../assets/css/cfs_icd_infra.css';
import useFetch from "react-fetch-hook";
import $ from "jquery";
import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Mobile_cfs_accord() {

        // gloabl line GSAP
        gsap.registerPlugin(ScrollTrigger);

        // Heading text animation variable
        const cfs_hd = useRef(null);
        const cfs_p = useRef(null);
        const cfs_p1 = useRef(null);
        const cfs_p2 = useRef(null);
        const cfs_p3 = useRef(null);
        const cfs_p4 = useRef(null);
    
    
        useEffect(() => {
    
            gsap.fromTo(".cfs_hd", {
                y: 250,
                opacity: 0,
                skewY: 41,
            }, {
                delay: 0.5,
                duration: 0.8,
                y: 0,
                skewY: 0,
                opacity: 1,
                ease: "power4.out",
                stagger: {
                    from: 'start',
                    amount: 0.5,
                },
                scrollTrigger: {
                    trigger: ".cfs_hd",
                    markers: false
                }
            });
    
            // Para animation code
            gsap.fromTo(".cfs_p", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 0.6,
                scrollTrigger: {
                    trigger: ".cfs_p",
                    markers: false
                }
            });
            // Para animation code
            gsap.fromTo(".cfs_p1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.4,
                ease: "linear",
                delay: 0.6,
                scrollTrigger: {
                    trigger: ".cfs_p1",
                    markers: false
                }
            });
            gsap.fromTo(".cfs_p2", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.4,
                ease: "linear",
                delay: 0.7,
                scrollTrigger: {
                    trigger: ".cfs_p2",
                    markers: false
                }
            });
            gsap.fromTo(".cfs_p3", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.4,
                ease: "linear",
                delay: 0.8,
                scrollTrigger: {
                    trigger: ".cfs_p3",
                    markers: false
                }
            });
            gsap.fromTo(".cfs_p4", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.4,
                ease: "linear",
                delay: 0.8,
                scrollTrigger: {
                    trigger: ".cfs_p4",
                    markers: false
                }
            });
    
        })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/cfs_and_icd_page/`
    );

    return (
        <>
            {data && data.map(({ acf }) => (
                <div className=''>

                    <div className='hd_dv' style={{ margin: 'auto' }}>
                        {acf.cfs_and_icd_tab_section.map((tab_cntn) =>
                            <h2 className='heading wht_txt cfs_hd' ref={cfs_hd}>{tab_cntn.cfs_and_icd_hd}</h2>
                        )}
                    </div>

                    <div id="accordion_cfs_mob" className="accordion_solution cfs_p cfs_icd_mob_sec" ref={cfs_p}>

                        {/* CFS Accord One */}
                        {acf.cfs_panvel.map((pnvl) =>
                            <div className="card">
                                <div className="card-header">
                                    <button className="card-link" data-toggle="collapse" data-target="#collapseOne">
                                        <div className='cfs_tab_div'>
                                            <div className='cfs_icon_prt1'>
                                                <img src={pnvl.cfs_tab_icon}></img>
                                            </div>
                                            <div className='cfs_icon_prt2'>
                                                <h5 className='cfs_tb_ttl1'>{pnvl.cfs_tab_name}</h5>
                                                <p className='cfs_state_nm'>{pnvl.cfs_tab_state}</p>
                                            </div>
                                        </div>
                                        <span className="status_icon">
                                            <img className="sol_plus_icon" src={require('../../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                            <img className="sol_minus_icon" src={require('../../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                        </span>
                                    </button>
                                </div>
                                <div id="collapseOne" className="collapse show" data-parent="#accordion_cfs_mob">
                                    <div className="card-body">
                                        <div className='warehouse_content'>
                                            <p className='cfs_ctnt_hd'>{pnvl.cfs_tab_description}</p>
                                            <h3 className='cfs_list_hd'>{pnvl.cfs_tab_highlight_hd}</h3>
                                            <ul className='global_list cfs_ul_list'>
                                                {pnvl.cfs_tab_highlight_list.map((hg_lst) =>
                                                    <li>{hg_lst.cfs_tab_hl_data}</li>
                                                )}
                                            </ul>
                                            <div className='cfs_col2'>
                                                <div className='cfs_img_dv'>
                                                    <img src={pnvl.cfs_tab_image}></img>
                                                </div>
                                                <div className='csf_img_txt'>
                                                    <h5 className='csf_img_txt_hd'>{pnvl.cfs_tab_image_hd}</h5>
                                                    <p>{pnvl.cfs_tab_image_description}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row cfs_box_section'>
                                            <div className='col-12'>
                                                <div className='result_box ' >
                                                    <div className='rslt_inner'>
                                                        <img src={require('../../../../assets/img/svg/import.svg').default}></img>
                                                        <p className='rslt_ttl'>Import</p>
                                                        <p class="rslt_bx_p">See the document here</p>
                                                        <a href='https://apollosupplychain.com//cms/wp-content/uploads/2023/03/Tariffs_Imports_Pune.pdf' className='pdf_link' target='_blank'>Click here</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='result_box '>
                                                    <div className='rslt_inner'>
                                                        <img src={require('../../../../assets/img/svg/Export.svg').default}></img>
                                                        <p className='rslt_ttl'>Export</p>
                                                        <p class="rslt_bx_p">See the document here</p>
                                                        <a href='https://apollosupplychain.com/cms/wp-content/uploads/2023/03/Tariffs_Expor_Pune.pdf' className='pdf_link' target='_blank'>Click here</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='result_box' >
                                                    <div className='rslt_inner'>
                                                        <img src={require('../../../../assets/img/svg/General.svg').default}></img>
                                                        <p className='rslt_ttl'>General</p>
                                                        <p class="rslt_bx_p">See the document here</p>
                                                        <a href='https://apollosupplychain.com/cms/wp-content/uploads/2023/03/Tariffs_General_Pune.pdf' className='pdf_link' target='_blank'>Click here</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* CFS Accord Two */}
                        {acf.cfs_chennai.map((cheni) =>
                            <div className="card">
                                <div className="card-header">
                                    <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseTwo">
                                        <div className='cfs_tab_div'>
                                            <div className='cfs_icon_prt1'>
                                                <img src={cheni.cfs_tab_icon}></img>
                                            </div>
                                            <div className='cfs_icon_prt2'>
                                                <h5 className='cfs_tb_ttl1'>{cheni.cfs_tab_name}</h5>
                                                <p className='cfs_state_nm'>{cheni.cfs_tab_state}</p>
                                            </div>
                                        </div>
                                        <span className="status_icon">
                                            <img className="sol_plus_icon" src={require('../../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                            <img className="sol_minus_icon" src={require('../../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                        </span>
                                    </button>
                                </div>
                                <div id="collapseTwo" className="collapse" data-parent="#accordion_cfs_mob">
                                    <div className="card-body">
                                        <div className='warehouse_content'>
                                            <p className='cfs_ctnt_hd'>{cheni.cfs_tab_description}</p>
                                            <h3 className='cfs_list_hd'>{cheni.cfs_tab_highlight_hd}</h3>
                                            <ul className='global_list cfs_ul_list'>
                                                {cheni.cfs_tab_highlight_list.map((hg_lst) =>
                                                    <li>{hg_lst.cfs_tab_hl_data}</li>
                                                )}
                                            </ul>
                                            <div className='cfs_col2'>
                                                <div className='cfs_img_dv'>
                                                    <img src={cheni.cfs_tab_image}></img>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row cfs_box_section'>
                                            <div className='col-lg-4 col-md-4 col-12'>
                                                <div className='result_box ' >
                                                    <div className='rslt_inner'>
                                                        <img src={require('../../../../assets/img/svg/import.svg').default}></img>
                                                        <p className='rslt_ttl'>Import</p>
                                                        <p class="rslt_bx_p">See the document here</p>
                                                        <a href='https://apollosupplychain.com/cms/wp-content/uploads/2023/03/Import-Gen-Tariff-chennai.pdf' className='pdf_link' target='_blank'>Click here</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-12'>
                                                <div className='result_box '>
                                                    <div className='rslt_inner'>
                                                        <img src={require('../../../../assets/img/svg/Export.svg').default}></img>
                                                        <p className='rslt_ttl'>Export</p>
                                                        <p class="rslt_bx_p">See the document here</p>
                                                        <a href='https://apollosupplychain.com/cms/wp-content/uploads/2023/03/Export-General-Tariff-chennai.pdf' className='pdf_link' target='_blank'>Click here</a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>                

                                    </div>
                                </div>
                            </div>
                        )}

                        {/* CFS Accord Three */}
                        {acf.cfs_thoothukudi.map((thoot) =>
                            <div className="card">
                                <div className="card-header">
                                    <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseThree">
                                        <div className='cfs_tab_div'>
                                            <div className='cfs_icon_prt1'>
                                                <img src={thoot.cfs_tab_icon}></img>
                                            </div>
                                            <div className='cfs_icon_prt2'>
                                                <h5 className='cfs_tb_ttl1'>{thoot.cfs_tab_name}</h5>
                                                <p className='cfs_state_nm'>{thoot.cfs_tab_state}</p>
                                            </div>
                                        </div>
                                        <span className="status_icon">
                                            <img className="sol_plus_icon" src={require('../../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                            <img className="sol_minus_icon" src={require('../../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                        </span>
                                    </button>
                                </div>
                                <div id="collapseThree" className="collapse" data-parent="#accordion_cfs_mob">
                                    <div className="card-body">
                                        <div className='warehouse_content'>
                                            <p className='cfs_ctnt_hd'>{thoot.cfs_tab_description}</p>
                                            <h3 className='cfs_list_hd'>{thoot.cfs_tab_highlight_hd}</h3>
                                            <ul className='global_list cfs_ul_list'>
                                                {thoot.cfs_tab_highlight_list.map((hg_lst) =>
                                                    <li>{hg_lst.cfs_tab_hl_data}</li>
                                                )}
                                            </ul>
                                            <div className='cfs_col2'>
                                                <div className='cfs_img_dv'>
                                                    <img src={thoot.cfs_tab_image}></img>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row cfs_box_section'>
                                            <div className='col-lg-4 col-md-4 col-12'>
                                                <div className='result_box ' >
                                                    <div className='rslt_inner'>
                                                        <img src={require('../../../../assets/img/svg/import.svg').default}></img>
                                                        <p className='rslt_ttl'>Import</p>
                                                        <p class="rslt_bx_p">See the document here</p>
                                                        <a href='https://apollosupplychain.com/cms/wp-content/uploads/2023/03/Tariffs_Import_Thoothukudi.pdf' className='pdf_link' target='_blank'>Click here</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-12'>
                                                <div className='result_box '>
                                                    <div className='rslt_inner'>
                                                        <img src={require('../../../../assets/img/svg/Export.svg').default}></img>
                                                        <p className='rslt_ttl'>Export</p>
                                                        <p class="rslt_bx_p">See the document here</p>
                                                        <a href='https://apollosupplychain.com/cms/wp-content/uploads/2023/03/Tariffs_Export_Thoothukudi.pdf' className='pdf_link' target='_blank'>Click here</a>
                                                    </div>
                                                </div>
                                            </div>
                                            

                                        </div>                

                                    </div>
                                </div>
                            </div>
                        )}

                        {/* CFS Accord Four */}
                        {/* {acf.icd_kashipur.map((kashi) =>
                            <div className="card">
                                <div className="card-header">
                                    <button className="collapsed card-link" data-toggle="collapse" data-target="#collapseFour">
                                        <div className='cfs_tab_div'>
                                            <div className='cfs_icon_prt1'>
                                                <img src={kashi.cfs_tab_icon}></img>
                                            </div>
                                            <div className='cfs_icon_prt2'>
                                                <h5 className='cfs_tb_ttl1'>{kashi.icd_tab_name}</h5>
                                                <p className='cfs_state_nm'>{kashi.icd_tab_state}</p>
                                            </div>
                                        </div>
                                        <span className="status_icon">
                                            <img className="sol_plus_icon" src={require('../../../../assets/img/svg/sol_accord_plus.svg').default}></img>
                                            <img className="sol_minus_icon" src={require('../../../../assets/img/svg/sol_accord_minus.svg').default}></img>
                                        </span>
                                    </button>
                                </div>
                                <div id="collapseFour" className="collapse" data-parent="#accordion_cfs_mob">
                                    <div className="card-body">
                                        <div className='warehouse_content'>
                                            <p className='cfs_ctnt_hd'>{kashi.icd_tab_description}</p>
                                            <h3 className='cfs_list_hd'>{kashi.icd_tab_highlight_hd}</h3>
                                            <ul className='global_list cfs_ul_list'>
                                                {kashi.icd_tab_highlight_list.map((hg_lst) =>
                                                    <li>{hg_lst.cfs_tab_hl_data}</li>
                                                )}
                                            </ul>
                                            <div className='cfs_col2'>
                                                <div className='cfs_img_dv'>
                                                    <img src={kashi.icd_tab_image}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )} */}

                    </div>

                </div>
            ))}
        </>
    )
}
