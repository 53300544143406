import React from 'react'
import '../../../../assets/css/service_offer_ware.css';

import { useEffect, useRef } from 'react';
import $ from 'jquery';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Service_offer_ware() {


    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const srvc_ofr_hd = useRef(null);
    const srvc_ofr_p1 = useRef(null);
    const srvc_ofr_p2 = useRef(null);
    const srvc_ofr_p3 = useRef(null);
    const srvc_ofr_p4 = useRef(null);
    const srvc_ofr_p5 = useRef(null);
    const srvc_ofr_img1 = useRef(null);
    const srvc_ofr_img2 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".srvc_ofr_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".srvc_ofr_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".srvc_ofr_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".srvc_ofr_p1",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".srvc_ofr_p2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".srvc_ofr_p2",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".srvc_ofr_p3", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".srvc_ofr_p3",
                markers: false
            }
        });

        gsap.fromTo(".srvc_ofr_p4", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".srvc_ofr_p4",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".srvc_ofr_p5", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".srvc_ofr_p5",
                markers: false
            }
        });


        gsap.fromTo(".srvc_ofr_img1", {
            x: 0
        }, {
            x: 550,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".srvc_ofr_img1",
                markers: false
            }
        });

        gsap.fromTo(".srvc_ofr_img2", {
            x: 0
        }, {
            x: 550,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".srvc_ofr_img2",
                markers: false
            }
        });

        $(".dedicated_content").hide();
        $(".dedicated").on("click", function () {
            var txt = $(".dedicated_content").is(':visible') ? 'Read More' : 'Read Less';
            $(".dedicated").text(txt);
            $('.dedicated_content').slideToggle(200);
        });
        $(".dedicated_link").on("click", function () {
            var txt = $(".dedicated_content").is(':visible') ? 'Read Less' : 'Read Less';
            $(".dedicated").text(txt);
            $('.dedicated_content').slideDown(200);
        });


        $(".multi_content").hide();
        $(".multi_btn").on("click", function () {
            var txt = $(".multi_content").is(':visible') ? 'Read More' : 'Read Less';
            $(".multi_btn").text(txt);
            $('.multi_content').slideToggle(200);
        });
        $(".multi_link").on("click", function () {
            var txt = $(".multi_content").is(':visible') ? 'Read Less' : 'Read Less';
            $(".multi_btn").text(txt);
            $('.multi_content').slideDown(200);
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/warehousing_page`
    );

    return (
        <>
            <div className='service_offer SO_ware mob_padding_LR tab_padding_LR' id='dedicated-warehousing'>

                {data && data.map(({ acf }) => (
                    <div className='cstm_container'>
                        <div className='row'>

                            {acf.service_offerings.map((ware_offer) =>
                                <div className='col-12'>

                                    <h2 className='heading wht_txt srvc_ofr_p1' ref={srvc_ofr_p1}>{ware_offer.service_offering_hd}</h2>
                                    <p className='so_sub_ttl srvc_ofr_p1' ref={srvc_ofr_p1}>{ware_offer.service_offering_description}</p>

                                </div>
                            )}

                        </div>
                        <div className='row so_row_2 warehouse_bdr dedicate_row'>
                            <div className='col-lg-7 col-md-6 col-12'>

                                {acf.dedicated_warehouse_.map((ware_offer1) =>
                                    <div className='so_col1_multi'>
                                        {/* <img className='so_col_img' src={require('../../../../assets/img/dedicated_warehousing.jpg')}></img> */}
                                        <p className='so_col_hd srvc_ofr_p2' ref={srvc_ofr_p2}>{ware_offer1.dedicated_warehouse_hd}</p>
                                        <p className='so_col_desc dw_boldhead srvc_ofr_p3' ref={srvc_ofr_p3}>{ware_offer1.dedicated_warehouse_sub_hd}</p>

                                        <p className=' so_col_desc srvc_ofr_p3' ref={srvc_ofr_p3}>{ware_offer1.dedicated_warehouse_description}</p>
                                        {/* <a className="dedicated srvc_ofr_p3 read_more_btn" data-content="toggle-text" ref={srvc_ofr_p3}>Read More</a> */}
                                    </div>
                                )}

                            </div>
                            <div className='col-lg-5 col-md-6 col-12'>
                                <div className='so_col1'>

                                    {acf.dedicated_warehouse_.map((ware_offer1) =>
                                        <div className='img_dv'>
                                            <div className='img_oly blue_oly srvc_ofr_img1' ref={srvc_ofr_img1}></div>
                                            <img className='so_col_img_multi' src={ware_offer1.dedicated_warehouse_image}></img>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='row so_row_2 ' id='multi-User-facilities'>
                            <div className='col-lg-5 col-md-6 col-12'>
                                <div className='so_col1 pl_0'>

                                    {acf.multi_user_facilities.map((ware_multi1) =>
                                        <div className='img_dv'>
                                            <div className='img_oly blue_oly srvc_ofr_img2' ref={srvc_ofr_img2}></div>
                                            <img className='so_col_img_multi' src={ware_multi1.multi_user_fac_image}></img>
                                        </div>
                                    )}

                                </div>
                            </div>
                            <div className='col-lg-7 col-md-6 col-12'>

                                {acf.multi_user_facilities.map((ware_multi1) =>
                                    <div className='so_col1_multi multi_box multi_inner_col'>
                                        {/* <img className='so_col_img' src={require('../../../../assets/img/dedicated_warehousing.jpg')}></img> */}
                                        <p className='so_col_hd srvc_ofr_p4' ref={srvc_ofr_p4}>{ware_multi1.multi_user_fac_hd}</p>
                                        <p className='so_col_desc dw_boldhead srvc_ofr_p5' ref={srvc_ofr_p5}>{ware_multi1.multi_user_fac_sub_hd}</p>
                                        <p className=' so_col_desc srvc_ofr_p5' ref={srvc_ofr_p5}>{ware_multi1.multi_user_fac_read_more_text}</p>
                                        {/* <a className="multi_btn srvc_ofr_p3 read_more_btn" data-content="toggle-text" ref={srvc_ofr_p3}>Read More</a> */}

                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
