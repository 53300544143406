import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import React, { useState, useRef } from 'react';
import $ from "jquery";
import './App.css';
import emailjs from 'emailjs-com';
import ScrollToTop from './component/ScrollToTop';

import Home_page from "./pages/home_page";
import About_page from "./pages/about_page";
import Warehousing_page from "./pages/warehousing_page";
import Infrastructure_page from "./pages/infrastructure_page";
import Supply_chain_page from "./pages/supply_chain_page";
import Controltower_page from "./pages/controltower_page";
import Freight_forwarding_page from "./pages/freight_forwarding_page";
import Land_transport_page from "./pages/land_transport_page";
import Blog_Inside_page from "./pages/blog_inside_page";
import Event_Inside_page from "./pages/event_inside_page";
import Press_release_inside_page from "./pages/press_release_inside_page";
import Blog_page from "./pages/blog_page";
import Event_page from "./pages/event_page";
import Press_release_page from "./pages/press_release_page";
import Application_form_page from "./pages/careers/application_form_page";
import Current_opening_page from "./pages/careers/current_opening_page";
import Current_opngs_inside_page from './pages/careers/current_opngs_inside_page';
import Life_at_asc_page from "./pages/careers/life_at_asc_page";
import Cstmr_durables_page from './pages/sector/cstmr_durables_page';
import Automotive_page from './pages/sector/automotive_page';
import Ecom_page from './pages/sector/ecom_page';
import Pharma_page from './pages/sector/pharma_page';
import Lifestyle_page from './pages/sector/lifestyle_page';
import Contact_page from './pages/contact_page';
import Apollo_edge_page from './pages/apollo_edge_page';
import Search_page from "./pages/search_page";
import Leadership_page from './pages/leadership_page';
import Privacy_page from "./pages/privacy_page";
import Term_page from "./pages/term_page";
import { Alert } from 'bootstrap';
import ReCAPTCHA from "react-google-recaptcha";

import '../src/assets/css/backtop_btn.css';

function App() {

  useEffect(() => {


    // Search Page hide & show code
    $('.search_box').on("click", function (e) {
      $('.scrollbar-track.scrollbar-track-y').css('display', 'none');
      $('.search_div').css('z-index', '999');
      $('.search_div').css('opacity', '1');
    });
    $('.cls-btn').on("click", function (e) {
      $('.scrollbar-track.scrollbar-track-y').css('display', 'block');
      $('.search_div').css('z-index', '-2');
      $('.search_div').css('opacity', '0');
    });


    // Global Enquire Form hide & show code
    $('.enq_sticky_btn').on("click", function (e) {
      $('.enquire_from_div').css('transform', 'translateX(0%)');
      $('.enquire_overlay').fadeIn();
      $('body').css('overflow', 'hidden');
    });

    $('.cta_btn').on("click", function (e) {
      $('.enquire_from_div').css('transform', 'translateX(0%)');
      $('.enquire_overlay').fadeIn();
      $('body').css('overflow', 'hidden');
    });
    $('.enq_close_btn').on("click", function (e) {
      $('.enquire_from_div').css('transform', 'translateX(101%)');
      $('.enquire_overlay').fadeOut();
      $('body').css('overflow-y', 'auto');
    });
    // $('.enquire_overlay').on("click", function (e) {
    //   $('.enquire_from_div').css('transform', 'translateX(101%)');
    //   $('.enquire_overlay').fadeOut();
    //   $('body').css('overflow-y', 'auto');
    // });


    if (window.location.href.includes('#consumer_case_study')) {
      let animationTime = 100;
      $('html, body').animate({
        scrollTop: $('#consumer_case_study').offset().top
      }, animationTime);
    }

    if (window.location.href.includes('#automotive_case_study')) {
      let animationTime = 100;
      $('html, body').animate({
        scrollTop: $('#automotive_case_study').offset().top
      }, animationTime);
    }

    if (window.location.href.includes('#ecom_case_study')) {
      $('html, body').animate({
        scrollTop: $('#ecom_case_study').offset().top
      }, 100);
    }

    if (window.location.href.includes('#pharmaceuticals')) {
      let animationTime = 100;
      $('html, body').animate({
        scrollTop: $('#pharmaceuticals').offset().top
      }, animationTime);
    }

    if (window.location.href.includes('#lifestyle_retail')) {
      let animationTime = 100;
      $('html, body').animate({
        scrollTop: $('#lifestyle_retail').offset().top
      }, animationTime);
    }

    if (window.location.href.includes('#technologies_for_you')) {
      let animationTime = 100;
      $('html, body').animate({
        scrollTop: $('#technologies_for_you').offset().top
      }, animationTime);
    }

    if (window.location.href.includes('#advantages')) {
      let animationTime = 100;
      $('html, body').animate({
        scrollTop: $('#advantages').offset().top
      }, animationTime);
    }



    if (window.location.href.includes('#features')) {
      let animationTime = 100;
      $('html, body').animate({
        scrollTop: $('#features').offset().top
      }, animationTime);
    }

    if (window.location.href.includes('#at_a_glance')) {
      let animationTime = 100;
      $('html, body').animate({
        scrollTop: $('#at_a_glance').offset().top
      }, animationTime);
    }

    if (window.location.href.includes('#inplantyard')) {
      let animationTime = 100;
      $('html, body').animate({
        scrollTop: $('#inplantyard').offset().top
      }, animationTime);
    }


    if (window.location.href.includes('#ocean_freight')) {
      let animationTime = 100;
      $('html, body').animate({
        scrollTop: $('#ocean_freight').offset().top
      }, animationTime);
    }


    if (window.location.href.includes('#air_freight')) {
      let animationTime = 100;
      $('html, body').animate({
        scrollTop: $('#air_freight').offset().top
      }, animationTime);
    }


  })


  const form = useRef();
  const [loading, setLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useState("");

  const nameinput = React.useRef(null);
  const emailinput = React.useRef(null);

  const orginput = React.useRef(null);
  const phoneinput = React.useRef(null);

  const departinput = React.useRef(null);
  const msginput = React.useRef(null);
  const captchaRef = useRef(null);

  // All field validation error hide code 
  const handleFocus1_1 = e => {
    e.target.classList.remove("error_line");

    let er1_1 = document.getElementById("nm_err1");
    er1_1.classList.remove("show_error");

    let er2_1 = document.getElementById("eml_err2");
    er2_1.classList.remove("show_error");

    let er2v_1 = document.getElementById("eml_err11");
    er2v_1.classList.remove("show_error");

    let er3_1 = document.getElementById("cmp_err1");
    er3_1.classList.remove("show_error");

    let er5_1 = document.getElementById("depart_err1");
    er5_1.classList.remove("show_error");

    let er6_1 = document.getElementById("msg_err1");
    er6_1.classList.remove("show_error");

  };
  // Phone field validation error hide code    
  const handleFocus1_2 = e => {
    e.target.classList.remove("error_line");

    let er4_1 = document.getElementById("phn_err2");
    er4_1.classList.remove("show_error");

    let er4v_1 = document.getElementById("phn_err11");
    er4v_1.classList.remove("show_error");
  };


  // Form action code
  const sendEmail1 = async (e) => {
    e.preventDefault();

    // Fields get value Code
    let name_fld = (nameinput.current.value);
    let email_fld = (emailinput.current.value);

    let org_fld = (orginput.current.value);
    let phone_fld = (phoneinput.current.value);

    let dprt_fld = (departinput.current.value);
    let msg_fld = (msginput.current.value);
    let token = captchaRef.current.getValue();

    // Phone Regex Code
    let phone_fltr = document.getElementById("phone_fld2");
    var mob_regx = /^([+][9][1]|[9][1]|[0]){0,1}([7-9]{1})([0-9]{9})$/;
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


    // Fields validation Code
    if (name_fld == '') {
      let v1 = document.getElementById("name_fld1");
      v1.className += " error_line";

      let er1 = document.getElementById("nm_err1");
      er1.className += " show_error";
    }
    else if (email_fld == '') {
      let v2 = document.getElementById("email_fld2");
      v2.className += " error_line";

      let er2 = document.getElementById("eml_err2");
      er2.className += " show_error";
    }
    else if (!email_fld.match(mailformat)) {
      let v2 = document.getElementById("email_fld2");
      v2.className += " error_line";

      let er2v = document.getElementById("eml_err11");
      er2v.className += " show_error";
    }
    else if (phone_fld == '') {
      let v4 = document.getElementById("phone_fld2");
      v4.className += " error_line";

      let er4 = document.getElementById("phn_err2");
      er4.className += " show_error";
    }

    else if (!mob_regx.test(phone_fltr.value)) {
      let v4 = document.getElementById("phone_fld2");
      v4.className += " error_line";

      let er4 = document.getElementById("phn_err11");
      er4.className += " show_error";
    }
    else if (org_fld == '') {
      let v3 = document.getElementById("org_fld1");
      v3.className += " error_line";

      let er3 = document.getElementById("cmp_err1");
      er3.className += " show_error";
    }
    else if (dprt_fld == '') {
      let v5 = document.getElementById("dprt_fld1");
      v5.className += " error_line";

      let er5 = document.getElementById("depart_err1");
      er5.className += " show_error";
    }
    else if (msg_fld == '') {
      let v6 = document.getElementById("msg_fld1");
      v6.className += " error_line";

      let er6 = document.getElementById("msg_err1");
      er6.className += " show_error";
    }
    else if(token == '') {
        console.log("You must confirm you are not a robot");
        let recap_er = document.getElementById("recap_err1");
        recap_er.className += " show_error";
    }
    else {

      let recap_er = document.getElementById("recap_err1");
      recap_er.classList.remove("show_error");

      fetch('https://script.google.com/macros/s/AKfycbzoVEFjn2l3Ce5JxVDMOW9nMMqm8YyvXjXWGQQMx7pUo0JrI3pWX438X9JDJUnfxmB7/exec', {
        method: 'POST',
        body: new FormData(form.current),

      }).then(res => {
        console.log("SUCCESSFULLY SUBMITTED")
        setLoading(false)
      })
        .catch(err => console.log(err))

      emailjs.sendForm('service_l2n43ts', 'template_ms2e7iq', e.target, 'SsPYHKCapw4h-xBn_')
        .then((result) => {

          console.log(result.text);
          setStatusMessage("Your Message has been sent successfully");
          let msg = document.getElementById("thnk_msg");
          msg.className += " thnk_msg_show";

          let frm_bx = document.getElementById("connect_form_bx");
          frm_bx.className += " cont_form_bx_hide";

        }, (error) => {

          console.log(error.text);
          setStatusMessage(`${error.text} happened`);

        });
      e.target.reset()

    }


  };


  $(document).ready(function () {


    // back to Top button code
    var btn = $('#backToTop');
    $(window).on('scroll', function() {
        if ($(window).scrollTop() > 300) {
            btn.addClass('show');
        } else {
            btn.removeClass('show');
        }
    });
    btn.on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, '300');
    });
    // back to Top button code



    var url1 = window.location.pathname;
    console.log(url1);


    if (url1 === '/') {
      let pg_name1 = 'home';
      $('#page_name1').val(pg_name1);
    }
    if (url1 === '/about-us') {
      let pg_name1 = 'about-us';
      $('#page_name1').val(pg_name1);
    }
    if (url1 === '/about-us/leadership') {
      let pg_name1 = 'leadership';
      $('#page_name1').val(pg_name1);
    }
    if (url1 === '/blog') {
      let pg_name1 = 'blog';
      $('#page_name1').val(pg_name1);
    }
    if (url1 === '/news_and_events') {
      let pg_name1 = 'news_and_events';
      $('#page_name1').val(pg_name1);
    }
    if (url1 === '/careers/life-at-ASC') {
      let pg_name1 = 'life-at-ASC';
      $('#page_name1').val(pg_name1);
    }
    if (url1 === '/careers/current-openings') {
      let pg_name1 = 'current-openings';
      $('#page_name1').val(pg_name1);
    }
    if (url1 === '/contact') {
      let pg_name1 = 'contact';
      $('#page_name1').val(pg_name1);
    }


    if (url1 === '/warehousing') {
      let pg_name1 = 'warehousing';
      $('#page_name1').val(pg_name1);
    }
    if (url1 === '/surface-transport') {
      let pg_name1 = 'surface-transport';
      $('#page_name1').val(pg_name1);
    }
    if (url1 === '/freight-forwarding') {
      let pg_name1 = 'freight-forwarding';
      $('#page_name1').val(pg_name1);
    }
    if (url1 === '/CFS-&-ICDs') {
      let pg_name1 = 'CFS-&-ICDs';
      $('#page_name1').val(pg_name1);
    }
    if (url1 === '/supply-chain-consulting') {
      let pg_name1 = 'supply-chain-consulting';
      $('#page_name1').val(pg_name1);
    }
    if (url1 === '/control-tower-&-analytics-center') {
      let pg_name1 = 'control-tower-&-analytics-center';
      $('#page_name1').val(pg_name1);
    }


    if (url1 === '/sector/consumer-durables-high-tech-industries') {
      let pg_name1 = 'consumer-durables-high-tech-industries';
      $('#page_name1').val(pg_name1);
    }
    if (url1 === '/sector/automotive-&-industrial-sector') {
      let pg_name1 = 'automotive-&-industrial-sector';
      $('#page_name1').val(pg_name1);
    }
    if (url1 === '/sector/e-commerce-&-FMCG') {
      let pg_name1 = 'e-commerce-&-FMCG';
      $('#page_name1').val(pg_name1);
    }
    if (url1 === '/sector/other-sector') {
      let pg_name1 = 'other-sector';
      $('#page_name1').val(pg_name1);
    }

    if (url1 === '/apollo-edge') {
      let pg_name1 = 'apollo-edge';
      $('#page_name1').val(pg_name1);
    }

  });


  const resultinput = React.useRef(null);

  const sendResult = (e) => {
    e.preventDefault();

    let resultinput_var = (resultinput.current.value);
    window.location.href = "/search/" + resultinput_var;
  };

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />

        <Routes>
          <Route exact path="/" element={<Home_page />} />
          <Route exact path="/about-us" element={<About_page />} />
          <Route exact path="/warehousing" element={<Warehousing_page />} />
          <Route exact path="/CFS" element={<Infrastructure_page />} />
          <Route exact path="/supply-chain-consulting" element={<Supply_chain_page />} />
          <Route exact path="/control-tower-&-analytics-center" element={<Controltower_page />} />
          <Route exact path="/freight-forwarding" element={<Freight_forwarding_page />} />
          <Route exact path="/media-center/blog/:slug" element={<Blog_Inside_page />} />
          <Route exact path="/media-center/event/:slug" element={<Event_Inside_page />} />
          <Route exact path="/media-center/press-release/:slug" element={<Press_release_inside_page />} />
          <Route exact path="/blog" element={<Blog_page />} />
          <Route exact path="/news_and_events" element={<Event_page />} />
          <Route exact path="/press-release" element={<Press_release_page />} />
          <Route exact path="/careers/application-form" element={<Application_form_page />} />
          <Route exact path="/careers/current-openings" element={<Current_opening_page />} />
          <Route exact path="/careers/current-openings/:slug" element={<Current_opngs_inside_page />} />
          <Route exact path="/careers/life-at-ASC" element={<Life_at_asc_page />} />
          <Route exact path="/sector/consumer-durables-high-tech-industries" element={<Cstmr_durables_page />} />
          <Route exact path="/sector/e-commerce-&-FMCG" element={<Ecom_page />} />
          <Route exact path="/sector/automotive-&-industrial-sector" element={<Automotive_page />} />
          <Route exact path="/sector/pharmaceuticals" element={<Pharma_page />} />
          <Route exact path="/sector/lifestyle-&-retail" element={<Lifestyle_page />} />

          <Route exact path="/careers/application-form" element={<Application_form_page />} />
          <Route exact path="/surface-transport" element={<Land_transport_page />} />

          <Route exact path="/contact" element={<Contact_page />} />
          <Route exact path="/apollo-edge" element={<Apollo_edge_page />} />
          <Route exact path='/about-us/leadership' element={<Leadership_page />} />

          <Route path="/search/:name" element={<Search_page />} />
          <Route path="/privacy-policy" element={<Privacy_page />} />
          <Route path="/disclaimer" element={<Term_page />} />

        </Routes>

      </BrowserRouter>



      {/*----------------------  BackToTop Button */}
      <a href="javascript:void(0);" id="backToTop" class="back-to-top">
          <i class="arrow"></i><i class="arrow"></i>
      </a>



      {/* Search box Overlay     */}
      <div className='search_div'>
        <div className='cls-btn'>
          <span className='line1'></span>
          <span className='line2'></span>
        </div>
        <div className='row srch-row'>
          <div className='col-12'>

            <form className='srch_form' onSubmit={sendResult}>
              <input className='srch-input' type='text' placeholder='' ref={resultinput} />
              <button type="submit" className='srch-btn'>
                Search
                {/* <img src={require('./assets/img/svg/search_icon_blk.svg').default}></img> */}
              </button>
            </form>

          </div>
        </div>
      </div>


      {/*---------------------- Connect Now Button  */}
      <a className="enq_sticky_btn">Connect Now <img src={require('./assets/img/svg/edit_icon.svg').default}></img></a>

      {/* global connect form div */}
      <div className='enquire_overlay'></div>
      <div className='enquire_from_div'>
        <img className='enq_close_btn' src={require('./assets/img/svg/close_icon_wht.svg').default}></img>
        <h4 className='heading wht_txt'>Connect with us</h4>

        <form className='enquire_from' ref={form} onSubmit={sendEmail1} id='connect_form_bx'>

          <input className='page_name' type='hidden' name='page_name' id='page_name1' />
          <div className='row'>
            <div className='col-md-12 col-12'>
              <div className='frm_inr_dv'>
                <input className='input_fld' type='text' placeholder='Name' name='name' id='name_fld1' ref={nameinput} onFocus={handleFocus1_1} />
                <p className='error_msg' id='nm_err1'>Please Enter Name</p>
              </div>
            </div>
            <div className='col-md-12 col-12'>
              <div className='frm_inr_dv'>
                <input className='input_fld' type='email' placeholder='Email Address' name='email' id='email_fld2' ref={emailinput} onFocus={handleFocus1_1} />
                <p className='error_msg' id='eml_err2'>Please Enter Email Address</p>
                <p className='error_msg' id='eml_err11'>Please Enter Valid Email Address</p>
              </div>
            </div>
            <div className='col-md-12 col-12'>
              <div className='frm_inr_dv'>
                <input className='input_fld' type='text' placeholder='Mobile No' name='phone' id='phone_fld2' ref={phoneinput} onFocus={handleFocus1_2} />
                <p className='error_msg' id='phn_err2'>Please Enter Phone No</p>
                <p className='error_msg' id='phn_err11'>Please Enter Valid Phone No</p>
              </div>
            </div>
            <div className='col-md-12 col-12'>
              <div className='frm_inr_dv'>
                <input className='input_fld' type='text' placeholder='Company' name='company' id='org_fld1' ref={orginput} onFocus={handleFocus1_1} />
                <p className='error_msg' id='cmp_err1'>Please Enter Organisation</p>
              </div>
            </div>
            <div className='col-12'>
              <div className='frm_inr_dv'>
                <select className='input_fld' name='service' id='dprt_fld1' ref={departinput} onFocus={handleFocus1_1} >
                  <option value="" disabled selected>Select Service</option>
                  <option value='Warehousing'>Warehousing</option>
                  <option value='Surface Transport'>Surface Transport</option>
                  <option value='Freight Forwarding'>Freight Forwarding</option>
                  <option value='CFS (Infra)'>CFS (Infra)</option>
                  <option value='Supply Chain Consulting'>Supply Chain Consulting</option>
                  <option value='Control Tower & Analytics Center'>Control Tower & Analytics Center</option>
                  <option value='others'>Others</option>
                </select>
                <p className='error_msg' id='depart_err1'>Please Select Service</p>
              </div>
            </div>
            <div className='col-12'>
              <div className='frm_inr_dv'>
                <textarea className='input_fld' placeholder='Tell Us More' name='message' id='msg_fld1' ref={msginput} onFocus={handleFocus1_1}></textarea>
                <p className='error_msg' id='msg_err1'>Please Enter Message</p>
              </div>
            </div>

            <div className='col-12'>
              <ReCAPTCHA sitekey="6LfInfMmAAAAACNp9E7DaElr2uxDJ_sF8jvI6XzW" ref={captchaRef} />
              <div className='frm_inr_dv recap_div'>
                  <p className='error_msg' id='recap_err1'>Please Check reCAPTCHA</p>
              </div>
              <br></br>
            </div>

            <div className='col-12'>
              <span className='btn blue_btn input_btn'>
                <input className='btn_txt' type='submit' name='submit' value='submit'></input>
                <span className='arrow_btn wht_arrow'></span>
              </span>
              
            </div>
          </div>
        </form>

        <p className='form_thanks_msg wht_txt connect_form_msg' id='thnk_msg'>Your form has been sent successfully</p>

      </div>




    </>
  );
}

export default App;
