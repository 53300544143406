import React from 'react'
import Header from "../component/global/header";
import Warehousing_spotlight from '../component/spotlight/warehousing_spotlight'; 
import Counter_ware from '../component/section/services/warehousing/counter_ware';
import Info_ware from '../component/section/services/warehousing/info_ware';
import Service_offer_ware from '../component/section/services/warehousing/service_offer_ware';
import Plant_warehouse from '../component/section/services/warehousing/plant_warehouse';
import Asc_advance_ware from '../component/section/services/warehousing/asc_advance_ware';
import Solution_center from '../component/section/services/warehousing/solution_center';
import Apollo_edge_service from '../component/section/services/apollo_edge_service';
import Customised_servc_ware from '../component/section/services/warehousing/customised_servc_ware';
import Connect_service from '../component/section/services/connect_service';

import Footer from "../component/global/footer";
// import CustomGlobe from "../CustomGlobe";

import Transition from '../component/transition';
import { gsap } from "gsap";
import { useEffect , useRef } from 'react';
import { Power3, Power4 } from "gsap";

export default function Warehousing_page() {

  let body = useRef(null);
  var tl_1 = gsap.timeline()

  useEffect(() => {

    // Page Transition Code

    tl_1.to(body,{
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut,
      delay: 1.2
    });
    return () => {
      tl_1.to(body, {
        opacity: "0",
        pointerEvents: 'none'
      });
    }

  })


  return (
    <>
      <Transition/>

      <div className='warehouse_pg srvc_pg'>
          <div ref={(el) => (body = el)} className="Headd">

            <Header/>
            <Warehousing_spotlight/>
            <Counter_ware/>
            <Info_ware/>
            <Service_offer_ware/>
            <Plant_warehouse/>
            <Asc_advance_ware/>
            <Solution_center/>
       
            <Customised_servc_ware/>
            <Apollo_edge_service/>
            <Connect_service/>
            <Footer/>
          </div>
      </div>
    </>
  )
}
