import React from 'react'
import '../../../assets/css/sustainability.css';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import $ from 'jquery';

import useFetch from "react-fetch-hook";

export default function Sustainability() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const sustain_hd = useRef(null);
    const sustain_p = useRef(null);
    const sustain_ad1 = useRef(null);
    const sustain_ad2 = useRef(null);
    const sustain_ad3 = useRef(null);
    const sustain_ad_p = useRef(null);

    useEffect(() => {

        gsap.fromTo(".sustain_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".sustain_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".sustain_p", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            delay: 0.2,
            ease: "linear",
            scrollTrigger: {
                trigger: ".sustain_p",
                markers: false
            }
        });

        gsap.fromTo(".sustain_ad_p", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            delay: 0.2,
            ease: "linear",
            scrollTrigger: {
                trigger: ".sustain_ad_p",
                markers: false
            }
        });
        gsap.fromTo(".sustain_ad1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.2,
            delay: 0.2,
            scrollTrigger: {
                trigger: ".sustain_ad1",
                markers: false
            }
        });
        gsap.fromTo(".sustain_ad2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.2,
            delay: 0.2,
            scrollTrigger: {
                trigger: ".sustain_ad2",
                markers: false
            }
        });
        gsap.fromTo(".sustain_ad3", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.2,
            delay: 0.2,
            scrollTrigger: {
                trigger: ".sustain_ad3",
                markers: false
            }
        });

        $('.sustain_ad1').on('click', function () {
            $('.sustain_sec').addClass('tab1_img');
            $('.sustain_sec').removeClass('tab2_img');
            $('.sustain_sec').removeClass('tab3_img');
        });

        $('.sustain_ad2').on('click', function () {
            $('.sustain_sec').removeClass('tab1_img');
            $('.sustain_sec').addClass('tab2_img');
            $('.sustain_sec').removeClass('tab3_img');
        });

        $('.sustain_ad3').on('click', function () {
            $('.sustain_sec').removeClass('tab1_img');
            $('.sustain_sec').removeClass('tab2_img');
            $('.sustain_sec').addClass('tab3_img');
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/about_page`
    );

    return (
        <>
            {/* <div className='sustain_sec mob_padding_LR tab_padding_LR tab1_img' id='why-us'>
                <div className='cstm_container'>
                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-12'>
                                <div className='sustain_col1'>

                                    {acf.why_sec.map((why_sub) =>
                                        <div className="hd_dv">
                                            <h2 className='heading wht_txt sustain_hd' ref={sustain_hd}>{why_sub.why_hd}</h2>
                                        </div>
                                    )}
                                    {acf.why_sec.map((why_sub) =>
                                        <p className='wht_txt why_us_subhead sustain_p' ref={sustain_p}>{why_sub.why_description}</p>
                                    )}
                                    <div className='tabs_dv'>
                                        <nav>
                                            <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">

                                                {acf.why_strong_network.map((why_sub1) =>
                                                    <a className="nav-link active tabs_link sustain_ad1" ref={sustain_ad1} id="nav-home-tab" data-toggle="tab" data-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">{why_sub1.why_accrd_hd}</a>
                                                )}
                                                {acf.why_technology.map((why_sub2) =>
                                                    <a className="nav-link tabs_link tab_link2 sustain_ad2" ref={sustain_ad2} id="nav-profile-tab" data-toggle="tab" data-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">{why_sub2.why_accrd_hd}</a>
                                                )}
                                                {acf.why_quality.map((why_sub3) =>
                                                    <a className="nav-link tabs_link sustain_ad3" ref={sustain_ad3} id="nav-contact-tab" data-toggle="tab" data-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">{why_sub3.why_accrd_hd}</a>
                                                )}
                                            </div>
                                        </nav>
                                        <div className="tab-content csmt_tab_dv" id="nav-tabContent">

                                            {acf.why_strong_network.map((why_sub1) =>
                                                <div className="tab-pane fade active show sustain_ad_p" ref={sustain_ad_p} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                    <h3 className='wht_txt tab_ttl'>{why_sub1.why_accrd_hd}</h3>
                                                    <p className="wht_txt">{why_sub1.why_acrd_description}</p>
                                                </div>
                                            )}
                                            {acf.why_technology.map((why_sub2) =>
                                                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                    <h3 className='wht_txt tab_ttl'>{why_sub2.why_accrd_hd}</h3>
                                                    <p className="wht_txt">{why_sub2.why_acrd_description}</p>
                                                </div>
                                            )}
                                            {acf.why_quality.map((why_sub3) =>
                                                <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                                    <h3 className='wht_txt tab_ttl'>{why_sub3.why_accrd_hd}</h3>
                                                    <p className="wht_txt">{why_sub3.why_acrd_description}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div> */}

            <div className='sustain_sec mob_padding_LR tab_padding_LR tab1_img' id='esg'>
                <div className='cstm_container'>
                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-12'>
                                <div className='sustain_col1'>


                                    <div className="hd_dv">
                                        <h2 className='heading sustain_hd' ref={sustain_hd}>Creating Value With ESG</h2>
                                    </div>

                                    {/* <p className='so_sub_ttl wht_txt sustain_sub_p sustain_p' ref={sustain_p}>Creating Value With ESG</p> */}
                                    <p className='sustain_p' ref={sustain_p}>ESG (Environment, Social, and Governance) is more than a corporate philosophy to us. It is an integral part of how we do business. As an organization, we are committed to creating a strong ESG proposition through our relationships and engagement with partners, customers, employees, leaders, communities, and the environment.</p>

                                    <div className='tabs_dv'>
                                        <nav>
                                            <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">

                                                <a className="esg_tab nav-link active tabs_link sustain_ad1" ref={sustain_ad1} id="nav-home-tab" data-toggle="tab" data-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Environmental</a>

                                                <a className="esg_tab nav-link tabs_link tab_link2 sustain_ad2" ref={sustain_ad2} id="nav-profile-tab" data-toggle="tab" data-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Social</a>

                                                <a className="esg_tab nav-link tabs_link sustain_ad3" ref={sustain_ad3} id="nav-contact-tab" data-toggle="tab" data-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Governance</a>

                                            </div>
                                        </nav>
                                        <div className="tab-content csmt_tab_dv" id="nav-tabContent">


                                            <div className="tab-pane fade active show sustain_ad_p" ref={sustain_ad_p} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                <p className='sustain_tab_para'>The Environmental pillar of our ESG policy comprises the following aims and objectives:</p>
                                                <ul className='global_list'>
                                                    <li className=''>Decarbonization of Supply Chains</li>
                                                    <li className=''>Reduction of Greenhouse Gas (GHG) Emissions</li>
                                                    <li className=''>Sustainable Transportation Solutions</li>
                                                    <li className=''>Enablement of EV (Electric Vehicles) Solutions</li>
                                                    <li className=''>Transition Toward Renewable Energy Sources</li>
                                                    <li className=''>Sustainable Packaging Solutions</li>
                                                    <li className=''>Fully Compliant with ISO and HSSE (Health, Safety, Security and Environment) Norms</li>
                                                </ul>
                                            </div>


                                            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                <p className='sustain_tab_para'>The Social pillar of our ESG policy comprises the following aims and objectives:</p>
                                                <ul className='global_list'>
                                                    <li className=''>Diversity & Inclusion</li>
                                                    <li className=''>A Nurturing Work Environment</li>
                                                    <li className=''>Positive and Healthy Employee Relations</li>
                                                    <li className=''>Empowering Community Relations</li>
                                                    <li className=''>Respect for Human Rights</li>
                                                </ul>
                                            </div>


                                            <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                                <p className='sustain_tab_para'>The Governance pillar of our ESG policy comprises the following aims and objectives:</p>
                                                <ul className='global_list'>
                                                    <li className=''>Sound Risk Management Policies & Execution Strategy</li>
                                                    <li className=''>Executive Compensation Aligned with Shareholder Interest</li>
                                                    <li className=''>Industry-Best Reporting and Disclosure Practices</li>
                                                    <li className=''>A Growth and Stability-Oriented Board Structure</li>
                                                    <li className=''>Strong Cybersecurity Policies and Practices</li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </>
    )
}
