import React from 'react'
import '../../../../assets/css/service_offer_ware.css';

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Network_design() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const net_hd = useRef(null);
    const net_p1 = useRef(null);
    const net_img1 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".net_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".net_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".net_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".net_p1",
                markers: false
            }
        });

        gsap.fromTo(".net_img1", {
            x: 0
        }, {
            xPercent: 101,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".net_img1",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/supply_chain_page/`
    );


    return (
        <>
            <div className='Network_offer SO_ware mob_padding_LR tab_padding_LR' id='network_design_re-design'>
             {data && data.map(({ acf }) => (
                <div className='cstm_container'>

                    <div className='row'>
                        <div className='col-lg-5 col-md-6 col-12'>
                            <div className='so_col1_network'>
                                <div className='img_dv'>
                                    <div className='img_oly net_img1' ref={net_img1}></div>
                                    {acf.network_section.map((netwrk_cnt) =>
                                        <img className='so_col_img consulting_img' src={netwrk_cnt.network_image}></img>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-6 col-12 '>
                            <div className='design_ttl'>

                                <div className='hd_dv'>
                                    {acf.network_section.map((netwrk_cnt) =>
                                        <h2 className='heading supply_head net_hd' ref={net_hd}>{netwrk_cnt.network_hd}</h2>
                                    )}
                                </div>
                                {acf.network_section.map((netwrk_cnt) =>                                 
                                    <p className='so_col_desc network_para net_p1' ref={net_p1}>{netwrk_cnt.network_description}</p>
                                )}
                            </div>
                        </div>
                    </div>




                </div>
             ))}    
            </div>
        </>
    )
}
