import React from 'react'
import $ from "jquery";
import '../../../../assets/css/info_ware.css';

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Infrastructure_sec() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const infra_in_p = useRef(null);
    const infra_in_p1 = useRef(null);

    useEffect(() => {


        if ($(window).width() <= 1600) {
            // Para animation code
            gsap.fromTo(".infra_in_p", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".infra_in_p",
                    markers: false
                }
            });
        }
        else {
            
            // Para animation code
            gsap.fromTo(".infra_in_p", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 2.5,
                scrollTrigger: {
                    trigger: ".infra_in_p",
                    markers: false
                }
            });
        }


        // Para animation code
        gsap.fromTo(".infra_in_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".infra_in_p1",
                markers: false
            }
        });

        $.fn.isInViewport = function () {
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight() / 2;
            var viewportTop = $(window).scrollTop();
            var viewportHalf = viewportTop + $(window).height() / 1;
            return elementBottom > viewportTop && elementTop < viewportHalf;
        };


        $(window).on("scroll", function () {

            $(".img_scroll_dv").each(function () {
                var windowTop = $(window).scrollTop();
                var elementTop = $(this).offset().top;
                var leftPosition = windowTop - elementTop;
                $(this)
                    .find(".inside_dv_img")
                    .css({ right: leftPosition });
            });

        });

    });

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/cfs_and_icd_page/`
    );

    return (
        <>
            <div className='info_ware_sec cfs_main_sec'>
            {data && data.map(({ acf }) => (

                <div className='row m_0'>

                    <div className='cstm_container col-md-12 col-12 p_0 mob_padding_LR tab_padding_LR'>
                        <div className='col-lg-12 p_0'>
                            <div className='info_ware_col1'>
                            {acf.cfs_and_icd_description1.map((infra_cntn2) =>
                                <p className='info_para_cust infra_paras infra_in_p' ref={infra_in_p}> {infra_cntn2.cfs_description}</p>
                            )}
                            </div>
                        </div>
                    </div>

                    <div className='col-12 p_0'>
                        <div className='img_scroll_dv'>
                            <div className='inside_dv_img'>

                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_4.jpg')}></img>

                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_4.jpg')}></img>

                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_4.jpg')}></img>


                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_4.jpg')}></img>

                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_4.jpg')}></img>

                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/infrastructure-for-you/about/abt_img_4.jpg')}></img>
                            </div>
                        </div>
                    </div>

                    
                    

                    <div className='cstm_container  mob_padding_LR tab_padding_LR'>
                        <div className='row'>
                           

                            <div className='col-lg-6 col-md-6 col-12 '>
                                {acf.cfs_and_icd_transport_info.map((infra_cntn2) =>
                                    <div className='info_ware_col_one infra_in_p1' dangerouslySetInnerHTML={{ __html: infra_cntn2.cfs_and_icd_description_left }} ref={infra_in_p1}>
                                    </div>
                                )} 
                            </div>

                            <div className='col-lg-6 col-md-6 col-12'>
                                {acf.cfs_and_icd_transport_info.map((infra_cntn2) =>
                                    <div className='info_ware_col_two infra_in_p1' dangerouslySetInnerHTML={{ __html: infra_cntn2.cfs_and_icd_description_right }} ref={infra_in_p1}>
                                    </div>
                                 )} 
                            </div>

                        </div>
                    </div>



                </div>
              ))} 
            </div>
        </>
    )
}
