import React from 'react'
import '../../assets/css/inside_page_spotlight.css'

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Infrastructure_spotlight() {

  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const lnd_spot_hd = useRef(null);
  const lnd_spt_p = useRef(null);
  const lnd_spt_ad1 = useRef(null);
  const lnd_spt_ad2 = useRef(null);
  const lnd_spt_ad3 = useRef(null);
  const lnd_spt_ad4 = useRef(null);
  const lnd_spt_ad5 = useRef(null);

  useEffect(() => {

    gsap.fromTo(".lnd_spot_hd", {
      y: 250,
      opacity: 0,
      skewY: 41,
    }, {
      delay: 2,
      duration: 0.8,
      y: 0,
      skewY: 0,
      opacity: 1,
      ease: "power4.out",
      stagger: {
        from: 'start',
        amount: 0.5,
      },
      scrollTrigger: {
        trigger: ".lnd_spot_hd",
        markers: false
      }
    });

    gsap.fromTo(".lnd_spt_p", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.5,
      scrollTrigger: {
        trigger: ".lnd_spt_p",
        markers: false
      }
    });

    // Para animation code
    gsap.fromTo(".lnd_spt_ad1", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.5,
      scrollTrigger: {
        trigger: ".lnd_spt_ad1",
        markers: false
      }
    });
    gsap.fromTo(".lnd_spt_ad2", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.7,
      scrollTrigger: {
        trigger: ".lnd_spt_ad2",
        markers: false
      }
    });
    gsap.fromTo(".lnd_spt_ad3", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.9,
      scrollTrigger: {
        trigger: ".lnd_spt_ad3",
        markers: false
      }
    });
    gsap.fromTo(".lnd_spt_ad4", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 3.1,
      scrollTrigger: {
        trigger: ".lnd_spt_ad4",
        markers: false
      }
    });
    gsap.fromTo(".lnd_spt_ad5", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 3.3,
      scrollTrigger: {
        trigger: ".lnd_spt_ad5",
        markers: false
      }
    });

  })

  return (
    <>
      <div className='landtransport_spotlight page_spotlight service_spotlight newspot_surface'>
        <div className='spotlight_container'>
          <div className='row'>
            <div className='col-12 pr_0'>
              <div className='spotlight_dv'>
                <div className='spot_contnt'>
                  <div className='spot_col1'>

                    <div className='hd_dv'>
                      <h1 className='spot_heading wht_txt lnd_spot_hd' ref={lnd_spot_hd}>Surface Transport</h1>
                    </div>


                    <p className='wht_txt offer_text lnd_spt_p' ref={lnd_spt_p}>FTL, PTL, LMD, 2-wheeler linehaul or liquid movement, our transportation portfolio has the right solution for you.</p>
                  </div>
                  <div className='spot_col2 infras_spot land_sec'>

                    <div class="dropdown">
                      <a href="#" class="js-link"><i class="fa fa-chevron-down"></i></a>
                      <ul class="warehouse_list">
                        <a className='lnd_spt_ad1' ref={lnd_spt_ad1} ><li className='service_listing lst_prnt'>A complete Range:</li> </a>
                        <ul class="asc_edge_spot_sub_ul">
                          <a className='lnd_spt_ad1 full_truck_link' ref={lnd_spt_ad1} href='/surface-transport#ftl'><li className='service_listing'>Full Truck Load</li> </a>
                          <a className='lnd_spt_ad2' ref={lnd_spt_ad2} href='/surface-transport#ptl'><li className='service_listing'>Part Truck Load</li> </a>
                          <a className='lnd_spt_ad3 last_mile_link' ref={lnd_spt_ad3} href='/surface-transport#last-mile-delivery'><li className='service_listing'>Last Mile Delivery
                          </li> </a>
                          <a className='lnd_spt_ad4' ref={lnd_spt_ad4} href='/surface-transport#the_prime'><li className='service_listing'>The Prime (2-wheeler linehaul)</li> </a>
                          <a className='lnd_spt_ad5' ref={lnd_spt_ad5} href='/surface-transport#liquid'><li className='service_listing'>Liquid</li> </a>
                          <li style={{display:'none'}}></li>
                        </ul>
                        <a className='lnd_spt_ad5' ref={lnd_spt_ad5} href='/surface-transport#technologies-for-your-transportation'><li className='service_listing'>The Technology Edge</li> </a>
                        <a className='lnd_spt_ad5' ref={lnd_spt_ad5} href='/surface-transport#growth-catalysts'><li className='service_listing'>Growth Catalysts</li> </a>

                      </ul>
                    </div>
                    {/* <a href='#' className='btn wht_btn'>
                      <span className='btn_txt'>Learn More</span>
                      <span className='arrow_btn wht_arrow'></span>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
