import React from 'react'
import '../../../assets/css/blog_featured.css';
import useFetch from "react-fetch-hook";

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Blog_featured() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const blog_ft_hd = useRef(null);
    const blog_ft_bx = useRef(null);

    useEffect(() => {

        gsap.fromTo(".blog_ft_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".blog_ft_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".blog_ft_bx", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".blog_ft_bx",
                markers: false
            }
        });

    })


    const { isLoading, data, error } = useFetch(
        "https://apollosupplychain.com/cms/wp-json/wp/v2/blogs?per_page=3"
    );


    return (
        <>
            <div className='blog_featured mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>
                    <div className='row'>
                        <div className='col-12 blg_feat_col'>
                            <div className='hd_dv' style={{margin:'auto'}}>
                                <h2 className='heading blog_ft_hd' ref={blog_ft_hd}>Featured</h2>
                            </div>

                        </div>

                        {data && data.map(({ id, title, acf, slug }) => (
                            <div className='col-lg-4 col-md-4 blog_ft_bx' ref={blog_ft_bx}>
                                <a href={"/media-center/blog/" + slug}>
                                    <div className='bf_col'>
                                        <div className='bf_prt_1'>
                                            <img src={acf.media_image}></img>
                                        </div>
                                        <div className='bf_prt_2'>
                                            <span className='bf_bx_tag'>{acf.type}</span>
                                            <h3 className='bf_bx_ttl'>{title.rendered}</h3>
                                            <p className='bf_bx_date'>{acf.date}</p>
                                            <img className='fb_arrow' src={require('../../../assets/img/svg/media_arrow.svg').default}></img>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                        {/* <div className='col-lg-4 col-md-4'>

                            <div className='bf_col'>
                                <a href='#'>
                                    <div className='bf_prt_1'>
                                        <img src={require('../../../assets/img/news_img1.jpg')}></img>
                                    </div>
                                    <div className='bf_prt_2'>
                                        <span className='bf_bx_tag'>Blogs</span>
                                        <h3 className='bf_bx_ttl'>Warehousing were evaluated for a smooth, stress-free import/export program</h3>
                                        <p className='bf_bx_date'>23 MAR 2022</p>
                                        <img className='fb_arrow' src={require('../../../assets/img/svg/media_arrow.svg').default}></img>
                                    </div>
                                </a>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        </>
    )
}
