import React from 'react'
import Header from "../../component/global/header";
import Automotive_spotlight from '../../component/spotlight/sector/automotive_spotlight'; 
import Service_sector_automo from '../../component/section/sector/automotive/service_sector_automo';
import Sector_info_automo from '../../component/section/sector/automotive/sector_info_automo';
import Case_study_automo from '../../component/section/sector/automotive/case_study_automo';
import Challange_automo from '../../component/section/sector/automotive/challange_automo';
import Solution_automo from '../../component/section/sector/automotive/solution_automo';
import Result_automo from '../../component/section/sector/automotive/result_automo';
import Connect_service from '../../component/section/services/connect_service';

import Footer from "../../component/global/footer";
// import CustomGlobe from "../CustomGlobe";

import Transition from '../../component/transition';
import { gsap } from "gsap";
import { useEffect , useRef } from 'react';
import { Power3, Power4 } from "gsap";





export default function Automotive_page() {

  let body = useRef(null);
  var tl_1 = gsap.timeline()

  useEffect(() => {

    // Page Transition Code

    tl_1.to(body,{
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut,
      delay: 1.2
    });
    return () => {
      tl_1.to(body, {
        opacity: "0",
        pointerEvents: 'none'
      });
    }

  })


  return (
    <>
      <Transition/>

      <div className='automotive_pg sector_pg'>
          <div ref={(el) => (body = el)} className="Headd">

            <Header/>
            <Automotive_spotlight/>
            <Sector_info_automo/>
            <Service_sector_automo/>
            
            <Case_study_automo/>
            <Challange_automo/>
            <Solution_automo/>
            <Result_automo/>
            <Connect_service/>
            <Footer/>
          </div>
      </div>
    </>
  )
}
