import React from 'react'
import '../../../../assets/css/challange_cstm.css';
import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Challange_cstm() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const ecom_rslt_hd = useRef(null);
    const ecom_rslt_p1 = useRef(null);
    const ecom_rslt_lst = useRef(null);

    useEffect(() => {

        gsap.fromTo(".ecom_rslt_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".ecom_rslt_hd",
                markers: false
            }
        });


        // Para animation code
        gsap.fromTo(".ecom_rslt_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ecom_rslt_p1",
                markers: false
            }
        });

        gsap.fromTo(".ecom_rslt_lst", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ecom_rslt_lst",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/ecom_sector_page`
    );


    return (
        <>
            <div className='challange_cstm  mob_padding_LR tab_padding_LR' >
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-12 ecom_chlng_hd_col'>

                                {acf.ecom_challenge.map((ecom_chal) =>
                                    <div className='hd_dv' style={{ margin: 'auto' }}>
                                        <h2 className='heading txt_cntr ecom_rslt_hd' ref={ecom_rslt_hd}>{ecom_chal.ecom_challenge_hd}</h2>
                                    </div>
                                )}
                            </div>

                            {/* {acf.ecom_challenge_list.map((ecom_chal1) =>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <ul className='global_list chalng_list'>
                                        <li className='ecom_rslt_lst' ref={ecom_rslt_lst} dangerouslySetInnerHTML={{ __html: ecom_chal1.ecom_challenge_list }}></li>
                                    </ul>
                                </div>
                            )} */}

                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className='chalng_box'>
                                    <div className='chalng_inner'>
                                        <div className='chalng_icon_dv'>
                                            <img src={require('../../../../assets/img/svg/chlng_icon.svg').default}></img>
                                        </div>
                                        <div className='chalng_txt_dv'>
                                            <p>A <b>low fill rate</b> impacting company’s competitiveness and profitability</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className='chalng_box'>
                                    <div className='chalng_inner'>
                                        <div className='chalng_icon_dv'>
                                            <img src={require('../../../../assets/img/svg/chlng_icon.svg').default}></img>
                                        </div>
                                        <div className='chalng_txt_dv'>
                                            <p>The primary transportation leg of the supply chain reported an <b>alarming empty run</b> rate of 50 percent.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className='chalng_box'>
                                    <div className='chalng_inner'>
                                        <div className='chalng_icon_dv'>
                                            <img src={require('../../../../assets/img/svg/chlng_icon.svg').default}></img>
                                        </div>
                                        <div className='chalng_txt_dv'>
                                            <p>A <b>low T+3 distribution rate</b>, which limited the company’s reach in rural markets, which had allowed their competitors to race ahead of them in several regions</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className='chalng_box chnlg_bx_1'>
                                    <div className='chalng_inner'>
                                        <div className='chalng_icon_dv'>
                                            <img src={require('../../../../assets/img/svg/chlng_icon.svg').default}></img>
                                        </div>
                                        <div className='chalng_txt_dv'>
                                            <p>A <b>high transportation</b> cost inflating the total cost of logistics</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-lg-4 col-md-6 col-12'>
                                <div className='chalng_box chnlg_bx_1'>
                                    <div className='chalng_inner'>
                                        <div className='chalng_icon_dv'>
                                            <img src={require('../../../../assets/img/svg/chlng_icon.svg').default}></img>
                                        </div>
                                        <div className='chalng_txt_dv'>
                                            <p>A <b>high attrition rate</b> impacting KPIs and customer satisfaction</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className='chalng_box chnlg_bx_1'>
                                    <div className='chalng_inner'>
                                        <div className='chalng_icon_dv'>
                                            <img src={require('../../../../assets/img/svg/chlng_icon.svg').default}></img>
                                        </div>
                                        <div className='chalng_txt_dv'>
                                            <p>A <b>high non-delivery percentage</b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            

                            

                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
