import React from 'react'
import '../../../assets/css/apllo_edge_tech.css';

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Apollo_edge_tech() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const tech_hd = useRef(null);
    const tech_p1 = useRef(null);
    const tech_img1 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".tech_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".tech_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".tech_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".tech_p1",
                markers: false
            }
        });

        gsap.fromTo(".tech_img1", {
            x: 0
        }, {
            x: 550,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".tech_img1",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/apollo_edge_page`
    );


    return (
        <>
            <div className='apollo_edge_tech  mob_padding_LR tab_padding_LR' id="technologies_for_you">

                {data && data.map(({ acf }) => (
                    <div className='cstm_container'>
                        <div className='row'>

                            {acf.technologies_for_you_section.map((edge_tech) =>
                                <div className='col-lg-7 col-md-12 col-12 ap_ed_col1'>

                                    <div className='hd_dv'>
                                        <h2 className='heading tech_hd' ref={tech_hd}>{edge_tech.technologies_hd}</h2>
                                    </div>

                                    <p className='apollo_tech_para tech_p1' ref={tech_p1}>{edge_tech.technologies_description}</p>
                                </div>
                            )}
                            {acf.technologies_for_you_section.map((edge_tech) =>
                                <div className='col-lg-5 col-md-12 col-12 '>

                                    <div className='img_dv'>
                                        <div className='img_oly tech_img1' ref={tech_img1}></div>
                                        <img className='tech_img ' src={edge_tech.technologies_image}></img>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
