import React from 'react'
import useFetch from "react-fetch-hook";
import $ from "jquery";

import { useEffect, useRef, useState } from 'react';

export default function News_tab() {

    useEffect(() => {
        $(function () {
            const rowsPerPage1 = 8;
            const rows1 = $('#news_row .event_bx');
            const rowsCount1 = rows1.length;
            const pageCount1 = Math.ceil(rowsCount1 / rowsPerPage1); // avoid decimals
            const numbers1 = $('#numbers1');

            // Generate the pagination.
            for (var i = 0; i < pageCount1; i++) {
                numbers1.append('<li><a href="#">' + (i + 1) + '</a></li>');
            }

            // Mark the first page link as active.
            $('#numbers1 li:first-child a').addClass('active');

            // Display the first set of rows.
            displayRows1(1);

            // On pagination click.
            $('#numbers1 li a').on('click', function (e) {
                var $this = $(this);

                e.preventDefault();

                // Remove the active class from the links.
                $('#numbers1 li a').removeClass('active');

                // Add the active class to the current link.
                $this.addClass('active');

                // Show the rows corresponding to the clicked page ID.
                displayRows1($this.text());
            });

            // Function that displays rows for a specific page.
            function displayRows1(index) {
                var start = (index - 1) * rowsPerPage1;
                var end = start + rowsPerPage1;

                // Hide all rows.
                rows1.hide();

                // Show the proper rows for this page.
                rows1.slice(start, end).show();
            }
        });
    })

    
    const { isLoading, data, error } = useFetch(
        "https://apollosupplychain.com/cms/wp-json/wp/v2/news/"
    );

    return (
        <>
            <div className='row' id='news_row'>

                {data && data.map(({ id, title, acf, slug }) => (


                    <div className='col-lg-3 col-md-4 col-12 event_bx' key={id} >
                        
                        
                        <a href={acf.url ? acf.url : "/media-center/event/" + slug}>

                        {/* <a href={acf.url} target='_blank'> */}

                            <div className='bf_col'>
                                <div className='bf_prt_1'>
                                    <img src={acf.media_image}></img>
                                </div>
                                <div className='bf_prt_2'>
                                    <span className='bf_bx_tag'>{acf.type}</span>
                                    <h3 dangerouslySetInnerHTML={{ __html: title.rendered }}  className='bf_bx_ttl' ></h3>
                                    <p className='bf_bx_date'>{acf.date}</p>
                                    <img className='fb_arrow' src={require('../../../assets/img/svg/media_arrow.svg').default}></img>
                                </div>
                            </div>
                        </a>
                    </div>


                ))}


                {/* <div className='col-12'>

                    <div className="pagination">
                        <ol id="numbers1"></ol>
                    </div>

                </div> */}


            </div>
        </>
    )
}
