import React from 'react'
import '../../../../assets/css/cfs_icd_infra.css'
import $ from "jquery";
import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";
import Mobile_cfs_accord from './mobile_cfs_accord';

export default function Cfs_icd_infra() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const cfs_hd = useRef(null);
    const cfs_p = useRef(null);
    const cfs_p1 = useRef(null);
    const cfs_p2 = useRef(null);
    const cfs_p3 = useRef(null);
    const cfs_p4 = useRef(null);


    useEffect(() => {

        gsap.fromTo(".cfs_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".cfs_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".cfs_p", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cfs_p",
                markers: false
            }
        });
        // Para animation code
        gsap.fromTo(".cfs_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.4,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cfs_p1",
                markers: false
            }
        });
        gsap.fromTo(".cfs_p2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.4,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cfs_p2",
                markers: false
            }
        });
        gsap.fromTo(".cfs_p3", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.4,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cfs_p3",
                markers: false
            }
        });
        gsap.fromTo(".cfs_p4", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.4,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".cfs_p4",
                markers: false
            }
        });



        $(".cfs_link").on('click', function () {
            var num = $('.tab-indicator').width();
            var slidenum = num * ($(this).attr("data-rel") - 1);

            $(".tab-indicator").css("left", slidenum);
        });

    })
    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/cfs_and_icd_page/`
    );

    return (
        <>
            <div className='cfs_icd_infra mob_padding_LR tab_padding_LR' id='cfs_icds'>

                <div className='desktop_cfs_tab'>
                    {data && data.map(({ acf }) => (
                        <div className='cstm_container'>
                            <div className='row'>
                                <div className='col-12'>

                                    <div className='hd_dv' style={{ margin: 'auto' }}>
                                        {acf.cfs_and_icd_tab_section.map((tab_cntn) =>
                                            <h2 className='heading wht_txt cfs_hd' ref={cfs_hd}>{tab_cntn.cfs_and_icd_hd}</h2>
                                        )}
                                    </div>

                                    <div className='cfs_tabs'>
                                        <nav>
                                            <div className="nav nav-tabs mb-3 cfs_row_tab cfs_p" id="nav-tab" role="tablist" ref={cfs_p}>

                                                <div class="tab-indicator"></div>

                                                {acf.cfs_panvel.map((pnvl) =>
                                                    <a className="nav-link active cfs_link cfs_p1" ref={cfs_p1} id="nav-Panvel-tab" data-toggle="tab" data-target="#nav-Panvel" type="button" role="tab" aria-controls="nav-Panvel" aria-selected="true" data-rel="1">
                                                        <div className='cfs_tab_div'>
                                                            <div className='cfs_icon_prt1'>
                                                                <img src={pnvl.cfs_tab_icon}></img>
                                                            </div>
                                                            <div className='cfs_icon_prt2'>
                                                                <h5 className='cfs_tb_ttl1'>{pnvl.cfs_tab_name}</h5>
                                                                <p className='cfs_state_nm'>{pnvl.cfs_tab_state}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )}

                                                {acf.cfs_chennai.map((cheni) =>
                                                    <a className="nav-link cfs_link cfs_p2" ref={cfs_p2} id="nav-Chennai-tab" data-toggle="tab" data-target="#nav-Chennai" type="button" role="tab" aria-controls="nav-Chennai" aria-selected="true" data-rel="2">
                                                        <div className='cfs_tab_div'>
                                                            <div className='cfs_icon_prt1'>
                                                                <img src={cheni.cfs_tab_icon}></img>
                                                            </div>
                                                            <div className='cfs_icon_prt2'>
                                                                <h5 className='cfs_tb_ttl1'>{cheni.cfs_tab_name}</h5>
                                                                <p className='cfs_state_nm'>{cheni.cfs_tab_state}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )}

                                                {acf.cfs_thoothukudi.map((thoot) =>
                                                    <a className="nav-link cfs_link cfs_p3" ref={cfs_p3} id="nav-Thoothukudi-tab" data-toggle="tab" data-target="#nav-Thoothukudi" type="button" role="tab" aria-controls="nav-Thoothukudi" aria-selected="true" data-rel="3">
                                                        <div className='cfs_tab_div'>
                                                            <div className='cfs_icon_prt1'>
                                                                <img src={thoot.cfs_tab_icon}></img>
                                                            </div>
                                                            <div className='cfs_icon_prt2'>
                                                                <h5 className='cfs_tb_ttl1'>{thoot.cfs_tab_name}</h5>
                                                                <p className='cfs_state_nm'>{thoot.cfs_tab_state}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )}

                                                {/* {acf.icd_kashipur.map((kashi) =>
                                                    <a className="nav-link cfs_link cfs_p4" ref={cfs_p4} id="nav-Kashipur-tab" data-toggle="tab" data-target="#nav-Kashipur" type="button" role="tab" aria-controls="nav-Kashipur" aria-selected="true" data-rel="4">
                                                        <div className='cfs_tab_div'>
                                                            <div className='cfs_icon_prt1'>
                                                                <img src={kashi.cfs_tab_icon}></img>
                                                            </div>
                                                            <div className='cfs_icon_prt2'>
                                                                <h5 className='cfs_tb_ttl1'>{kashi.icd_tab_name}</h5>
                                                                <p className='cfs_state_nm'>{kashi.icd_tab_state}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )} */}
                                            </div>
                                        </nav>
                                        <div className="tab-content cfs-tab-cntnt cfs_p" id="nav-tabContent" ref={cfs_p}>


                                            {acf.cfs_panvel.map((pnvl) =>
                                                <div className="tab-pane fade active show" id="nav-Panvel" role="tabpanel" aria-labelledby="nav-Panvel-tab">
                                                    <div className='cfs_content_dv'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <p className='cfs_ctnt_hd'>{pnvl.cfs_tab_description}</p>
                                                            </div>
                                                            <div className='col-lg-6 col-md-6 col-12'>
                                                                <h3 className='cfs_list_hd'>{pnvl.cfs_tab_highlight_hd}</h3>
                                                                <ul className='global_list cfs_ul_list'>

                                                                    {pnvl.cfs_tab_highlight_list.map((hg_lst) =>
                                                                        <li>{hg_lst.cfs_tab_hl_data}</li>
                                                                    )}

                                                                </ul>
                                                            </div>
                                                            <div className='col-lg-6 col-md-6 col-12'>
                                                                <div className='cfs_col2'>
                                                                    <div className='cfs_img_dv'>
                                                                        <img src={pnvl.cfs_tab_image}></img>
                                                                    </div>
                                                                    <div className='csf_img_txt'>
                                                                        <h5 className='csf_img_txt_hd'>{pnvl.cfs_tab_image_hd}</h5>
                                                                        <p>{pnvl.cfs_tab_image_description}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row cfs_box_section'>
                                                            <div className='col-lg-4 col-md-4 col-12'>
                                                                <div className='result_box ' >
                                                                    <div className='rslt_inner'>
                                                                        <img src={require('../../../../assets/img/svg/import.svg').default}></img>
                                                                        <p className='rslt_ttl'>Import</p>
                                                                        <p class="rslt_bx_p">See the document here</p>
                                                                        <a href='https://apollosupplychain.com/cms/wp-content/uploads/2023/03/Tariffs_Imports_Pune.pdf' className='pdf_link' target='_blank'>Click here</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 col-12'>
                                                                <div className='result_box '>
                                                                    <div className='rslt_inner'>
                                                                        <img src={require('../../../../assets/img/svg/Export.svg').default}></img>
                                                                        <p className='rslt_ttl'>Export</p>
                                                                        <p class="rslt_bx_p">See the document here</p>
                                                                        <a href='https://apollosupplychain.com/cms/wp-content/uploads/2023/03/Tariffs_Expor_Pune.pdf' className='pdf_link' target='_blank'>Click here</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 col-12'>
                                                                <div className='result_box' >
                                                                    <div className='rslt_inner'>
                                                                        <img src={require('../../../../assets/img/svg/General.svg').default}></img>
                                                                        <p className='rslt_ttl'>General</p>
                                                                        <p class="rslt_bx_p">See the document here</p>
                                                                        <a href='https://apollosupplychain.com/cms/wp-content/uploads/2023/03/Tariffs_General_Pune.pdf' className='pdf_link' target='_blank'>Click here</a>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            )}

                                            {acf.cfs_chennai.map((cheni) =>
                                                <div className="tab-pane fade" id="nav-Chennai" role="tabpanel" aria-labelledby="nav-Chennai-tab">
                                                    <div className='cfs_content_dv'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <p className='cfs_ctnt_hd'>{cheni.cfs_tab_description}</p>
                                                            </div>
                                                            <div className='col-lg-6 col-md-6 col-12'>
                                                                <h3 className='cfs_list_hd'>{cheni.cfs_tab_highlight_hd}</h3>
                                                                <ul className='global_list cfs_ul_list'>
                                                                    {cheni.cfs_tab_highlight_list.map((hg_lst) =>
                                                                        <li>{hg_lst.cfs_tab_hl_data}</li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                            <div className='col-lg-6 col-md-6 col-12'>
                                                                <div className='cfs_col2'>
                                                                    <div className='cfs_img_dv'>
                                                                        <img src={cheni.cfs_tab_image}></img>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row cfs_box_section'>

                                                            <div className='col-lg-2 col-md-2 col-12'></div>
                                                            <div className='col-lg-4 col-md-4 col-12'>
                                                                <div className='result_box ' >
                                                                    <div className='rslt_inner'>
                                                                        <img src={require('../../../../assets/img/svg/import.svg').default}></img>
                                                                        <p className='rslt_ttl'>Import</p>
                                                                        <p class="rslt_bx_p">See the document here</p>
                                                                        <a href='https://apollosupplychain.com/cms/wp-content/uploads/2023/03/Import-Gen-Tariff-chennai.pdf' className='pdf_link' target='_blank'>Click here</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 col-12'>
                                                                <div className='result_box '>
                                                                    <div className='rslt_inner'>
                                                                        <img src={require('../../../../assets/img/svg/Export.svg').default}></img>
                                                                        <p className='rslt_ttl'>Export</p>
                                                                        <p class="rslt_bx_p">See the document here</p>
                                                                        <a href='https://apollosupplychain.com/cms/wp-content/uploads/2023/03/Export-General-Tariff-chennai.pdf' className='pdf_link' target='_blank'>Click here</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-2 col-md-2 col-12'></div>

                                                        </div>

                                                    </div>
                                                </div>
                                            )}

                                            {acf.cfs_thoothukudi.map((thoot) =>
                                                <div className="tab-pane fade" id="nav-Thoothukudi" role="tabpanel" aria-labelledby="nav-Thoothukudi-tab">
                                                    <div className='cfs_content_dv'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <p className='cfs_ctnt_hd'>{thoot.cfs_tab_description}</p>
                                                            </div>
                                                            <div className='col-lg-6 col-md-6 col-12'>
                                                                <h3 className='cfs_list_hd'>{thoot.cfs_tab_highlight_hd}</h3>
                                                                <ul className='global_list cfs_ul_list'>
                                                                    {thoot.cfs_tab_highlight_list.map((hg_lst) =>
                                                                        <li>{hg_lst.cfs_tab_hl_data}</li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                            <div className='col-lg-6 col-md-6 col-12'>
                                                                <div className='cfs_col2'>
                                                                    <div className='cfs_img_dv'>
                                                                        <img src={thoot.cfs_tab_image}></img>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row cfs_box_section'>

                                                            <div className='col-lg-2 col-md-2 col-12'></div>
                                                            <div className='col-lg-4 col-md-4 col-12'>
                                                                <div className='result_box ' >
                                                                    <div className='rslt_inner'>
                                                                        <img src={require('../../../../assets/img/svg/import.svg').default}></img>
                                                                        <p className='rslt_ttl'>Import</p>
                                                                        <p class="rslt_bx_p">See the document here</p>
                                                                        <a href='https://apollosupplychain.com/cms/wp-content/uploads/2023/03/Tariffs_Import_Thoothukudi.pdf' className='pdf_link' target='_blank'>Click here</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 col-12'>
                                                                <div className='result_box '>
                                                                    <div className='rslt_inner'>
                                                                        <img src={require('../../../../assets/img/svg/Export.svg').default}></img>
                                                                        <p className='rslt_ttl'>Export</p>
                                                                        <p class="rslt_bx_p">See the document here</p>
                                                                        <a href='https://apollosupplychain.com/cms/wp-content/uploads/2023/03/Tariffs_Export_Thoothukudi.pdf' className='pdf_link' target='_blank'>Click here</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-2 col-md-2 col-12'></div>

                                                        </div>


                                                    </div>
                                                </div>
                                            )}

                                            {/* {acf.icd_kashipur.map((kashi) =>
                                                <div className="tab-pane fade" id="nav-Kashipur" role="tabpanel" aria-labelledby="nav-Kashipur-tab">
                                                    <div className='cfs_content_dv'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <p className='cfs_ctnt_hd'>{kashi.icd_tab_description}</p>
                                                            </div>
                                                            <div className='col-lg-6 col-md-6 col-12'>
                                                                <h3 className='cfs_list_hd'>{kashi.icd_tab_highlight_hd}</h3>
                                                                <ul className='global_list cfs_ul_list'>
                                                                    {kashi.icd_tab_highlight_list.map((hg_lst) =>
                                                                        <li>{hg_lst.cfs_tab_hl_data}</li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                            <div className='col-lg-6 col-md-6 col-12'>
                                                                <div className='cfs_col2'>
                                                                    <div className='cfs_img_dv'>
                                                                        <img src={kashi.icd_tab_image}></img>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )} */}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className='mobile_cfs_accord'>
                    <Mobile_cfs_accord/>
                </div>

                                 
                        
              

            </div>
        </>
    )
}
