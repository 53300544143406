import React from 'react'
import Header from "../../component/global/header";
import Cstmr_durable_spotlight from '../../component/spotlight/sector/cstmr_durable_spotlight'; 
import Sector_info_cstm from '../../component/section/sector/customer_durables/sector_info_cstm';
import Case_study_cstm from '../../component/section/sector/customer_durables/case_study_cstm';
import Challange_cstm from '../../component/section/sector/customer_durables/challange_cstm';
import Solution_cstm from '../../component/section/sector/customer_durables/solution_cstm';
import Result_cstm from '../../component/section/sector/customer_durables/result_cstm';
import Connect_service from '../../component/section/services/connect_service';

import Footer from "../../component/global/footer";
// import CustomGlobe from "../CustomGlobe";

import Transition from '../../component/transition';
import { gsap } from "gsap";
import { useEffect , useRef } from 'react';
import { Power3, Power4 } from "gsap";
import Sector_customer from '../../component/section/sector/customer_durables/sector_customer';
import Service_sector from '../../component/section/sector/customer_durables/service_sector_cstm';












export default function Cstmr_durables_page() {

  let body = useRef(null);
  var tl_1 = gsap.timeline()

  useEffect(() => {

    // Page Transition Code

    tl_1.to(body,{
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut,
      delay: 1.2
    });
    return () => {
      tl_1.to(body, {
        opacity: "0",
        pointerEvents: 'none'
      });
    }

  })


  return (
    <>
      <Transition/>

      <div className='consumer_pg sector_pg'>
          <div ref={(el) => (body = el)} className="Headd">

            <Header/>
            <Cstmr_durable_spotlight/>
            <Sector_info_cstm/>
            <Service_sector/>
            
            <Case_study_cstm/>
            <Challange_cstm/>
            <Solution_cstm/>
            <Result_cstm/>
            {/* <Sector_customer/> */}
            <Connect_service/>
            <Footer/>
          </div>
      </div>
    </>
  )
}
