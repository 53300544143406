import React from 'react'
import Header from "../../component/global/header";
import Ecom_spotlight from '../../component/spotlight/sector/ecom_spotlight'; 
import Service_sector_ecom from '../../component/section/sector/e_commerce/service_sector_ecom';
import Sector_info_ecom from '../../component/section/sector/e_commerce/sector_info_ecom';
import Case_study_ecom from '../../component/section/sector/e_commerce/case_study_ecom';
import Challange_ecom from '../../component/section/sector/e_commerce/challange_ecom';
import Solution_ecom from '../../component/section/sector/e_commerce/solution_ecom';
import Result_ecom from '../../component/section/sector/e_commerce/result_ecom';
import Connect_service from '../../component/section/services/connect_service';

import Footer from "../../component/global/footer";
// import CustomGlobe from "../CustomGlobe";

import Transition from '../../component/transition';
import { gsap } from "gsap";
import { useEffect , useRef } from 'react';
import { Power3, Power4 } from "gsap";
import $ from 'jquery';

export default function Cstmr_durables_page() {

  let body = useRef(null);
  var tl_1 = gsap.timeline()

  useEffect(() => {

    // Page Transition Code

    tl_1.to(body,{
      opacity: "1",
      pointerEvents: "auto",
      ease: Power4.easeInOut,
      delay: 1.2
    });
    return () => {
      tl_1.to(body, {
        opacity: "0",
        pointerEvents: 'none'
      });
    }

   
  })


  return (
    <>
      <Transition/>

      <div className='e_com_pg sector_pg'>
          <div ref={(el) => (body = el)} className="Headd">

            <Header/>
            <Ecom_spotlight/>
            <Sector_info_ecom/>
            <Service_sector_ecom/>
            
            <Case_study_ecom/>
            <Challange_ecom/>
            <Solution_ecom/>
            <Result_ecom/>
            <Connect_service/>
            <Footer/>
          </div>
      </div>
    </>
  )
}
