import React from 'react'
import '../../../../assets/css/solution_cstm.css';

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Solution_cstm() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const ecom_sol_hd = useRef(null);
    const ecom_sol_p1 = useRef(null);
    const ecom_sol_p2 = useRef(null);
    const ecom_sol_p3 = useRef(null);
    const ecom_sol_p4 = useRef(null);
    const ecom_sol_img1 = useRef(null);


    useEffect(() => {

        gsap.fromTo(".ecom_sol_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".ecom_sol_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".ecom_sol_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.4,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ecom_sol_p1",
                markers: false
            }
        });
        gsap.fromTo(".ecom_sol_p2", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.4,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ecom_sol_p2",
                markers: false
            }
        });
        gsap.fromTo(".ecom_sol_p3", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.4,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ecom_sol_p3",
                markers: false
            }
        });
        gsap.fromTo(".ecom_sol_p4", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.4,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ecom_sol_p4",
                markers: false
            }
        });

        gsap.fromTo(".ecom_sol_img1", {
            opacity: 0
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".ecom_sol_img1",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/ecom_sector_page`
    );


    return (
        <>
            <div className='ecommerce_solution sol_automo solution_cstm mob_padding_LR tab_padding_LR' >
                <div className='cstm_container'>

                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-lg-12 col-12'>

                                {acf.ecom_solution_sec.map((ecom_solu) =>
                                    <div className='hd_dv' style={{ margin: 'auto' }}>
                                        <h1 className='heading wht_txt txt_cntr ecom_sol_hd' ref={ecom_sol_hd}>{ecom_solu.ecom_solution_hd}</h1>
                                    </div>
                                )}

                                {/* <div className='solution_cstm_dv'>
                                    <img className='sol_line_img ecom_sol_img1' ref={ecom_sol_img1} src={require('../../../../assets/img/svg/solution_line.svg').default}></img>

                                    {acf.ecom_solution_list_sec.map((ecom_solu1) =>
                                        <div className='sol_bx ecom_sol_p1' ref={ecom_sol_p1}>
                                            <p>{ecom_solu1.ecom_solution_list}</p>
                                        </div>
                                    )}

                                </div> */}


                                <div className='solution_cstm_dv'>

                                    <div className='row'>

                                        

                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Happy Drivers. Improved Delivery</p>
                                                        <p>We helped the company initiate performance-linked incentive pay-outs to drivers which led to a higher delivery percentage.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Reduced Empty Runs</p>
                                                        <p>We helped the company implement a more efficient route planning system to help reduce the percentage of empty runs in the primary transportation leg.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Faster Go-To-Market</p>
                                                        <p>We relocated a warehouse closer to the consumption point which, in turn, enhanced faster GTM strategy.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Moving from 2-day delivery model to same day delivery</p>
                                                        <p>We enabled last-mile delivery planning to improve vehicle visibility and utilization.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Real-time visibility on order delivery</p>
                                                        <p>We provided technology solution for track and trace, TAT (Turn Around Time) monitoring and vehicle/ driver performance.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-12'>
                                            <div className='solution_box'>
                                                <div className='solution_inner'>
                                                    <div className='solution_icon_dv'>
                                                        <img src={require('../../../../assets/img/svg/solution_icon.svg').default}></img>
                                                    </div>
                                                    <div className='solution_txt_dv'>
                                                        <p className='solution_bx_hd'>Increased Sales Efforts for the Customer</p>
                                                        <p>We deployed our fleets to increase penetration into the rural markets through on-ground campaigns and in turn using the consumer insights and our rural market reach to drive planning visibility and last mile operations. We educated the consumers about increased cost savings on bulk orders and efficient usage of the customer’s mobile application at the time of deliveries.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                 

                                    </div>

                                </div>


                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
