import React from 'react'
import '../../../assets/css/inside_page_spotlight.css'
import '../../../assets/css/sector_spotlight.css';

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Cstmr_durable_spotlight() {

  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const ecom_spot_hd = useRef(null);

  useEffect(() => {

    gsap.fromTo(".ecom_spot_hd", {
      y: 250,
      opacity: 0,
      skewY: 41,
    }, {
      delay: 1.2,
      duration: 0.8,
      y: 0,
      skewY: 0,
      opacity: 1,
      ease: "power4.out",
      stagger: {
        from: 'start',
        amount: 0.5,
      },
      scrollTrigger: {
        trigger: ".ecom_spot_hd",
        markers: false
      }
    });

  })

  const { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/ecom_sector_page`
  );

  return (
    <>
      <div className='sector_spotlight ecommerce_spotlight page_spotlight'>
        <div className='spotlight_container'>
          <div className='row'>
            <div className='col-12 pr_0'>
              <div className='spotlight_dv'>

                {data && data.map(({ acf }) => (
                  <div className='spot_contnt'>
                    <div className='spot_col1'>

                      {acf.ecom_spotlight.map((ecom_sub1) =>
                        <div className='hd_dv'>
                          <h1 className='spot_heading wht_txt ecom_spot_hd' ref={ecom_spot_hd}>{ecom_sub1.ecom_spot_hd}</h1>
                        </div>
                      )}

                    </div>
                    <div className='spot_col2' style={{ visibility: 'hidden' }}>
                      <p className='wht_txt'>Our countrywide network of warehouses provides a wide range of services including dedicated and multi-user facilities.</p>
                      <a href='#' className='btn wht_btn'>
                        <span className='btn_txt'>Learn More</span>
                        <span className='arrow_btn wht_arrow'></span>
                      </a>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
