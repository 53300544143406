import React from 'react'
import Header from "../component/global/header";
import Leadership_spotlight from '../component/spotlight/leadership_spotlight';
import Leadership_sec from '../component/section/leadership/leadership_sec';
import Footer from "../component/global/footer";

import Transition from '../component/transition';
import { gsap } from "gsap";
import { useEffect, useRef } from 'react';
import { Power3, Power4 } from "gsap";


export default function Leadership_page() {

    let body = useRef(null);
    var tl_1 = gsap.timeline()

    useEffect(() => {

        // Page Transition Code

        tl_1.to(body, {
            opacity: "1",
            pointerEvents: "auto",
            ease: Power4.easeInOut,
            delay: 1.2
        });
        return () => {
            tl_1.to(body, {
                opacity: "0",
                pointerEvents: 'none'
            });
        }

    })

    return (
        <>
            <Transition />
            <div className='leader_pg'>
                <div ref={(el) => (body = el)} className="Headd">

                    <Header />
                    <Leadership_spotlight />
                    <Leadership_sec />
                    <Footer />

                </div>
            </div>
        </>
    )
}
