import React from 'react'
import '../../assets/css/footer.css';
import Newsletter from '../global/newsletter';


export default function Footer() {
  return (
    <>
      <Newsletter />
      <div className='footer_sec mob_padding_LR tab_padding_LR'>
        <div className='cstm_container'>
          <div className='row'>

            <div className='col-lg-3 col-md-12 col-12'>
              <div className='ftr_col1'>
                <a className=" asc_div" href="/">
                  <img className='asc_logo_footer' src={require('../../assets/img/svg/apollo_icon.svg').default}></img>
                </a>
                <ul className='social_media'>
                  {/* <li><a href='#'><img src={require('../../assets/img/svg/youtube_icon.svg').default}></img></a></li> */}
                  {/* <li><a href='#'><img src={require('../../assets/img/svg/insta_icon.svg').default}></img></a></li> */}
                  <li><a href='https://www.linkedin.com/company/apollo-logisolutions-ltd/' target='_blank'><img src={require('../../assets/img/svg/link_icon.svg').default}></img></a></li>
                </ul>
                <p className='cp_txt right_txt desktop_link'>2022 Apollo Supply Chain. All rights reserved.</p>
              </div>
            </div>

            <div className='col-lg-9 col-md-12 col-12'>
              <div className='row'>
                
                <div className='col-lg-4 col-md-4 col-12'>
                  <div className='ftr_col2'>
                      <h4 className='ftr_col_hd'>Quick links</h4>
                      <ul className='ftr_link_ul'>
                        <li className='ftr_link_li'><a href='/'>Home</a></li>
                        <li className='ftr_link_li'><a href='/about-us'>About Us</a></li>
                        <li className='ftr_link_li'><a href='/#apollo-edge'>The Edge</a></li>
                        <li className='ftr_link_li'><a href='/blog'>Blog</a></li>
                        <li className='ftr_link_li'><a href='/news_and_events'>News And Events</a></li>
                        <li className='ftr_link_li'><a href='/careers/life-at-ASC'>Life At Apollo Supply Chain</a></li>
                        <li className='ftr_link_li'><a href='/contact'>Contact Us</a></li>
                      </ul>
                  </div>
                </div>

                <div className='col-lg-4 col-md-4 col-12 servc_col'>
                  <div className='ftr_col3'>
                      <h4 className='ftr_col_hd'>Services</h4>
                      <ul className='ftr_link_ul'>
                        <li className='ftr_link_li'><a href='/warehousing'>Warehousing</a></li>
                        <li className='ftr_link_li'><a href='/surface-transport'>Surface Transport</a></li>
                        <li className='ftr_link_li'><a href='/freight-forwarding'>Freight Forwarding</a></li>
                        <li className='ftr_link_li'><a href='/CFS'>CFS (Infra)</a></li>
                        <li className='ftr_link_li'><a href='/supply-chain-consulting'>Supply Chain Consulting</a></li>
                        <li className='ftr_link_li'><a href='/control-tower-&-analytics-center'>Control Tower & Analytics Center</a></li>
                      </ul>
                  </div>
                </div>

                <div className='col-lg-4 col-md-4 col-12'>
                  <div className='ftr_col4'>
                      <h4 className='ftr_col_hd'>Sectors</h4>
                      <ul className='ftr_link_ul'>
                        <li className='ftr_link_li'><a href='/sector/consumer-durables-high-tech-industries'>Consumer Durables, High-Tech Industries</a></li>
                        <li className='ftr_link_li'><a href='/sector/automotive-&-industrial-sector'>Automotive & Industrial Sector</a></li>
                        <li className='ftr_link_li'><a href='/sector/e-commerce-&-FMCG'>E-Commerce & FMCG</a></li>
                        <li className='ftr_link_li'><a href='/sector/pharmaceuticals'>Pharmaceuticals</a></li>
                        <li className='ftr_link_li'><a href='/sector/lifestyle-&-retail'>Lifestyle & Retail </a></li>
                        
                      </ul>
                  </div>
                </div>

                <div className='copyri8_dv col-12'>
                  <div className='ftr_col5'>
                    <h4 className='cp_txt ln_1'><a href='/privacy-policy'>Privacy Policy</a>  &nbsp; | &nbsp; <a href='/disclaimer'>Disclaimer</a></h4> 
                    <h4 className='cp_link ln_2'>Website designed by <a href='https://www.parashifttech.com/' target='_blank'>PARASHIFT</a></h4>
                    <h4 className='cp_txt right_txt mob_link'>2022 Apollo Supply Chain. All rights reserved.</h4>  
                  </div>
                </div>

              </div>
            </div>


          </div>
        </div>
      </div>
    </>
  )
}
