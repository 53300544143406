import React from 'react'
import '../../../assets/css/media.css';
import { useParams } from "react-router-dom";
import useFetch from "react-fetch-hook";

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";

export default function Event_inside_sec() {

  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const event_in_sec_p1 = useRef(null);
  const event_in_sec_img1 = useRef(null);

  useEffect(() => {

    // Para animation code
    gsap.fromTo(".event_in_sec_p1", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 1,
      ease: "linear",
      delay: 2,
      scrollTrigger: {
        trigger: ".event_in_sec_p1",
        markers: false
      }
    });

    gsap.fromTo(".event_in_sec_img1", {
      x: 0
    }, {
      xPercent: 100,
      duration: 0.5,
      ease: "linear",
      delay: 2,
      scrollTrigger: {
        trigger: ".event_in_sec_img1",
        markers: false
      }
    });

    
  })

  let { slug } = useParams();

  const { isLoading, data, error } = useFetch(
    `https://apollosupplychain.com/cms/wp-json/wp/v2/news/?slug=${slug}`
  );

  const pg_url = document.URL;

  return (
    <>
      <div className='media_div  mob_padding_LR tab_padding_LR'>
        <div className='cstm_container'>
          <div className='row media_row'>

            <div className='col-lg-1 col-md-1 col-1'>

              <div className='media_col1 event_in_sec_p1' ref={event_in_sec_p1}>

                <FacebookShareButton url={pg_url} >
                  <FacebookIcon size={30} round={true}></FacebookIcon>
                </FacebookShareButton>
                
                <LinkedinShareButton url={pg_url} >
                  <LinkedinIcon size={30} round={true}></LinkedinIcon>
                </LinkedinShareButton>

                <TwitterShareButton url={pg_url} >
                  <TwitterIcon size={30} round={true}></TwitterIcon>
                </TwitterShareButton>

              </div>

            </div>

            <div className='col-lg-11 col-md-11 col-11'>

              {data && data.map(({ id, title, acf, content }) => (
                <div className='media_col2'>


                  <div className='img_dv'>
                    <div className='img_oly event_in_sec_img1' ref={event_in_sec_img1}></div>
                    <img className='media_hd_img' src={acf.media_image}></img>
                  </div>

                  <div dangerouslySetInnerHTML={{ __html: content.rendered }} className='event_in_sec_p1' ref={event_in_sec_p1}></div>

                  {/* <div className='media_ctn_box'>
                    <h3 className='media_ttl'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi laoreet magna ut odio blandit posuere. Nulla pretium risus quam, at ultricies nisl posuere sed. Morbi tempus pellentesque felis, sed tempus orci. Praesent tortor purus, lacinia eu porta vel, interdum vel elit. Nam sed odio eu nisl molestie sagittis. Phasellus convallis, nibh nec vehicula molestie, elit eros facilisis diam, ut tempus mi felis ac massa. Vivamus id tristique nisi, blandit eleifend tellus. Morbi sit amet ante nunc. Ut consectetur lobortis pharetra.</p>
                  </div> */}

                </div>
              ))}

            </div>

          </div>
        </div>
      </div>
    </>
  )
}
