import React from 'react'
import '../../../../assets/css/service_offer_ware.css';

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Custom_brokerage() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const brok_hd = useRef(null);
    const brok_p1 = useRef(null);
    const brok_img1 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".brok_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".brok_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".brok_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".brok_p1",
                markers: false
            }
        });

        gsap.fromTo(".brok_img1", {
            x: 0
        }, {
            xPercent: 101,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".brok_img1",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/cfs_and_icd_page/`
    );

    return (
        <>
            <div className='simulation_offer SO_ware mob_padding_LR tab_padding_LR' id='custom_brokerage'>
            {data && data.map(({ acf }) => (
                <div className='cstm_container'>

                    <div className='row'>
                        
                            <div className='col-lg-7 col-md-6 col-12'>
                                <div className='so_col1_multi'>
                                    {acf.custom_brokerage_section.map((brokrg_cntn) =>
                                        <div> 
                                            <div className='hd_dv'>                                    
                                                <h1 className='heading wht_txt brok_hd' ref={brok_hd}>{brokrg_cntn.custom_brokerage_hd}</h1>                                    
                                            </div>

                                            <p className='so_col_desc custom_subhead brok_p1' ref={brok_p1}> {brokrg_cntn.custom_brokerage_sub_hd} </p>
                                        </div>
                                     )}


                                    {acf.custom_brokerage_section.map((brokrg_cntn) =>
                                        <div className='brok_p1' dangerouslySetInnerHTML={{ __html: brokrg_cntn.custom_brokerage_description }} ref={brok_p1}>                                         
                                        </div>
                                    )}
                                </div>
                            </div>
                       
                        <div className='col-lg-5 col-md-6 col-12'>
                            <div className='so_col1'>
                                <div className='img_dv'>
                                    <div className='img_oly grd_blue_oly brok_img1' ref={brok_img1}></div>
                                    <img className='so_col_img_simulation' src={require('../../../../assets/img/services/infrastructure-for-you/custom-brokerage/custom_brokerage.jpg')}></img>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
               ))}
            </div>
        </>
    )
}
