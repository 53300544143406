import React from 'react'
import '../../../../assets/css/sector_info.css';

import { useEffect, useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useFetch from "react-fetch-hook";

export default function Sector_info_cstm() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const auto_info_p1 = useRef(null);

    useEffect(() => {


        // Para animation code
        gsap.fromTo(".auto_info_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 1.2,
            scrollTrigger: {
                trigger: ".auto_info_p1",
                markers: false
            }
        });

    })

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/automotive_sec_page`
    );


    return (
        <>
            <div className='sector_info mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>
                    {data && data.map(({ acf }) => (
                        <div className='row'>
                            <div className='col-12'>

                                {acf.automotive_info.map((auto_sub2) =>
                                    <p className='sec_info_para auto_info_p1' ref={auto_info_p1}>{auto_sub2.automotive__description1}</p>
                                )}
                                {acf.automotive_info.map((auto_sub2) =>
                                    <p className='sec_info_para auto_info_p1' ref={auto_info_p1}>{auto_sub2.automotive__description2}</p>
                                )}
                                
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
