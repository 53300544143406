import React from 'react'
import $ from "jquery";

import '../../../../assets/css/info_ware.css';
import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Supply_chain() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const supc_p = useRef(null);
    const supc_p1 = useRef(null);

    useEffect(() => {


        if ($(window).width() <= 1380) {
            // Para animation code
            gsap.fromTo(".supc_p", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".supc_p",
                    markers: false
                }
            });
        }
        else {

            // Para animation code
            gsap.fromTo(".supc_p", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 2.5,
                scrollTrigger: {
                    trigger: ".supc_p",
                    markers: false
                }
            });
        }


        // Para animation code
        gsap.fromTo(".supc_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.8,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".supc_p1",
                markers: false
            }
        });

        $.fn.isInViewport = function () {
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight() / 2;
            var viewportTop = $(window).scrollTop();
            var viewportHalf = viewportTop + $(window).height() / 1;
            return elementBottom > viewportTop && elementTop < viewportHalf;
        };


        $(window).on("scroll", function () {

            $(".img_scroll_dv").each(function () {
                var windowTop = $(window).scrollTop();
                var elementTop = $(this).offset().top;
                var leftPosition = windowTop - elementTop;
                $(this)
                    .find(".inside_dv_img")
                    .css({ right: leftPosition });
            });

        });

    });

    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/supply_chain_page/`
    );

    return (
        <>
            <div className='info_ware_sec '>
             {data && data.map(({ acf }) => (
                <div className='row m_0'>

                    <div className='cstm_container col-md-12 col-12 p_0 mob_padding_LR tab_padding_LR'>
                        <div className='col-lg-12 p_0'>
                            <div className='info_ware_col1'>
                              {acf.supply_chain_description_1.map((supply_des) =>
                                <p className='info_para_cust supc_p' ref={supc_p}>{supply_des.supply_description}</p>
                              )}
                            </div>                             
                        </div>
                    </div>

                    <div className='col-12 p_0'>
                        <div className='img_scroll_dv'>
                            <div className='inside_dv_img'>

                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image4.jpg')}></img>

                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image4.jpg')}></img>

                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image4.jpg')}></img>


                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image4.jpg')}></img>

                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image4.jpg')}></img>

                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image1.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image2.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image3.jpg')}></img>
                                <img className='scroll_img' src={require('../../../../assets/img/services/supply-chain-consulting-services/Consulting_Image4.jpg')}></img>
                                
                            </div>
                        </div>
                    </div>

                    <div className='cstm_container mob_padding_LR tab_padding_LR'>
                        <div className='row'>

                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='info_ware_col1'>
                                    {acf.supply_chain_description_2.map((supply_des2) =>
                                        <p className='ware_para supc_p1' ref={supc_p1}>{supply_des2.supply_chain_description_2_left}</p>
                                    )}
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='info_ware_col1'>
                                    {acf.supply_chain_description_2.map((supply_des3) =>
                                        <p className='ware_para supc_p1' ref={supc_p1}>{supply_des3.supply_chain_description_2_right}</p>
                                    )}

                                </div>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-lg-2 col-md-2 col-12'></div>
                            <div className='col-lg-8 col-md-8 col-12'>
                                <div className='info_ware_col1'>
                                    {acf.supply_chain_heading.map((supply_hd) =>
                                        <p className='supply_control supc_p1' ref={supc_p1}>{supply_hd.supply_chain_bottom_hd}</p>
                                    )}
                                </div>
                            </div>

                            <div className='col-lg-2 col-md-2 col-12'></div>

                        </div>
                    </div>



                </div>
             ))}
            </div>
        </>
    )
}
