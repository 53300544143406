import React from 'react'
import '../../../assets/css/blog_featured.css';
import '../../../assets/css/event_sec.css';

import useFetch from "react-fetch-hook";



export default function Press_rel_sec() {

    const { isLoading, data, error } = useFetch(
        "https://apollosupplychain.com/cms/wp-json/wp/v2/press_release/"
    );

    return (
        <>
            <div className='event_sec blog_featured mob_padding_LR tab_padding_LR'>
                <div className='cstm_container'>
                    <div className='row'>

                        {data && data.map(({ id, title, acf, slug }) => (

                            <div className='col-lg-3 col-md-3'>
                                <a href={"/media-center/press-release/" + slug}>
                                <div className='bf_col'>
                                    <div className='bf_prt_1'>
                                        <img src={acf.media_image}></img>
                                    </div>
                                    <div className='bf_prt_2'>
                                        <span className='bf_bx_tag'>Press Release</span>
                                        <h3 className='bf_bx_ttl'>{title.rendered}</h3>
                                        <p className='bf_bx_date'>23 MAR 2022</p>
                                        <img className='fb_arrow' src={require('../../../assets/img/svg/media_arrow.svg').default}></img>
                                    </div>
                                </div>
                                </a>
                            </div>

                        ))}
                        <div className='col-lg-3 col-md-3'>
                            <div className='bf_col'>
                                <div className='bf_prt_1'>
                                    <img src={require('../../../assets/img/news_img1.jpg')}></img>
                                </div>
                                <div className='bf_prt_2'>
                                    <span className='bf_bx_tag'>Blogs</span>
                                    <h3 className='bf_bx_ttl'>Warehousing were evaluated for a smooth, stress-free import/export program</h3>
                                    <p className='bf_bx_date'>23 MAR 2022</p>
                                    <img className='fb_arrow' src={require('../../../assets/img/svg/media_arrow.svg').default}></img>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3'>
                            <div className='bf_col'>
                                <div className='bf_prt_1'>
                                    <img src={require('../../../assets/img/news_img2.jpg')}></img>
                                </div>
                                <div className='bf_prt_2'>
                                    <span className='bf_bx_tag'>Blogs</span>
                                    <h3 className='bf_bx_ttl'>Warehousing were evaluated for a smooth, stress-free import/export program</h3>
                                    <p className='bf_bx_date'>23 MAR 2022</p>
                                    <img className='fb_arrow' src={require('../../../assets/img/svg/media_arrow.svg').default}></img>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3'>
                            <div className='bf_col'>
                                <div className='bf_prt_1'>
                                    <img src={require('../../../assets/img/news_img3.jpg')}></img>
                                </div>
                                <div className='bf_prt_2'>
                                    <span className='bf_bx_tag'>Blogs</span>
                                    <h3 className='bf_bx_ttl'>Warehousing were evaluated for a smooth, stress-free import/export program</h3>
                                    <p className='bf_bx_date'>23 MAR 2022</p>
                                    <img className='fb_arrow' src={require('../../../assets/img/svg/media_arrow.svg').default}></img>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-3'>
                            <div className='bf_col'>
                                <div className='bf_prt_1'>
                                    <img src={require('../../../assets/img/news_img1.jpg')}></img>
                                </div>
                                <div className='bf_prt_2'>
                                    <span className='bf_bx_tag'>Blogs</span>
                                    <h3 className='bf_bx_ttl'>Warehousing were evaluated for a smooth, stress-free import/export program</h3>
                                    <p className='bf_bx_date'>23 MAR 2022</p>
                                    <img className='fb_arrow' src={require('../../../assets/img/svg/media_arrow.svg').default}></img>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3'>
                            <div className='bf_col'>
                                <div className='bf_prt_1'>
                                    <img src={require('../../../assets/img/news_img2.jpg')}></img>
                                </div>
                                <div className='bf_prt_2'>
                                    <span className='bf_bx_tag'>Blogs</span>
                                    <h3 className='bf_bx_ttl'>Warehousing were evaluated for a smooth, stress-free import/export program</h3>
                                    <p className='bf_bx_date'>23 MAR 2022</p>
                                    <img className='fb_arrow' src={require('../../../assets/img/svg/media_arrow.svg').default}></img>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3'>
                            <div className='bf_col'>
                                <div className='bf_prt_1'>
                                    <img src={require('../../../assets/img/news_img3.jpg')}></img>
                                </div>
                                <div className='bf_prt_2'>
                                    <span className='bf_bx_tag'>Blogs</span>
                                    <h3 className='bf_bx_ttl'>Warehousing were evaluated for a smooth, stress-free import/export program</h3>
                                    <p className='bf_bx_date'>23 MAR 2022</p>
                                    <img className='fb_arrow' src={require('../../../assets/img/svg/media_arrow.svg').default}></img>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3'>
                            <div className='bf_col'>
                                <div className='bf_prt_1'>
                                    <img src={require('../../../assets/img/news_img1.jpg')}></img>
                                </div>
                                <div className='bf_prt_2'>
                                    <span className='bf_bx_tag'>Blogs</span>
                                    <h3 className='bf_bx_ttl'>Warehousing were evaluated for a smooth, stress-free import/export program</h3>
                                    <p className='bf_bx_date'>23 MAR 2022</p>
                                    <img className='fb_arrow' src={require('../../../assets/img/svg/media_arrow.svg').default}></img>
                                </div>
                            </div>
                        </div>



                    </div>

                </div>
            </div>
        </>
    )
}
