import React from 'react'
import '../../../../assets/css/case_study.css';

import { useEffect, useRef } from 'react';
import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Service_sector_pharma() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const cstm_Cs_p1 = useRef(null);
    const cstm_Cs_img1 = useRef(null);

    useEffect(() => {

        if ($(window).width() <= 1440) {
            // Para animation code
            gsap.fromTo(".cstm_Cs_p1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_p1",
                    markers: false
                }
            });

            gsap.fromTo(".cstm_Cs_img1", {
                x: 0
            }, {
                x: 550,
                duration: 0.5,
                ease: "linear",
                delay: 0.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_img1",
                    markers: false
                }
            });
        }
        else {
            // Para animation code
            gsap.fromTo(".cstm_Cs_p1", {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.8,
                ease: "linear",
                delay: 1.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_p1",
                    markers: false
                }
            });

            gsap.fromTo(".cstm_Cs_img1", {
                x: 0
            }, {
                x: 550,
                duration: 0.5,
                ease: "linear",
                delay: 1.2,
                scrollTrigger: {
                    trigger: ".cstm_Cs_img1",
                    markers: false
                }
            });

        }

    })


    return (
    <>

        {/*---- Warehousing  Service ----*/}
        <div className='servc_sec_white SO_ware mob_padding_LR tab_padding_LR'>
            <div className='cstm_container'>

                <div className='row'>

                    <div className='col-lg-5 col-md-12 col-12'>
                        <div className='so_col1_optimizatio'>

                            <div className='img_dv'>
                                <img className='so_col_img_multi' src={require('../../../../assets/img/pharma_warehouse.jpeg')}></img>
                            </div>

                        </div>
                    </div>


                    <div className='col-lg-7 col-md-12 col-12 sector_srvc_col2 '>
                
                        <div className='so_col1_multi truck_main'>

                            <div className='hd_dv'>
                                <h2 className='heading'>Warehousing</h2>
                            </div>

                            <ul class="global_list srvc_sec">
                                <li class="wht_txt">Temperature Controlled Warehousing</li>
                                <li class="wht_txt">ISO 9001 & 45001 Certified facilities </li>
                                <li class="wht_txt">FDA, FSSAI compliant</li>
                                <li class="wht_txt">Best-in-class tech solutions for customized reporting, accuracy, real-time visibility, inventory control and shell-life management</li>
                                <li class="wht_txt">Receipt & physical verification of materials</li>
                                <li class="wht_txt">Line Management, Line Feeding, Sequencing</li>
                                <li class="wht_txt">Active inventory tracking</li>
                                <li class="wht_txt">First Expired, First Out (FEFO); First-in, First-out (FIFO) </li>
                            </ul>

                        </div>
                        
                    </div>


                </div>

            </div>
        </div>

        {/*---- Transportation Service ----*/}
        <div className='servc_sec_blue SO_ware mob_padding_LR tab_padding_LR'>
            <div className='cstm_container'>

                <div className='row'>

                    <div className='col-lg-7 col-md-12 col-12'>
                    
                        <div className='so_col1_multi'>

                            <div className='hd_dv'>
                                <h2 className='heading wht_txt'>Transportation</h2>
                            </div>

                        
                            <ul class="global_list ">
                                <li class="wht_txt">Surface & Air Transportation </li>
                                <li class="wht_txt">Primary & Secondary Transportation</li>
                                <li class="wht_txt">LTL/ PTL</li>
                                <li class="wht_txt">Milk Run Deliveries, Last-Mile Delivery</li>
                                <li class="wht_txt">Cold chain management with real-time monitoring and control </li>
                                <li class="wht_txt">Control Tower for real-time visibility and decision making support to management</li>
                            </ul>

                        </div>
                        
                    </div>

                    <div className='col-lg-5 col-md-12 col-12'>
                        <div className='so_col1_optimizatio'>

                            <div className='img_dv'>
                                <img className='so_col_img_multi' src={require('../../../../assets/img/sectors/other_sector/pharma_transport.jpg')}></img>
                            </div>

                        </div>
                    </div>
                    

                </div>

            </div>
        </div>

        {/*---- Freight Forwarding & Customs Management  Service ----*/}
        <div className='servc_sec_white SO_ware mob_padding_LR tab_padding_LR'>
            <div className='cstm_container'>

                <div className='row'>

                    <div className='col-lg-5 col-md-12 col-12'>
                        <div className='so_col1_optimizatio'>

                            <div className='img_dv'>
                                <img className='so_col_img_multi' src={require('../../../../assets/img/sectors/other_sector/pharma_freight.jpg')}></img>
                            </div>

                        </div>
                    </div>


                    <div className='col-lg-7 col-md-12 col-12  '>
                
                        <div className='so_col1_multi truck_main'>

                            <div className='hd_dv'>
                                <h2 className='heading'>Freight Forwarding & Customs Management</h2>
                            </div>

                            <ul class="global_list srvc_sec">
                                <li class="wht_txt">Air & Ocean Freight</li>
                                <li class="wht_txt">Customs Brokerage operations</li>
                                <li class="wht_txt">Advance License & EPCG License</li>
                                <li class="wht_txt">License Liasoning with DGFT</li>
                            </ul>

                        </div>
                        
                    </div>


                </div>

            </div>
        </div>

    </>
  )
}
