import React from 'react'
import '../../assets/css/inside_page_spotlight.css'

import { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from 'jquery'; 
export default function About_spotlight() {

  // gloabl line GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Heading text animation variable
  const abt_spot_hd = useRef(null);
  const abt_spt_ad1 = useRef(null);
  const abt_spt_ad2 = useRef(null);
  const abt_spt_ad3 = useRef(null);
  const abt_spt_ad4 = useRef(null);
  const abt_spt_ad5 = useRef(null);

  useEffect(() => {

    gsap.fromTo(".abt_spot_hd", {
      y: 250,
      opacity: 0,
      skewY: 41,
    }, {
      delay: 2,
      duration: 0.8,
      y: 0,
      skewY: 0,
      opacity: 1,
      ease: "power4.out",
      stagger: {
        from: 'start',
        amount: 0.5,
      },
      scrollTrigger: {
        trigger: ".abt_spot_hd",
        markers: false
      }
    });

    // Para animation code
    gsap.fromTo(".abt_spt_ad1", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.5,
      scrollTrigger: {
        trigger: ".abt_spt_ad1",
        markers: false
      }
    });
    gsap.fromTo(".abt_spt_ad2", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.6,
      scrollTrigger: {
        trigger: ".abt_spt_ad2",
        markers: false
      }
    });
    gsap.fromTo(".abt_spt_ad3", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.7,
      scrollTrigger: {
        trigger: ".abt_spt_ad3",
        markers: false
      }
    });
    gsap.fromTo(".abt_spt_ad4", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.8,
      scrollTrigger: {
        trigger: ".abt_spt_ad4",
        markers: false
      }
    });
    gsap.fromTo(".abt_spt_ad5", {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.3,
      ease: "linear",
      delay: 2.9,
      scrollTrigger: {
        trigger: ".abt_spt_ad5",
        markers: false
      }
    });

    let anchorSelector = 'a[href^="#"]';
    $(anchorSelector).on('click', function (e) {
      e.preventDefault();
      let destination = $(this.hash);
      let scrollPosition
        = destination.offset().top;
      let animationDuration = 500;
      $('html, body').animate({
        scrollTop: scrollPosition
      }, animationDuration);
    });


  })

  return (
    <>
      <div className='about_spotlight page_spotlight'>
        <div className='spotlight_container'>
          <div className='row'>
            <div className='col-12 pr_0'>
              <div className='spotlight_dv'>
                <div className='spot_contnt'>
                  <div className='spot_col1'>

                    <div className='hd_dv'>
                      <h1 className='spot_heading wht_txt abt_spot_hd' ref={abt_spot_hd}>About</h1>
                    </div>
                    <div className='hd_dv'>
                      <h1 className='spot_heading wht_txt abt_spot_hd' ref={abt_spot_hd}>apollo Supply Chain</h1>
                    </div>

                  </div>
                  <div className='spot_col2 about_hyp'>
                    {/* <p className='wht_txt'>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.</p> */}
                    {/* <a href='#' className='btn wht_btn'>
                      <span className='btn_txt'>Learn More</span>
                      <span className='arrow_btn wht_arrow'></span>
                    </a> */}
                    <div class="dropdown">
                      <a href="#" class="js-link"><i class="fa fa-chevron-down"></i></a>
                      <ul class="warehouse_list">
                        <a href='/about-us#company-profile' className='abt_spt_ad1' ref={abt_spt_ad1}><li className='service_listing'>Company Profile</li> </a>
                        <a href='/about-us#purpose' className='abt_spt_ad2' ref={abt_spt_ad2}><li className='service_listing'>Purpose and Values</li> </a>
                        {/* <a href='/about-us#growth-journey' className='abt_spt_ad3' ref={abt_spt_ad2}><li className='service_listing'>Growth Journey</li> </a> */}
                        <a href='/about-us#esg' className='abt_spt_ad5' ref={abt_spt_ad5}><li className='service_listing'>ESG</li> </a>


                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
