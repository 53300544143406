import React from 'react'
import '../../../../assets/css/service_offer_ware.css';

import { useEffect, useRef } from 'react';
import $ from 'jquery';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "react-fetch-hook";

export default function Simulation_design() {

    // gloabl line GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Heading text animation variable
    const simu_hd = useRef(null);
    const simu_p1 = useRef(null);
    const simu_img1 = useRef(null);

    useEffect(() => {

        gsap.fromTo(".simu_hd", {
            y: 250,
            opacity: 0,
            skewY: 41,
        }, {
            delay: 0.2,
            duration: 0.8,
            y: 0,
            skewY: 0,
            opacity: 1,
            ease: "power4.out",
            stagger: {
                from: 'start',
                amount: 0.5,
            },
            scrollTrigger: {
                trigger: ".simu_hd",
                markers: false
            }
        });

        // Para animation code
        gsap.fromTo(".simu_p1", {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".simu_p1",
                markers: false
            }
        });

        gsap.fromTo(".simu_img1", {
            x: 0
        }, {
            xPercent: 101,
            duration: 0.5,
            ease: "linear",
            delay: 0.2,
            scrollTrigger: {
                trigger: ".simu_img1",
                markers: false
            }
        });
        $(".simulatn_content").hide();
        $(".simulatn").on("click", function () {
            var txt = $(".simulatn_content").is(':visible') ? 'Read More' : 'Read Less';
            $(".simulatn").text(txt);
            $('.simulatn_content').slideToggle(200);
        }); // for Para
        
        $(".simulatn_link").on("click", function () {
            var txt = $(".simulatn_content").is(':visible') ? 'Read Less' : 'Read Less';
            $(".simulatn").text(txt);
            $('.simulatn_content').slideDown(200);
        }); // for Spotlight link

    })
    const { isLoading, data, error } = useFetch(
        `https://apollosupplychain.com/cms/wp-json/wp/v2/supply_chain_page/`
    );

    return (
        <>
            <div className='simulation_offer SO_ware mob_padding_LR tab_padding_LR' id='warehouse_design_simulation'>

            {data && data.map(({ acf }) => (
                <div className='cstm_container'>

                    <div className='row'>
                        <div className='col-lg-7 col-md-6 col-12'>
                            {acf.simulation_section.map((simu_cntn) =>
                                <div className='so_col1_multi'>

                                    <div className='hd_dv'>
                                        <h2 className='heading wht_txt simulation_head simu_hd' ref={simu_hd}>{simu_cntn.simulation_hd}</h2>
                                    </div>

                                    <p className='so_col_desc simulation_subpara simu_p1' ref={simu_p1}>{simu_cntn.simulation_description1}</p>
                                    <div className=' simu_p1' ref={simu_p1} dangerouslySetInnerHTML={{ __html: simu_cntn.simulation_description2 }} ></div>
                                    {/* <a className="simulatn simu_p1 read_more_btn wht_txt" data-content="toggle-text" ref={simu_p1}>Read More</a> */}
                                </div>
                            )}
                        </div>
                        <div className='col-lg-5 col-md-6 col-12'>
                            <div className='so_col1'>
                                <div className='img_dv'>
                                    <div className='img_oly grd_blue_oly simu_img1' ref={simu_img1}></div>
                                    {acf.simulation_section.map((simu_cntn) =>
                                    <img className='' src={simu_cntn.simulation_image}></img>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                ))}
            </div>
        </>
    )
}
